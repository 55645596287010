import { useContext } from "react";
import { ReferentialContext } from 'shared/stores/Referential';
import _ from 'lodash';

export function useCountryPartitioning() {
    const [referentialContext] = useContext(ReferentialContext);
    const accountTree = referentialContext["accountTree"];
    const countries = [];

    const watchsiteMapper = (watchsite) => {
        if (!_.find(countries, (c) => c.id === watchsite.country.id)) {
            countries.push(watchsite.country);
        }        
        return watchsite;
    };
    const nodeMapper = (node) => {
        let children = node.childs.map(nodeMapper);
        children = children.concat(node.watchsites.map(watchsiteMapper));
        return {...node, ...{"children": children}};
    };

    accountTree.nodes.map(nodeMapper);
    accountTree.watchsites.map(watchsiteMapper);

    return [countries];
}