import { React, Link, toast, DefaultLayout, useContext, useEffect, useState } from 'commons';
import { useTranslation } from 'react-i18next';
import { useAccount } from 'hooks/Account';
import FavoriteButtonComponent from 'components/FavoriteButtonComponent';
import StartRestrictionMessageComponent from 'components/StartRestrictionMessageComponent';
import { HOTLINE_STATUS_DRAFT, getHotlineStatus, getHotlineStatusColor, getHotlineStatusLabel } from 'shared/data';
import { HotlineApi } from 'shared/api';
import { ListComponent, FilterComponent, LoadButton, StatusComponent, TreeReferentialComponent } from 'shared/components';
import { ReferentialContext } from 'shared/stores';
import { FileUtils, convertCriteriesToSearchQuery, denyIfCantPerform, canPerform, toggleArrayValue, preventDefault, prefixLinkTo } from 'shared/services';
import { useList } from 'shared/hooks';

export default function HotlineList(props) {

    const { t } = useTranslation();
    const [account] = useAccount();

    denyIfCantPerform(props, "hotline:list", {account: account});

    const columns = [
        {id: 'title', title: t("Titre")},
        {id: 'created_at', title: t("Date de la question"), format: "date"},
        {id: 'answered_at', title: t("Date de la réponse"), format: "date"},
        {id: 'created_by', title: t("Auteur"), format: "user"},
        {id: 'categories', title: t("Domaines / sous domaines"), render: (row, params) =>
            <TreeReferentialComponent className="arborescence" items={params.tree} value={row.categories.map(e => e.id)} />
        },
        {id: 'status', title: t("Statut"), render: (row) => {
            const status = row.status;
            return (
                <StatusComponent 
                    value={getHotlineStatusLabel(status)}
                    color={getHotlineStatusColor(status)}
                />
            );
        }},
        {id: 'actions', title: '', sortable: false, render: (row) => 
            <ul className="actions">
                {row.status === HOTLINE_STATUS_DRAFT && canPerform("hotline:write", {account: account, hotline: row}) && <li>
                    <Link title={t("Modifier la question")} to={prefixLinkTo() + `/hotlines/${row.id}`}>
                        <i className="icon-actions-modifier" aria-hidden="true"></i>
                    </Link>
                </li>}
                {(row.status !== HOTLINE_STATUS_DRAFT || !canPerform("hotline:write", {account: account, hotline: row})) && <li>
                    <Link title={t("Consulter la question")} to={prefixLinkTo() + `/hotlines/${row.id}`}>
                        <i className="icon-actions-consulter-fiche" aria-hidden="true"></i>
                    </Link>
                </li>}                
                <li>
                    <FavoriteButtonComponent item={row.id} type="hotline" isFavorite={row.favorite} />
                </li>
            </ul>
        }
    ];

    let availableKeywordFields = [
        {value: 'title', label: 'Titre'},
        {value: 'question', label: 'Question'},
        {value: 'answer', label: 'Réponse'},
    ];

    const [referentialContext] = useContext(ReferentialContext);
    const domainTree = referentialContext["restrictedTree"];
    const [exporting, setExporting] = useState(false);

    const [
        rows,
        totalrows,
        criterias,
        sorting,
        direction,
        limit,
        page,,,
        addCriteria,
        updateSorting,
        updateLimit,
        updatePage,
        submitSearch,
        loading,,
        refresh
    ] = useList("hotline-list", HotlineApi, null, "created_at", {keyword_fields: ['title', 'question', 'answer']}, "DESC");

    let filterType = {
        categories: {type: "terms", fields: ["categories"]},
        keyword: {type: "wildcard", fields: criterias.keyword_fields},
        status: {type: "terms", fields: ["status"]},
        favorite: {type: "match", fields: ["favorite"], cast: "int"},
    };

    useEffect(() => {
        submitSearch(filterType)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function exportHotlines() {
        setExporting(true);
        const exportQuery = convertCriteriesToSearchQuery(criterias, filterType);
        const fileName = FileUtils.getFilePrefix() + "-Hotlines.xlsx";
        HotlineApi
            .export(exportQuery, fileName)
            .then(responseData => {
                setExporting(false);
                toast.success(t("L'export a été initié, vous serez notifié lorsqu'il sera disponible."));
            })
            .catch(() => {
                setExporting(false);
                toast.error(t("Erreur durant la création de l'export."))
            });
    }

    return (
        <DefaultLayout screen="E42" title={t("Liste hotline")}>
            <h1>{t("Liste hotline")}</h1>
            <section>
            <div className="bloc">
                <form className="form" onSubmit={(e) => preventDefault(e, submitSearch(filterType))} >
                    <section className="filters">
                        <header>
                            <h2><button className="toggler" type="button">{t("Recherche")}</button></h2>
                        </header>
                        <div className="bg-gris-25">
                            <div className="row">
                                <FilterComponent
                                    label={t("Domaines / Sous domaines")}
                                    type="dropdown-tree-select"
                                    name="categories"
                                    onChange={value => addCriteria("categories", value)} 
                                    value={criterias.categories}
                                    data={domainTree}
                                    mode="hierarchical"
                                />
                                <FilterComponent 
                                    type="keyword"
                                    name="keyword"
                                    label={t("Recherche libre")}
                                    fields={availableKeywordFields}
                                    selectedFields={criterias.keyword_fields}
                                    onChange={value => addCriteria("keyword", value)}
                                    onFieldChange={value => addCriteria("keyword_fields", toggleArrayValue(criterias.keyword_fields, value))}
                                    value={criterias.keyword || ""} 
                                />
                                <FilterComponent
                                    type="buttons"
                                    name="status"
                                    onChange={v => addCriteria("status", v)}
                                    value={criterias.status}
                                    label={t("Statut")}
                                    options={getHotlineStatus()}
                                    multiple
                                />
                                <FilterComponent
                                    type="radio"
                                    name="favorite"
                                    label={t("Favoris")}
                                    blankLabel="Tous"
                                    options={[{value: "1", label: t("Oui")}, {value: "0", label: t("Non")}]}
                                    value={criterias.favorite}
                                    onChange={v => addCriteria("favorite", v)}
                                />
                            </div>
                        </div>
                        <div className="bg-gris-25 border-b border-gris-60">
                            <div className="row">
                                <div className="col-md-9">
                                    <button id="clearfilter-user" onClick={() => refresh(true)} type="button" className="btn btn-bleu-4 icon"><i className="icon-filtres-poubelle" aria-hidden="true"></i>Réinitialiser la recherche</button>
                                </div>
                                <div className="col-md-3 text-right">                                    
                                    <LoadButton id="search-hotline" type="submit" label={t("Rechercher")} loading={loading} />
                                </div>
                            </div>
                        </div>
                    </section>
                </form>

                <form className="form">
                    <ListComponent
                        id="hotline"
                        loading={loading}
                        selectable={false}
                        rows={rows}
                        columns={columns}
                        sorting={sorting}
                        direction={direction}
                        onSortingChange={updateSorting}
                        perpage={limit}
                        onPerpageChange={updateLimit}
                        page={page}
                        onPageChange={updatePage}
                        totalrows={totalrows}
                        columnsRenderParams={{
                            tree: domainTree
                        }}
                        globalActions={(
                            <>
                                {account.is_start === false && canPerform("hotline:create", {account: account}) && <Link id="new-hotline" to={prefixLinkTo() + "/hotlines/new"} className="btn btn-primary h25 icon">
                                    <i className="icon-boutons-ajouter-creer-affecter" aria-hidden="true"></i>&nbsp;Nouvelle question
                                </Link>}
                                <LoadButton
                                    onClick={exportHotlines}
                                    loading={exporting}
                                    iconClass="icon-file-excel"
                                    className="btn btn-primary h25 icon"
                                    label={t("Exporter")}
                                    id="export-hotline"
                                />
                            </>
                        )}
                    />
                </form>
            </div>
            <StartRestrictionMessageComponent />
            </section>
        </DefaultLayout>
    );
}