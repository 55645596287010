import AbstractApi from 'shared/api/Abstract';
import ApiRequest from 'shared/services/ApiRequest';
import FileApi from 'shared/api/File';

const PREFIX = "sheetsaffected";

const SheetAffectedApi = {

    get: function(id) {
        return AbstractApi.get(PREFIX, id);
    },

    getLastComment: function(id) {
        return ApiRequest.get(PREFIX + "/" + id + "/lastcomment").then(response => response.data);
    },

    search: function(query, from, size, sorting, direction) {
        return AbstractApi.search(PREFIX + "/searches", query, from, size, sorting, direction);
    },

    searchIndicators: function(query) {
        return ApiRequest.post(PREFIX + "/searchesindicators", {query: query}).then(response => response.data);
    },

    count: function(query) {
        return AbstractApi.count(PREFIX + "/searches/count", query);
    },

    getAffectations: function(sheet) {
        return ApiRequest.get(PREFIX + "/" + sheet + "/affectations").then(response => response.data);
    },

    bulkAffect: function (data) {
        return ApiRequest.post(PREFIX + "/bulk/affect", data);
    },
    
    save: function(data) {
        return AbstractApi.save(PREFIX, data);
    },

    duplicate: function(data) {
        return ApiRequest.post(PREFIX + "/duplications", data);
    },

    expressProcessing(data) {
        return ApiRequest.patch(PREFIX + "/expressprocessing", data);
    },

    export: function(query, mode, fileName) {
        return AbstractApi.export(PREFIX, query, mode, fileName);
    },

    uploadAttachments: function(files) {
        return FileApi.upload(PREFIX + "/upload/attachments", files);
    },

    exportPdf: function(id) {
        return ApiRequest
            .post(PREFIX + "/exportpdf/" + id, {}, {responseType: 'blob'})
            .then(response => response.data);
    },

    bulkUpdate: function(ids, data) {
        return ApiRequest.patch(
            PREFIX + "/bulk/update",
            {
                "ids": ids,
                "data": data
            }
        ).then(response => response.data.message);
    },
    
    inlineUpdate: function(affectations) {
        return ApiRequest
            .put(
                PREFIX,
                {
                    "affectations": affectations,
                }
            ).then(response => response.data.message);
    },

    /* Comments */

    saveComment: function(sheetAffected, data) {
        return AbstractApi.save(PREFIX + "/" + sheetAffected + "/comment", data);
    },

    deleteComment: function(sheetAffected, id) {
        return AbstractApi.delete(PREFIX + "/" + sheetAffected + "/comment", id); 
    },

    addToDatabase: function(sheet, watchsite) {
        return ApiRequest.post(PREFIX + "/databases", {sheet: sheet, watchsite: watchsite}).then(response => response.data);
    }
}
export default SheetAffectedApi;