import { React, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DefaultLayout from "layouts/DefaultLayout";
import ExportSpecificList from 'shared/views/export/SpecificList';
import ExportApi from 'shared/api/Export';

export default function ExportList() {

    const { t } = useTranslation();
    const [totalExports, setTotalExports] = useState(0);

    useEffect(() => {
        ExportApi.getTotalAmount().then(data => {setTotalExports(data.amount)});
        // eslint-disable-next-line
    } , []);

    return (
        <DefaultLayout title={t("Mes exports")}>
            <h1>{t("Mes exports")}</h1>
            {totalExports > 0 && <>
                {process.env.REACT_APP_CONTEXT === 'frontoffice' && <>
                    <ExportSpecificList type="sheetaffected" title={t("Export de fiches affectées")} />
                    <ExportSpecificList type="requirementaffected" title={t("Export des exigences affectées")} />
                </>}
                <ExportSpecificList type="hotline" title={t("Export hotline")} />
                {process.env.REACT_APP_CONTEXT === 'backoffice' && <>
                    <ExportSpecificList type="sheet" title={t("Export recherche avancée")} />
                    <ExportSpecificList type="dashboard" title={t("Export tableau de bord")} />
                    <ExportSpecificList type="connectionlogs" title={t("Exports d'historique de connexions")} />
                    <ExportSpecificList type="sheetaffected" title={t("Fond documentaire")} />
                </>}
                <ExportSpecificList type="obligationgenerator" title="Export obligations réglementaires" />
            </>}
            {totalExports === 0 &&
                <span>Aucun export disponible</span>
            }
        </DefaultLayout>
    );
}