import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from "react-router-dom";
import { useAccount } from 'hooks/Account';
import { useAccess } from 'hooks/Access';
import { useCountryPartitioning } from 'hooks/CountryPartitioning';
import { Can, canPerform, prefixLinkTo } from "shared/services";
import DocumentarySpaceLinkApi from 'api/DocumentarySpaceLink';

export default function Menu(props) {
    const { t } = useTranslation();
    const [account] = useAccount();
    const [countries] = useCountryPartitioning();
    const [,, writableWatchsites, readableWatchsites] = useAccess();
    const canPerformAccountConfiguration = canPerform("account:write") || canPerform("category:list", {account: account});
    const canPerformUserConfiguration = canPerform("user:list") || canPerform("tree:read");
    const [link, setLink] = useState();
    const [exportedFiles, setExportedFiles] = useState(localStorage.getItem("exportReady") ? localStorage.getItem("exportReady") : 0);

    setInterval(() => setExportedFiles(localStorage.getItem("exportReady") ? localStorage.getItem("exportReady") : 0), 1000);

    if (link === undefined) {
        DocumentarySpaceLinkApi.link().then(res => setLink(res.link));
    }

    function toggleUserConfiguration(event) {
        event.preventDefault();
        document.getElementById("user-configuration").classList.toggle("act");
        if (document.getElementById("user-configuration").classList.contains("act")
            && !document.body.classList.contains("main-nav-opened")
        ) {
            document.body.classList.toggle("main-nav-opened");
        }
        return false;
    }

    function toggleAccountConfiguration(event) {
        event.preventDefault();
        document.getElementById("account-parameters").classList.toggle("act");
        if (document.getElementById("account-parameters").classList.contains("act")
            && !document.body.classList.contains("main-nav-opened")
        ) {
            document.body.classList.toggle("main-nav-opened");
        }
        return false;
    }

    return (
        <nav className="main-nav">
            <ul>
                {!props.isLogged && <>
                    <li>
                        <a id="menu-login" href="/" title={t("Retour à Pilot-Veille")}>
                            <i className="icon-nav-accueil" aria-hidden="true"></i>&nbsp;{t("Retour à Pilot-Veille")}
                        </a>
                    </li>
                </>}
                {props.isLogged && <>
                    <li>
                        <NavLink id="menu-login" to={prefixLinkTo() + "/"} title={t("Accueil")}>
                            <i className="icon-nav-accueil" aria-hidden="true"></i>&nbsp;{t("Accueil")}
                        </NavLink>
                    </li>
                    <Can perform="sheetaffected:list" data={{account: account}} yes={() => (
                        <li>
                            <NavLink id="menu-sheets-affected" to={prefixLinkTo() + "/sheets-affected"} title={t("Ma veille")}>
                                <i className="icon-nav-ma-veille" aria-hidden="true"></i>&nbsp;{t("Ma veille")}
                            </NavLink>
                        </li>)} />
                    {account.is_start === false &&
                        <Can perform="requirementaffected:list" data={{account: account}} yes={() => (
                            <li>
                                <NavLink id="menu-sheets-affected" to={prefixLinkTo() + "/requirements-affected"} title={t("Exigences")}>
                                    <i className="icon-nav-arbo-point-veille" aria-hidden="true"></i>&nbsp;{t("Exigences")}
                                </NavLink>
                            </li>)} />
                    }
                    <Can perform="action:list" data={{account: account, writableWatchsites: writableWatchsites, readableWatchsites: readableWatchsites}} yes={() => (
                        <li>
                            <NavLink id="menu-homepage" to={prefixLinkTo() + "/actions"} title={t("Plan d'action")}>
                                <i className="icon-wrench" aria-hidden="true"></i>&nbsp;{t("Plan d'action")}
                            </NavLink>
                        </li>)} />
                    <Can perform="hotline:list" data={{account: account}} yes={() => (
                        <li>
                            <NavLink id="menu-hotline" to={prefixLinkTo() + "/hotlines"} title={t("Hotline")}>
                                <i className="icon-nav-ma-hotline" aria-hidden="true"></i>&nbsp;{t("Hotline")}
                            </NavLink>
                        </li>)} />
                    <Can perform="statistics:read" data={{account: account, writableWatchsites: writableWatchsites, readableWatchsites: readableWatchsites}} yes={() => (
                        <li>
                            <NavLink id="menu-statistics" to={prefixLinkTo() + "/statistics"} title={t("Statistiques")}>
                                <i className="icon-chart-bar" aria-hidden="true"></i>&nbsp;{t("Statistiques")}
                            </NavLink>
                        </li>)} />
                    <Can perform="sheet:list" data={{account: account, writableWatchsites: writableWatchsites, readableWatchsites: readableWatchsites}} yes={() => (
                        <li>
                            <NavLink id="menu-sheets" to={prefixLinkTo() + "/sheets"} title={t("Rechercher un texte")}>
                                <i className="icon-nav-recherche-texte" aria-hidden="true"></i>&nbsp;{t("Rechercher un texte")}
                            </NavLink>
                        </li>)} />                   
                    <Can perform="documentaryspace:list" data={{account: account, countries: countries}} yes={() => (
                        <>
                            <li>
                                <NavLink id="menu-documentary_space" to="/documentary-space" title={t("Espace documentaire")}>
                                    <i className="icon-nav-espace-documentaire" aria-hidden="true"></i>&nbsp;{t("Espace documentaire")}
                                </NavLink>
                            </li>
                        </>
                    )} />
                    <Can perform="user:update-profile" yes={() => (
                        <li>
                            <NavLink id="menu-my-profile" to={prefixLinkTo() + "/my-profile"} title={t("Mon profil")}>
                                <i className="icon-actions-modifier-utilisateur" aria-hidden="true"></i>&nbsp;{t("Mon profil")}
                            </NavLink>
                        </li>)} />
                    {canPerformUserConfiguration && <li id="user-configuration" className="hassub">
                        <NavLink id="menu-user-configuration" to={prefixLinkTo() + "/"} onClick={toggleUserConfiguration} title={t("Gestion des utilisateurs")}><i className="icon-nav-utilisateur-externe" aria-hidden="true"></i>&nbsp;{t("Gestion des utilisateurs")}</NavLink>
                        <button className="submenu-toggler" onClick={toggleUserConfiguration}><i className="icon-right-open" aria-hidden="true"></i></button>
                        <ul>
                            <Can perform="user:list" yes={() => (<li><NavLink id="menu-users" to={prefixLinkTo() + "/users"} title={t("Gestion des utilisateurs en liste")}><i className="icon-nav-utilisateur-externe" aria-hidden="true"></i>&nbsp;{t("En liste")}</NavLink></li>)} />
                            <Can perform="tree:read" yes={() => (<li><NavLink id="menu-tree" to={prefixLinkTo() + "/tree"} title={t("Gestion des utilisateurs par point de veille")}><i className="icon-nav-arbo-point-veille" aria-hidden="true"></i>&nbsp;{t("Par point de veille")}</NavLink></li>)} />
                        </ul>
                    </li>}                   
                    {canPerformAccountConfiguration && <li id="account-parameters" className="hassub">
                        <NavLink id="menu-account-parameters" to={prefixLinkTo() + "/"} onClick={toggleAccountConfiguration} title={t("Paramètre")}><i className="icon-nav-mon-plan-actions" aria-hidden="true"></i>&nbsp;{t("Paramètre")}</NavLink>
                        <button className="submenu-toggler" onClick={toggleAccountConfiguration}><i className="icon-right-open" aria-hidden="true"></i></button>
                        <ul>
                            <Can perform="account:write" yes={() => (<li><NavLink id="menu-account-configuration" to={prefixLinkTo() + "/account-configuration"} title={t("Configuration du compte")}><i className="icon-nav-mes-parametres" aria-hidden="true"></i>&nbsp;{t("Configuration du compte")}</NavLink></li>)} />
                            <Can perform="category:list" data={{account: account}} yes={() => (<li><NavLink id="menu-categories" to={prefixLinkTo() + "/categories"} title={t("Gestion des catégories")}><i className="icon-nav-gestion-categories" aria-hidden="true"></i>&nbsp;{t("Gestion des catégories")}</NavLink></li>)} />
                        </ul>
                    </li>}
                    <li>
                        <NavLink id="exports-list" to="/export" title={t("Mes exports")}>
                            <i className="icon-accueil-dernieres-fiches" aria-hidden="true">
                                {exportedFiles > 0 && <span class="badge badge-pill badge-danger">{exportedFiles}</span>}
                            </i>&nbsp;{t("Mes exports")}
                        </NavLink>
                    </li>
                    <Can perform="obligationgenerator:read" data={{account: account}} yes={() => (
                        <li>
                            <NavLink id="menu-obligationgenerator-export" to={prefixLinkTo() + "/obligationgenerator"} title="Générateur d'obligations">
                                <i className="icon-documentaire-jurisprudence" aria-hidden="true"></i>&nbsp;Générateur d'obligations
                            </NavLink>
                        </li>)} />
                </>}
            </ul>
        </nav>
    )
}
