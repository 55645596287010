import { useTranslation } from 'react-i18next';
import BarLoader from "react-spinners/BarLoader";
import { formatDate } from "shared/services/Utils";
import Modal from 'react-modal';
import { smallModalCustomStyles } from 'shared/services/ModalStyle';
import { useState } from "react";
import FileUtils from 'shared/services/FileUtils';
import FieldComponent from "shared/components/FieldComponent";

export default function FileListComponent(props) {

    const { t } = useTranslation();
    const values = Array.isArray(props.values) ? props.values : [props.values];
    const { uploads, onRemoveFile, showSize, showDate, withMetadata, className, style } = props;
    const [download, setDownload] = useState("");

    function preDownloadFile(event, file) {
        event.preventDefault();
        setDownload(file.name);

        FileUtils.download(file)
            .then(() => setDownload(""))
            .catch(() => setDownload(""));
    }

    function removeFile(event, hash) {
        event.preventDefault();
        onRemoveFile(hash);
    }

    function fileExtraInfo(file) {
        let extraInfo = [];
        if (showSize) {
            extraInfo.push(file.size);
        }
        if (showDate) {
            extraInfo.push(formatDate(file.date));
        }
        if (extraInfo.length > 0) {
            return "(" + extraInfo.join(', ') + ")";
        }
        return null;
    }

    function renderValueList() {
        return values.map(file => 
            <li key={file.hash}>
                {file.image ? 
                    <button style={{backgroundColor: 'transparent', border: 0}} onClick={e => preDownloadFile(e, file)}>
                        <img src={`data:image/jpeg;base64,${file.image}`} width="100" alt="Aperçu" />
                    </button>
                :
                    <>
                        <i className="icon-filtres-fichier" aria-hidden="true"></i>&nbsp;
                        <button style={{backgroundColor: 'transparent', border: 0}} onClick={e => preDownloadFile(e, file)}>
                            <strong>{file.name}</strong>&nbsp;{fileExtraInfo(file)}
                        </button>
                    </>
                }
                {withMetadata && <>
                    <FieldComponent 
                        label={t("Titre")}
                        name={`title-${file.hash}`}
                        value={file.title}
                        onChange={value => props.onMetadataUpdate("title", value, file)}
                        wrapper={([label, input]) => <>{label}{input}</>}
                    />
                    <FieldComponent 
                        label={t("Date")}
                        type="date"
                        name={`document_date-${file.hash}`}
                        value={file.document_date}
                        onChange={value => props.onMetadataUpdate("document_date", value, file)}
                        wrapper={([label, input]) => <>{label}{input}</>}
                    />
                </>}
                {onRemoveFile && (
                    <button type="button" onClick={e => removeFile(e, file.hash)}>
                        <i className="icon-filtres-poubelle" aria-hidden="true"></i>
                    </button>
                )}
            </li>
        );
    }

    function renderUploadList() {
        return uploads.map(fileName => 
            <li key={fileName}>
                <i className="icon-filtres-fichier" aria-hidden="true"></i>&nbsp;
                {fileName}
                <BarLoader loading width={200} color="#5bad27" css="display:inline-block;margin-left: 10px;" />
            </li>
        );
    }

    return (<>
        <Modal isOpen={download !== ""} style={smallModalCustomStyles}>
            <div style={{textAlign: 'center', paddingBottom: 0}}>
                <div>Récupération du fichier <strong>{download}</strong>, veuillez patienter...</div>
                <BarLoader loading width={500} color="#5bad27" css="display:inline-block;margin-left: 10px;" />
            </div>
        </Modal>
        <ul className={className} style={style}>
            {(values.length > 0 || uploads.length > 0) ? 
                <>
                    {renderValueList()}
                    {renderUploadList()}
                </>
            : 
                <li>Aucun fichier</li>
            }
        </ul>
    </>);
}

FileListComponent.defaultProps = {
    showDate: false,
    showSize: false,
    withMetadata: false,
    uploads: [],
    values: [],
    className: "filelist",
};
