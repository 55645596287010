import { React } from 'commons';
import { useTranslation } from 'react-i18next';
import { useToggler } from "shared/hooks";
import { ToggableBlocComponent } from "shared/components";
import DocumentarySpaceDetailCommonRows from "views/documentaryspace/Detail/CommonRows";
import DocumentarySpaceDetailRow from "views/documentaryspace/Detail/Row";

export default function DocumentarySpaceDetailNewsFlash(props) {

    const { t } = useTranslation();
    const [togglers, toggle] = useToggler({"document": true});

    return <form className="form">
        <section>
            <h1>{props.document.title}</h1>
            <ToggableBlocComponent label={t("Document")} id="document" toggled={togglers["document"]} toggle={toggle} className="border-gris-25 border-lrb">
                <DocumentarySpaceDetailCommonRows document={props.document} />
                <DocumentarySpaceDetailRow label={t("Fichier pdf")} value={props.document.pdf} type="file" />
            </ToggableBlocComponent>
            <section className="row">
                <div className="col text-right">
                    <button id="close-detail" onClick={props.onRequestClose} className="btn btn-bleu-4">{t("Fermer")}</button>
                </div>
            </section>
        </section>
    </form>;
}