export const REFERENTIAL_DOMAIN = 1;
export const REFERENTIAL_SUB_DOMAIN = 2;
export const REFERENTIAL_THEME = 3;
export const REFERENTIAL_TRANSMITTER = 4;
export const REFERENTIAL_TEXT_TYPE = 5;
export const REFERENTIAL_TEXT_CODIFICATION = 6;
export const REFERENTIAL_SOURCE = 7;
export const REFERENTIAL_TEXT_IMPACT = 8;
export const REFERENTIAL_INVOLVED_ACTOR = 9;
export const REFERENTIAL_INVOLVED_FUNCTION = 10;
export const REFERENTIAL_DISPATCH_GROUP = 11;
export const REFERENTIAL_DESCRIPTORS = 12;
export const REFERENTIAL_CATEGORY = 13;
export const REFERENTIAL_SUB_CATEGORY = 14;
export const REFERENTIAL_DOCUMENT_TYPE = 15;
export const REFERENTIAL_COUNTRY = 16;
export const REFERENTIAL_APPLICATION_AREA = 17;

export const REFERENTIAL_TYPE = {
    [REFERENTIAL_DOMAIN]: "Domaines",
    [REFERENTIAL_SUB_DOMAIN]: "Sous domaines",
    [REFERENTIAL_THEME]: "Thèmes",
    [REFERENTIAL_TRANSMITTER]: "Emetteurs",
    [REFERENTIAL_TEXT_TYPE]: "Types de texte",
    [REFERENTIAL_TEXT_CODIFICATION]: "Codifications des textes",
    [REFERENTIAL_SOURCE]: "Sources",
    [REFERENTIAL_TEXT_IMPACT]: "Impacts des textes",
    [REFERENTIAL_INVOLVED_ACTOR]: "Acteurs concernés",
    [REFERENTIAL_INVOLVED_FUNCTION]: "Fonctions impactées",
    [REFERENTIAL_DISPATCH_GROUP]: "Groupement des dépêches",
    [REFERENTIAL_DESCRIPTORS]: "Descripteurs",
    [REFERENTIAL_DOCUMENT_TYPE]: "Types de document",
    [REFERENTIAL_COUNTRY]: "Pays",
    [REFERENTIAL_APPLICATION_AREA]: "Zone d'application géographique",
};

export const REFERENTIAL_PARENT = {
    [REFERENTIAL_SUB_DOMAIN]: REFERENTIAL_DOMAIN,
    [REFERENTIAL_THEME]: REFERENTIAL_SUB_DOMAIN,
    [REFERENTIAL_SUB_CATEGORY]: REFERENTIAL_CATEGORY,
};

export function getReferentialType() {
    let referentialType = [];
    for (const [key, value] of Object.entries(REFERENTIAL_TYPE)) {
        referentialType.push({
            value: parseInt(key), 
            label: value
        });
    }
    referentialType.sort((a, b) => a.label.localeCompare(b.label));
    return referentialType;
}

export const REFERENTIALS = {
    REFERENTIAL_DOMAIN: REFERENTIAL_DOMAIN,
    REFERENTIAL_SUB_DOMAIN: REFERENTIAL_SUB_DOMAIN,
    REFERENTIAL_THEME: REFERENTIAL_THEME,
    REFERENTIAL_TRANSMITTER: REFERENTIAL_TRANSMITTER,
    REFERENTIAL_TEXT_TYPE: REFERENTIAL_TEXT_TYPE,
    REFERENTIAL_TEXT_CODIFICATION: REFERENTIAL_TEXT_CODIFICATION,
    REFERENTIAL_SOURCE: REFERENTIAL_SOURCE,
    REFERENTIAL_TEXT_IMPACT: REFERENTIAL_TEXT_IMPACT,
    REFERENTIAL_INVOLVED_ACTOR: REFERENTIAL_INVOLVED_ACTOR,
    REFERENTIAL_INVOLVED_FUNCTION: REFERENTIAL_INVOLVED_FUNCTION,
    REFERENTIAL_DISPATCH_GROUP: REFERENTIAL_DISPATCH_GROUP,
    REFERENTIAL_DESCRIPTORS: REFERENTIAL_DESCRIPTORS,
    REFERENTIAL_CATEGORY: REFERENTIAL_CATEGORY,
    REFERENTIAL_SUB_CATEGORY: REFERENTIAL_SUB_CATEGORY,
    REFERENTIAL_DOCUMENT_TYPE: REFERENTIAL_DOCUMENT_TYPE,
    REFERENTIAL_COUNTRY: REFERENTIAL_COUNTRY,
    REFERENTIAL_APPLICATION_AREA: REFERENTIAL_APPLICATION_AREA,
};