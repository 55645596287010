import Home from "views/Home";
import Logout from "views/Logout";
import UserList from "views/user/List";
import UserForm from "views/user/Form";
import Tree from "views/tree/Tree";
import Node from "views/tree/Node";
import Watchsite from "views/tree/Watchsite";
import SheetList from "views/sheet/List";
import SheetDetail from "views/sheet/Detail";
import SheetPrivateForm from "views/sheet/PrivateForm";
import SheetAffectedList from "views/sheetaffected/List";
import SheetAffectedForm from "views/sheetaffected/Form";
import CategoryList from "views/category/List";
import CategoryForm from "views/category/Form";
import AccountConfigurationForm from "views/account/ConfigurationForm";
import HistoryList from "shared/views/history/List";
import ActionList from "views/action/List";
import ActionForm from "views/action/Form";
import Cgu from "shared/views/Cgu";
import Denied from "shared/views/Denied";
import MyProfile from "views/MyProfile";
import RequirementAffectedList from "views/requirementaffected/List";
import RequirementAffectedForm from "views/requirementaffected/Form";
import WatchsiteList from "views/sheetaffected/Watchsite";
import HotlineList from "views/hotline/List";
import HotlineForm from "views/hotline/Form";
import DocumentarySpaceLink from "views/DocumentarySpaceLink";
import DocumentarySpaceHome from "views/documentaryspace/Home";
import DocumentarySpaceSearch from "views/documentaryspace/Search";
import DocumentarySpaceList from "views/documentaryspace/List";
import ObligationList from "views/obligation/List";
import StatisticsList from "views/statistics/List";
import ExportList from "shared/views/export/List";
import ObligationGeneratorFormWrapper from "views/obligationgenerator/FormWrapper";

import Login from "views/Login";
import AdslLogin from "views/AdslLogin";
import SelectAccount from "views/SelectAccount";
import Loader from "views/Loader";
import UserValidation from "views/UserValidation";
import PasswordForgot from "shared/views/password/PasswordForgot";
import PasswordInitialization from "shared/views/password/PasswordInitialization";
import Maintenance from "views/Maintenance";

const routes = [
    {path: "/login", breadcrumb: "Connexion", component: Login},
    {path: "/adsllogin", breadcrumb: "Connexion", component: AdslLogin},    
    {path: "/selectaccount", breadcrumb: "Sélection du compte", component: SelectAccount},
    {path: "/loader", breadcrumb: "Chargement", component: Loader},
    {path: "/users/validation/:hash", breadcrumb: "Validation d'un utilisateur", component: UserValidation},
    {path: "/users/forgotpassword", breadcrumb: "Mot de passe oublié", component: PasswordForgot},
    {path: "/users/initpassword/:hash", breadcrumb: "Initialisation de votre mot de passe", component: PasswordInitialization},
    {path: "/maintenance", breadcrumb: "Maintenance", component: Maintenance},

    {path: "/", breadcrumb: "Accueil", component: Home},
    {path: "/logout", breadcrumb: "Déconnexion", component: Logout},
    {path: "/tree", breadcrumb: "Arborescence et point de veille", component: Tree},
    {path: "/tree/nodes/:id", breadcrumb: "Configuration des utilisateurs du noeud", component: Node},
    {path: "/tree/watchsites/:id", breadcrumb: "Configuration des utilisateurs du point de veille", component: Watchsite},
    {path: "/users", breadcrumb: "Utilisateurs", component: UserList},
    {path: "/users/new", breadcrumb: "Nouvel utilisateur", component: UserForm},
    {path: "/users/:id", breadcrumb: "Modification utilisateur", component: UserForm},
    {path: "/categories", breadcrumb: "Catégories", component: CategoryList},
    {path: "/categories/new", breadcrumb: "Création", component: CategoryForm},
    {path: "/categories/:id", breadcrumb: "Edition", component: CategoryForm},
    {path: "/account-configuration", breadcrumb: "Configuration du compte", component: AccountConfigurationForm},
    {path: "/history/:type/:id", breadcrumb: "Historique", component: HistoryList},
    {path: "/actions", breadcrumb: "Plan d'action", component: ActionList},
    {path: "/actions/new", breadcrumb: "Création d'une action", component: ActionForm},
    {path: "/actions/:id", breadcrumb: "Modification d'une action", component: ActionForm},
    {path: "/cgu", breadcrumb: "Conditions générales d'utilisation", component: Cgu},
    {path: "/denied", breadcrumb: "Accès refusé", component: Denied},
    {path: '/my-profile', breadcrumb: "Mon profil", component: MyProfile},
    {path: "/sheets", breadcrumb: "Rechercher un texte", component: SheetList},
    {path: "/sheets/private/new", breadcrumb: "Fiche privée", component: SheetPrivateForm},
    {path: "/sheets/private/:id", breadcrumb: "Edition fiche privée", component: SheetPrivateForm},
    {path: "/sheets/:id", breadcrumb: "Fiche", component: SheetDetail},
    {path: "/sheets/:id/watchsites", breadcrumb: "Traitement de fiche en multi points de veille", component: WatchsiteList},
    {path: "/sheets-affected", breadcrumb: "Ma veille", component: SheetAffectedList},
    {path: "/sheets-affected/:id", breadcrumb: "Conformité d'une fiche", component: SheetAffectedForm},
    {path: "/requirements-affected", breadcrumb: "Liste des exigences", component: RequirementAffectedList},
    {path: "/requirements-affected/:id", breadcrumb: "Conformité d'une exigence", component: RequirementAffectedForm},
    {path: "/hotlines", breadcrumb: "Liste hotline", component: HotlineList},
    {path: "/hotlines/new", breadcrumb: "Hotline", component: HotlineForm},
    {path: "/hotlines/:id", breadcrumb: "Edition hotline", component: HotlineForm},
    {path: "/documentary-space-link", breadcrumb: "Espace documentaire", component: DocumentarySpaceLink},
    {path: "/documentary-space", breadcrumb: "Espace documentaire", component: DocumentarySpaceHome},
    {path: "/documentary-space/search", breadcrumb: "Espace documentaire", component: DocumentarySpaceSearch},
    {path: "/documentary-space/thematicfile", breadcrumb: "Dossiers et fiches thématiques", component: DocumentarySpaceList},    
    {path: "/documentary-space/caselaw", breadcrumb: "Jurisprudence", component: DocumentarySpaceList},
    {path: "/documentary-space/clientspecificmodule", breadcrumb: "Module spécifique client", component: DocumentarySpaceList},
    {path: "/documentary-space/drafttext", breadcrumb: "Projet de texte", component: DocumentarySpaceList},
    {path: "/documentary-space/form", breadcrumb: "Formulaire / déclaration (CERFA et notice)", component: DocumentarySpaceList},
    {path: "/documentary-space/news", breadcrumb: "Actualité documentaire", component: DocumentarySpaceList},
    {path: "/documentary-space/newsflash", breadcrumb: "Flash d'information", component: DocumentarySpaceList},
    {path: "/documentary-space/newsletter", breadcrumb: "Newsletter", component: DocumentarySpaceList},
    {path: "/documentary-space/regulatorycontrol", breadcrumb: "Contrôle règlementaire", component: DocumentarySpaceList},
    {path: "/documentary-space/regulatoryflowchart", breadcrumb: "Organigramme règlementaire", component: DocumentarySpaceList},
    {path: "/documentary-space/sanction", breadcrumb: "Sanction", component: DocumentarySpaceList},
    {path: "/obligations", breadcrumb: "Contrôles et documents obligatoires", component: ObligationList},
    {path: "/statistics", breadcrumb: "Tableau de bord / Statistiques", component: StatisticsList},
    {path: "/export", breadcrumb: "Mes exports", component: ExportList},
    {path: "/obligationgenerator", breadcrumb: "Générateur d'obligations", component: ObligationGeneratorFormWrapper},
    

    {path: "/oidc/", breadcrumb: "Accueil", component: Home},
    {path: "/oidc/tree", breadcrumb: "Arborescence et point de veille", component: Tree},
    {path: "/oidc/tree/nodes/:id", breadcrumb: "Configuration des utilisateurs du noeud", component: Node},
    {path: "/oidc/tree/watchsites/:id", breadcrumb: "Configuration des utilisateurs du point de veille", component: Watchsite},
    {path: "/oidc/users", breadcrumb: "Utilisateurs", component: UserList},
    {path: "/oidc/users/new", breadcrumb: "Nouvel utilisateur", component: UserForm},
    {path: "/oidc/users/:id", breadcrumb: "Modification utilisateur", component: UserForm},
    {path: "/oidc/categories", breadcrumb: "Catégories", component: CategoryList},
    {path: "/oidc/categories/new", breadcrumb: "Création", component: CategoryForm},
    {path: "/oidc/categories/:id", breadcrumb: "Edition", component: CategoryForm},
    {path: "/oidc/account-configuration", breadcrumb: "Configuration du compte", component: AccountConfigurationForm},
    {path: "/oidc/history/:type/:id", breadcrumb: "Historique", component: HistoryList},
    {path: "/oidc/actions", breadcrumb: "Plan d'action", component: ActionList},
    {path: "/oidc/actions/new", breadcrumb: "Création d'une action", component: ActionForm},
    {path: "/oidc/actions/:id", breadcrumb: "Modification d'une action", component: ActionForm},
    {path: "/oidc/cgu", breadcrumb: "Conditions générales d'utilisation", component: Cgu},
    {path: "/oidc/denied", breadcrumb: "Accès refusé", component: Denied},
    {path: "/oidc/my-profile", breadcrumb: "Mon profil", component: MyProfile},
    {path: "/oidc/sheets", breadcrumb: "Rechercher un texte", component: SheetList},
    {path: "/oidc/sheets/private/new", breadcrumb: "Fiche privée", component: SheetPrivateForm},
    {path: "/oidc/sheets/private/:id", breadcrumb: "Edition fiche privée", component: SheetPrivateForm},
    {path: "/oidc/sheets/:id", breadcrumb: "Fiche", component: SheetDetail},
    {path: "/oidc/sheets/:id/watchsites", breadcrumb: "Traitement de fiche en multi points de veille", component: WatchsiteList},
    {path: "/oidc/sheets-affected", breadcrumb: "Ma veille", component: SheetAffectedList},
    {path: "/oidc/sheets-affected/:id", breadcrumb: "Conformité d'une fiche", component: SheetAffectedForm},
    {path: "/oidc/requirements-affected", breadcrumb: "Liste des exigences", component: RequirementAffectedList},
    {path: "/oidc/requirements-affected/:id", breadcrumb: "Conformité d'une exigence", component: RequirementAffectedForm},
    {path: "/oidc/hotlines", breadcrumb: "Liste hotline", component: HotlineList},
    {path: "/oidc/hotlines/new", breadcrumb: "Hotline", component: HotlineForm},
    {path: "/oidc/hotlines/:id", breadcrumb: "Edition hotline", component: HotlineForm},
    {path: "/oidc/documentary-space-link", breadcrumb: "Espace documentaire", component: DocumentarySpaceLink},
    {path: "/oidc/documentary-space", breadcrumb: "Espace documentaire", component: DocumentarySpaceSearch},
    {path: "/oidc/documentary-space/thematicfile", breadcrumb: "Dossiers et fiches thématiques", component: DocumentarySpaceList},    
    {path: "/oidc/documentary-space/caselaw", breadcrumb: "Jurisprudence", component: DocumentarySpaceList},
    {path: "/oidc/documentary-space/clientspecificmodule", breadcrumb: "Module spécifique client", component: DocumentarySpaceList},
    {path: "/oidc/documentary-space/drafttext", breadcrumb: "Projet de texte", component: DocumentarySpaceList},
    {path: "/oidc/documentary-space/form", breadcrumb: "Formulaire / déclaration (CERFA et notice)", component: DocumentarySpaceList},
    {path: "/oidc/documentary-space/news", breadcrumb: "Actualité documentaire", component: DocumentarySpaceList},
    {path: "/oidc/documentary-space/newsflash", breadcrumb: "Flash d'information", component: DocumentarySpaceList},
    {path: "/oidc/documentary-space/newsletter", breadcrumb: "Newsletter", component: DocumentarySpaceList},
    {path: "/oidc/documentary-space/regulatorycontrol", breadcrumb: "Contrôle règlementaire", component: DocumentarySpaceList},
    {path: "/oidc/documentary-space/regulatoryflowchart", breadcrumb: "Organigramme règlementaire", component: DocumentarySpaceList},
    {path: "/oidc/documentary-space/sanction", breadcrumb: "Sanction", component: DocumentarySpaceList},
    {path: "/oidc/obligations", breadcrumb: "Contrôles et documents obligatoires", component: ObligationList},
    {path: "/oidc/statistics", breadcrumb: "Tableau de bord / Statistiques", component: StatisticsList},
    {path: "/oidc/obligationgenerator", breadcrumb: "Générateur d'obligations", component: ObligationGeneratorFormWrapper},
];

export default routes;