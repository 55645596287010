import React from "react";
import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";
import Can from "shared/services/Can";
import _ from "lodash";
import { ServicesApave } from 'shared/data';
import { useSecurity } from 'shared/hooks/Security';
import { useAccount } from 'hooks/Account';
import { prefixLinkTo } from 'shared/services';
import logoapave from "images/logoapave.svg";
import logopilotveille from "images/logopilotveille.png";
import logopilotstart from "images/logopilotstart.png";

export default function Header(props) {

    const { t } = useTranslation();
    const [user] = useSecurity();
    const [account, nbaccounts] = useAccount();
    let logoutLink = "/logout";
    if (document.location.pathname.substring(0, 5) === "/oidc") {
        logoutLink = "/oidc/redirect_uri?logout=" + document.location.origin + "/logout";
    }

    function toggleMenu() {
        document.body.classList.toggle("main-nav-opened");
    }

    function logout(event) {
        event.preventDefault();
        document.location.href = logoutLink;
    }

    return (
        <header className="page-header">
            <button id="main-menu-toggler" className="main-menu-toggler" onClick={toggleMenu}><i className="icon-nav-burger" aria-hidden="true"></i></button>
            <div className="bandeau">
                <div className="main-logo">
                    <Link to={prefixLinkTo() + "/"} title={t("Retour à l'accueil")}><img src={logoapave} width="96" alt="Retour à l'accueil" /></Link>
                </div>
                <div className="main-name">
                    {(account.is_start && <img src={logopilotstart} alt="Logo Pilot Veille Start" />)
                    || <img src={logopilotveille} alt="Logo Pilot Veille" />}
                </div>                
                {props.isLogged && <>
                    <div className="client-logo">
                        {account.logo && account.logo.image && <img src={`data:image/jpeg;base64,${account.logo.image}`} height="45" alt={`Logo ${account.name}`} />}
                    </div>
                    <div className="client-name">
                        {account.name}
                    </div>
                </>}
                <div className="tools">
                    <a target="_blank" href="/documentation.pdf" className="help" title={t("Aide")}>
                        <i className="icon-help-circled" aria-hidden="true"></i>
                    </a>
                    {props.isLogged && <>
                        <div className="user-menu">
                            <h3>{user.firstname} <span>{user.lastname}</span></h3>
                            <ul>
                                {process.env.NODE_ENV === "development" && <li>{user.roles.map(role => (<span key={role} className="color-rouge">{role}<br/></span>))}</li>}
                                <Can perform="user:update-profile" yes={() => (<li><Link to={prefixLinkTo() + "/my-profile"} title={t("Mon profil")}>{t("Configuration")}</Link></li>)} />
                                {nbaccounts > 1 && <li><Link to={prefixLinkTo() + "/selectaccount"}>{t("Changer de compte")}</Link></li>}
                                {_.size(user.adsl_services) > 1 && <li><a id="menu-oneapave" href={process.env.REACT_APP_ADSL_URL} rel="noreferrer" title={t("OneApave")} target="_blank">{t("One Apave")}</a></li>}
                                {_.size(user.adsl_services) > 1 && <>{ServicesApave.map(service => {
                                    let output = <React.Fragment key={service.id}></React.Fragment>;
                                    Object.entries(user.adsl_services).forEach(userService => {
                                        if (parseInt(userService[1].id) === parseInt(service.id)) {
                                            output = <li key={service.id}><a href={process.env.REACT_APP_ADSL_URL} rel="noreferrer" title={service.label} target="_blank">{service.label}</a></li>;
                                        }
                                    });
                                    return output;
                                })}</>}
                                <li><Link onClick={logout} to={logoutLink}>{t("Se déconnecter")}</Link></li>
                            </ul>
                        </div>
                    </>}
                </div>
            </div>
        </header>
    )
}
