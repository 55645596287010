import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAccount } from 'hooks/Account';
import DefaultLayout from "layouts/DefaultLayout";
import ConfigurationApi from "shared/api/Configuration";
import HtmlComponent from "shared/components/HtmlComponent";
import LastSheets from 'views/home/LastSheets';
import Consultant from 'views/home/Consultant';
import DocumentarySpace from 'views/home/DocumentarySpace';
import ConformityChart from 'views/home/ConformityChart';
import ConformityManagement from 'views/home/ConformityManagement';
import LastHotlines from 'views/home/LastHotlines';
import LastActions from 'views/home/LastActions';
import Can from "shared/services/Can";

export default function Home() {

    const { t } = useTranslation();
    const [account] = useAccount();
    const [message, setMessage] = useState("");

    useEffect(() => ConfigurationApi.get("message_home_fo").then(c => setMessage(c.value)), []);

    return (
        <DefaultLayout title={t("Accueil")} contentWrapperClassName="content-wrapper bg-gris-10" screen="E26">
            <h1 className="main-title">{t("Bienvenue sur Pilot Veille")}</h1>
            {message && <div className="row"><div className="col-md-12"><HtmlComponent>{message}</HtmlComponent></div></div>}
            <div className="row grid home">
                <Can perform="sheetaffected:list" data={{account: account}} yes={() => <div className="col-xs-12 col-md-12"><LastSheets /></div>} />
                {!account.is_start && <>
                    <Can perform="account:documentary_space" data={{account: account}} yes={() => <div className="col-sm-12 col-md-6 col-xl-4"><DocumentarySpace /></div>} />
                    <Can perform="account:compliance_management" data={{account: account}} yes={() => <div className="col-sm-12 col-md-6 col-xl-4"><ConformityChart /></div>} />
                    <Can perform="account:compliance_management" data={{account: account}} yes={() => <div className="col-sm-12 col-md-6 col-xl-4"><ConformityManagement /></div>} />
                    <Can perform="account:hotline" data={{account: account}} yes={() => <div className="col-sm-12 col-md-6 col-xl-4"><LastHotlines /></div>} />
                    <Can perform="account:actionplan" data={{account: account}} yes={() => <div className="col-sm-12 col-md-6 col-xl-4"><LastActions /></div>} />                
                    <div className="col-md-12 consultant"><Consultant /></div>
                </>}
            </div>
        </DefaultLayout>
    );
}
