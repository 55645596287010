import { React, DefaultLayout, useContext, useState } from 'commons';
import { useTranslation } from 'react-i18next';
import { useAccount } from 'hooks/Account';
import { ReferentialContext } from 'shared/stores';
import { denyIfCantPerform, compileDataToSelectOptions } from 'shared/services';
import { FieldComponent, LoadButton } from 'shared/components';
import DocumentarySpaceResultSection from 'views/documentaryspace/Result/Section';
import { REFERENTIALS, typeLabels } from 'shared/data';

export default function DocumentarySpaceList(props) {

    const { t } = useTranslation();
    const [account] = useAccount();
    const type = props.match.params.type ? props.match.params.type : document.location.href.substring(document.location.href.lastIndexOf('/') + 1);
    const classificationOptions = [];
    const classificationLabel = {
        noclassification: "Aucun classement",
        date: "Par date",
        domain: "Par domaine",
    }
    const [referentialContext] = useContext(ReferentialContext);
    const documentTypes = compileDataToSelectOptions(referentialContext[REFERENTIALS.REFERENTIAL_DOCUMENT_TYPE], 'id', 'value');

    denyIfCantPerform(props, "account:documentary_space", {account: account});
    if (type === "clientspecificmodule") {
        denyIfCantPerform(props, "account:private_documentary_space", {account: account});
    }

    let defaultClassifyingOption = "noclassification";
    switch (type) {
        case 'caselaw': 
            defaultClassifyingOption = "domain";
            classificationOptions.push("domain");
            classificationOptions.push("date");
            break;
        case 'form': 
        case 'thematicfile':
        case 'regulatorycontrol':
        case 'regulatoryflowchart':
        case 'sanction':
            defaultClassifyingOption = "domain";
            break;
        case 'drafttext': 
        case 'news': 
        case 'clientspecificmodule':
            classificationOptions.push("noclassification");
            classificationOptions.push("domain");
            break;
        case 'newsflash': 
        case 'newsletter':
            defaultClassifyingOption = "date";
            break;        
        default: 
            // should not be reached
            break;
    }

    const [classification, setClassification] = useState(defaultClassifyingOption);
    const [documentType, setDocumentType] = useState(null);
    const [query, setQuery] = useState(null);
    const [filtering, setFiltering] = useState(false);

    function getClassificationOptions() {
        const options = [];
        classificationOptions.forEach(option => {
            options.push({
                value: option,
                label: classificationLabel[option]
            });
        });
        return options;
    }

    return <DefaultLayout screen="E50" title={t("Liste de documents")}>
        <h1>Liste de documents - {typeLabels[type]}</h1>
        {classificationOptions.length > 0 && <section className="bloc">
            <header><h2>Configuration</h2></header>
            <div className="bg-blanc border-rlb border-gris-40">
                <form className="form">
                    <FieldComponent 
                        type="radio"
                        label={t("Afficher par")}
                        name="classification"
                        onChange={value => setClassification(value)}
                        value={classification}
                        options={getClassificationOptions()}
                    />
                </form>
            </div>
        </section>}
        {type === "clientspecificmodule" && <section className="bloc">
            <header><h2>Filtre</h2></header>
            <div className="bg-blanc border-rlb border-gris-40">
                <form className="form">
                    <div className="row">
                        <div className="col-10">
                            <FieldComponent
                                type="select"
                                label={t("Type de document")}
                                name="document_type"
                                options={documentTypes}
                                clearable={true}
                                value={documentType}
                                onChange={value => setDocumentType(value)}
                            />
                        </div>
                        <div className="col-2 text-right">
                            <LoadButton 
                                loading={filtering}
                                label={t("Filtrer")} 
                                name="filter-clientspecificmodule" 
                                id="filter-clientspecificmodule"
                                type="button"
                                onClick={() => {
                                    setFiltering(true);
                                    if (documentType) {
                                        setQuery({"bool":{"must":[{"match":{"document_type": documentType}}]}});
                                    } else {
                                        setQuery(null)
                                    }
                                }}
                            />
                        </div>
                    </div>
                </form>
            </div>
        </section>}
        <div className="bloc">
            <DocumentarySpaceResultSection 
                perpage={20}
                query={query}
                classification={classification}
                type={type}
                showPagination={true}
                showHeader={false}
                showAllDocumentLink={false}
                signalSearchDone={() => setFiltering(false)}
            />
        </div>
    </DefaultLayout>;
}