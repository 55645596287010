import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import PreferenceApi from 'api/Preference';
import FieldComponent from 'shared/components/FieldComponent';
import LoadButton from 'shared/components/LoadButton';

export default function SheetAffectedSaveSearch(props) {

    const { t } = useTranslation();
    const [label, setLabel] = useState("");
    const [saving, setSaving] = useState(false);
    const [currentSearches, setCurrentSearches] = useState([]);

    useEffect(() => {
        PreferenceApi
            .get("E31_filters")
            .then(data => setCurrentSearches(data.value));
    }, []);

    function save(e) {
        e.preventDefault();

        let searches = {...currentSearches};
        searches[label] = props.filters;

        setSaving(true);
        PreferenceApi
            .save({
                "identifier": "E31_filters",
                "value": searches  
            })
            .then(() => {
                setSaving(false);
                toast.success(t("Enregistrement effectuée"));
                props.onClose();
            });
    }

    return <>
        <form onSubmit={save} id="form-save-search" className="form">
            <section className="bloc">
                <header className="border-rlb border-gris-40">
                    <h2 className="uppercase">Enregistrement de la recherche</h2>
                </header>
                <section>
                    <FieldComponent
                        name="label" 
                        label={t("Libellé de la recherche")} 
                        value={label} 
                        onChange={value => setLabel(value)}
                    />
                    <section className="row">
                        <div className="col">
                            <button
                                type="button"
                                id="back-sheetsaffected-list"
                                onClick={props.onClose}
                                className="btn btn-bleu-4"
                            >{t("Retour à la liste")}</button>
                        </div>
                        <div className="col text-right">  
                            <LoadButton 
                                loading={saving} 
                                label={t("Enregistrer et fermer")}
                                name="save-and-close"
                                id="save-and-close-save-search"
                            />
                        </div>
                    </section>   
                </section>
            </section>
        </form>
    </>;
}