import { React, _, fileDownload, toast, Link, DefaultLayout, useEffect, useState, useContext } from 'commons';
import { useTranslation } from 'react-i18next';
import FavoriteButtonComponent from 'components/FavoriteButtonComponent';
import StartRestrictionMessageComponent from 'components/StartRestrictionMessageComponent';
import ActionListBatch from 'views/action/list/Batch';
import { getActionsStatus, getActionStatusLabel, getActionStatusColor, ACTION_STATUS_CLOSED } from 'data/Action';
import { getConformities, getConformityModes } from 'data/SheetAffected';
import { getApplicabilities } from 'data/ApplicabilityState';
import { getPriorities, getPriorityLabel } from 'data/Priority';
import { useAccount } from 'hooks/Account';
import { useAccess } from 'hooks/Access';
import { useCountryPartitioning } from 'hooks/CountryPartitioning';
import ActionApi from 'api/Action';
import { useList, usePosition, useQuery } from 'shared/hooks';
import { ReferentialContext } from 'shared/stores';
import { ExternalUserApi } from 'shared/api';
import { ListComponent, FileListComponent, FilterComponent, StatusComponent, LoadButton }  from 'shared/components';
import { prefixLinkTo, AccountUtils, FileUtils, TreeUtils, preventDefault, compileDataToSelectOptions, toggleArrayValue, convertCriteriesToSearchQuery, canPerform, denyIfCantPerform, formatDate, isExpired, updateFormFilterRangeValues, stripTags } from 'shared/services';
import { getDegree, REFERENTIALS } from 'shared/data';

export default function ActionList(props) {

    const { t } = useTranslation();
    const [initializePosition, setPosition] = usePosition("action-list");
    const [account] = useAccount();
    const [countries] = useCountryPartitioning();
    const [canWrite, canRead, writableWatchsites, readableWatchsites] = useAccess();
    const [exporting, setExporting] = useState(false);
    const [selection, setSelection] = useState([]);
    const [displayFilter, setDisplayFilter] = useState(true);
    const [advancedSearch, setAdvancedSearch] = useState(false);
    const [tree, setTree] = useState([]);
    const [accountUsers, setAccountUsers] = useState([]);
    const [referentialContext] = useContext(ReferentialContext);

    // referential
    const accountTree = referentialContext["accountTree"];
    const text_types = compileDataToSelectOptions(referentialContext[REFERENTIALS.REFERENTIAL_TEXT_TYPE], 'id', 'value');
    const text_impacts = compileDataToSelectOptions(referentialContext[REFERENTIALS.REFERENTIAL_TEXT_IMPACT], 'id', 'value');
    const sources = compileDataToSelectOptions(referentialContext[REFERENTIALS.REFERENTIAL_SOURCE], 'id', 'value');
    const transmitters = compileDataToSelectOptions(referentialContext[REFERENTIALS.REFERENTIAL_TRANSMITTER], 'id', 'value');
    const involved_actors = compileDataToSelectOptions(referentialContext[REFERENTIALS.REFERENTIAL_INVOLVED_ACTOR], 'id', 'value');
    const involved_functions = compileDataToSelectOptions(referentialContext[REFERENTIALS.REFERENTIAL_INVOLVED_FUNCTION], 'id', 'value');
    const descriptors = compileDataToSelectOptions(referentialContext[REFERENTIALS.REFERENTIAL_DESCRIPTORS], 'id', 'value');
    const application_areas = compileDataToSelectOptions(referentialContext[REFERENTIALS.REFERENTIAL_APPLICATION_AREA], 'id', 'value');
    const domainTree = referentialContext["restrictedTree"];
    const categoryTree = referentialContext[REFERENTIALS.REFERENTIAL_CATEGORY];

    // access
    const hasTechnicalLevelAccess = canPerform("account:technical_level", {account: account});
    const hasCategoriesAccess = canPerform("account:categories", {account: account});
    const hasComplianceManagementAccess = canPerform("account:compliance_management", {account: account});

    denyIfCantPerform(props, "action:list", {account: account, writableWatchsites: writableWatchsites, readableWatchsites: readableWatchsites});

    let columns = [
        {id: 'id', title: t("Numéro")},
        {id: 'actions', title: '', sortable: false, render: (row) => <ul className="actions">
            <li>
                {canPerform(
                    "action:read",
                    {
                        watchsiteReadableCallback: (w) => canRead(parseInt(w.replace("watchsite:", ""))),
                        watchsiteWritableCallback: (w) => canWrite(parseInt(w.replace("watchsite:", ""))),
                        action: row,
                        account: account
                    }
                ) && <Link title={t("Editer")} to={prefixLinkTo() + `/actions/${row.id}`}>
                    <i className="icon-actions-modifier" aria-hidden="true"></i>
                </Link>}
                <FavoriteButtonComponent item={row.id} type="action" isFavorite={row.favorite} />
            </li>
        </ul>},
        {id: 'label', title: t("Libellé d'action")},
        {id: 'watchsite.label', title: t("Point de veille"), render: (row) => row.watchsite ? row.watchsite.label : <strong>Vous n'avez pas accès au point de veille</strong>},
        {id: 'sheet_affected', title: t("Fiche"), sortable: false, render: (row) => <>{row.sheet_affected && <span title={stripTags(row.sheet_affected.sheet.title)}>
            <strong>{row.sheet_affected.sheet.id}</strong> - {row.sheet_affected.sheet.text_types.map(type => <>
                {type.value}
            </>)} du {formatDate(row.sheet_affected.sheet.text_date)}
        </span>}</>},
        {id: 'requirements_affected', title: t("Exigences"), sortable: false, render: (row) => <ul>{row.requirements_affected.map(r => <li key={r.id} title={r.requirement.description}><strong title={`${r.requirement.description.substring(0, 100)}...`}>{r.requirement.id}</strong></li>)}</ul>},
        {id: 'status', title: t("Statut"), render: (row) => <StatusComponent value={getActionStatusLabel(row.status)} color={getActionStatusColor(row.status)} />},
        {id: 'pilot', title: t("Pilote"), render: (row) => row.pilot && row.pilot.active ? row.pilot.firstname + " " + row.pilot.lastname : ""},
        {id: 'priority', title: t("Priorité"), render: (row) => <StatusComponent value={getPriorityLabel(row.priority)} color="valid" />},
        {id: 'deadline_date', title: t("Date limite de réalisation"), render: (row) => <>
            {isExpired(new Date(row.deadline_date)) && row.status !== ACTION_STATUS_CLOSED && <strong className={"color-rouge"}>{formatDate(row.deadline_date)}</strong>}
            {(!isExpired(new Date(row.deadline_date)) || row.status === ACTION_STATUS_CLOSED) && <>{formatDate(row.deadline_date)}</>}
        </>},
        {id: 'achievement_date', title: t("Date de réalisation"), format: "date"},
        {id: 'evaluation_date', title: t("Date d'évaluation"), format: "date"},
        {id: 'closing_date', title: t("Date de clôture"), format: "date"},
        {id: 'description', title: t("Action détaillée"), format: 'crop',  crop: 150},                
        {id: 'custom_field1', title: account.customfield1_label ? account.customfield1_label : "Champ complémentaire 1"},
        {id: 'custom_field2', title: account.customfield2_label ? account.customfield2_label : "Champ complémentaire 2"},
        {id: 'cost', title: t("Coût")},
        {id: 'attachments', title: t("Pièces jointes"), render: (row) => <FileListComponent values={row.attachments} />},
        {id: 'grouping_code', title: t("Code regroupement")},
    ];

    let freeSearchFields = [
        {value: 'label', label: 'Libellé action'},
        {value: 'description', label: 'Action détaillée'},
        {value: 'sheet_affected.sheet.reglementary_text', label: 'Texte réglementaire'},
        {value: 'sheet_affected.sheet.attachments', label: 'Fichiers APAVE'},
        {value: 'sheet_affected.sheet.requirement.description', label: 'Description exigence'},
        {value: 'sheet_affected.comment_consultant', label: 'Commentaire consultant'},
        {value: 'sheet_affected.comments', label: 'Commentaire client'},
        {value: 'sheet_affected.attachments', label: 'Documents clients associés'},
    ];

    const query = useQuery();
    const initialCriterias = {hidden: 0, keyword_fields: ["label"]};
    const rangeCriterias = [
        'created_at',
        'deadline_date',
        'closing_date',
        'achievement_date',
        'sheets_text_date',
        'sheets_publication_date',
        'sheet_affected_hidden_date',
        'sheet_affected_review_date',
        'sheet_affected_evaluation_date',
        'requirements_affected_review_date',
        'requirements_affected_evaluation_date',
    ];
    const contextCriterias = {};
    if (query.has("watchsite")) {
        contextCriterias.watchsites = ["watchsite:" + query.get("watchsite")];
    }
    if (query.has("sheet")) {
        contextCriterias.sheet = query.get("sheet");
    }
    if (query.has("requirement")) {
        contextCriterias.requirement = query.get("requirement");
    }
    if (query.has("deadline_date")) {
        contextCriterias.deadline_date = {end: new Date(query.get("deadline_date"))};
    }

    const [
        rows,
        totalrows,
        criterias,
        sorting,
        direction,
        limit,
        page,,,
        addCriteria,
        updateSorting,
        updateLimit,
        updatePage,
        submitSearch,
        loading,,
        refresh,
        activeCriterias
    ] = useList("action-list", ActionApi, preFetch, "label", initialCriterias, "asc", 20, null, contextCriterias);

    let filterType = {
        id: {type: "match", fields: ["id"]},
        sheet: {type: "match", fields: ["sheet_affected.sheet"]},
        requirement: {type: "match", fields: ["requirements_affected.requirement"]},
        status: {type: "terms", fields: ["status"]},
        watchsites: {type: "terms", fields: ["watchsite"]},
        created_by: {type: "match", fields: ["created_by"]},
        domains: {type: "terms", fields: ["sheet_affected.sheet.categories"]},
        pilot: {type: "match", fields: ["pilot"]},
        created_at: {type: "range", fields: ["created_at"]},
        deadline_date: {type: "range", fields: ["deadline_date"]},
        closing_date: {type: "range", fields: ["closing_date"]},
        achievement_date: {type: "range", fields: ["achievement_date"]},
        hidden: {type: "match", fields: ["hidden"]},
        grouping_code: {type: "match", fields: ["grouping_code"]},
        favorite: {type: "match", fields: ["favorite"], cast: "int"},
    };

    let filterTypeAdvanced = {...filterType, ...{
        // keyword
        keyword: {type: "wildcard", fields: criterias.keyword_fields},

        // sheet
        sheets_text_date: {type: "range", fields: ["sheet_affected.sheet.text_date"]},
        sheets_publication_date: {type: "range", fields: ["sheet_affected.sheet.publication_date"]},
        sheets_text_type: {type: "terms", fields: ["sheet_affected.sheet.text_types"]},
        sheets_text_number: {type: "terms", fields: ["sheet_affected.sheet.text_number"]},
        sheets_source_id: {type: "terms", fields: ["sheet_affected.sheet.source"]},
        sheets_transmitter_id: {type: "terms", fields: ["sheet_affected.sheet.transmitter"]},
        sheets_nor: {type: "match", fields: ["sheet_affected.sheet.nor"]},
        sheets_repealed_text: {type: "match", fields: ["sheet_affected.sheet.repealed_text"], cast: "int"},
        sheets_major_text: {type: "match", fields: ["sheet_affected.sheet.major_text"], cast: "int"},
        sheets_cut_into_requirements: {type: "match", fields: ["sheet_affected.sheet.cut_into_requirements"], cast: "int"},
        sheets_descriptor: {type: "terms", fields: ["sheet_affected.sheet.descriptors"]},
        sheets_private: {type: "match", fields: ["sheet_affected.sheet.is_private"], cast: "int"},
        sheets_application_areas: {type: "contains", fields: ["sheet_affected.sheet.application_areas"]},        

        // sheet affected
        sheet_affected_responsable_evaluation: {type: "match", fields: ["sheet_affected.responsable_evaluation"]},
        sheet_affected_hidden_date: {type: "range", fields: ["sheet_affected.hidden_date"]},
        sheet_affected_affectation_date: {type: "range", fields: ["sheet_affected.affectation_date"]},
        sheet_affected_hidden: {type: "match", fields: ["sheet_affected.hidden"], cast: "int"},

        // requirement
        requirements_affected_hidden: {type: "match", fields: ["requirements_affected.hidden"], cast: "int"},
        requirements_applicability_state: {type: "terms", fields: ["requirements_affected.requirement.applicability_state"]},
    }};

    if (hasTechnicalLevelAccess) {
        filterTypeAdvanced.sheets_text_impact = {type: "terms", fields: ["sheet_affected.sheet.text_impacts"]};
        filterTypeAdvanced.sheets_corporal_impact_degree = {type: "terms", fields: ["sheet_affected.sheet.corporal_impact_degree"]};
        filterTypeAdvanced.sheets_legal_impact_degree = {type: "terms", fields: ["sheet_affected.sheet.legal_impact_degree"]};
        filterTypeAdvanced.sheets_media_impact_degree = {type: "terms", fields: ["sheet_affected.sheet.media_impact_degree"]};
        filterTypeAdvanced.sheets_financial_impact_degree = {type: "terms", fields: ["sheet_affected.sheet.financial_impact_degree"]};
        filterTypeAdvanced.sheets_involved_actor = {type: "terms", fields: ["sheet_affected.sheet.involved_actors"]};
        filterTypeAdvanced.sheets_involved_function = {type: "terms", fields: ["sheet_affected.sheet.involved_functions"]};
    }

    if (hasCategoriesAccess) {
        filterTypeAdvanced.sheet_affected_categories = {type: "terms", fields: ["sheet_affected.categories"]};
    }

    if (hasComplianceManagementAccess) {
        // sheet affected
        filterTypeAdvanced.sheet_affected_mode = {type: "match", fields: ["sheet_affected.conformity_mode"]};
        filterTypeAdvanced.sheet_affected_conformity = {type: "terms", fields: ["sheet_affected.conformity"]};
        filterTypeAdvanced.sheet_affected_priority = {type: "match", fields: ["sheet_affected.priority"]};
        filterTypeAdvanced.sheet_affected_review_date = {type: "range", fields: ["sheet_affected.review_date"]};
        filterTypeAdvanced.sheet_affected_evaluation_date = {type: "range", fields: ["sheet_affected.evaluation_date"]};

        // requirement affected
        filterTypeAdvanced.requirements_affected_review_date = {type: "range", fields: ["requirements_affected.review_date"]};
        filterTypeAdvanced.requirements_affected_evaluation_date = {type: "range", fields: ["requirements_affected.evaluation_date"]};
        filterTypeAdvanced.requirements_affected_conformity = {type: "match", fields: ["requirements_affected.conformity"]};
        filterTypeAdvanced.requirements_affected_priority = {type: "match", fields: ["requirements_affected.priority"]};
    }

    if (countries.length > 1) {
        filterType.watchsite_country = {type: "match", fields: ["watchsite.country"]};

        columns.splice(
            5,
            0,
            {
                id: 'watchsite_country',
                title: t("Pays"),
                sortable: false,
                render: (row) => <>{row.watchsite.country.value}</>
            }
        );
    }

    useEffect(() => setTree(TreeUtils.accountTreeFilterMapper(accountTree, true, criterias.watchsites)), [criterias.watchsites, accountTree]);

    useEffect(() => {
        ExternalUserApi.list({active: 1, valid: 1, sort: 'lastname', limit: 500}).then(([r]) => setAccountUsers(r.map(u => { return { value: u.user_id, label: u.firstname + " " + u.lastname }})));

        if (_.isNil(activeCriterias.watchsites)) {
            addCriteria("watchsites", writableWatchsites);
        }

        submitSearch(advancedSearch ? filterTypeAdvanced : filterType, () => initializePosition(), false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function preFetch(newSorting, newDirection, newCriterias, newLimit, newPage, newFilters) {
        let completeCriterias = {...newCriterias};
        completeCriterias = updateFormFilterRangeValues(completeCriterias, rangeCriterias);

        return [newSorting, newDirection, completeCriterias, newLimit, newPage, newFilters]
    }

    function clearfilter() {
        props.history.push(prefixLinkTo() + "/actions");
        refresh(true, true, {hidden: 0, keyword_fields: ["label"]});
    }

    function exportActions(event) {
        event.preventDefault();
        setExporting(true);

        const exportQuery = convertCriteriesToSearchQuery(criterias, (advancedSearch ? filterTypeAdvanced : filterType));
        ActionApi.export(exportQuery)
            .then(data => {
                setExporting(false);
                fileDownload(data, FileUtils.getFilePrefix() + "-Actions.xlsx");
            })
            .catch(error => {
                setExporting(false);
                toast.error(t("Erreur durant la création de l'export."))
            });
    }

    function getNewUri() {
        let newUri = "/actions/new";
        let params = {};
        if (query.has("watchsite")) {
            params.watchsites = query.get("watchsite");
        }
        if (query.has("sheet")) {
            params.sheets = query.get("sheet");
        }
        if (query.has("requirement")) {
            params.requirements = query.get("requirement");
        }
        if (Object.keys(params).length > 0) {
            newUri = newUri + "?" + (new URLSearchParams(params)).toString();
        }
        return newUri;
    }

    return (
        <DefaultLayout onScroll={(position) => setPosition(position)} screen="E36" title={t("Liste des actions")}>
            <h1>{t("Liste des actions")}</h1>
            <section>
            <div className="bloc">
                <form className="form" onSubmit={(e) => preventDefault(e, submitSearch(advancedSearch ? filterTypeAdvanced : filterType))}>                    
                    <section className="filters">
                        <header>
                            <div className="row">
                                <div className="col-md-2"><h2><button className={displayFilter ? "toggler actif" : "toggler"} type="button" onClick={() => setDisplayFilter(!displayFilter)}>{t("Recherche")}</button></h2></div>
                                <div className="col-md-2"><h2><button className={advancedSearch ? "toggler actif" : "toggler"} type="button" onClick={() => setAdvancedSearch(!advancedSearch)}>{t("Recherche avancée")}</button></h2></div>
                            </div>                            
                        </header>
                        <div className={displayFilter ? "bg-gris-25" : "d-none"}>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="row">
                                        <FilterComponent label={t("Numéro d'action")} name="id" onChange={value => addCriteria("id", value)} value={criterias.id} />
                                        <FilterComponent label={t("Numéro de fiche")} name="sheet" onChange={value => addCriteria("sheet", value)} value={criterias.sheet} />
                                        <FilterComponent label={t("Numéro d'exigence")} name="requirement" onChange={value => addCriteria("requirement", value)} value={criterias.requirement} />
                                        <FilterComponent type="select" label={t("Statut")} name="status" onChange={value => addCriteria("status", value)} value={criterias.status} options={getActionsStatus()} multiple clearable />
                                    </div>
                                    <div className="row">
                                        <FilterComponent type="dropdown-tree-select" label={t("Point de veille")} name="watchsites" onChange={value => addCriteria("watchsites", value)} value={criterias.watchsites} data={tree} mode="multiSelect" />
                                        <FilterComponent type="select" label={t("Auteur")} name="created_by" onChange={value => addCriteria("created_by", value)} value={criterias.created_by} options={accountUsers} clearable />
                                        <FilterComponent type="dropdown-tree-select" label={t("Domaine / Sous domaine / Thème")} name="domains" onChange={value => addCriteria("domains", value)} value={criterias.domains} data={domainTree} mode="multiSelect" />
                                        <FilterComponent type="select" label={t("Pilote")} name="pilot" onChange={value => addCriteria("pilot", value)} value={criterias.pilot} options={accountUsers} clearable />
                                    </div>
                                    <div className="row">
                                        <FilterComponent type="daterange" label={t("Date de création")} name="created_at" onChange={value => addCriteria("created_at", value)} value={criterias.created_at} />
                                        <FilterComponent type="daterange" label={t("Date limite de réalisation")} name="deadline_date" onChange={value => addCriteria("deadline_date", value)} value={criterias.deadline_date} />
                                        <FilterComponent type="daterange" label={t("Date de clôture")} name="closing_date" onChange={value => addCriteria("closing_date", value)} value={criterias.closing_date} />
                                        <FilterComponent type="daterange" label={t("Date de réalisation")} name="achievement_date" onChange={value => addCriteria("achievement_date", value)} value={criterias.achievement_date} />
                                    </div>
                                    <div className="row">
                                        {countries.length > 1 && <FilterComponent label={t("Pays")} type="select" multiple={false} name="watchsite_country" onChange={value => addCriteria("watchsite_country", value)} value={criterias.watchsite_country} options={compileDataToSelectOptions(countries, "id", "value")} />}
                                        <FilterComponent type="select" label={t("Masqué")} name="hidden" onChange={value => addCriteria("hidden", value)} value={criterias.hidden} options={[{value: 1, label: t("Oui")}, {value: 0, label: t("Non")}]} clearable />
                                        <FilterComponent label={t("Code regroupement")} name="grouping_code" onChange={value => addCriteria("grouping_code", value)} value={criterias.grouping_code} />
                                        <FilterComponent type="radio" name="favorite" label={t("Favoris")} blankLabel="Tous" options={[{value: "1", label: t("Oui")}, {value: "0", label: t("Non")}]} value={criterias.favorite} onChange={value => addCriteria("favorite", value)} />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <section className={displayFilter && advancedSearch ? "" : "d-none"}>
                            <header className="bg-gris-40">
                                <h3><button className="toggler actif" type="button" onClick={() => setAdvancedSearch(!advancedSearch)}>{t("Recherche avancée")}</button></h3>
                            </header>
                            <div className="bg-gris-25">
                                <div className="row">
                                    <FilterComponent
                                        label={t("Recherche libre")}
                                        name="keyword"
                                        type="keyword"
                                        fields={freeSearchFields}
                                        selectedFields={criterias.keyword_fields}
                                        onChange={value => addCriteria("keyword", value)}
                                        onFieldChange={value => addCriteria("keyword_fields", toggleArrayValue(criterias.keyword_fields, value))}
                                        value={criterias.keyword || ""} 
                                    />
                                </div>
                                <section>
                                    <header className="bg-gris-30">
                                        <h3>Criteres sur les fiches</h3>
                                    </header>
                                    <div className="bg-gris-25">
                                        <div className="row">
                                            <FilterComponent type="daterange" label={t("Date de texte")} name="sheets_text_date" onChange={value => addCriteria("sheets_text_date", value)} value={criterias.sheets_text_date} />
                                            <FilterComponent type="daterange" label={t("Date de publication")} name="sheets_publication_date" onChange={value => addCriteria("sheets_publication_date", value)} value={criterias.sheets_publication_date} />
                                            <FilterComponent type="select" label={t("Type de texte")} name="sheets_text_type" onChange={value => addCriteria("sheets_text_type", value)} value={criterias.sheets_text_type} options={text_types} multiple />
                                            <FilterComponent label={t("Numéro de texte")} name="sheets_text_number" onChange={value => addCriteria("sheets_text_number", value)} value={criterias.sheets_text_number} />
                                            <FilterComponent type="select" label={t("Source")} name="sheets_source_id" onChange={value => addCriteria("sheets_source_id", value)} value={criterias.sheets_source_id} options={sources} multiple />
                                            <FilterComponent type="select" label={t("Emetteur")} name="sheets_transmitter_id" onChange={value => addCriteria("sheets_transmitter_id", value)} value={criterias.sheets_transmitter_id} options={transmitters} multiple />
                                            <FilterComponent label={t("Numéro NOR")} name="sheets_nor" onChange={value => addCriteria("sheets_nor", value)} value={criterias.sheets_nor} />
                                            {hasTechnicalLevelAccess && <>
                                                <FilterComponent type="select" label={t("Acteurs concernés")} name="sheets_involved_actor" onChange={value => addCriteria("sheets_involved_actor", value)} value={criterias.sheets_involved_actor} options={involved_actors} multiple />
                                                <FilterComponent type="select" label={t("Fonctions concernées")} name="sheets_involved_function" onChange={value => addCriteria("sheets_involved_function", value)} value={criterias.sheets_involved_function} options={involved_functions} multiple />
                                                <FilterComponent type="select" label={t("Impact du texte")} name="sheets_text_impact" onChange={value => addCriteria("sheets_text_impact", value)} value={criterias.sheets_text_impact} options={text_impacts} multiple />
                                            </>}
                                            <FilterComponent type="select" label={t("Descripteurs")} name="sheets_descriptor" onChange={value => addCriteria("sheets_descriptor", value)} value={criterias.sheets_descriptor} options={descriptors} multiple />
                                            <div className="col-md-3"></div>
                                            <FilterComponent type="radio" label={t("Texte abrogé")} name="sheets_repealed_text" onChange={value => addCriteria("sheets_repealed_text", value)} value={criterias.sheets_repealed_text} blankLabel="Tous" options={[{value: "1", label: t("Oui")}, {value: "0", label: t("Non")}]} />
                                            <FilterComponent type="radio" label={t("Texte majeur")} name="sheets_major_text" onChange={value => addCriteria("sheets_major_text", value)} value={criterias.sheets_major_text} blankLabel="Tous" options={[{value: "1", label: t("Oui")}, {value: "0", label: t("Non")}]} />
                                            <FilterComponent type="radio" label={t("Texte découpé en exigences")} name="sheets_cut_into_requirements" onChange={value => addCriteria("sheets_cut_into_requirements", value)} value={criterias.sheets_cut_into_requirements} blankLabel="Tous" options={[{value: "1", label: t("Oui")}, {value: "0", label: t("Non")}]} />
                                            <FilterComponent type="radio" label={t("Fiche privée")} name="sheets_private" onChange={value => addCriteria("sheets_private", value)} value={criterias.sheets_private} blankLabel="Tous" options={[{value: "1", label: t("Oui")}, {value: "0", label: t("Non")}]} />
                                            {hasTechnicalLevelAccess && <>
                                                <FilterComponent type="degree-checkbox" label={t("Degré d'impact corporel")} name="sheets_corporal_impact_degree" onChange={value => addCriteria("sheets_corporal_impact_degree", value)} value={criterias.sheets_corporal_impact_degree} options={getDegree()} />
                                                <FilterComponent type="degree-checkbox" label={t("Degré d'impact juridique")} name="sheets_legal_impact_degree" onChange={value => addCriteria("sheets_legal_impact_degree", value)} value={criterias.sheets_legal_impact_degree} options={getDegree()} />
                                                <FilterComponent type="degree-checkbox" label={t("Degré d'impact médiatique")} name="sheets_media_impact_degree" onChange={value => addCriteria("sheets_media_impact_degree", value)} value={criterias.sheets_media_impact_degree} options={getDegree()} />
                                                <FilterComponent type="degree-checkbox" label={t("Degré d'impact financier")} name="sheets_financial_impact_degree" onChange={value => addCriteria("sheets_financial_impact_degree", value)} value={criterias.sheets_financial_impact_degree} options={getDegree()} />
                                            </>}
                                            <FilterComponent label={t("Zone d'application géographique")} type="select" multiple={true} name="sheets_application_areas" onChange={value => addCriteria("sheets_application_areas", value)} value={criterias.sheets_application_areas} options={application_areas} />
                                        </div>
                                    </div>
                                </section>
                                <section>
                                    <header className="bg-gris-30">
                                        <h3>Criteres sur les fiches affectées</h3>
                                    </header>
                                    <div className="bg-gris-25">
                                        <div className="row">
                                            <FilterComponent type="select" label={t("Responsable de la dernière évaluation")} name="sheet_affected_responsable_evaluation" onChange={value => addCriteria("sheet_affected_responsable_evaluation", value)} value={criterias.sheet_affected_responsable_evaluation} options={accountUsers} />
                                            {hasComplianceManagementAccess && <>
                                                <FilterComponent label={t("Mode gestion")} type="select" name="sheet_affected_conformity" onChange={value => addCriteria("sheet_affected_mode", value)} value={criterias.sheet_affected_mode} options={getConformityModes()} clearable />
                                                <FilterComponent label={t("Conformité de la fiche")} type="buttons" name="sheet_affected_conformity" onChange={value => addCriteria("sheet_affected_conformity", value)} value={criterias.sheet_affected_conformity} clearable options={getConformities()} multiple />
                                                <FilterComponent type="select" label={t("Priorité")} name="sheet_affected_priority" onChange={value => addCriteria("sheet_affected_priority", value)} value={criterias.sheet_affected_priority} clearable options={getPriorities()} />
                                                <FilterComponent type="daterange" label={t("Date de revue")} name="sheet_affected_review_date" onChange={value => addCriteria("sheet_affected_review_date", value)} value={criterias.sheet_affected_review_date} />
                                                <FilterComponent type="daterange" label={t("Date d'évaluation")} name="sheet_affected_evaluation_date" onChange={value => addCriteria("sheet_affected_evaluation_date", value)} value={criterias.sheet_affected_evaluation_date} />
                                            </>}
                                            <FilterComponent type="daterange" label={t("Date de masquage")} name="sheet_affected_hidden_date" onChange={value => addCriteria("sheet_affected_hidden_date", value)} value={criterias.sheet_affected_hidden_date} />
                                            <FilterComponent type="daterange" label={t("Date de mise en ligne")} name="sheet_affected_affectation_date" onChange={value => addCriteria("sheet_affected_affectation_date", value)} value={criterias.sheet_affected_affectation_date} />
                                            {hasCategoriesAccess && <FilterComponent type="dropdown-tree-select" label={AccountUtils.getCategoriesLabel(account)} name="sheet_affected_categories" onChange={value => addCriteria("sheet_affected_categories", value)} value={criterias.sheet_affected_categories} data={categoryTree} mode="multiSelect" />}
                                            <FilterComponent type="radio" label={t("Fiche masquée")} name="sheet_affected_hidden" onChange={value => addCriteria("sheet_affected_hidden", value)} value={criterias.sheet_affected_hidden} blankLabel="Tous" options={[{value: "1", label: t("Oui")}, {value: "0", label: t("Non")}]} />                                            
                                        </div>
                                    </div>
                                </section>                                
                                <section>
                                    <header className="bg-gris-30">
                                        <h3>Criteres sur les exigences affectées</h3>
                                    </header>
                                    <div className="bg-gris-25">
                                        <div className="row">
                                            {hasComplianceManagementAccess && <>
                                                <FilterComponent type="daterange" label={t("Date de revue de l'exigence")} name="requirements_affected_review_date" onChange={value => addCriteria("requirements_affected_review_date", value)} value={criterias.requirements_affected_review_date} />
                                                <FilterComponent type="daterange" label={t("Date d'évaluation de l'exigence")} name="requirements_affected_evaluation_date" onChange={value => addCriteria("requirements_affected_evaluation_date", value)} value={criterias.requirements_affected_evaluation_date} />
                                                <FilterComponent label={t("Conformité de l'exigence")} type="buttons" name="requirements_affected_conformity" onChange={value => addCriteria("requirements_affected_conformity", value)} value={criterias.requirements_affected_conformity} clearable options={getConformities()} multiple />
                                                <FilterComponent type="select" label={t("Priorité de l'exigence")} name="requirements_affected_priority" onChange={value => addCriteria("requirements_affected_priority", value)} value={criterias.requirements_affected_priority} clearable options={getPriorities()} />
                                            </>}
                                            <FilterComponent type="radio" label={t("Exigence masquée")} name="requirements_affected_hidden" onChange={value => addCriteria("requirements_affected_hidden", value)} value={criterias.requirements_hidden} blankLabel="Tous" options={[{value: "1", label: t("Oui")}, {value: "0", label: t("Non")}]} />
                                            <FilterComponent label={t("État d'applicabilité de l'exigence")} type="buttons" name="requirements_applicability_state" onChange={value => addCriteria("requirements_applicability_state", value)} value={criterias.requirements_applicability_state} clearable options={getApplicabilities()} multiple />
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </section>

                        <div className="bg-gris-25 border-b border-gris-60">
                            <div className="row">
                                <div className="col-md-9">
                                    <button id="clearfilter-action" onClick={clearfilter} type="button" className="btn btn-bleu-4 icon"><i className="icon-filtres-poubelle" aria-hidden="true"></i>Réinitialiser la recherche</button>
                                </div>
                                <div className="col-md-3 text-right">                                    
                                    <LoadButton id="search-action" type="submit" label={t("Rechercher")} loading={loading} />
                                </div>
                            </div>
                        </div>
                    </section>

                    <ListComponent 
                        id="actions"
                        loading={loading}
                        className="table table-bordered table-striped scrollable"
                        selectable={true}
                        selection={selection}
                        onSelect={(s) => setSelection(s)}
                        rows={rows}
                        columns={columns}
                        sorting={sorting}
                        direction={direction}
                        onSortingChange={updateSorting}
                        perpage={limit}
                        onPerpageChange={updateLimit}
                        page={page}
                        onPageChange={updatePage}
                        totalrows={totalrows}
                        globalActions={(
                            <>
                                <Link id="new-action" to={prefixLinkTo() + getNewUri()} className="btn btn-primary h25 icon">
                                    <i className="icon-boutons-plus" aria-hidden="true"></i>&nbsp;Créer une action
                                </Link>
                                <LoadButton
                                    loading={exporting}
                                    onClick={exportActions}
                                    label={t("Exporter")}
                                    id="export-actions"
                                    className="btn btn-primary h25"
                                    iconClassName="icon-file-excel"
                                />
                            </>
                        )}
                        batchActions={<ActionListBatch cleanSelection={() => setSelection([])} rows={rows} selection={selection} afterUpdate={refresh} pilots={accountUsers} />}
                    />
                </form>                
            </div>
            <StartRestrictionMessageComponent />
            </section>
        </DefaultLayout>
    );
}