import React, { useEffect, useState, useContext } from "react";
import { ReferentialContext } from 'shared/stores/Referential';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import fileDownload from 'js-file-download';
import Modal from 'react-modal';
import { useAccount } from 'hooks/Account';
import { useAccess } from 'hooks/Access';
import SheetApi from 'shared/api/Sheet';
import { smallModalCustomStyles } from 'shared/services/ModalStyle';
import SheetUtils from "shared/services/SheetUtils";
import { denyIfCantPerform, canPerform } from "shared/services/Can";
import PageLoadingComponent from "shared/components/PageLoadingComponent";
import LoadButton from 'shared/components/LoadButton';
import SheetCommonDetail from 'shared/partials/SheetCommonDetail';
import AutoaffectationForm from 'partials/AutoaffectationForm';
import FileUtils from 'shared/services/FileUtils';

export default function SheetDetail(props) {

    const { t } = useTranslation();
    const history = useHistory();
    const [account] = useAccount();
    const [,, writableWatchsites, readableWatchsites] = useAccess();
    const [exporting, setExporting] = useState("");
    const [autoaffecting, setAutoaffecting] = useState(false);
    const [sheet, setSheet] = useState({});
    const [isLoad, setIsLoad] = useState(true);
    const [referentialContext] = useContext(ReferentialContext);
    
    denyIfCantPerform(props, "sheet:read", {account: account, writableWatchsites: writableWatchsites, readableWatchsites: readableWatchsites});

    const userTree = referentialContext["writableAccountTree"];

    useEffect(() => {
        const sheetId = props.match.params.id;
        if (sheetId) {
            SheetApi
                .get(sheetId)
                .then(freshSheet => {
                    setSheet(freshSheet);
                    setIsLoad(false);
                })
                .catch(response => {
                    toast.error(t("Une erreur est survenue lors de la récupération de la fiche"));
                    history.goBack();
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props]);

    function exportPdf(event) {
        event.preventDefault();
        setExporting("export-sheet");

        const accountName = FileUtils.getCleanFileName(account.name);

        SheetApi
            .exportPdf(sheet.id)
            .then(binaries => {
                setExporting("");
                fileDownload(binaries, "fiche-" + sheet.id + "-" + accountName + ".pdf");
            })
            .catch(error => {
                setExporting("");
                toast.error(error.response.data.message);
            });
    }
    
    function exportRequirements() {
        setExporting("export-requirements");
        SheetApi
            .exportRequirements(sheet.id)
            .then(binaries => {
                setExporting("");
                fileDownload(binaries, FileUtils.getFilePrefix() + "-Fiche" + sheet.id + "-Exigences.xlsx");
            })
            .catch(error => {
                setExporting("");
                toast.error(error.response.data.message);
            });
    }

    if (isLoad === true) {
        return <PageLoadingComponent label={t("Détail fiche publique")} />
    }

    const sheetStatesWarning = SheetUtils.getSheetStatesWarning(sheet);

    return (
        <SheetCommonDetail 
            title={`Fiche ${sheet.id}`}
            displaySynthesisLevel={canPerform("account:synthesis_level", {account: account})}
            displayTechnicalLevel={canPerform("account:technical_level", {account: account})}
            sheet={sheet}
            warning={sheetStatesWarning ? [sheetStatesWarning] : null}
            screen="E32"
        >
            <section className="row">
                <div className="col-md-2"></div>
                <div className="col-md-10 text-right">
                    <LoadButton 
                        loading={exporting === "export-sheet"} 
                        label={t("Exporter en PDF")}
                        name="export-sheet"
                        id="export-sheet"
                        onClick={exportPdf}
                    />
                    {sheet.cut_into_requirements === true && (
                        <LoadButton 
                            loading={exporting === "export-requirements"} 
                            label={"Exporter les exigences"}
                            name="export-requirements"
                            id="export-requirements"
                            onClick={exportRequirements}
                        />
                    )}
                </div>
            </section>
            <Modal isOpen={autoaffecting} onRequestClose={() => setAutoaffecting(false)} style={smallModalCustomStyles}>
                <AutoaffectationForm
                    sheet={sheet}
                    userTree={userTree}
                    onRequestClose={() => setAutoaffecting(false)}
                />
            </Modal>

        </SheetCommonDetail>
    );
}