import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import FavoriteApi from 'api/Favorite';
import BarLoader from "react-spinners/ClipLoader";
import { toast } from 'react-toastify';

export default function FavoriteButtonComponent(props) {

    const { t } = useTranslation();
    const { item, isFavorite, type } = props;
    const [processing, setProcessing] = useState(false);
    const [favorite, setFavorite] = useState(isFavorite);

    function toggleFavorite(e) {
        e.preventDefault();
        setProcessing(true);

        if (favorite) {
            FavoriteApi
                .remove(type, item)
                .then(result => {
                    setFavorite(false);
                    setProcessing(false);
                    toast.success(t("Retirer des favoris"));
                })
                .catch(() => {
                    setProcessing(false);
                    toast.error(t("Une erreur est survenue lors de la suppression des favoris"))
                });
        } else {
            FavoriteApi
                .add(type, item)
                .then(result => {
                    setFavorite(true);
                    setProcessing(false);
                    toast.success(t("Ajouter aux favoris"));
                })
                .catch(() => {
                    setProcessing(false);
                    toast.error(t("Une erreur est survenue lors de l'ajout aux favoris"))
                });
        }
    }

    return (
        <button title={favorite ? "Retirer des favoris" : "Ajouter au favoris"} onClick={toggleFavorite} style={{outline: "none"}}>
            {processing && <BarLoader color="#5bad27" loading={true} size={15} css="display:block;margin:0px auto;" />}
            {!processing && <i className={favorite ? "icon-boutons-afficher-favoris" : "icon-actions-ajouter-favorits"} aria-hidden="true"></i>}
        </button>
    );
}