import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, Prompt, useHistory } from 'react-router-dom';
import _ from 'lodash';
import { toast } from 'react-toastify';
import fileDownload from 'js-file-download';
import Modal from 'react-modal';
import SheetCommonDetail from 'shared/partials/SheetCommonDetail';
import AutoaffectationForm from 'partials/AutoaffectationForm';
import PageLoadingComponent from 'shared/components/PageLoadingComponent';
import ToggableBlocComponent from "shared/components/ToggableBlocComponent";
import FieldComponent from 'shared/components/FieldComponent';
import LoadButton from 'shared/components/LoadButton';
import HtmlComponent from "shared/components/HtmlComponent";
import FileComponent from 'shared/components/FileComponent';
import FileListComponent from 'shared/components/FileListComponent';
import AccessApi from 'shared/api/Access';
import SheetAffectedApi from 'shared/api/SheetAffected';
import { useForm } from 'shared/hooks/Form';
import { useToggler } from "shared/hooks/Toggler";
import { useAccount } from 'hooks/Account';
import { useAccess } from 'hooks/Access';
import { useComments} from 'hooks/Comments';
import { useCountryPartitioning } from 'hooks/CountryPartitioning';
import { ReferentialContext } from 'shared/stores/Referential';
import { CONFORMITY_MODE_REQUIREMENT, getConformities, getConformityModes } from 'data/SheetAffected';
import { getPriorities } from 'data/Priority';
import { REFERENTIAL_CATEGORY } from 'shared/data/ReferentialType';
import CommentsComponent from 'components/CommentsComponent';
import StartRestrictionMessageComponent from 'components/StartRestrictionMessageComponent';
import SheetAffectedCategoryPicker from 'views/sheetaffected/form/SheetAffectedCategoryPicker';
import { canPerform, denyIfCantPerform } from "shared/services/Can";
import { formatDate, formatUser, compileDataToSelectOptions, compileErrorsFromResponse } from "shared/services/Utils";
import AccountUtils from 'shared/services/AccountUtils';
import SheetUtils from "shared/services/SheetUtils";
import FileUtils from "shared/services/FileUtils";
import { smallModalCustomStyles } from 'shared/services/ModalStyle';
import { prefixLinkTo } from 'shared/services';
import TreeReferentialComponent from "shared/components/tree/TreeReferentialComponent";
import StatusComponent from 'shared/components/StatusComponent';

export default function SheetAffectedForm(props) {

    const { t } = useTranslation();
    const [autoaffecting, setAutoaffecting] = useState(false);
    const [exporting, setExporting] = useState(false);
    const [sheetForAutoaffectation] = useState(null);
    const history = useHistory();
    const [account] = useAccount();
    const [canWrite] = useAccess();
    const [countries] = useCountryPartitioning();
    const [saving, setSaving] = useState(false);
    const [loading, setLoading] = useState(true);
    const [sheetAffected, setSheetAffected] = useState(null);
    const [hasWriteAccess, setHasWriteAccess] = useState(false);
    const [pilots, setPilots] = useState([]);
    const [, toggle,,,,,getToggler] = useToggler({
        'comment_consultant': true,
        'comment_headquarters': true,
        'comments': true,
        'conformity': true,
        'categories': true,
        'attachments': true,
        'visibility': true,
        'others': true,
    });
    const [referentialContext] = useContext(ReferentialContext);

    // referential
    const categoryTree = referentialContext[REFERENTIAL_CATEGORY];
    const accountTree = referentialContext["accountTree"];

    denyIfCantPerform(props, "sheetaffected:read", {account: account});

    const [data, errors, setErrors, setValue, setData, reloadOrClose, formHasModifications] = useForm({});
    const [commentActions, commentErrors, onCommentErrors, resetCommentErrors, doCommentActions, setCommentActions] = useComments(SheetAffectedApi);

    function formatData(formData) {
        return {
            'id': formData.id,
            'sheet': formData.sheet.id,
            'watchsite': formData.watchsite.id,
            'categories': formData.categories.map(c => c.id),
            'priority': formData.priority,
            'evaluation_date': formData.evaluation_date ? new Date(formData.evaluation_date) : null,
            'review_date': formData.review_date ? new Date(formData.review_date) : null,
            'conformity_mode': formData.conformity_mode,
            'conformity': formData.conformity,
            'include_in_alert': formData.include_in_alert,
            'hidden': formData.hidden ? 1 : 0,
            'comments': formData.comments,
            'attachments': formData.attachments,
            'pilot': formData.pilot ? formData.pilot.id : null,
        };
    }

    useEffect(() => {
        SheetAffectedApi
            .get(props.match.params.id)
            .then(newData => {
                setData(formatData(newData));
                setSheetAffected(newData);
                setHasWriteAccess(canWrite(newData.watchsite.id));

                AccessApi
                    .getUsersWithWriteAccess(newData.watchsite.id)
                    .then(users => {
                        setPilots(
                            compileDataToSelectOptions(users, 'id',
                                [
                                    'firstname',
                                    'lastname',
                                    {
                                        field:'email',
                                        render:(v) => '(' + v + ')'
                                    }
                                ]
                            )
                        );
                    });
            
                setLoading(false);
            })
            .catch((e) => {
                toast.error(t("Une erreur est survenue lors de la récupération de la fiche"));
                history.goBack();
            });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function submit(event) {
        event.preventDefault();
        setSaving(true);
        resetCommentErrors();

        doCommentActions(sheetAffected.id, data.comments).then(() => {
            let json = {...data};
            json.hidden = data.hidden === 1 ? true : false;
            json.attachments = data.attachments.map(file => file.hash) || [];
            json.comments = [];

            SheetAffectedApi
                .save(json)
                .then((newData) => {
                    setData(formatData(newData));
                    setSheetAffected(newData);

                    setErrors({});
                    setSaving(false);
                    toast.success(t("Enregistrement effectué."));
                    reloadOrClose(event, props, "sheets-affected", sheetAffected.id);
                })
                .catch((error) => {
                    if (error.response) {
                        if (error.response.data.message) {
                            toast.error(error.response.data.message);                    
                        } else {
                            setErrors(compileErrorsFromResponse(error.response));
                            toast.error(t("Des erreurs sont survenues"));
                        }
                    }
                });
        }).catch((error) => {
            setSaving(false);
            onCommentErrors(error);
        });
    }

    function exportPdf(event, includeReglementaryText = false) {
        event.preventDefault();
        setExporting(true);
        SheetAffectedApi
            .exportPdf(sheetAffected.id, includeReglementaryText)
            .then(binaries => {
                setExporting("");
                fileDownload(
                    binaries,
                    "fiche-" + sheetAffected.sheet.id + "-" + FileUtils.getCleanFileName(sheetAffected.watchsite.label) + '.pdf'
                );
            })
            .catch(error => {
                setExporting("");
                toast.error(error.response.data.message);
            });
    }

    function updateEvaluationDate(evaluationDate) {
        const newData = {...data};
        newData.evaluation_date = evaluationDate;

        if (evaluationDate && account.default_review_date) {
            let reviewDate = new Date(evaluationDate);
            reviewDate.setMonth(reviewDate.getMonth() + account.default_review_date);
            newData.review_date = reviewDate;
        }

        setData(newData);
    }

    if (loading === true) {
        return <PageLoadingComponent label={t("Conformité de la fiche")} />
    }

    const sheetStatesWarning = SheetUtils.getSheetStatesWarning(sheetAffected.sheet);

    return (
        <SheetCommonDetail 
            title={`Conformité de la fiche <strong>${sheetAffected.sheet.id}</strong> pour le point de veille <strong>${sheetAffected.watchsite.label}</strong>`}
            displaySynthesisLevel={canPerform("account:synthesis_level", {account: account})}
            displayTechnicalLevel={canPerform("account:technical_level", {account: account})}
            sheet={sheetAffected.sheet}
            warning={sheetStatesWarning ? [sheetStatesWarning] : null}
            affectedLinkedSheets={sheetAffected.link_affected_sheet_ids || []}
            sidebar={[
                {anchor: "#comments", label: t("Suivi"), active: true, childs: [
                    {anchor: "#comments", label: t("Commentaires"), active: true},
                    {anchor: "#conformity", label: t("Conformité"), active: (account.access_compliance_management === 1)},
                    {anchor: "#categories", label: t("Catégories"), active: true},
                    {anchor: "#sheet-affected-attachments", label: t("Fichier(s) joint(s)"), active: true},
                    {anchor: "#visibility", label: t("Visibilité"), active: true},
                    {anchor: "#others", label: t("Autres informations"), active: true},
                ]}
            ]}
            screen="E33"
            displayCountry={countries.length > 1}
        >
            <Prompt
                when={formHasModifications()}
                message={t("Vous avez des modifications non enregistrées, voulez-vous vraiment quitter ?")}
            />
            <form onSubmit={submit} id="form-sheet-affected" className="form">
                <section>
                    <span id="comments" className="anchor"></span>
                    {canPerform("account:consultant_level", {account: account}) &&
                        <ToggableBlocComponent
                            label={t("Commentaire consultant")}
                            id="comment_consultant"
                            toggled={getToggler("comment_consultant")}
                            toggle={toggle}
                            className="toggle-group border border-gris-40"
                        >
                            <div className="bg-blanc">
                                <HtmlComponent>{sheetAffected.comment_consultant}</HtmlComponent>
                            </div>
                        </ToggableBlocComponent>
                    }
                    {canPerform("account:headquarter_comment", {account: account}) && sheetAffected.comment_headquarters && 
                        <ToggableBlocComponent
                            label={t("Commentaire siège")}
                            id="comment_headquarters"
                            toggled={getToggler("comment_headquarters")}
                            toggle={toggle}
                            className="toggle-group border border-gris-40"
                        >
                            <div className="bg-blanc">
                                <HtmlComponent>{sheetAffected.comment_headquarters}</HtmlComponent>
                            </div>
                        </ToggableBlocComponent>
                    }
                    {canPerform("account:customer_comment", {account: account}) &&
                        <ToggableBlocComponent
                            label={"Commentaire(s) " + _.truncate(sheetAffected.watchsite.label, {length: 150})}
                            id="comments"
                            toggled={getToggler("comments")}
                            toggle={toggle}
                            className="toggle-group border border-gris-40"
                        >
                            <CommentsComponent
                                value={data.comments}
                                onChange={values => setValue("comments", values)}
                                actions={commentActions}
                                onAction={values => setCommentActions(values)}
                                errors={commentErrors}
                                watchsite={sheetAffected.watchsite}
                            />
                        </ToggableBlocComponent>
                    }

                    {canPerform("account:compliance_management", {account: account}) &&
                        <ToggableBlocComponent
                            label={"Conformité pour " + sheetAffected.watchsite.label}
                            id="conformity"
                            toggled={getToggler("conformity")}
                            toggle={toggle}
                            className="toggle-group border border-gris-40"
                        >
                            <div className="bg-blanc border-b border-gris-40">
                                <FieldComponent 
                                    type="buttons"
                                    name="conformity_mode" 
                                    label={t("Mode de gestion")} 
                                    value={data.conformity_mode}
                                    preview={!hasWriteAccess}
                                    onChange={value => setValue("conformity_mode", parseInt(value))} 
                                    options={getConformityModes(sheetAffected.sheet.cut_into_requirements)}
                                    disabled={account.is_start === true}
                                />
                            </div>
                            <div className="bg-blanc border-b border-gris-40">
                                {data.conformity_mode !== CONFORMITY_MODE_REQUIREMENT && <>
                                    <div style={{maxWidth: (data.conformity_mode !== CONFORMITY_MODE_REQUIREMENT || !hasWriteAccess) ? 1000 : 650}}>
                                        <FieldComponent 
                                            wrapButtonsClassName="statuts conformity"
                                            type="buttons"
                                            name="conformity" 
                                            label={t("Statut de conformité")} 
                                            value={data.conformity} 
                                            onChange={value => setValue("conformity", parseInt(value))} 
                                            options={getConformities()}
                                            preview={!hasWriteAccess}
                                            valueRender={(value, options) => <StatusComponent value={value} options={options} />}
                                            disabled={account.is_start === true}
                                        />
                                    </div>
                                
                                    <div className="bg-blanc">
                                        <FieldComponent 
                                            type="buttons"
                                            name="priority" 
                                            label={t("Priorité")} 
                                            value={data.priority} 
                                            onChange={value => setValue("priority", parseInt(value))} 
                                            options={getPriorities()}
                                            preview={!hasWriteAccess}
                                            valueRender={(value, options) => <StatusComponent value={value} options={options} size="medium" />}
                                        />
                                    </div>
                                </>}
                                {data.conformity_mode === CONFORMITY_MODE_REQUIREMENT && account.is_start === false &&
                                    <div className="flex-label">
                                        <label>Priorité : </label>
                                        <StatusComponent value={sheetAffected.equivalent_priority} options={getPriorities()} size="medium" />
                                    </div>
                                }
                            </div>
                            {data.conformity_mode === CONFORMITY_MODE_REQUIREMENT && sheetAffected.indicators && account.is_start === false &&
                                <div className="border-b border-gris-40 bg-gris-10">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="flex-label">
                                                <label>Exigences : </label>
                                                <span id="nb_requirements">{sheetAffected.indicators.nb_requirements} exigence(s) suivi(s)</span>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="flex-label">
                                                <label>Indicateurs de conformités : </label>
                                                <ul className="arborescence no-arrow">
                                                    <li id="indicators_ok">Conforme : {sheetAffected.indicators.ok}%</li>
                                                    <li id="indicators_nok">Ecart : {sheetAffected.indicators.nok}%</li>
                                                    <li id="indicators_tospecified">A préciser : {sheetAffected.indicators.tospecified}%</li>
                                                    <li id="indicators_new">Nouveau : {sheetAffected.indicators.new}%</li>
                                                    <li id="indicators_info">Pour information : {sheetAffected.indicators.info}%</li>
                                                    <li id="indicators_na">Non applicable : {sheetAffected.indicators.na}%</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                            <div className={"border-b border-gris-40 " + (data.conformity_mode === CONFORMITY_MODE_REQUIREMENT ? "bg-blanc" : "bg-gris-10")}>
                                <div className="row">
                                    {data.conformity_mode !== CONFORMITY_MODE_REQUIREMENT && <>
                                        <div className="col-md-6">
                                            <FieldComponent 
                                                type="date"
                                                name="evaluation_date" 
                                                label={t("Date d'évaluation")} 
                                                value={data.evaluation_date} 
                                                onChange={value => updateEvaluationDate(value)} 
                                                preview={!hasWriteAccess}
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <FieldComponent 
                                                type="date"
                                                name="review_date" 
                                                label={t("Date de revue")} 
                                                value={data.review_date}
                                                onChange={value => setValue("review_date", value)} 
                                                preview={!hasWriteAccess}
                                            />
                                        </div>
                                    </>}
                                    {data.conformity_mode === CONFORMITY_MODE_REQUIREMENT && <>
                                        <div className="col-md-6">
                                            <div className="flex-label">
                                                <label>Date d'évaluation</label>
                                                <span id="equivalent_evaluation_date">{formatDate(sheetAffected.equivalent_evaluation_date)}</span>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="flex-label">
                                                <label>Date de revue</label>
                                                <span id="equivalent_review_date">{formatDate(sheetAffected.equivalent_review_date)}</span>
                                            </div>
                                        </div>
                                    </>}
                                </div>
                            </div>
                            <div className={"border-b border-gris-40 " + (data.conformity_mode !== CONFORMITY_MODE_REQUIREMENT ? "bg-blanc" : "bg-gris-10")}>
                                <div className="row">
                                    <div className="col-md-6">
                                        <FieldComponent 
                                            type="select"
                                            name="pilot" 
                                            label={t("Pilote")}
                                            value={data.pilot} 
                                            onChange={value => setValue("pilot", value)} 
                                            clearable
                                            options={pilots}
                                            className="field"
                                            preview={!hasWriteAccess}
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <div className="flex-label">
                                            <label>Date de désignation :</label>
                                            <span id="pilot_updated_at">{formatDate(sheetAffected.pilot_updated_at, true) || "Inconnue"}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <StartRestrictionMessageComponent />
                        </ToggableBlocComponent>                        
                    }

                    {canPerform("account:categories", {account: account}) && 
                        <ToggableBlocComponent
                            label={AccountUtils.getCategoriesLabel(account)}
                            id="categories"
                            toggled={getToggler("categories")}
                            toggle={toggle}
                            className="toggle-group border border-gris-40"
                        >
                            <div className="bg-blanc">
                                {hasWriteAccess &&
                                    <SheetAffectedCategoryPicker
                                        categories={categoryTree}
                                        value={data.categories}
                                        onChange={value => setValue("categories", value)}
                                    />
                                }
                                {!hasWriteAccess && 
                                    <div className="bg-blanc">
                                        <TreeReferentialComponent 
                                            readOnly 
                                            className="arborescence" 
                                            value={data.categories} 
                                            items={categoryTree} 
                                        />
                                    </div>
                                }
                            </div>
                        </ToggableBlocComponent>   
                    }

                    <ToggableBlocComponent
                        label={t("Fichier(s) joint(s)")}
                        id="attachments"
                        toggled={getToggler("attachments")}
                        toggle={toggle}
                        className="toggle-group border border-gris-40"
                    >
                        <div className="bg-blanc">
                            {hasWriteAccess && 
                                <FileComponent
                                    name="sheet-affected-attachments"
                                    label={t("Joindre un fichier")}
                                    upload={SheetAffectedApi.uploadAttachments}
                                    value={data.attachments}
                                    error={errors.attachments}
                                    extensions={['.pdf', '.doc', '.docx', '.xls', '.xlsx', '.ppt', '.pptx', '.jpg', '.jpeg', '.png']}
                                    showDate
                                    showSize
                                    multiple
                                    onChange={values => setValue("attachments", values)}
                                    template="table"
                                />
                            }
                            {!hasWriteAccess &&
                                <div className="bg-blanc">
                                    <FileListComponent
                                        values={data.attachments}
                                        showDate={true}
                                        showSize={true}
                                    />
                                </div>
                            }
                        </div>
                    </ToggableBlocComponent>

                    <ToggableBlocComponent
                        label={t("Visibilité")}
                        id="visibility"
                        toggled={getToggler("visibility")}
                        toggle={toggle}
                        className="toggle-group border border-gris-40"
                    >
                        <div className="bg-blanc">
                            <FieldComponent 
                                type="radio"
                                name="hidden" 
                                label={t("Fiche masquée")} 
                                value={data.hidden} 
                                onChange={value => setValue("hidden", parseInt(value))} 
                                options={[
                                    {value: 1, label: t("Oui")},
                                    {value: 0, label: t("Non")}
                                ]}
                                preview={!hasWriteAccess}
                            />
                            {sheetAffected.hidden_date && sheetAffected.hidden && (
                                <div className="flex-label">
                                    <label>Date de masquage : </label>
                                    {formatDate(sheetAffected.hidden_date, true)}
                                </div>
                            )}
                        </div>
                    </ToggableBlocComponent>

                    <ToggableBlocComponent
                        label={t("Autres informations")}
                        id="others"
                        toggled={getToggler("others")}
                        toggle={toggle}
                        className="toggle-group border border-gris-40"
                        margin={20}
                    >
                        <div className="bg-blanc">
                            <div className="row">
                                <div className="col-md-4">
                                    <label>Auteur de la dernière modification :</label>
                                    {formatUser(sheetAffected.updated_by)}
                                </div>
                                <div className="col-md-4">
                                    {sheetAffected.created_by && <>
                                        <label>Auteur de l'affectation :</label>
                                        {formatUser(sheetAffected.created_by)}
                                    </>}
                                </div>
                                <div className="col-md-4">
                                    <label>Date d'affectation client :</label>
                                    {formatDate(sheetAffected.affectation_date, true)}
                                </div>
                            </div>
                        </div>
                    </ToggableBlocComponent>                    
                </section>
                <section className="row">
                    <div className="col">
                        <Link id="link-to-history" className="btn btn-primary" to={prefixLinkTo() + `/history/sheets_affected/${sheetAffected.id}`} target="_blank">
                            <i className="icon-boutons-voir-historique" aria-hidden="true"></i>&nbsp;Voir l'historique
                        </Link>
                        <LoadButton loading={exporting} type="button" id="sheet-affected-export-pdf" name="export-pdf" onClick={(e) => exportPdf(e, false)} label={t("Export PDF")} iconClassName="icon-actions-consulter-pdf" />
                        <LoadButton loading={exporting} type="button" id="sheet-affected-export-pdf-with-text" name="export-pdf-with-text" onClick={(e) => exportPdf(e, true)} label="Export PDF complet" iconClassName="icon-actions-consulter-pdf" />
                    </div>
                    {account.is_start === false && <div className="col text-center">
                        {sheetAffected.sheet.cut_into_requirements && data.conformity_mode === CONFORMITY_MODE_REQUIREMENT && 
                            <Link id="sheet-affected-requirements" className="btn btn-primary" to={prefixLinkTo() + `/requirements-affected?watchsite=${sheetAffected.watchsite.id}&sheet=${sheetAffected.sheet.id}`}>
                                <i className="icon-boutons-lien-vers" aria-hidden="true"></i>&nbsp;Exigences ({sheetAffected.indicators ? sheetAffected.indicators.nb_requirements : 0})
                            </Link>
                        }
                    </div>}
                    <div className="col text-right">
                        {account.is_start === false && canPerform("account:actionplan", {account: account}) && <>
                            {hasWriteAccess && <Link id="sheet-affected-new-action" className="btn btn-primary" to={prefixLinkTo() + `/actions/new?watchsites=${sheetAffected.watchsite.id}&sheets=${sheetAffected.sheet.id}`}>
                                <i className="icon-wrench" aria-hidden="true"></i>&nbsp;Créer une action
                            </Link>}
                            <Link id="sheet-affected-actions" className="btn btn-primary" to={prefixLinkTo() + `/actions?watchsite=${sheetAffected.watchsite.id}&sheet=${sheetAffected.sheet.id}`}>
                                <i className="icon-wrench" aria-hidden="true"></i>&nbsp;Plan d'actions ({sheetAffected.indicators ? sheetAffected.indicators.nb_actions : 0})
                            </Link>
                        </>}                        
                    </div>
                </section>
                <hr />
                <section className="row">
                    <div className="col">
                        <Link id="back-sheet-affected-list" to={prefixLinkTo() + "/sheets-affected"} className="btn btn-bleu-4">{t("Retour à la veille reglementaire")}</Link>
                    </div>
                    {hasWriteAccess && <>                    
                        <div className="col text-right">
                            <LoadButton 
                                loading={saving} 
                                label={t("Enregistrer et fermer")}
                                name="save-and-close"
                                id="save-and-close-sheet-affected"
                            />
                            <LoadButton 
                                loading={saving} 
                                label={t("Enregistrer")} 
                                name="save" 
                                id="save-sheet-affected"
                            />
                        </div>
                    </>}
                </section>
            </form>
            <Modal isOpen={autoaffecting} onRequestClose={() => setAutoaffecting(false)} style={smallModalCustomStyles}>
                <AutoaffectationForm
                    sheet={sheetForAutoaffectation}
                    userTree={accountTree}
                    onRequestClose={() => setAutoaffecting(false)}
                />
            </Modal>
        </SheetCommonDetail>
    );
}