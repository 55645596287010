import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import DefaultLayout from "layouts/DefaultLayout";
import { denyIfCantPerform } from 'shared/services/Can';
import { prefixLinkTo } from 'shared/services';
import ToggableBlocComponent from "shared/components/ToggableBlocComponent";
import PageLoadingComponent from 'shared/components/PageLoadingComponent';
import { useToggler } from "shared/hooks/Toggler";
import NodeApi from 'shared/api/Node';
import NodeUsersList from 'views/tree/node/NodeUsersList';

export default function Node(props) {

    denyIfCantPerform(props, "tree:read");

    const { t } = useTranslation();
    const [togglers, toggle] = useToggler({'node-info': true, 'node-users': true});

    const [node, setNode] = useState();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (props.match.params.id) {
            NodeApi
                .get(props.match.params.id)
                .then(node => {
                    setNode(node);
                    setLoading(false);                    
                })
                .catch(error => console.log(error));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    if (loading) {
        return <PageLoadingComponent label={t("Configuration des utilisateurs du noeud")} />
    } else {
        return (
            <DefaultLayout title={t("Configuration des utilisateurs du noeud")}>
                <section>
                    <h1>{t("Configuration des utilisateurs du noeud")}</h1>                            
                    <ToggableBlocComponent label={t("Informations")} id="node-info" toggled={togglers["node-info"]} toggle={toggle}>
                        <form className="form">
                            <div className="flex-label">
                                <label>Noeud</label>
                                {node.label}
                            </div>
                        </form>
                    </ToggableBlocComponent>
                    <ToggableBlocComponent label={t("Configuration des utilisateurs")} id="node-users" toggled={togglers["node-users"]} toggle={toggle} wrapChildren={false}>
                        <NodeUsersList node={node.id} parent={node.parent} />
                    </ToggableBlocComponent>
                    <div className="row">
                        <div className="col">
                            <Link id="back-tree" to={prefixLinkTo() + "/tree"} className="btn btn-bleu-4">{t("Retour à l'arborescence")}</Link>
                        </div>
                    </div>
                </section>
            </DefaultLayout>
        );
    }
    
}