import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import DefaultLayout from "layouts/DefaultLayout";
import BarLoader from "react-spinners/BarLoader";
import DocumentarySpaceLinkApi from 'api/DocumentarySpaceLink';

export default function DocumentarySpaceLink(props) {

    const { t } = useTranslation();

    function redirect() {
        DocumentarySpaceLinkApi
            .link()
            .then(response => window.open(response.link))
            .catch(() => toast.error(t("Erreur durant la redirection")));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(redirect, []);

    return (
        <DefaultLayout title={t("Espace documentaire")}>
            <h1>{t("Espace documentaire")}</h1>
            <BarLoader loading={true} width={100} color="#5bad27" css="display:block;margin:10px auto;" />
            <p className="text-center">Redirection en cours</p>            
        </DefaultLayout>
    );
}