import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import BarLoader from "react-spinners/BarLoader";
import logoapave from "images/logoapave.svg";
import logopilotveille from "images/logopilotveille.png";
import { FieldComponent, LoadButton } from 'shared/components';
import { useForm } from 'shared/hooks';
import { PasswordApi } from 'shared/api';
import { compileErrorsFromResponse } from 'shared/services';
import { toast } from 'react-toastify';

export default function PasswordInitialization() {

    const hash = /users\/initpassword\/([a-z0-9]*)/g.exec(document.location.pathname)[1];
    const [data, errors, setErrors, setValue] = useForm({password: "", confirmation: ""});
    const [processing, setProcessing] = useState(false);
    const [validating, setValidating] = useState(true);

    function validRequest() {
        PasswordApi
            .validRequest(hash)
            .then(() => setValidating(false))
            .catch(() => {                
                toast.error("Demande de nouveau mot de passe non reconnue")
                setTimeout(() => {
                    document.location.href = "/denied";
                }, 2000);
            });
    }

    function submit(event) {
        event.preventDefault();
        setProcessing(true);

        PasswordApi
            .initPassword(hash, data.password, data.confirmation)
            .then(() => {
                toast.success("Votre de passe est à jour. Vous allez être redirigé")
                setTimeout(() => {
                    document.location.href = "/login";
                }, 2000);
            })
            .catch(error => {
                if (error.response.data.message) {
                    toast.error(error.response.data.message);
                } else {
                    setErrors(compileErrorsFromResponse(error.response));
                    toast.error("Des erreurs sont survenues");
                }
            })
            .finally(() => setProcessing(false));
    }

    useEffect(validRequest, [hash]);

    return (
        <section className="login">
            <form className="form" onSubmit={submit}>
                <h1>
                    <div className="main-logo">
                        <img src={logoapave} width="96" alt="Logo Apave" />
                    </div>
                    <div className="main-name">
                        <img src={logopilotveille} alt="Logo Pilot Veille" />&nbsp;&nbsp;Initialisation de votre mot de passe
                    </div>
                </h1>
                <div className="bloc">
                    <section>
                        <header>
                            <h2>Initialisation de votre mot de passe</h2>
                        </header>
                        <div className="bg-blanc border-rlb border-gris-40">
                            <BarLoader loading={validating} width={100} color="#5bad27" css="display:block;margin:10px auto;" />
                            {!validating && <>
                                <FieldComponent 
                                    name="password"
                                    label="Mot de passe"
                                    type="password"
                                    error={errors.password}
                                    value={data.password}
                                    onChange={value => setValue("password", value)}
                                />
                                <FieldComponent 
                                    name="confirmation"
                                    label="Confirmer le mot de passe"
                                    type="password"
                                    error={errors.confirmation}
                                    value={data.confirmation}
                                    onChange={value => setValue("confirmation", value)}
                                />
                                <div className="row">
                                    <div className="col-md-3 text-right order-2">
                                        <LoadButton
                                            loading={processing}
                                            loaderWidth={25}
                                            label="Envoyer"
                                            name="send"
                                            id="send"
                                            displayIcon={false}
                                        />
                                    </div>
                                    <div className="col-md-9 offset-label order-1">
                                        <Link
                                            id="back-login"
                                            to="/login"
                                            className="btn btn-secondary"
                                        >Retour à la connexion</Link>
                                    </div>
                                </div>
                            </>}
                        </div>
                    </section>
                </div>
            </form>
        </section>
    )
}