import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import SheetAffectedApi from 'shared/api/SheetAffected';
import { useForm } from 'shared/hooks/Form';
import { useToggler } from "shared/hooks/Toggler";
import FieldComponent from 'shared/components/FieldComponent';
import LoadButton from 'shared/components/LoadButton';
import ToggableBlocComponent from "shared/components/ToggableBlocComponent";
import PageLoadingComponent from 'shared/components/PageLoadingComponent';
import { compileErrorsFromResponse } from 'shared/services/Utils';

export default function AutoaffectationForm(props) {

    const { t } = useTranslation();
    const { sheet, userTree, onRequestClose } = props;
    const [data, errors, setErrors, setValue] = useForm({sheet: sheet.id, watchsites: [], alert: true});
    const [loading, setLoading] = useState(true);
    const [tree, setTree] = useState([]);
    const [affectations, setAffectations] = useState([]);
    const [affecting, setAffecting] = useState(false);
    const [togglers, toggle] = useToggler({"configuration": true, "sheet": true});

    useEffect(() => {
        SheetAffectedApi
            .getAffectations(sheet.id)
            .then(freshAffectations => {
                setLoading(false);
                setAffectations(freshAffectations)
            });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const watchsiteMapper = (watchsite) => {
            const affectation = affectations.filter(a => parseInt(a.watchsite.id) === parseInt(watchsite.id));
            let label = watchsite.label;
            if (affectation.length > 0) {
                label = label + (affectation[0].hidden ? " - Fiche masquée" : " - Déjà affectée");
            }
            return {
                "label": label,
                "value": "watchsite:" + watchsite.id,
                "force_checked": affectation.length > 0,
                "checked": data.watchsites.includes("watchsite:" + watchsite.id) || affectation.length > 0,
                "disabled": affectation.length > 0,
            } 
        };
        const nodeMapper = (node) => {
            let children = node.childs.map(nodeMapper);
            children = children.concat(node.watchsites.map(watchsiteMapper));
            return {
                "label": node.label,
                "value": "node:" + node.id,
                "children": children,
                "checked": data.watchsites.includes("node:" + node.id),
                "disabled": false,
                "expanded": true,
            }
        };

        if(userTree) {
            setTree(userTree.nodes.map(nodeMapper).concat(userTree.watchsites.map(watchsiteMapper)));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userTree, data.watchsites, affectations]);

    function affect(event) {
        event.preventDefault();
        setAffecting(true);

        setErrors({});

        SheetAffectedApi
            .bulkAffect({
                sheet: data.sheet,
                alert: data.alert,
                perimeter: data.watchsites
            })
            .then(() => {
                toast.success(t("Fiche affectée."));
                onRequestClose();
            })
            .catch(error => {
                setAffecting(false);
                if (error.response.data.message) {
                    toast.error(error.response.data.message);                    
                } else {
                    setErrors(compileErrorsFromResponse(error.response));
                    toast.error(t("Des erreurs sont survenues"));
                }
            });
    }

    if (loading) {
        return <PageLoadingComponent label={`Affectation de la fiche ${sheet.id}`} layout={false} />
    } else {
        return (
            <form onSubmit={affect} id="form-affect" className="form">
                <section>
                    <h1>Affectation de la fiche {sheet.id}</h1>
                    <ToggableBlocComponent label={t("Information sur la fiche")} id="sheet" toggled={togglers["sheet"]} toggle={toggle} className="border-gris-25 border-lrb bg-gris-20">
                        <div className="flex-label">
                            {sheet.id} - {sheet.title}
                        </div>
                    </ToggableBlocComponent>
                    <ToggableBlocComponent label={t("Paramètres")} id="configuration" toggled={togglers["configuration"]} toggle={toggle}>
                        <FieldComponent type="checksingle" name="alert" label={t("Inclure dans l'alerte mail")} checksingleLabel={t("Oui")} onChange={value => setValue("alert", (value ? 1 : 0))} checked={data.alert} value="1" />
                        <FieldComponent 
                            type="dropdown-tree-select"
                            name="watchsites"
                            label={`${t("Point de veilles")} *`}
                            data={tree}
                            error={errors.watchsites}
                            onChange={value => setValue("watchsites", value)}
                            value={data.watchsites}
                            mode="multiSelect"
                            showDropdown="initial"
                            
                        />
                    </ToggableBlocComponent>
                    <section className="row">
                        <div className="col">
                            <button id="back-sheets-list" onClick={onRequestClose} className="btn btn-bleu-4">{t("Retour à la liste")}</button>
                        </div>
                        <div className="col text-right">
                            <LoadButton loading={affecting} label={t("Affecter")} name="affecting-sheet" id="affecting-sheet" />
                        </div>
                    </section>
                </section>
            </form>
        );
    }
}