import { React, toast, fileDownload, DefaultLayout, useState, useEffect, useContext } from 'commons';
import { useTranslation } from 'react-i18next';
import { useList, usePosition } from 'shared/hooks';
import { useAccount } from 'hooks/Account';
import { useAccess } from 'hooks/Access';
import { useCountryPartitioning } from 'hooks/CountryPartitioning';
import { ReferentialContext } from 'shared/stores';
import StatisticsApi from 'api/Statistics';
import FilterComponent from 'shared/components/FilterComponent';
import FieldComponent from 'shared/components/FieldComponent';
import { REFERENTIALS } from 'shared/data';
import { FileUtils, TreeUtils, canPerform, denyIfCantPerform, convertCriteriesToSearchQuery, compileDataToSelectOptions, preventDefault } from 'shared/services';
import AccountUtils from 'shared/services/AccountUtils';
import StatisticsChart from 'views/statistics/list/Chart';
import StartRestrictionMessageComponent from 'components/StartRestrictionMessageComponent';

export default function StatisticsList(props) {
    
    const { t } = useTranslation();
    const [initializePosition, setPosition] = usePosition("statistics");
    const [account] = useAccount();
    const [countries] = useCountryPartitioning();
    const [,,, readableWatchsites] = useAccess();
    const [tree, setTree] = useState([]);
    const [filterShow, toggleFilter] = useState(true);
    const [params, setParams] = useState({axis: "domain", indicator: "conformity-status"});
    const [referentialContext] = useContext(ReferentialContext);

    // referential
    const text_types = compileDataToSelectOptions(referentialContext[REFERENTIALS.REFERENTIAL_TEXT_TYPE], 'id', 'value');
    const domainTree = referentialContext["restrictedTree"];
    const categoryTree = referentialContext[REFERENTIALS.REFERENTIAL_CATEGORY];
    const accountTree = referentialContext["writableAccountTree"];

    // account access
    const hasCategoriesAccess = canPerform("account:categories", {account: account});

    denyIfCantPerform(props, "statistics:read", {account: account, readableWatchsites: readableWatchsites});

    let filterType = {
        "conformity-status": {
            sheet_id: {type: "terms", fields: ["sheet.id"]},
            sheet_text_type: {type: "terms", fields: ["sheet.text_types"]},
            watchsites: {type: "terms", fields: ["watchsite"]},
            sheet_text_date: {type: "range", fields: ["sheet.text_date"]},
            sheet_publication_date: {type: "range", fields: ["sheet.publication_date"]},
            sheet_categories: {type: "terms", fields: ["sheet.categories"]},
        },
        "action-plan-progress": {
            sheet_id: {type: "terms", fields: ["sheet_affected.sheet.id"]},
            sheet_text_type: {type: "terms", fields: ["sheet_affected.sheet.text_types"]},
            watchsites: {type: "terms", fields: ["watchsite"]},
            sheet_text_date: {type: "range", fields: ["sheet_affected.sheet.text_date"]},
            sheet_publication_date: {type: "range", fields: ["sheet_affected.sheet.publication_date"]},
            sheet_categories: {type: "terms", fields: ["sheet_affected.sheet.categories"]},
        }
    };
    if (hasCategoriesAccess) {
        filterType["conformity-status"].categories = {type: "terms", fields: ["categories"]};
        filterType["action-plan-progress"].categories = {type: "terms", fields: ["sheet_affected.categories"]};
    }

    if (countries.length > 1) {
        filterType["conformity-status"].sheet_country = {type: "match", fields: ["sheet.country"]};
        filterType["action-plan-progress"].sheet_country = {type: "match", fields: ["sheet_affected.sheet.country"]};
    }

    const [
        rows,,
        criterias,,,,,,,
        addCriteria,,,,
        submitSearch,
        loading,
        setLoading,
        refresh
    ] = useList("statistics", StatisticsApi, preFetch, "group_id", {}, "asc", 0);

    
    useEffect(() => {
        setTree(TreeUtils.accountTreeFilterMapper(accountTree, false, criterias.watchsites))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [criterias.watchsites, accountTree]);

    useEffect(() => {
        submitSearch(filterType[params.indicator], () => initializePosition(), false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
        setLoading(true);
        submitSearch(filterType[params.indicator], () => initializePosition(), false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params]);

    function preFetch(newSorting, newDirection, newCriterias, newLimit, newPage) {
        if (params.axis === "quarterly" || params.axis === "annual") {
            filterType["conformity-status"].photo_date = {type: "range", fields: ["photo_date"]};
            filterType["action-plan-progress"].photo_date = {type: "range", fields: ["photo_date"]};

            filterType["conformity-status"].categories = {type: "terms", fields: ["sheet_affected.categories"]};
            filterType["action-plan-progress"].categories = {type: "terms", fields: ["sheet_affected.categories"]};
        }

        return [newSorting, newDirection, getParsedCriterias(newCriterias), newLimit, newPage, filterType[params.indicator], params];
    }

    function exportStatistics(exportType) {
        setLoading(true);

        StatisticsApi
            .export(
                exportType,
                params.axis,
                params.indicator,
                convertCriteriesToSearchQuery(getParsedCriterias(criterias), filterType[params.indicator])
            )
            .then(responseData => {
                setLoading(false);
                let fileExtension = '.xlsx';
                if (exportType === 'pdf') {
                    fileExtension = '.pdf';
                }
                fileDownload(responseData, FileUtils.getFileNameDate() + "-stats-" + params.indicator + "-" + params.axis + fileExtension);
            })
            .catch(() => {
                setLoading(false);
                toast.error(t("Erreur durant la création de l'export."))
            });
    }

    function getParsedCriterias(criterias) {
        let parsedCriterias = {...criterias};
        if (parsedCriterias.watchsites && params.indicator !== "action-plan-progress") {
            parsedCriterias.watchsites = parsedCriterias.watchsites.map(w => parseInt(String(w).replace('watchsite:', '')));
        }
        if (parsedCriterias.sheet_id) {
            parsedCriterias.sheet_id = parsedCriterias.sheet_id.split(',');
        }
        return parsedCriterias;
    }

    return (
        <DefaultLayout onScroll={(position) => setPosition(position)} screen="E41" title={t("Tableau de bord / Statistiques")}>
            <h1>{t("Tableau de bord / statistiques")}</h1>
            <section>
            <div className="bloc">
                <form className="form" onSubmit={(e) => preventDefault(e, submitSearch(filterType[params.indicator]))}>
                    <section className="filters">
                        <header>
                            <h2><button className={filterShow ? "toggler actif" : "toggler"} type="button" onClick={() => toggleFilter(!filterShow)}>{t("Recherche")}</button></h2>
                        </header>
                        {filterShow && <>
                            <div className="bg-gris-25">
                                <div className="row">
                                    <FilterComponent type="text" name="sheet_id" onChange={value => addCriteria("sheet_id", value)} value={criterias.sheet_id} label={t("Numéro de fiche")} />
                                    <FilterComponent type="dropdown-tree-select" name="sheet_categories" onChange={value => addCriteria("sheet_categories", value)} value={criterias.sheet_categories}  label={t("Domaine / Sous domaine / Thème")} data={domainTree} mode="hierarchical" />
                                    <FilterComponent type="select" name="sheet_text_type" onChange={value => addCriteria("sheet_text_type", value)} value={criterias.sheet_text_type} label={t("Type de texte")} clearable options={text_types} multiple />
                                    <FilterComponent type="dropdown-tree-select" name="watchsites" onChange={value => addCriteria("watchsites", value)} value={criterias.watchsites} data={tree} mode="hierarchical" useMapper={false} label={t("Point de veille ou noeud")} />
                                    {hasCategoriesAccess && <>
                                        <FilterComponent type="dropdown-tree-select" name="categories" onChange={value => addCriteria("categories", value)} value={criterias.categories} label={AccountUtils.getCategoriesLabel(account)} data={categoryTree} mode="hierarchical" />
                                    </>}
                                    <FilterComponent type="daterange" name="sheet_text_date" onChange={value => addCriteria("sheet_text_date", value)} value={criterias.sheet_text_date} label={t("Date du texte")} />
                                    <FilterComponent type="daterange" name="sheet_publication_date" onChange={value => addCriteria("sheet_publication_date", value)} value={criterias.sheet_publication_date} label={t("Date de publication")} />
                                    {countries.length > 1 && <FilterComponent label={t("Pays")} type="select" multiple={false} name="sheet_country" onChange={value => addCriteria("sheet_country", value)} value={criterias.sheet_country} options={compileDataToSelectOptions(countries, "id", "value")} />}
                                    {(params.axis === "quarterly" || params.axis === "annual") && <>
                                        <FilterComponent type="quarterrange" name="photo_date" onChange={value => addCriteria("photo_date", value)} value={criterias.photo_date} label="Plage de date" />
                                    </>}
                                </div>
                            </div>
                            <div className="bg-gris-25 border-b border-gris-60">
                                <div className="row">
                                    <div className="col-md-9">
                                        <button id="clearfilter-statistics" onClick={() => refresh(true)} type="button" className="btn btn-bleu-4 icon"><i className="icon-filtres-poubelle" aria-hidden="true"></i>Réinitialiser la recherche</button>
                                    </div>
                                    <div className="col-md-3 text-right">
                                        <button id="search-statistics" type="submit" className="btn btn-primary">{t("Rechercher")}</button>
                                    </div>
                                </div>
                            </div>
                        </>}
                    </section>
                    {filterShow && <section className="configurations">
                        <header>
                            <h2>Configuration</h2>
                        </header>
                        <div className="border-lrb border-gris-25">
                            <div className="row">
                                <div className="col-md-6">
                                    <FieldComponent
                                        type="radio"
                                        name="indicator"
                                        onChange={value => setParams({axis: params.axis, indicator: value})}
                                        value={params.indicator}
                                        label={t("Indicateur")}
                                        options={[
                                            {value: "conformity-status", label: t("Statut de conformité")},
                                            {value: "action-plan-progress", label: t("Avancement plan d'action")}
                                        ]}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <FieldComponent
                                        type="radio"
                                        name="axis"
                                        onChange={value => setParams({axis: value, indicator: params.indicator})}
                                        value={params.axis}
                                        label={t("Axe de présentation")}
                                        options={[
                                            {value: "global", label: t("Global")},
                                            {value: "domain", label: t("Domaine")},
                                            {value: "subdomain", label: t("Sous-domaine")},
                                            {value: "theme", label: t("Thème")},
                                            {value: "watchsite", label: t("Point de veille")},
                                            {value: "quarterly", label: t("Trimestriel")},
                                            {value: "annual", label: t("Annuel")}
                                        ]}
                                    />
                                </div>
                            </div>
                        </div>
                    </section>}
                </form>
            </div>
            {account.is_start !== true && <div className="bloc">
                <section>
                    <header>
                        {params.indicator === "conformity-status" && <h2>Indicateur statut de conformité</h2>}
                        {params.indicator === "action-plan-progress" && <h2>Indicateur avancement plan d'action</h2>}                        
                    </header>
                    <div className="bg-gris-25 text-right">
                        <button id="export-statistics-pdf" type="button" className="btn btn-primary h25" onClick={(e) => preventDefault(e, exportStatistics('pdf'))}>
                            <i className="icon-boutons-export-pdf" aria-hidden="true"></i>&nbsp;Exporter en PDF
                        </button>
                        <button id="export-statistics-excel" type="button" className="btn btn-primary h25" onClick={(e) => preventDefault(e, exportStatistics('excel'))}>
                            <i className="icon-file-excel" aria-hidden="true"></i>&nbsp;Exporter en feuille de calcul
                        </button>
                    </div>
                    <div className="border-lrb border-gris-25">
                        <StatisticsChart
                            loading={loading}
                            selectable={false}
                            rows={rows}
                            indicator={params.indicator}
                        />
                    </div>
                </section>
            </div>}
            <StartRestrictionMessageComponent />
            </section>
        </DefaultLayout>
    );
}