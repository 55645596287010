import React, { useContext } from "react";
import { useTranslation } from 'react-i18next';
import _ from "lodash";
import { Link } from "react-router-dom";
import DefaultLayout from "layouts/DefaultLayout";
import SidebarComponent from 'shared/components/SidebarComponent';
import ToggableBlocComponent from "shared/components/ToggableBlocComponent";
import PreviewFieldComponent from "shared/components/PreviewFieldComponent"
import HtmlComponent from "shared/components/HtmlComponent";
import ChecksingleReadOnlyComponent from "shared/components/ChecksingleReadOnlyComponent";
import ChecksingleListReadOnlyComponent from "shared/components/ChecksingleListReadOnlyComponent";
import DegreeReadOnlyComponent from "shared/components/DegreeReadOnlyComponent";
import TreeReferentialComponent from "shared/components/tree/TreeReferentialComponent";
import { useToggler } from "shared/hooks/Toggler";
import { ReferentialContext } from 'shared/stores/Referential';
import SheetUtils from "shared/services/SheetUtils";
import { REFERENTIALS } from "shared/data/ReferentialType";
import { nl2br, padNumber, formatDate } from "shared/services/Utils";
import FileUtils from "shared/services/FileUtils";
import { getSheetTodo } from 'shared/data/SheetTodo';
import { SHEET_STATE_UNNECESSARY } from "shared/data/SheetState";
import ListReadOnlyComponent from "shared/components/ListReadOnlyComponent";
import { prefixLinkTo } from 'shared/services';

export default function SheetCommonDetail(props) {

    const { t } = useTranslation();
    const {sheet, warning, displayTechnicalLevel, displaySynthesisLevel, backoffice } = props;
    const [referentialContext] = useContext(ReferentialContext);
    const [togglers, toggle] = useToggler({
        'general': true,
        'linked-sheet': true,
        'attachments': true,
        'technical-level': true,
        'others': true,
        'internal': true,
        'tasks': true,
    });
    const linkedSheets = SheetUtils.getLinkedSheets(sheet);

    const tree = _.cloneDeep(referentialContext["tree"]);
    const textImpacts = referentialContext[REFERENTIALS.REFERENTIAL_TEXT_IMPACT];
    const involvedActors = referentialContext[REFERENTIALS.REFERENTIAL_INVOLVED_ACTOR];
    const involvedFunctions = referentialContext[REFERENTIALS.REFERENTIAL_INVOLVED_FUNCTION];

    const sheetUriSuffix = document.location.pathname.indexOf("/preview") > -1 ? "/preview" : "";

    let sidebarLinks = [
        {anchor: "#general", label: t("Niveau général"), active: true, childs: [
            {anchor: "#linked-sheet", label: t("Fiches liées"), active: true},
            {anchor: "#attachments", label: t("Documents et liens"), active: true},
        ]},
        {anchor: "#technical-level", label: t("Niveau technique"), active: displayTechnicalLevel && sheet.state_technical !== SHEET_STATE_UNNECESSARY},
    ];
    if (backoffice){
        sidebarLinks.push({
            anchor: '#others',
            label: t("Informations complémentaires"),
            active: true,
        });
        sidebarLinks.push({
            anchor: '#internal',
            label: t("Note interne"),
            active: true,
        });
        sidebarLinks.push({
            anchor: '#tasks',
            label: t("Tâches clients"),
            active: true,
        });
    }
    sidebarLinks = sidebarLinks.concat(props.sidebar);

    return (
        <DefaultLayout screen={props.screen} title={`Fiche ${sheet.id}`}>
            {props.title && <h1><HtmlComponent>{props.title}</HtmlComponent></h1>}
            <section className="sidebar">
                <SidebarComponent
                    label={t("Détail fiche publique")}
                    links={sidebarLinks}
                />

                <section>
                    {warning && warning.map(w => 
                        <div className="alert alert-warning" role="alert">
                            <strong>Avertissement : </strong>{w}
                        </div>
                    )}
                    
                    <ToggableBlocComponent label={t("Niveau général")} id="general" toggled={togglers["general"]} toggle={toggle}>
                        <div className="bg-blanc border-b border-gris-40">
                            <div className="row">
                                <div className="col-lg-10">
                                    {props.displayCountry && sheet.country.picture && sheet.country.picture.image && <>
                                        <img src={`data:image/jpeg;base64,${sheet.country.picture.image}`} height="12" title={sheet.country.value} alt={`Drapeau ${sheet.country.value}`} />&nbsp;
                                    </>}
                                    {padNumber(sheet.id)} : <HtmlComponent>{sheet.title}</HtmlComponent>
                                </div>
                                <div className="col-lg-2 text-center border-l border-gris-40">
                                    {sheet.reglementary_text && (
                                        <button className="btn btn-primary infobulle" data-info={"Télécharger " + sheet.reglementary_text.name} onClick={e => { e.preventDefault(); FileUtils.download(sheet.reglementary_text); }}>
                                            <i className="icon-actions-consulter-pdf" aria-hidden="true"></i>
                                        </button>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="bg-blanc border-l border-vert-apave border-big">
                            {displaySynthesisLevel && <PreviewFieldComponent label={t("Synthèse")}>
                                {sheet.synthesis && <HtmlComponent>{sheet.synthesis || 'N/A'}</HtmlComponent>}
                            </PreviewFieldComponent>}
                            {sheet.legifrance_source && <div className="text-right">
                                <a href={sheet.legifrance_source} className="btn btn-primary">
                                    <i className="icon-autres-lien-fiche" aria-hidden="true"></i>
                                    &nbsp;Lien vers la source officielle
                                </a>
                            </div>}          
                        </div>
                        <div className="bg-gris-10 border-t border-gris-40">
                            <div className="row">
                                <div className="col-sm-12 col-md-12 col-lg-4">
                                    {sheet.major_text && <p className="flex-label mb-0">
                                        <strong className="label" style={{flexBasis: 150}}>Texte majeur&nbsp;:</strong>                                            
                                        <span><i className="icon-autres-texte-majeur color-vert-apave" aria-hidden="true"></i></span>
                                    </p>}
                                    <p className="flex-label mb-0">
                                        <strong className="label" style={{flexBasis: 150}}>Emetteur&nbsp;:</strong>
                                        <span>{sheet.transmitter ? sheet.transmitter.value : 'N/A'}</span>
                                    </p>
                                    <p className="flex-label mb-0">
                                        <strong className="label" style={{flexBasis: 160}}>Source du texte&nbsp;:</strong>
                                        <span>{sheet.source ? sheet.source.value + (sheet.source_number ? " N° " + sheet.source_number : "") : 'N/A'}</span>
                                    </p>
                                    <p className="flex-label mb-0">
                                        <strong className="label" style={{flexBasis: 160}}>Type(s) de texte&nbsp;:</strong>
                                        <span>{sheet.text_types.map(elem => elem.value).join(', ') || 'N/A'}</span>
                                    </p>
                                    <p className="flex-label mb-0">
                                        <strong className="label" style={{flexBasis: 160}}>N° du texte&nbsp;:</strong>
                                        <span>{sheet.text_number || 'N/A'}</span>
                                    </p>
                                </div>
                                <div className="col-sm-12 col-md-12 col-lg-4">
                                        <p className="flex-label mb-0">
                                        <strong className="label" style={{flexBasis: 160}}>Numéro NOR&nbsp;:</strong>
                                        <span>{sheet.nor || 'N/A'}</span>
                                    </p>
                                    <p className="flex-label mb-0">
                                        <strong className="label" style={{flexBasis: 150}}>Consolidation suivie&nbsp;:</strong>
                                        <span><ChecksingleReadOnlyComponent value={sheet.consolidated_text} /></span>
                                    </p>
                                    <p className="flex-label mb-0">
                                        <strong className="label" style={{flexBasis: 150}}>Texte abrogé&nbsp;:</strong>
                                        <span><ChecksingleReadOnlyComponent value={sheet.repealed_text} /></span>
                                    </p>
                                    {sheet.application_areas && sheet.application_areas.length > 0 && <p className="flex-label mb-0">
                                        <strong className="label" style={{flexBasis: 150}}>Zone d'application géographique&nbsp;:</strong>
                                        <span>
                                            {sheet.application_areas.map(area => area.value).join(", ")}
                                        </span>
                                    </p>}
                                </div>
                                <div className="col-sm-12 col-md-12 col-lg-4">
                                    <p className="flex-label mb-0">
                                        <strong className="label" style={{flexBasis: 160}}>Date de publication&nbsp;:</strong>
                                        <span>{sheet.publication_date ? formatDate(sheet.publication_date) : 'N/A'}</span>
                                    </p>
                                    <p className="flex-label mb-0">
                                        <strong className="label" style={{flexBasis: 160}}>Date du texte&nbsp;:</strong>
                                        <span>{sheet.text_date ? formatDate(sheet.text_date) : 'N/A'}</span>
                                    </p>
                                    <p className="flex-label mb-0">
                                        <strong className="label" style={{flexBasis: 160}}>Date de mise à jour&nbsp;:</strong>
                                        <span>{sheet.text_update_date ? formatDate(sheet.text_update_date) : 'N/A'}</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="bg-blanc border-t border-gris-40">
                            <PreviewFieldComponent label={t("Descripteurs")}>
                                {sheet.descriptors.map(elem => elem.value).join(' | ')}
                            </PreviewFieldComponent>
                        </div>

                        {sheet.categories.length > 0 && <div className="bg-gris-10 border-t border-gris-40">
                                <PreviewFieldComponent label={t("Domaines")}>
                                    {tree.length > 0 && sheet.categories.length > 0 &&
                                        <TreeReferentialComponent 
                                            readOnly 
                                            className="arborescence" 
                                            value={sheet.categories.map(e => e.id)} 
                                            items={tree} 
                                        />
                                    }
                                </PreviewFieldComponent>
                                <div className="bg-blanc">
                                    {sheet.main_domain && (
                                        <PreviewFieldComponent label={t("Domaine principal")}>
                                            {sheet.main_domain.value}
                                        </PreviewFieldComponent>
                                    )}
                                    {sheet.main_subdomain && (
                                        <PreviewFieldComponent label={t("Sous-domaine principal")}>
                                            {sheet.main_subdomain.value}
                                        </PreviewFieldComponent>
                                    )}
                                    {sheet.main_theme && (
                                        <PreviewFieldComponent label={t("Thème principal")}>
                                            {sheet.main_theme.value}
                                        </PreviewFieldComponent>
                                    )}
                                </div>
                            </div>}

                        <ToggableBlocComponent label={t("Fiches liés")} id="linked-sheet" headerClassName="bg-gris-50" toggled={togglers["linked-sheet"]} toggle={toggle} margin={0} className="toggle-group border-b border-gris-40">
                            <table className="bg-blanc table table-striped">
                                <thead>
                                    <tr>
                                        <th className="w-100px">Fiche</th>
                                        <th>Titre</th>
                                        <th className="w-60px">Lien</th>
                                        {!backoffice && <th className="w-60px">Affecté</th>}
                                        <th className="w-60px">Texte majeur</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {linkedSheets.length > 0 && linkedSheets.map(linkedSheet => (
                                        <tr key={linkedSheet.id}>
                                            <td>{padNumber(linkedSheet.id)}</td>
                                            <td><HtmlComponent>{linkedSheet.title}</HtmlComponent></td>
                                            <td>
                                                {_.some(props.affectedLinkedSheets, {sheet: linkedSheet.id}) && <Link to={prefixLinkTo() + "/sheets-affected/" + _.find(props.affectedLinkedSheets, {sheet: linkedSheet.id}).sheetaffected} target="_blank" className="infobulle" data-info="Voir la fiche dans un nouvel onglet">
                                                    <i className="icon-autres-lien-fiche" aria-hidden="true"></i>
                                                </Link>}
                                                {!_.some(props.affectedLinkedSheets, {sheet: linkedSheet.id}) && <Link to={prefixLinkTo() + "/sheets/" + linkedSheet.id + sheetUriSuffix} target="_blank" className="infobulle" data-info="Voir la fiche dans un nouvel onglet">
                                                    <i className="icon-autres-lien-fiche" aria-hidden="true"></i>
                                                </Link>}
                                            </td>
                                            {!backoffice && <td><ChecksingleReadOnlyComponent value={_.some(props.affectedLinkedSheets, {sheet: linkedSheet.id})} /></td>}
                                            <td><ChecksingleReadOnlyComponent value={linkedSheet.major_text} /></td>
                                        </tr>
                                    ))}
                                    {linkedSheets.length === 0 && <tr><td colSpan="5">Aucune fiche.</td></tr>}
                                </tbody>
                            </table>
                        </ToggableBlocComponent>
                        <ToggableBlocComponent label={t("Documents et liens")} id="attachments" headerClassName="bg-gris-50" toggled={togglers["attachments"]} toggle={toggle} margin={0} className="toggle-group border-b border-gris-40">
                            <table className="bg-blanc table table-striped">
                                <thead>
                                    <tr>
                                        <th>Titre</th>
                                        <th className="w-60px">Lien</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {sheet.attachments.length > 0 && sheet.attachments.map(attachment => (
                                        <tr key={attachment.hash}>
                                            <td>{attachment.name}</td>
                                            <td>
                                                <a href={attachment.name} className="infobulle" data-info={"Télécharger " + attachment.name} onClick={e => { e.preventDefault(); FileUtils.download(attachment); }}>
                                                    <i className="icon-autres-lien-fiche" aria-hidden="true"></i>
                                                </a>
                                            </td>
                                        </tr>
                                    ))}
                                    {sheet.weblinks.length > 0 && sheet.weblinks.map(weblink => (
                                        <tr key={weblink}>
                                            <td>{weblink}</td>
                                            <td>
                                                <a href={weblink} target="_blank" className="infobulle" rel="noreferrer" data-info="Ouvrir le lien dans un nouvel onglet">
                                                    <i className="icon-autres-lien-fiche" aria-hidden="true"></i>
                                                </a>
                                            </td>
                                        </tr>
                                    ))}
                                    {sheet.attachments.length === 0 && sheet.weblinks.length === 0 && <tr><td colSpan="5">Aucun document ou lien.</td></tr>}
                                </tbody>
                            </table>
                        </ToggableBlocComponent>
                    </ToggableBlocComponent>

                    {displayTechnicalLevel && sheet.state_technical !== SHEET_STATE_UNNECESSARY && (
                        <ToggableBlocComponent label={t("Niveau technique")} id="technical-level" toggled={togglers["technical-level"]} toggle={toggle}>
                            {sheet.technical_comment && <div className="bg-blanc border-l border-vert-apave border-big">
                                <PreviewFieldComponent label={t("Commentaire technique")}>
                                    <HtmlComponent>{sheet.technical_comment}</HtmlComponent>
                                </PreviewFieldComponent>
                            </div>}
                            {sheet.deadlines.length > 0 && <div className="bg-gris-10 border-t border-gris-40">
                                <PreviewFieldComponent label={t("Échéances")}>
                                    {sheet.deadlines.map(d => <p><strong>{formatDate(d.date)}</strong>{d.article && <> | Numéro d’article : {d.article}</>}<br/><HtmlComponent>{d.text}</HtmlComponent></p>)}
                                </PreviewFieldComponent>
                            </div>}
                            {sheet.text_codification && <div className="bg-blanc border-t border-gris-40">
                                <PreviewFieldComponent label={t("Codification du texte")}>
                                    {sheet.text_codification.value}
                                </PreviewFieldComponent>
                            </div>}
                            <div className="bg-blanc border-t border-gris-40">
                                <div className="row">
                                    <div className="col-md-10 offset-2">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <PreviewFieldComponent label={t("Degrés d'impact corporel")}>
                                                    <DegreeReadOnlyComponent value={sheet.corporal_impact_degree} />
                                                </PreviewFieldComponent>
                                            </div>
                                            <div className="col-md-6">
                                                <PreviewFieldComponent label={t("Degrés d'impact juridique")}>
                                                    <DegreeReadOnlyComponent value={sheet.legal_impact_degree} />
                                                </PreviewFieldComponent>
                                            </div>
                                            <div className="col-md-6" style={{paddingTop: 15}}>
                                                <PreviewFieldComponent label={t("Degrés d'impact médiatique")}>
                                                    <DegreeReadOnlyComponent value={sheet.media_impact_degree} />
                                                </PreviewFieldComponent>
                                            </div>
                                            <div className="col-md-6" style={{paddingTop: 15}}>
                                                <PreviewFieldComponent label={t("Degrés d'impact financier")}>
                                                    <DegreeReadOnlyComponent value={sheet.financial_impact_degree} />
                                                </PreviewFieldComponent>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="bg-gris-10 border-t border-gris-40">
                                <div className="row">
                                    <div className="col-md-4">
                                        <PreviewFieldComponent label={t("Impacts du texte")} flex={false}>
                                            <ChecksingleListReadOnlyComponent
                                                value={sheet.text_impacts.map(elem => elem.id)}
                                                options={textImpacts}
                                            />
                                        </PreviewFieldComponent>
                                    </div>
                                    <div className="col-md-4">
                                        <PreviewFieldComponent label={t("Acteurs concernés")} flex={false}>
                                            <ChecksingleListReadOnlyComponent
                                                value={sheet.involved_actors.map(elem => elem.id)}
                                                options={involvedActors}
                                            />
                                        </PreviewFieldComponent>
                                    </div>
                                    <div className="col-md-4">
                                        <PreviewFieldComponent label={t("Fonctions concernées")} flex={false}>
                                            <ChecksingleListReadOnlyComponent
                                                value={sheet.involved_functions.map(elem => elem.id)}
                                                options={involvedFunctions}
                                            />
                                        </PreviewFieldComponent>
                                    </div>
                                </div>
                            </div>
                        </ToggableBlocComponent>
                    )}
                    {backoffice && 
                        <ToggableBlocComponent label={t("Informations complémentaires")} id="others" toggled={togglers["others"]} toggle={toggle}>
                            
                            <div className="bg-blanc">
                                <PreviewFieldComponent label={t("Date de création")}>
                                    {formatDate(sheet.created_at)}
                                </PreviewFieldComponent>
                            </div>

                            <div className="bg-gris-20">
                                <PreviewFieldComponent label={t("Condidentiel client")}>
                                    {sheet.confidential_account ? sheet.confidential_account.label : null}
                                </PreviewFieldComponent>
                            </div>

                            <div className="bg-blanc">
                                <PreviewFieldComponent label={t("Commentaire consultant type")}>
                                    {sheet.consultant_comment ? sheet.consultant_comment : null}
                                </PreviewFieldComponent>
                            </div>

                            <div className="bg-gris-20">
                                <PreviewFieldComponent label={t("Auteur dernière modification niveau synthèse")}>
                                    {sheet.synthesis_updated_by && <>{sheet.synthesis_updated_by.lastname} {sheet.synthesis_updated_by.firstname}</>}
                                </PreviewFieldComponent>

                                <PreviewFieldComponent label={t("Date de dernière modification niveau synthèse")}>
                                    {formatDate(sheet.synthesis_updated_at, true)}
                                </PreviewFieldComponent>
                                
                                <PreviewFieldComponent label={t("Auteur dernière modification niveau technique")}>
                                    {sheet.technical_updated_by && <>{sheet.technical_updated_by.lastname} {sheet.technical_updated_by.firstname}</>}
                                </PreviewFieldComponent>

                                <PreviewFieldComponent label={t("Date de dernière modification niveau technique")}>
                                    {formatDate(sheet.technical_updated_at, true)}
                                </PreviewFieldComponent>
                            </div>
                            
                            <div className="bg-blanc">
                                <PreviewFieldComponent label={t("Sanctions")}>
                                    <HtmlComponent>{sheet.sanction}</HtmlComponent>
                                </PreviewFieldComponent>
                            </div>
                            
                            <div className="bg-gris-20">
                                <PreviewFieldComponent label={t("Actions à réaliser")}>
                                    <ListReadOnlyComponent
                                        value={sheet.todos}
                                        options={getSheetTodo()}
                                    />
                                </PreviewFieldComponent>
                            </div>

                            <div className="bg-blanc">
                                <PreviewFieldComponent label={t("Communication interne")}>
                                    {nl2br(sheet.internal_comment)}
                                </PreviewFieldComponent>
                            </div>
                        </ToggableBlocComponent>
                    }                    
                    {backoffice && <ToggableBlocComponent label={t("Note interne")} id="internal" toggled={togglers["internal"]} toggle={toggle}>
                        <div className="bg-blanc">
                            <PreviewFieldComponent label={t("Note interne")}>
                                {nl2br(sheet.internal_comment || 'N/A')}
                            </PreviewFieldComponent>
                        </div>
                    </ToggableBlocComponent>}
                    {backoffice && <ToggableBlocComponent label={t("Tâches clients")} id="tasks" toggled={togglers["tasks"]} toggle={toggle}>
                        <div className="bg-blanc">
                            <PreviewFieldComponent label={t("Contrôle à effectuer")}>
                                {nl2br(sheet.control_todo || 'N/A')}
                            </PreviewFieldComponent>
                        </div>
                        <div className="bg-gris-20">
                            <PreviewFieldComponent label={t("Document à faire")}>
                                {nl2br(sheet.document_todo || 'N/A')}
                            </PreviewFieldComponent>
                        </div>
                        <div className="bg-blanc">
                            <PreviewFieldComponent label={t("Périodicité")}>
                                {nl2br(sheet.periodicity || 'N/A')}
                            </PreviewFieldComponent>
                        </div>
                    </ToggableBlocComponent>}
                    {props.children}
                </section>
            </section>
        </DefaultLayout>
    );
}

SheetCommonDetail.defaultProps = {
    displayTechnicalLevel: true,
    displaySynthesisLevel: true,
    sidebar: [],
    backoffice: false,
    displayCountry: false,
};
