import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import BarLoader from "react-spinners/BarLoader";
import FieldComponent from 'shared/components/FieldComponent';
import LoadButton from 'shared/components/LoadButton';
import SheetAffectedApi from 'shared/api/SheetAffected';
import { useForm } from 'shared/hooks/Form';
import { useAccount } from 'hooks/Account';
import { useAccess } from 'hooks/Access';
import { getConformities, getConformityModes } from 'data/SheetAffected';
import { canPerform } from "shared/services/Can";
import SheetAffectedCategoryPicker from '../views/sheetaffected/form/SheetAffectedCategoryPicker';
import { ReferentialContext } from 'shared/stores/Referential';
import { REFERENTIAL_CATEGORY } from 'shared/data/ReferentialType';
import AccessApi from '../shared/api/Access';
import ExternalUserApi from './../shared/api/ExternalUser';
import _ from 'lodash';
import WatchsiteApi from '../shared/api/Watchsite';
import { compileDataToSelectOptions } from '../shared/services/Utils';

export default function ExpressProcessing(props) {

    const { t } = useTranslation();
    const [account] = useAccount();
    const [canWrite] = useAccess();
    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);
    const [pilots, setPilots] = useState([]);
    const [pilotField, setPilotField] = useState(null);
    const [data,,, setValue, setData] = useForm({
        comment_headquarters: '',
        comment: '',
        hidden: null,
        conformity_mode: null,
        conformity: null,
        evaluation_date: null,
        review_date: null,
        priority: null,
        categories: []
    });

    const conformityModes = [{value: null, label: t("Ne pas modifier"), color: "gris-60"}].concat(getConformityModes());    
    const conformities = [{value: null, label: t("Ne pas modifier"), color: "gris-60"}].concat(getConformities());
    const [referentialContext] = useContext(ReferentialContext);
    const categoryTree = referentialContext[REFERENTIAL_CATEGORY];

    useEffect(() => {
        if (props.selection.length === 0) {
            toast.warning(t("Votre sélection est vide"));
            props.onRequestClose();
        }

        let access = true;

        props.selection.forEach(item => {
            if (!canWrite(item.watchsite)) {
                // eslint-disable-next-line react-hooks/exhaustive-deps
                access = false;  
            }
        });

        if (!access) {
            toast.error(t("Vous n'avez pas les droits suffisants. Veuillez revoir la sélection"));
            props.onRequestClose();
        }

        if (props.selection.length === 1) {
            SheetAffectedApi
                .get(props.selection[0].sheetaffected)
                .then(freshdata => {
                    setData({
                        'priority': freshdata.priority,
                        'evaluation_date': freshdata.evaluation_date ? new Date(freshdata.evaluation_date) : null,
                        'review_date': freshdata.review_date ? new Date(freshdata.review_date) : null,
                        'conformity_mode': freshdata.conformity_mode,
                        'conformity': freshdata.conformity,
                        'hidden': freshdata.hidden ? 1 : 0,
                        'categories': freshdata.categories.map(c => c.id),
                    });
                    setLoading(false);
                });
        } else {
            setLoading(false);
        }

        ExternalUserApi.list({active: 1, valid: 1, sort: "lastname", limit: "1000"}).then(([users]) => {
            setPilots(
                compileDataToSelectOptions(users, 'user_id',
                    [
                        'firstname',
                        'lastname',
                        {
                            field:'email',
                            render:(v) => '(' + v + ')'
                        }
                    ]
                )
            );
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function submit() {
        setSaving(true);

        const payload = {...data};
        if (payload.evaluation_date) {
            payload.evaluation_date.setHours(12);
            payload.evaluation_date.setMinutes(0);
            payload.evaluation_date.setSeconds(0);
        }
        if (payload.review_date) {
            payload.review_date.setHours(12);
            payload.review_date.setMinutes(0);
            payload.review_date.setSeconds(0);
        }

        SheetAffectedApi
            .expressProcessing({...payload, ...{sheetsaffected: props.selection.map(i => i.sheetaffected)}})
            .then(() => {
                toast.success(t("Traitement effectué"));
                setSaving(false);
                props.onSuccess();
            })
            .catch(error => {
                setSaving(false);
                toast.error(error.response.data.message);
            });
    }

    function getWatchsiteWriteAccessUsers(watchsiteIds, callback, watchsiteUsers = {}) 
    {
        if (watchsiteIds.length > 0) {
            const watchsiteId = watchsiteIds[0];
            AccessApi.getUsersWithWriteAccess(watchsiteId).then((data) => {
                watchsiteUsers[watchsiteId] = data;
                watchsiteIds.shift();              
                return getWatchsiteWriteAccessUsers(watchsiteIds, callback, watchsiteUsers);
            });
        } else {
            return callback(watchsiteUsers);
        }
    }

    function selectPilot(pilotId) {
        setPilotField(false);
        let watchsiteIds = _.uniq(props.selection.map(item => item.watchsite));
        getWatchsiteWriteAccessUsers(watchsiteIds, function(watchsiteUsers) {
            let errors = [];
            _.forEach(watchsiteUsers, (users, wid) => {
                if (!_.find(users, {id: parseInt(pilotId)})) {
                    errors.push(wid);
                }
            })
            if (errors.length > 0) {
                const pilot = _.find(pilots, {value: parseInt(pilotId)});
                WatchsiteApi.list().then(([watchsites]) => {
                    errors = errors.map((wid) => _.find(watchsites, {id: parseInt(wid)}).label);
                    console.log(props.selection);
                    setValue("pilot", null);
                    setPilotField(
                        "Impossible de sélectionner le pilote <" + pilot.label + 
                        ">, celui-ci ne posséde pas les droits suffisants sur " +
                        (errors.length > 1 ? "les points de veille suivants" : "le point de veille")
                        + " : " + errors.join(', ') + "."
                    );
                });
            } else {
                setValue("pilot", pilotId);
                setPilotField(null);
            }
        });
    }

    function updateEvaluationDate(evaluationDate) {
        const newData = {...data};
        newData.evaluation_date = evaluationDate;

        if (evaluationDate && account.default_review_date) {
            let reviewDate = new Date(evaluationDate);
            reviewDate.setMonth(reviewDate.getMonth() + account.default_review_date);
            newData.review_date = reviewDate;
        }

        setData(newData);
    }

    if (loading) {
        return <BarLoader width={300} color="#5bad27" css="display:block;margin:10px auto;" />
    }

    return (
        <form onSubmit={submit} id="form-express-processing" className="form">
            <section className="bloc">
                <header className="border-rlb border-gris-40">
                    <h2 className="uppercase">Traitement express</h2>
                </header>
                <section>
                    {canPerform("sheetaffected:headquarter_comment:write", {account: account}) && <FieldComponent
                        type="textarea"
                        rte={true}
                        name="expressprocessing_comment_headquarters"
                        label={t("Commentaire siège")}
                        value={data.comment_headquarters}
                        onChange={value => setValue("comment_headquarters", value)}
                    />}
                    {canPerform('sheetaffected:comment:create', {account: account}) && 
                        <FieldComponent
                            type="textarea"
                            rte={true}
                            name="expressprocessing_comment"
                            label={t("Commentaire")}
                            value={data.comment}
                            onChange={value => setValue("comment", value)}
                        />
                    }
                    {pilotField !== false &&
                        <FieldComponent
                            type="select"
                            name="pilot"
                            label="Pilote"
                            value={data.pilot}
                            onChange={value => selectPilot(value)}
                            options={pilots}
                            error={_.isString(pilotField) ? pilotField : null}
                        />
                    }
                    {pilotField === false &&
                        <div className="flex-label">
                                <label forHtml="pilot" id="pilot-label">Pilote :</label>
                                <BarLoader css="display:block;margin-top:8px;" />
                        </div>
                    }
                    <FieldComponent
                        type="radio"
                        name="expressprocessing_hidden"
                        label={t("Fiche masquée")}
                        value={data.hidden}
                        onChange={value => setValue("hidden", value ? parseInt(value) : null)} 
                        options={[
                            {"value": null, "label": "Ne pas modifier"}, 
                            {"value": 1, "label": t("Oui")}, 
                            {"value": 0, "label": t("Non")}
                        ]} 
                    />
                    {canPerform("account:compliance_management", {account: account}) && <>                        
                        <FieldComponent 
                            type="buttons"
                            name="expressprocessing_conformity_mode" 
                            label={t("Mode de gestion")} 
                            value={data.conformity_mode} 
                            onChange={value => setValue("conformity_mode", value ? parseInt(value) : null)} 
                            options={conformityModes}
                        />
                        {data.conformity_mode !== 1 && <>                            
                            <FieldComponent 
                                type="buttons"
                                name="expressprocessing_conformity" 
                                label={t("Statut de conformité")} 
                                value={data.conformity} 
                                onChange={value => setValue("conformity", value ? parseInt(value) : null)}
                                options={conformities}
                            />
                            <FieldComponent
                                type="date"
                                name="expressprocessing_evaluation_date"
                                label={t("Date d'évaluation")}
                                value={data.evaluation_date}
                                onChange={value => updateEvaluationDate(value || null)}
                            />
                            <FieldComponent 
                                type="date"
                                name="expressprocessing_review_date"
                                label={t("Date de revue")}
                                value={data.review_date || null}
                                onChange={value => setValue("review_date", value || null)}
                            />
                            <FieldComponent 
                                type="buttons"
                                name="expressprocessing_priority" 
                                label={t("Priorité")} 
                                value={data.priority} 
                                onChange={value => setValue("priority", value ? parseInt(value) : null)} 
                                options={[
                                    {value: null, label: t("Ne pas modifier"), color: "gris-60"},
                                    {value: 0, label: t("Aucune"), color: "valid"},
                                    {value: 1, label: t("P1"), color: "valid"},
                                    {value: 2, label: t("P2"), color: "valid"},
                                    {value: 3, label: t("P3"), color: "valid"},
                                    {value: 4, label: t("P4"), color: "valid"}
                                ]}
                            />
                        </>}

                        
                    </>}
                    {canPerform("account:categories", {account: account}) && <>
                        <SheetAffectedCategoryPicker
                            categories={categoryTree}
                            value={data.categories}
                            onChange={value => setValue("categories", value)}
                        />
                    </>}

                    <section className="row">
                        <div className="col">
                            <button type="button" id="back-sheetsaffected-list" onClick={props.onRequestClose} className="btn btn-bleu-4">{t("Retour à la liste")}</button>
                        </div>
                        <div className="col text-right">  
                            <LoadButton 
                                loading={saving} 
                                label={t("Enregistrer et fermer")}
                                name="save-and-close"
                                id="save-and-close-express-processing"
                            />
                        </div>
                    </section>                    
                </section>
            </section>
        </form>
    );
}