import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAccount } from 'hooks/Account';

export default function Consultant() {

    const { t } = useTranslation();
    const [account] = useAccount();

    function renderConsultant(consultant) {
        return <div className="col-md-4" key={consultant.id}>
            <p>
                {consultant.firstname} <strong>{consultant.lastname}</strong>
                <br />
                <a href={`mailto:${consultant.email}`}>{consultant.email}</a>
                {(consultant.telephone || consultant.mobile) && <>
                    <br />
                    {consultant.telephone}
                    {consultant.telephone && consultant.mobile && <> | </>}
                    {consultant.mobile}
                </>}
            </p>
        </div>;
    }
    
    return (
        <div className="contenu padding bg-vert-apave color-blanc">
			<div className="row">
				<div className="col-md-1 text-center">
					<i className="icon-accueil-consultants-compte" aria-hidden="true"></i>
				</div>
				<div className="col-md-11">
					<h2 className="text-left">{t("Consultant(s) du compte")}</h2>
					<div className="row">
                        {account.pilot_consultants.map(renderConsultant)}
                        {account.associate_consultants.map(renderConsultant)}
					</div>
				</div>
			</div>
		</div>
    );
}
