import { React } from 'commons';
import { useTranslation } from 'react-i18next';
import { useToggler } from "shared/hooks";
import { ToggableBlocComponent, HtmlComponent } from "shared/components";
import { prefixLinkTo } from 'shared/services';
import DocumentarySpaceDetailCommonRows from "views/documentaryspace/Detail/CommonRows";
import DocumentarySpaceDetailRow from "views/documentaryspace/Detail/Row";

export default function DocumentarySpaceDetailNews(props) {

    const { t } = useTranslation();
    const [togglers, toggle] = useToggler({"document": true});

    return <form className="form">
        <section>
            <h1>{props.document.title}</h1>
            <ToggableBlocComponent label={t("Document")} id="document" toggled={togglers["document"]} toggle={toggle} className="border-gris-25 border-lrb">
                <DocumentarySpaceDetailCommonRows document={props.document} />
                <DocumentarySpaceDetailRow label={t("Description")} value={props.document.description} />
                <DocumentarySpaceDetailRow label={t("Image")} value={props.document.image} type="file" />
                {props.document.sheet &&
                    <DocumentarySpaceDetailRow
                        label={t("Fiche")}
                        value={props.document.sheet.id}
                        type="link"
                        to={prefixLinkTo() + `/sheets/${props.document.sheet.id}`}>
                        <HtmlComponent>{props.document.sheet.title}</HtmlComponent>
                    </DocumentarySpaceDetailRow>
                }
                <DocumentarySpaceDetailRow label={t("Lien internet")} value={props.document.url} type="link-external" />
            </ToggableBlocComponent>
            <section className="row">
                <div className="col text-right">
                    <button id="close-detail" onClick={props.onRequestClose} className="btn btn-bleu-4">{t("Fermer")}</button>
                </div>
            </section>
        </section>
    </form>;
}