import { React, fileDownload, toast, _, DefaultLayout, useEffect, useContext, useState } from 'commons';
import { useTranslation } from 'react-i18next';
import { useList } from 'shared/hooks';
import { ReferentialContext } from 'shared/stores';
import { SheetApi } from 'shared/api';
import { ListComponent, FilterComponent, TreeReferentialComponent, LoadButton } from 'shared/components';
import { FileUtils, preventDefault, denyIfCantPerform } from 'shared/services';

export default function ObligationList(props) {
    
    denyIfCantPerform(props, "obligation:list");

    const { t } = useTranslation();
    const [exporting, setExporting] = useState(false);
    const [lastQuery, setLastQuery] = useState(null);
    const [referentialContext] = useContext(ReferentialContext);
    const domainTree = referentialContext["tree"];

    const columns = [
        {id: 'id', title: t("Numéro de la fiche")},
        {id: 'title', title: t("Titre de la fiche")},
        {id: 'control_todo', title: t("Contrôle à effectuer")},
        {id: 'document_todo', title: t("Document à faire")},        
        {id: 'periodicity', title: t("Périodicité")},
        {id: 'categories', title: t("Domaines / sous domaines / thèmes"), sortable: false, render: (row, params) => <TreeReferentialComponent className="arborescence" items={params.tree} value={row.categories.map(e => e.id)} />},
    ];

    let filterType = {
        categories: {type: "terms", fields: ["categories"]},
        keyword: {type: "wildcard", fields: ["title", "document_todo", "control_todo"]},       
    };

    const [
        rows,
        totalrows,
        criterias,
        sorting,
        direction,
        limit,
        page,,,
        addCriteria,
        updateSorting,
        updateLimit,
        updatePage,
        submitSearch,
        loading,,
        refresh
    ] = useList("obligation-list", SheetApi, null, "id", null, "asc", 20, modifyQuery);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => submitSearch(filterType), []);

    function modifyQuery(query) {
        if (_.isNil(query.bool)) {
            query.bool = {must: [
                {match: {entity_type: "App\\Entity\\Sheet"}}
            ]};
        }

        query.bool.must.push(
            {
                bool: {
                    should: [
                        {range: {control_todo: {gte: '""', lte: '*'}}},
                        {range: {document_todo: {gte: '""', lte: '*'}}},
                    ]
                }
            }
        );

        if (criterias.type === "control") {
            query.bool.must.push({range: {control_todo: {gte: '""', lte: '*'}}});
        }
        if (criterias.type === "document") {
            query.bool.must.push({range: {document_todo: {gte: '""', lte: '*'}}});
        }

        setLastQuery(query);
        return query;
    }

    function exportObligations() {
        setExporting(true);
        SheetApi.exportObligations(lastQuery)
            .then(data => {
                setExporting(false);
                fileDownload(data, FileUtils.getFilePrefix() + "-Obligations.xlsx");
            })
            .catch(error => {
                setExporting(false);
                toast.error(t("Erreur durant la création de l'export."))
            });
    }

    return (     
        <DefaultLayout screen="E58" title={t("Contrôles et documents obligatoires")}>
            <h1>{t("Contrôles et documents obligatoires")}</h1>
            <div className="bloc">
                <form className="form" onSubmit={(e) => preventDefault(e, submitSearch(filterType))} >
                    <section className="filters">
                        <header>
                            <h2><button className="toggler" type="button">{t("Recherche")}</button></h2>
                        </header>
                        <div className="bg-gris-25">
                            <div className="row">
                                <FilterComponent label={t("Recherche libre")} name="keyword" onChange={value => addCriteria("keyword", value)} value={criterias.keyword || ""} />
                                <FilterComponent label={t("Type de contrôle")} name="type" type="select" onChange={value => addCriteria("type", value)} value={criterias.type} clearable={true} blankLabel="Tous" options={[{value: "control", label: t("Contrôle à effectuer")}, {value: "document", label: t("Document à faire")}]} />
                                <FilterComponent label={t("Domaines / Sous domaines / Thèmes")} type="dropdown-tree-select" name="categories" onChange={value => addCriteria("categories", value)} value={criterias.categories} data={domainTree} mode="hierarchical" />
                            </div>
                        </div>
                        <div className="bg-gris-25 border-b border-gris-60">
                            <div className="row">
                                <div className="col-md-9">
                                    <button id="clearfilter-obligation" onClick={() => refresh(true)} type="button" className="btn btn-bleu-4 icon"><i className="icon-filtres-poubelle" aria-hidden="true"></i>Réinitialiser la recherche</button>
                                </div>
                                <div className="col-md-3 text-right">
                                    <LoadButton id="search-obligation" type="submit" label={t("Rechercher")} loading={loading} />
                                </div>
                            </div>
                        </div>
                    </section>
                    
                    <ListComponent 
                        id="obligation"
                        loading={loading}
                        selectable={false}
                        rows={rows}
                        columns={columns}
                        sorting={sorting}
                        direction={direction}
                        onSortingChange={updateSorting}
                        perpage={limit}
                        onPerpageChange={updateLimit}
                        page={page}
                        onPageChange={updatePage}
                        totalrows={totalrows}
                        columnsRenderParams={{
                            tree: domainTree
                        }}
                        globalActions={(
                            <LoadButton 
                                loading={exporting}
                                onClick={exportObligations}
                                label={t("Exporter")}
                                id="export-obligations"
                                className="btn btn-primary h25"
                                iconClass="icon-file-excel"
                                type="button"
                            />
                        )}
                    />
                </form>
            </div>
        </DefaultLayout>
    );
}