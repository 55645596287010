import React from 'react';
import { useTranslation } from 'react-i18next';
import DefaultLayout from "layouts/DefaultLayout";

export default function Page404() {

    const { t } = useTranslation();

    return (
        <DefaultLayout updateLastVisit={false} title={t("Erreur")}>
            <h1>{t("Erreur")}</h1>
            <p>Cette page n'existe pas.</p>
        </DefaultLayout>
    );
}
