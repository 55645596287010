import { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import DefaultLayout from "layouts/DefaultLayout";
import ToggableBlocComponent from "shared/components/ToggableBlocComponent";
import FieldComponent from 'shared/components/FieldComponent';
import LoadButton from 'shared/components/LoadButton';
import DomainSelectionComponent from 'shared/components/DomainSelectionComponent';
import Can, { denyIfCantPerform } from "shared/services/Can";
import { compileErrorsFromResponse } from 'shared/services/Utils';
import { useToggler } from "shared/hooks/Toggler";
import { useForm } from 'shared/hooks/Form';
import { useSecurity } from 'shared/hooks/Security';
import { useAccount } from 'hooks/Account';
import { toast } from 'react-toastify';
import ExternalUserApi from 'shared/api/ExternalUser';
import ReferentialApi from 'shared/api/Referential';
import AccountApi from 'shared/api/Account';
import { getLanguages } from 'shared/data/Language';
import { ReferentialContext } from 'shared/stores/Referential';
import { REFERENTIALS } from 'shared/data/ReferentialType';

export default function MyProfile(props) {

    denyIfCantPerform(props, "user:update-profile");

    const { t, i18n } = useTranslation();
    const [, referentialDispatcher] = useContext(ReferentialContext);
    const [user,, setUser] = useSecurity();
    const [account] = useAccount();
    const [currentAccountsIds, setCurrentAccountsIds] = useState([]);
    const [data, errors, setErrors, setValue, setData] = useForm({        
        lastname: user.lastname,
        firstname: user.firstname,
        telephone: user.telephone,
        civility: user.civility,
        function: user.function,
        alert_activation: (user.alert_activation === true ? 1 : 0),
        alert_restriction: (user.alert_restriction === true ? 1 : 0),
        alert_frequency: user.alert_frequency,
        alert_format: user.alert_format,
        alert_sheet_status: user.alert_sheet_status,
        alert_domains: user.alert_domains || [],
        alert_review_activation: user.alert_review_activation === true ? 1 : 0,
        alert_deadline_activation: user.alert_deadline_activation === true ? 1 : 0,
        alert_documentary_space_activation: user.alert_documentary_space_activation === true ? 1 : 0,
        language: user.language ? user.language : "fr",
    });
    const [saving, setSaving] = useState(false);
    const [togglers, toggle] = useToggler({'my-profile': true, 'alerts': true,});   

    function loadUserAccounts() {
        AccountApi
            .useraccounts(user.email)
            .then(([ua]) => {
                setCurrentAccountsIds(ua.map(item => item.id));
            });
    }

    function getAlertFrequencyOptions() {
        let frequencies = [
            {value: 0, label: t("Quotidienne")},
            {value: 1, label: t("Quotidienne + Récapitulatif mensuel")},
            {value: 2, label: t("Hebdomadaire")},
            {value: 3, label: t("Hebdomadaire + Récapitulatif mensuel")},
            {value: 4, label: t("Bimensuelle")},
            {value: 5, label: t("Mensuelle")},
        ];
        return frequencies.filter(v => Object.values(user.available_alert_frequencies).includes(v.value));
    }

    function submit(event) {
        event.preventDefault();
        setSaving(true);

        let newData = {...data};
        const availableFrequency = getAlertFrequencyOptions().map(o => o.value);
        if (!availableFrequency.includes(newData.alert_frequency)) {
            newData.alert_frequency = null;
        }

        ExternalUserApi
            .profileUpdate(newData)
            .then(u => {
                setSaving(false);
                setErrors([]);
                setData({        
                    lastname: u.lastname,
                    firstname: u.firstname,
                    telephone: u.telephone,
                    civility: u.civility,
                    function: u.function,
                    alert_activation: (u.alert_activation === true ? 1 : 0),
                    alert_restriction: (u.alert_restriction === true ? 1 : 0),
                    alert_frequency: u.alert_frequency,
                    alert_format: u.alert_format,
                    alert_sheet_status: u.alert_sheet_status,
                    alert_domains: u.alert_domains || [],
                    alert_review_activation: u.alert_review_activation === true ? 1 : 0,
                    alert_deadline_activation: u.alert_deadline_activation === true ? 1 : 0,
                    alert_documentary_space_activation: u.alert_documentary_space_activation === true ? 1 : 0,
                    language: u.language ? u.language : "fr"
                });
                setUser(u);
                i18n.changeLanguage(u.language);
                document.documentElement.setAttribute("lang", i18n.language);
                localStorage.setItem("current-language", i18n.language);
                if (i18n.language === "ar") {
                    document.documentElement.setAttribute("dir", "rtl");
                } else {
                    document.documentElement.setAttribute("dir", "ltr");
                }
                loadReferential();
                toast.success(t("Enregistrement effectué."));
            })
            .catch(error => {
                setSaving(false);
                if (error.response.data.message) {
                    toast.error(error.response.data.message);                    
                } else {
                    setErrors(compileErrorsFromResponse(error.response));
                    toast.error(t("Enregistrement impossible, veuillez corriger préalablement les erreurs."));
                }
            });
    }

    function loadReferential() {        
        referentialDispatcher({action: "status", data: "loading"});
        axios
            .all([
                ReferentialApi.list(REFERENTIALS.REFERENTIAL_TEXT_TYPE),
                ReferentialApi.list(REFERENTIALS.REFERENTIAL_TEXT_IMPACT),
                ReferentialApi.list(REFERENTIALS.REFERENTIAL_SOURCE),
                ReferentialApi.list(REFERENTIALS.REFERENTIAL_TRANSMITTER),
                ReferentialApi.list(REFERENTIALS.REFERENTIAL_INVOLVED_ACTOR),
                ReferentialApi.list(REFERENTIALS.REFERENTIAL_INVOLVED_FUNCTION),
                ReferentialApi.list(REFERENTIALS.REFERENTIAL_DESCRIPTORS),
                ReferentialApi.list(REFERENTIALS.REFERENTIAL_DOCUMENT_TYPE),
                ReferentialApi.tree(REFERENTIALS.REFERENTIAL_CATEGORY),                    
                ReferentialApi.tree(),
                ReferentialApi.userDomainTree()
            ])
            .then(axios.spread((
                [type],
                [impact],
                [source],
                [transmitter],
                [involvedActor],
                [involvedFunction],
                [descriptor],
                [documentType],
                category,
                tree,
                userDomainTree
            ) => {
                referentialDispatcher({action: "populate", data: type, type: REFERENTIALS.REFERENTIAL_TEXT_TYPE});
                referentialDispatcher({action: "populate", data: impact, type: REFERENTIALS.REFERENTIAL_TEXT_IMPACT});
                referentialDispatcher({action: "populate", data: source, type: REFERENTIALS.REFERENTIAL_SOURCE});
                referentialDispatcher({action: "populate", data: transmitter, type: REFERENTIALS.REFERENTIAL_TRANSMITTER});
                referentialDispatcher({action: "populate", data: involvedActor, type: REFERENTIALS.REFERENTIAL_INVOLVED_ACTOR});
                referentialDispatcher({action: "populate", data: involvedFunction, type: REFERENTIALS.REFERENTIAL_INVOLVED_FUNCTION});
                referentialDispatcher({action: "populate", data: descriptor, type: REFERENTIALS.REFERENTIAL_DESCRIPTORS});
                referentialDispatcher({action: "populate", data: documentType, type: REFERENTIALS.REFERENTIAL_DOCUMENT_TYPE});
                referentialDispatcher({action: "populate", data: category, type: REFERENTIALS.REFERENTIAL_CATEGORY});
                referentialDispatcher({action: "populate", data: tree, type: 'tree'});
                referentialDispatcher({action: "populate", data: userDomainTree, type: 'userDomainTree'});

                referentialDispatcher({action: "status", data: "load"});
            }));
    }


    useEffect(loadUserAccounts, [user.email]);

    return (
        <DefaultLayout screen="E57" title={t("Mon profil")}>
            <h1>{t("Mon profil")}</h1>
            <section>
                <form onSubmit={submit} id="form-my-profile" className="form">
                    <ToggableBlocComponent
                        label={t("Mon profil")}
                        id="my-profile"
                        toggled={togglers["my-profile"]}
                        toggle={toggle}
                    >
                        <div className="bg-blanc">
                            <FieldComponent 
                                name="email"
                                label={t("Email")}
                                className="field medium"
                                disabled
                                value={user.email}
                            />
                        </div>
                        <hr />
                        <div className="bg-blanc">
                            <FieldComponent
                                type="radio"
                                name="civility"
                                label={t("Civilité")}
                                options={[{value:"Mr", label: t("Monsieur")}, {value:"Mrs", label: t("Madame")}]}
                                value={data.civility}
                                onChange={value => setValue("civility", value)}
                            />
                            <FieldComponent
                                name="lastname"
                                label={`${t("Nom")} *`}
                                className="field small"
                                error={errors.lastname}
                                value={data.lastname} 
                                onChange={value => setValue("lastname", value)}
                            />
                            <FieldComponent
                                name="firstname"
                                label={`${t("Prénom")} *`}
                                className="field small"
                                error={errors.firstname}
                                value={data.firstname}
                                onChange={value => setValue("firstname", value)}
                            />
                            <FieldComponent
                                name="telephone" 
                                label={t("N° de téléphone")}
                                className="field small"
                                value={data.telephone}
                                onChange={value => setValue("telephone", value)}
                            />
                            <FieldComponent
                                name="function"
                                label={t("Fonction")}
                                className="field small"
                                value={data.function}
                                onChange={value => setValue("function", value)}
                            />
                            <FieldComponent
                                type="select"
                                name="language"
                                label={t("Langue")}
                                className="field small"
                                value={data.language}
                                options={getLanguages()}
                                onChange={value => setValue("language", value)}
                            />
                        </div> 
                    </ToggableBlocComponent>
                    <ToggableBlocComponent
                        label={t("Alertes")}
                        id="alerts"
                        toggled={togglers["alerts"]}
                        toggle={toggle}
                    >
                        <div className="bg-blanc">
                            <FieldComponent
                                type="radio"
                                name="alert_activation"
                                label={t("Activer les alertes")}
                                options={[{value:1, label: t("Oui")}, {value:0, label: t("Non")}]}
                                value={data.alert_activation}
                                onChange={value => setValue("alert_activation", parseInt(value))}
                            />
                            {data.alert_activation === 1 && <>
                                <FieldComponent
                                    type="radio"
                                    name="alert_frequency"
                                    label={t("Fréquence des alertes")}
                                    options={getAlertFrequencyOptions()}
                                    value={data.alert_frequency}
                                    error={errors.alert_frequency}
                                    onChange={value => setValue("alert_frequency", parseInt(value))}
                                />
                                <FieldComponent
                                    type="radio"
                                    name="alert_format"
                                    label={t("Format des alertes")}
                                    options={[
                                        {value:0, label: t("Séparé : Une fiche par mail")},
                                        {value:1, label: t("Groupé : Fiches groupées dans un mail")}
                                    ]}
                                    value={data.alert_format}
                                    onChange={value => setValue("alert_format", parseInt(value))}
                                />
                                <Can perform="user:update-alert-sheet-status" data={{account :account}} yes={() => (
                                    <FieldComponent
                                        type="radio"
                                        name="alert_sheet_status"
                                        label={t("Etat d'avancement de la fiche")}
                                        options={[
                                            {value:0, label: t("Alerte lorsque la fiche est finalisée")},
                                            {value:1, label: t("Alerte dès que la fiche est affectée")}
                                        ]}
                                        value={data.alert_sheet_status}
                                        onChange={value => setValue("alert_sheet_status", parseInt(value))}
                                    />
                                )} />
                                <Can perform="user:update-alert-domains" data={{account :account}} yes={() => (    
                                    <>
                                        <FieldComponent
                                            type="radio"
                                            name="alert_restriction"
                                            label={t("Accès à tous les domaines, sous domaines, thèmes")}
                                            options={[{value:0, label: t("Oui")}, {value:1, label: t("Non")}]}
                                            value={data.alert_restriction}
                                            onChange={value => setValue("alert_restriction", parseInt(value))}
                                        />
                                        {data.alert_restriction === 1 && <>
                                            <DomainSelectionComponent 
                                                label={t("Restriction des alertes aux domaines/sous-domaines/thèmes")}
                                                accounts={currentAccountsIds}
                                                name="alert_domains"
                                                onChange={value => setValue("alert_domains", value)}
                                                value={data.alert_domains}
                                            />
                                        </>}
                                    </>
                                )} />
                            </>}
                        </div>
                        <hr />
                        <div className="bg-blanc">
                            <Can perform="account:compliance_management" data={{account :account}} yes={() => (
                                <FieldComponent
                                    type="radio"
                                    name="alert_review_activation"
                                    label={t("Activation des alertes sur les dates de revue des fiches")}
                                    options={[{value:1, label: t("Oui")}, {value:0, label: t("Non")}]}
                                    value={data.alert_review_activation}
                                    onChange={value => setValue("alert_review_activation", parseInt(value))}
                                />
                            )} />
                            <Can perform="account:actionplan" data={{account :account}} yes={() => (
                                <FieldComponent
                                    type="radio"
                                    name="alert_deadline_activation"
                                    label={t("Activation des alertes sur les dates limite de réalisation des actions")}
                                    options={[{value:1, label: t("Oui")}, {value:0, label: t("Non")}]}
                                    value={data.alert_deadline_activation}
                                    onChange={value => setValue("alert_deadline_activation", parseInt(value))}
                                />
                            )} />
                        </div>
                        <Can perform="user:activate-alert-documentary-space" data={{account :account}} yes={() => (<>
                            <hr />
                            <div className="bg-blanc">
                                <FieldComponent
                                    type="radio"
                                    name="alert_documentary_space_activation"
                                    label={t("Réception de la newsletter de l'espace documentaire")}
                                    options={[{value:1, label: t("Oui")}, {value:0, label: t("Non")}]}
                                    value={data.alert_documentary_space_activation}
                                    onChange={value => setValue("alert_documentary_space_activation", parseInt(value))}
                                />
                            </div>
                        </>)} />
                    </ToggableBlocComponent>
                    <section className="row">
                        <div className="col text-right">
                            <LoadButton 
                                loading={saving} 
                                label={t("Enregistrer")} 
                                name="save" 
                                id="save-my-profile"
                            />
                        </div>                        
                    </section>  
                </form>
            </section>
        </DefaultLayout>
    );
}