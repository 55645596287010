import { useTranslation } from 'react-i18next';

export default function ChecksingleReadOnlyComponent(props) {

    const { t } = useTranslation();
    const { value } = props;

    if (value) {
        return <>
            <i className="icon-ok-squared color-vert-apave" aria-hidden="true"></i>
            <span className="sr-only">{t("Oui")}</span>        
        </>;
    }

    return <>
        <i className="icon-check-empty" aria-hidden="true"></i>
        <span className="sr-only">{t("Non")}</span>
    </>;
}