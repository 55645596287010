import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import BarLoader from "react-spinners/BarLoader";

import DefaultLayout from "layouts/DefaultLayout";

import { useAccount } from 'hooks/Account';
import { useToggler } from "shared/hooks/Toggler";

import AccountApi from 'shared/api/Account';

import { denyIfCantPerform } from 'shared/services/Can';

import TreeNodeComponent from 'views/tree/tree/TreeNodeComponent';
import TreeWatchsiteComponent from 'views/tree/tree/TreeWatchsiteComponent';
import ToggableBlocComponent from "shared/components/ToggableBlocComponent";

export default function Tree(props) {

    denyIfCantPerform(props, "tree:read");

    const { t } = useTranslation();
    const [togglers, toggle] = useToggler({'tree': true});
    const [tree, setTree] = useState([]);
    const [account] = useAccount();

    useEffect(() => {
       AccountApi
            .getTree(account.id)
            .then(data => setTree(data))
            .catch(error => console.log(error));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <DefaultLayout screen="E08" title={t("Arborescence et point de veille")}>
            <h1>{t("Arborescence et point de veille")}</h1>
            <ToggableBlocComponent label={t("Arborescence et point de veille")} id="tree" toggled={togglers["tree"]} toggle={toggle}>
                <ul className="arborescence accounttree no-arrow offset-200px">
                    {tree.nodes && tree.nodes.map((treeItem) => (
                        <TreeNodeComponent key={treeItem.id} item={treeItem} />
                    ))}
                    {tree.watchsites && tree.watchsites.map((treeItem) => (
                        <TreeWatchsiteComponent key={treeItem.id} item={treeItem} />
                    ))}
                </ul>
                <BarLoader loading={tree.length === 0} width={100} color="#5bad27" css="display:block;margin:10px auto;" />
            </ToggableBlocComponent>
        </DefaultLayout>
    );
}