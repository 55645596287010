import axios from 'axios';

let backendUrl = process.env.REACT_APP_BACKEND_URL;
if (document.location.pathname.substring(0, 5) === "/oidc") {
    backendUrl = process.env.REACT_APP_FID_BACKEND_URL;
}

const SecurityApi = {

    getCurrentUser: function() {
        return axios.get(
            backendUrl + "api/" + process.env.REACT_APP_CONTEXT + "/users/current",
            {
                withCredentials: true,
                headers: {'Content-Type': 'application/json'}
            }
        )
    },

    getCurrentAccount: function() {
        return axios.get(
            backendUrl + "api/" + process.env.REACT_APP_CONTEXT + "/accounts/current",
            {
                withCredentials: true,
                headers: {'Content-Type': 'application/json'}
            }
        )
    },

    login: function(data) {
        return axios.post(
            backendUrl + "api/" + process.env.REACT_APP_CONTEXT + "/login",
            data,
            {
                withCredentials: true,
                headers: {'Content-Type': 'application/json'}
            }
        );
    },

    adslLogin: function(data) {
        return axios.post(
            backendUrl + "api/" + process.env.REACT_APP_CONTEXT + "/login/fromadsl",
            data,
            {
                withCredentials: true,
                headers: {'Content-Type': 'application/json'}
            }
        );
    },

    logout: function() {
        return axios.post(
            backendUrl + "api/" + process.env.REACT_APP_CONTEXT + "/logout",
            [],
            {
                withCredentials: true,
                headers: {'Content-Type': 'application/json'}
            }
        );
    },

    getInvitationDetail: function (hash) {
        return axios.get(
            backendUrl + "api/" + process.env.REACT_APP_CONTEXT + "/invitations/" + hash,
            {
                withCredentials: true,
                headers: {'Content-Type': 'application/json'}
            }
        );
    },

    validation: function (data) {
        return axios.post(
            backendUrl + "api/" + process.env.REACT_APP_CONTEXT + "/invitations/validate",
            data,
            {
                withCredentials: true,
                headers: {'Content-Type': 'application/json'}
            }
        );
    },

    selectAccount: function (account) {
        return axios.post(
            backendUrl + "api/" + process.env.REACT_APP_CONTEXT + "/selectaccount",
            {
                account: account,
                timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
            },
            {
                withCredentials: true,
                headers: {'Content-Type': 'application/json'}
            }
        );
    }

}
export default SecurityApi;
