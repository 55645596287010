import React, { useState, useEffect, useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, Prompt, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import queryString from 'query-string';
import DefaultLayout from "layouts/DefaultLayout";
import { useForm } from 'shared/hooks/Form';
import { useAccount } from 'hooks/Account';
import { ReferentialContext } from 'shared/stores/Referential';
import { compileErrorsFromResponse, compileDataToSelectOptions } from 'shared/services/Utils';
import { denyIfCantPerform } from "shared/services/Can";
import { prefixLinkTo } from 'shared/services';
import FieldComponent from 'shared/components/FieldComponent';
import PageLoadingComponent from 'shared/components/PageLoadingComponent';
import LoadButton from 'shared/components/LoadButton';
import { REFERENTIAL_TYPE, REFERENTIAL_CATEGORY, REFERENTIAL_SUB_CATEGORY, REFERENTIAL_PARENT } from 'shared/data/ReferentialType';
import ReferentialApi from 'shared/api/Referential';
import CategoryApi from 'api/Category';

export default function CategoryForm(props) {

    const { t } = useTranslation();
    const [account] = useAccount();

    denyIfCantPerform(props, "category:write", {account: account});

    const history = useHistory();
    const [saving, setSaving] = useState("");
    const [parents, setParents] = useState([{value: "", label: t("Chargement")}]);
    const [isLoad, setIsLoad] = useState(!!props.match.params.id);
    const [, referentialDispatcher] = useContext(ReferentialContext);

    const params = queryString.parse(props.location.search);
    const [data, errors, setErrors, setValue, setData, reloadOrClose, formHasModifications] = useForm({
        id: null,
        value: "",
        type: params.parent !== null && params.parent !== undefined ? REFERENTIAL_SUB_CATEGORY : REFERENTIAL_CATEGORY,
        parent: parseInt(params.parent) || null,
        account: account.id,
    });

    const loadParents = useCallback(() => {
        if(data.type !== null && REFERENTIAL_PARENT[data.type]) {
            CategoryApi
                .search({"bool": {"must": [{"match": {"type": REFERENTIAL_PARENT[data.type]}}]}}, 0, 9999)
                .then(([referentials]) => {
                    setParents(compileDataToSelectOptions(referentials, 'id', 'value'));
                });
        }
    }, [data.type]);

    useEffect(() => {
        const referentialId = props.match.params.id;
        if (referentialId) {
            CategoryApi
                .get(referentialId)
                .then(referential => {
                    setIsLoad(false);
                    let newReferential = {...referential};
                    if (newReferential.parent) {
                        newReferential.parent = referential.parent.id;
                    }
                    setData(d => Object.assign({}, d, newReferential));
                })
                .catch(() => {
                    toast.error(t("Une erreur est survenue lors de la récupération de la catégorie"));
                    history.goBack();
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props, setIsLoad, setData]);

    useEffect(() => {
        loadParents();
    }, [loadParents])

    function submit(event) {
        event.preventDefault();
        if (event.target.id === "form-category") {
            setSaving(event.nativeEvent.submitter.name);

            let savedData = { ...data};
            if (savedData.parent === -1) {
                savedData.parent = null;
            }

            CategoryApi
                .save(savedData)
                .then(referential => {
                    setData(Object.assign({}, data, {"id": referential.id}));
                    setErrors({});
                    setSaving("");
                    toast.success(t("Enregistrement effectué."));
                    reloadOrClose(event, props, "categories", referential.id);

                    ReferentialApi
                        .tree(REFERENTIAL_CATEGORY)
                        .then(categoriesTree => {
                            referentialDispatcher({action: "populate", data: categoriesTree, type: REFERENTIAL_CATEGORY});
                        });
                })
                .catch(error => {
                    setSaving("");
                    if (error.response.data.message) {
                        toast.error(error.response.data.message);                    
                    } else {
                        setErrors(compileErrorsFromResponse(error.response));
                        toast.error(t("Des erreurs sont survenues"));
                    }
                });
        }
    }

    const pageName = props.match.params.id ? "Edition d'une catégorie" : "Ajout d'une catégorie";

    if (isLoad === true) {
        return <PageLoadingComponent label={pageName} />
    }

    return (
        <DefaultLayout screen="E28" title={pageName}>
            <Prompt
                when={formHasModifications()}
                message={t("Vous avez des modifications non enregistrées, voulez-vous vraiment quitter ?")}
            />
            <h1>{pageName}</h1>
            <section className="bloc">
                <form onSubmit={submit} id="form-category" className="form">
                    <section id="configuration" className="bloc">
                        <header className="bg-gris-45 uppercase">
                            Configuration "{REFERENTIAL_TYPE[data.type]}"
                        </header>
                        <div className="border-gris-25 border-lrb bg-blanc">
                            <FieldComponent 
                                name="value" 
                                label={`${t("Libellé")} *`} 
                                error={errors.value} 
                                value={data.value}
                                onChange={value => setValue("value", value)} 
                            />

                            {(REFERENTIAL_PARENT[data.type]) && (
                                <FieldComponent
                                    type="select"
                                    name="parent"
                                    label={`${t("Parent")} *`}
                                    className="field small"
                                    onChange={value => setValue("parent", value)}
                                    value={data.parent}
                                    error={errors.parent}
                                    clearable
                                    options={parents}
                                />
                            )}
                        </div>
                    </section>
                    <section className="row">
                        <div className="col">
                            <Link id="back-category-list" to={prefixLinkTo() + "/categories"} className="btn btn-bleu-4">{t("Retour à la liste")}</Link>
                        </div>
                        <div className="col text-right">
                            <LoadButton 
                                loading={saving === "save-and-close"} 
                                label={t("Enregistrer et fermer")}
                                name="save-and-close"
                                id="save-and-close-category"
                            />
                            <LoadButton 
                                loading={saving === "save"} 
                                label={t("Enregistrer")} 
                                name="save" 
                                id="save-category"
                            />
                        </div>
                    </section>
                </form>
            </section>
        </DefaultLayout>
    );
}
