import React from 'react';
import { useTranslation } from 'react-i18next';

import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import DropdownTreeSelect from "react-dropdown-tree-select";
import DatePicker, { registerLocale } from "react-datepicker";
import fr from "date-fns/locale/fr";
import en from "date-fns/locale/en-US";
import ar from "date-fns/locale/ar";
import es from "date-fns/locale/es";
import _ from 'lodash';

registerLocale("fr", fr);
registerLocale("en", en);
registerLocale("ar", ar);
registerLocale("es", es);

export default function FilterComponent(props) {   

    const { t, i18n } = useTranslation();
    const id = props.name;
    const { type, multiple } = props;
    let { className } = props;
    let labelClassName = "";
    let values;

    switch(type) {
        case 'select':
        case 'async-select':
            values = [];

            if (props.options) {
                const flatOptions = _.flatMapDeep(props.options, (opt) => opt.options ? opt.options : opt);
                if (!_.isNil(props.value)) {
                    Object.entries(flatOptions).forEach(([index, option]) => {
                        if (!props.multiple && option.value === props.value) {
                            values.push(option);
                        }
                        if (props.multiple && props.value.indexOf(option.value) > -1) {
                            values.push(option);
                        }
                    });
                }
            }
            className = "react-select-container " + className;
            break;
        default:
    }

    if (!(
        _.isNil(props.value)
        || (_.isArray(props.value) && props.value.length === 0)
        || props.value === ""
        || _.isEqual(props.value, {start:null, end:null})
    )) {
        labelClassName += " color-vert-apave";
    }

    function toggle(e) {
        e.target.parentElement.nextElementSibling.classList.toggle("d-none");
        e.target.classList.toggle("actif");
    }

    function formatValue(event, data) {
        switch(type) {
            case 'dropdown-tree-select':
                switch (props.mode) {
                    case 'hierarchical': return data.map(i => i.value);
                    case 'multiSelect': return data.map(i => i.value);
                    case 'radioSelect': return data.length > 0 ? data.shift().value : null;
                    default: return event.value;
                }
            case 'buttons':
                if(multiple === true) {
                    let values = props.value ? [...props.value] : [];
                    if (values.includes(parseInt(event.target.value))) {
                        values.splice(values.indexOf(parseInt(event.target.value)), 1);
                    } else {
                        values.push(parseInt(event.target.value));
                    }
                    return values;
                } else {
                    return event.target.value;
                }
            case 'checkbox':
            case 'degree-checkbox':
                let values = props.value || [];
                if (event.target.checked) {
                    values.push(parseInt(event.target.value));
                } else {
                    values.splice(values.indexOf(parseInt(event.target.value)), 1);
                }
                return values;
            case 'checksingle':
                return event.target.checked;
            case 'daterange':
            case 'quarterrange':
                let newValue = event ? event : null;
                if (newValue) {
                    const offset = -(event.getTimezoneOffset());
                    const offsetDate = new Date();
                    offsetDate.setTime(event.getTime() + offset * 60000);

                    if (data === "start") {
                        newValue = offsetDate.toISOString();
                    } else {
                        newValue.setHours(23, 59, 59);
                        newValue = offsetDate.toISOString();
                    }
                }

                let range = Object.assign({}, {start: null, end: null}, props.value);
                return Object.assign({}, range, {[data]: newValue});
            case 'date':
            case 'datetime':
                return event;
            case 'select':
            case 'async-select':
                if(multiple === true) {
                    let values = [];
                    if (event) {
                        Object.entries(event).forEach(([index, option]) => {
                            values.push(option.value);
                        });
                    }
                    return values;
                } else {
                    return event ? event.value : null;
                }
            default:
                return event.target.value || event.value || null;
        }
    }

    function renderInput() {
        switch(type) {
            case 'checksingle':
                return (
                    <>
                        <input
                            type="checkbox"
                            className={className}
                            name={props.name}
                            id={id}
                            onChange={(event, data) => props.onChange(formatValue(event, data), event)}
                            value={props.value}
                            checked={props.checked}
                            disabled={props.disabled}
                            readOnly={props.readOnly}
                        />
                        <label htmlFor={id}>{props.checksingleLabel}</label>
                    </>
                );
            case 'radio':
            case 'degree':
                return (<>
                    {props.blankLabel && <>
                        <input
                            type="radio" 
                            name={props.name}
                            id={`${props.name}-blank`}
                            value={""}
                            onChange={(event, data) => props.onChange(formatValue(event, data), event)}
                            selected={!props.value}
                            checked={!props.value}
                            disabled={props.disabled}
                            readOnly={props.readOnly}
                        />
                        <label htmlFor={`${props.name}-blank`}>{props.blankLabel}</label>
                    </>}
                    {props.options.map((option, index) => (<React.Fragment key={index}>
                        <input
                            type="radio" 
                            name={props.name}
                            id={`${props.name}-${index}`}
                            value={option.value}
                            onChange={(event, data) => props.onChange(formatValue(event, data), event)}
                            selected={option.value === props.value}
                            checked={option.value === props.value}
                            disabled={props.disabled}
                            readOnly={props.readOnly}
                        />
                        <label className={option.labelClassName} htmlFor={`${props.name}-${index}`}>
                            {option.labelSrOnly ?
                                <span className="sr-only">{option.label}</span>
                                :
                                option.label
                            }
                        </label>
                    </React.Fragment>))}
                </>)
            case 'degree-checkbox':
                return (<>
                    {props.options.map((option, index) => (<React.Fragment key={index}>
                        <input
                            type="checkbox" 
                            name={props.name}
                            id={`${props.name}-${index}`}
                            value={option.value}
                            onChange={(event, data) => props.onChange(formatValue(event, data), event)}
                            selected={Array.isArray(props.value) && props.value.includes(option.value)}
                            checked={Array.isArray(props.value) && props.value.includes(option.value)}
                            disabled={props.disabled}
                            readOnly={props.readOnly}
                        />
                        <label className={option.labelClassName} htmlFor={`${props.name}-${index}`}>
                            {option.labelSrOnly ?
                                <span className="sr-only">{option.label}</span>
                                :
                                option.label
                            }
                        </label>
                    </React.Fragment>))}
                </>)
            case 'checkbox':
                return (<>
                    {props.options.map((option, index) => (<>
                        <input
                            key={index}
                            type="checkbox" 
                            name={props.name}
                            id={`${props.name}-${index}`}
                            value={option.value}
                            onChange={(event, data) => props.onChange(formatValue(event, data), event)}
                            selected={Array.isArray(props.value) && props.value.includes(option.value)}
                            checked={Array.isArray(props.value) && props.value.includes(option.value)}
                            disabled={props.disabled}
                            readOnly={props.readOnly}
                        />
                        <label className={option.labelClassName} htmlFor={`${props.name}-${index}`}>
                            {option.labelSrOnly ?
                                <span className="sr-only">{option.label}</span>
                                :
                                option.label
                            }
                        </label>
                    </>))}
                </>);
            case 'select':
                const selectOptions = [...props.options];
                selectOptions.forEach(option => {
                    option.label = t(option.label);
                });

                return (
                    <Select
                        className={className}
                        classNamePrefix="react-select"
                        placeholder="Sélectionnez..."
                        value={values}
                        name={props.name}
                        id={id}
                        onChange={(event, data) => props.onChange(formatValue(event, data), event)}
                        defaultValue={props.value}
                        required={props.required}
                        options={selectOptions}
                        isMulti={props.multiple}
                        isClearable={props.clearable}
                        isDisabled={props.disabled}
                    />
                );
            case 'async-select':
                return (
                    <AsyncSelect
                        className={className}
                        classNamePrefix="react-select"
                        placeholder=""
                        name={props.name}
                        id={id}
                        onChange={(event, data) => props.onChange(formatValue(event, data), event)}
                        defaultValue={props.value}
                        required={props.required}
                        loadOptions={props.loadOptions}
                        cacheOptions={props.cacheOptions || true}
                        isMulti={props.multiple}
                        isClearable={props.clearable}
                    />
                );
            case 'dropdown-tree-select':
                const mapper = (item) => {
                    if (props.value) {
                        if (props.mode === "multiSelect") {
                            item.checked = props.value.includes(item.value) || (item.parent && item.parent.checked === true);
                        } else {
                            item.checked = props.value.includes(item.value);
                        }
                    } else {
                        item.checked = false;
                    }
                    item.tagLabel = item.parent ? item.parent.tagLabel + " / " + item.label : item.label;
                    if (props.mode === "multiSelect") {
                        item.disabled = item.parent ? item.parent.checked === true || item.parent.disabled === true : false;
                    }
                    
                    if (item.children) {
                        item.children.forEach(child => {
                            child.parent = {
                                checked: item.checked,
                                tagLabel: item.tagLabel,
                                value: item.value,
                                label: item.label
                            };
                        });
                        item.children = item.children.map(mapper);
                        item.children.forEach(child => {
                            item.expanded = child.checked || child.expanded || item.expanded;
                        });
                    }
                    return item;
                };
                return (
                    <DropdownTreeSelect
                        texts={{
                            placeholder: "Sélectionnez..."
                        }}
                        disabled={props.disabled}
                        className={className}
                        name={props.name}
                        id={id}
                        onChange={(event, data) => props.onChange(formatValue(event, data), event)}
                        data={props.useMapper ? props.data.map(mapper) : props.data}
                        mode={props.mode}
                    />
                );
                case 'quarterrange':
                    let startQuarter = _.isNil(props.value) || _.isNil(props.value.start) ? null : props.value.start;
                    let endQuarter = _.isNil(props.value) || _.isNil(props.value.end) ? null : props.value.end;
                    if (_.isString(startQuarter)) {
                        startQuarter = new Date(startQuarter);
                    }
                    if (_.isString(endQuarter)) {
                        endQuarter = new Date(endQuarter);
                    }
    
                    return (<>
                        <label htmlFor={`${id}-starttime`}>Début :</label>
                        <span className="field-wrap">
                            <DatePicker
                                id={`${id}-starttime`}
                                locale={i18n.language}
                                selectsStart
                                className={className}
                                placeholderText=""
                                dateFormat="yyyy, QQQ"
                                onChange={(value) => props.onChange(formatValue(value, "start")) }
                                showQuarterYearPicker
                                selected={startQuarter}
                                startDate={startQuarter}
                                endDate={endQuarter}
                            />
                        </span>
                        <label htmlFor={`${id}-endtime`}>Fin :</label>
                        <span className="field-wrap">
                            <DatePicker
                                id={`${id}-endtime`}
                                locale={i18n.language}
                                selectsEnd
                                className={className}
                                placeholderText=""
                                dateFormat="yyyy, QQQ"
                                onChange={(value) => props.onChange(formatValue(value, "end")) }
                                showQuarterYearPicker
                                selected={endQuarter}
                                startDate={startQuarter}
                                endDate={endQuarter}
                            />
                        </span>
                    </>);
            case 'daterange':
                let startValue = _.isNil(props.value) || _.isNil(props.value.start) ? null : props.value.start;
                let endValue = _.isNil(props.value) || _.isNil(props.value.end) ? null : props.value.end;
                if (_.isString(startValue)) {
                    startValue = new Date(startValue);
                }
                if (_.isString(endValue)) {
                    endValue = new Date(endValue);
                }

                return (<>
                    <label htmlFor={`${id}-starttime`}>Début :</label>
                    <span className="field-wrap">
                        <DatePicker
                            id={`${id}-starttime`}
                            locale={i18n.language}
                            selectsStart
                            className={className}
                            placeholderText="jj/mm/aaaa"
                            dateFormat="dd/MM/yyyy"
                            onChange={(value) => props.onChange(formatValue(value, "start")) }
                            selected={startValue}
                            startDate={startValue}
                            endDate={endValue}
                        />
                    </span>
                    <label htmlFor={`${id}-endtime`}>Fin :</label>
                    <span className="field-wrap">
                        <DatePicker
                            id={`${id}-endtime`}
                            locale={i18n.language}
                            selectsEnd
                            className={className}
                            placeholderText="jj/mm/aaaa"
                            dateFormat="dd/MM/yyyy"
                            onChange={(value) => props.onChange(formatValue(value, "end")) }
                            selected={endValue}
                            startDate={startValue}
                            endDate={endValue}
                            minDate={startValue}
                        />
                    </span>
                </>);
            case 'date':
            case 'datetime':
                return (
                    <DatePicker
                        id={id}
                        locale={i18n.language}
                        onChange={(event, data) => props.onChange(formatValue(event, data), event)}
                        className="field xsmall"
                        selected={props.value}
                        showTimeSelect={type === "datetime"}
                        placeholderText={type === "date" ? "dd/mm/yyyy" : "dd/mm/yyyy hh:mm"}
                        dateFormat={type === "date" ? "dd/MM/yyyy" : "dd/MM/yyyy H:I"}
                        openToDate={new Date()}
                    />
                );
            case 'buttons':
                if (props.multiple) {
                    return (<>
                        {props.options.map((option, index) => (<React.Fragment key={index}>
                            <input
                                type="checkbox" 
                                name={props.name}
                                id={`${props.name}-${index}`}
                                value={option.value}
                                onChange={(event, data) => props.onChange(formatValue(event, data), event)}
                                selected={props.value ? props.value.includes(option.value) : false}
                                checked={props.value ? props.value.includes(option.value) : false}
                                disabled={props.disabled}
                                readOnly={props.readOnly}
                            />
                            <label className={`bg-checked-${option.color}`} htmlFor={`${props.name}-${index}`}>
                                {option.labelSrOnly ?
                                    <span className="sr-only">{t(option.label)}</span>
                                    :
                                    t(option.label)
                                }
                            </label>
                        </React.Fragment>))}
                    </>)
                } else {
                    return (<>
                        {props.options.map((option, index) => (<React.Fragment key={index}>
                            <input
                                type="radio" 
                                name={props.name}
                                id={`${props.name}-${index}`}
                                value={option.value}
                                onChange={(event, data) => props.onChange(formatValue(event, data), event)}
                                selected={option.value === props.value}
                                checked={option.value === props.value}
                                disabled={props.disabled}
                                readOnly={props.readOnly}
                            />
                            <label className={`bg-checked-${option.color}`} htmlFor={`${props.name}-${index}`}>
                                {option.labelSrOnly ?
                                    <span className="sr-only">{t(option.label)}</span>
                                    :
                                    t(option.label)
                                }
                            </label>
                        </React.Fragment>))}
                    </>)
                }
            default:
                return (
                    <input
                        type={props.type}
                        className={className}
                        name={props.name}
                        id={id}
                        disabled={props.disabled}
                        onFocus={props.onFocus}
                        onChange={(event, data) => props.onChange(formatValue(event, data), event)}
                        readOnly={props.readOnly}
                        onBlur={props.onBlur}
                        value={props.value || ""}
                        required={props.required}
                        pattern={props.pattern}
                        multiple={props.multiple}
                        checked={props.checked}
                        maxLength={props.maxLength}
                    />
                );
        }
    }

    function renderFilter() {
        switch(type) {
            case "degree":
            case "degree-checkbox":
                return <div className={props.colClassName}>
                    <fieldset className="degres">
                        <legend className={labelClassName}><button onClick={toggle} className="toggler actif" type="button">{props.label} : </button></legend>
                        <div className="toggle-group">
                            {renderInput()}
                        </div>
                    </fieldset>
                </div>;
            case "checkbox":
                return <div className={props.colClassName}>
                    <label className={labelClassName} htmlFor={id}><button onClick={toggle} className="toggler actif" type="button">{props.label} : </button></label>
                    <div className="toggle-group add-space">
                        <span className="field-wrap" style={{"flexDirection": "column"}}>
                            {renderInput()}
                            {props.children}
                        </span>
                    </div>                
                </div>;
            case 'buttons':
                return <div className={props.colClassName}>
                    <fieldset className="statuts">
                        <legend className={labelClassName}><button onClick={toggle} className="toggler actif" type="button">{props.label} : </button></legend>
                        <div className="toggle-group">
                            {renderInput()}
                        </div>
                    </fieldset>
                    </div>;
            case "radio":
                return <div className={props.colClassName}>
                    <fieldset>
                        <legend className={labelClassName}><button onClick={toggle} className="toggler actif" type="button">{props.label} : </button></legend>
                        <div className="toggle-group">
                            {renderInput()}
                        </div>
                    </fieldset>
                </div>;
            case "daterange":
            case "quarterrange":
                return <div className={props.colClassName}>
                    <fieldset className="date">
                        <legend className={labelClassName}><button onClick={toggle} className="toggler actif" type="button">{props.label}</button></legend>
                        <div className="toggle-group">
                            {renderInput()}
                            {props.children}
                        </div>
                    </fieldset>
                </div>;
            case "dropdown-tree-select": case "select":
                return <div className={props.colClassName}>
                    <label className={labelClassName} htmlFor={id}><button onClick={toggle} className="toggler actif" type="button">{props.label} : </button></label>
                    <div className="toggle-group add-space">
                        {props.children}
                        <span className="field-wrap mb-0">
                            {renderInput()}
                        </span>
                    </div>                
                </div>;
            case "keyword":
                return <div className={props.colClassName}>
                    <label className={labelClassName} htmlFor={id}><button onClick={toggle} className="toggler actif" type="button">{props.label} : </button></label>
                    <div className="toggle-group add-space">
                        <span className="field-wrap">
                            {renderInput()}
                            {props.children}
                        </span>
                        <fieldset>
                            <div>
                            {props.fields.map(field => <React.Fragment key={field.value}>
                                <input
                                    checked={props.selectedFields.includes(field.value)}
                                    onChange={(e) => {
                                        if (props.onFieldChange) {
                                            props.onFieldChange(e.target.value);
                                        }
                                    }}
                                    type="checkbox"
                                    value={field.value}
                                    id={`${id}-field-${field.value}`}
                                />
                                <label htmlFor={`${id}-field-${field.value}`}>{t(field.label)}</label>
                            </React.Fragment>)}
                            </div>
                        </fieldset>
                    </div>
                </div>;
            default:
                return <div className={props.colClassName}>
                    <label className={labelClassName} htmlFor={id}><button onClick={toggle} className="toggler actif" type="button">{props.label} : </button></label>
                    <div className="toggle-group add-space">
                        <span className="field-wrap">
                            {renderInput()}
                            {props.children}
                        </span>
                    </div>                
                </div>;
        }
    }

    return renderFilter();
}

FilterComponent.defaultProps = {
    type: "text",
    className: "field",
    disabled: false,
    colClassName: "col-md-3",
    useMapper: true
};