import { React, toast, Link, DefaultLayout, useEffect, useState } from 'commons';
import { useTranslation } from 'react-i18next';
import { useAccount } from 'hooks/Account';
import { FilterComponent, FieldComponent, LoadButton } from 'shared/components';
import { useList, usePosition, useForm, useToggler } from 'shared/hooks';
import ListComponent from 'shared/components/ListComponent';
import { AccountApi } from 'shared/api';
import CategoryApi from 'api/Category';
import { denyIfCantPerform, confirm, preventDefault, prefixLinkTo } from 'shared/services';
import { REFERENTIAL_CATEGORY } from 'shared/data';

export default function CategoryList(props) {

    const { t } = useTranslation();
    const [initializePosition, setPosition] = usePosition("category-list");
    const [, toggleToggler,,,,, getToggler] = useToggler();
    const [account,, setAccount] = useAccount();
    const [saving, setSaving] = useState(false);
    const [data,,, setValue] = useForm({
        id: account.id,
        category_label: account.category_label,
        subcategory_label: account.subcategory_label,
        customfield1_label: account.customfield1_label,
        customfield2_label: account.customfield2_label,
        default_review_date: account.default_review_date,
    });

    denyIfCantPerform(props, "category:list", {account: account});

    const columns = [
        {
            id: 'value',
            title: t("Libellé"),
            render: (row) => {
                return (<>
                    {row.parent && <div style={{width: 25, display: 'inline-block'}}></div>}
                    {row.value}
                </>);
            }
        },
        {
            id: 'action',
            title: '',
            sortable: false,
            className: 'w-100px',
            render: (row) => {            
                return (
                    <ul className="actions">
                        <li>
                            <Link title={t("Modifier")} to={prefixLinkTo() + "/categories/" + row.id} id="edit-category">
                                <i className="icon-actions-modifier" aria-hidden="true"></i>
                            </Link>
                        </li>
                        <li>
                            <Link 
                                title={t("Supprimer")}
                                onClick={(e) => deleteCategory(e, row)}
                                to={prefixLinkTo() + "/categories/delete/" + row.id}
                                id="delete-category"
                            >
                            <i className="icon-actions-supprimer" aria-hidden="true"></i></Link>
                        </li>
                        {!row.parent && (
                            <li>
                                <Link 
                                    title={t("Ajouter une sous-catégorie")}
                                    to={prefixLinkTo() + "/categories/new?parent=" + row.id}
                                    id="add-sub-domain"
                                >
                                    <i className="icon-boutons-ajouter-creer-affecter" aria-hidden="true"></i>
                                </Link>
                            </li>
                        )}
                    </ul>
                )
            }
        }
    ];

    const filterType = {
        type: {type: "match", fields: ["type"]},
        keyword: {type: "wildcard", fields: ["value"]},
        account: {type: "match", fields: ["account"]},
    };

    const [
        rows,
        totalrows,
        criterias,
        sorting,
        direction,
        limit,
        page,,,
        addCriteria,
        updateSorting,
        updateLimit,
        updatePage,
        submitSearch,
        loading,
        setLoading,
        refresh,
    ] = useList("category-list", CategoryApi, null, "value", {type: REFERENTIAL_CATEGORY});

    useEffect(() => {
        submitSearch(filterType, () => initializePosition(), false);
        // eslint-disable-next-line
    }, []);

    function deleteCategory(event, referential) {
        event.preventDefault();

        if (referential.used || (referential.childs && referential.childs.length > 0)) {
            toast.warning(t("Impossible de supprimer la catégorie, elle est utilisée ou possède une sous-catégorie."))
        } else {
            confirm('Etes-vous sur de vouloir supprimer la catégorie "' + referential.value + '" ?', function() {
                setLoading(true);

                CategoryApi
                    .delete(referential.id)
                    .then(response => {
                        setLoading(false);
                        toast.success(t("Catégorie supprimée"));
                        refresh();
                    })
                    .catch(error => {
                        setLoading(false);
                        toast.error(error.response.data.message)
                    });
            });
        }
    }

    function save(event) {
        event.preventDefault();
        setSaving(true);

        AccountApi
            .saveConfiguration(data)
            .then(d => {
                setAccount(d);
                setSaving(false);
                toast.success(t("Enregistrement effectué."));
            })
            .catch(error => {
                setSaving(false);
                toast.error(error.response.data.message);
            });
    }

    return (
        <DefaultLayout onScroll={(position) => setPosition(position)} screen="E27" title={t("Gestion des catégories et sous-catégories")}>
            <h1>{t("Gestion des catégories et sous-catégories")}</h1>            
            <div className="bloc">
                <form className="form" onSubmit={(e) => preventDefault(e, submitSearch(filterType))}>
                    <section className="filters">
                        <header>
                            <h2><button className="toggler" type="button">{t("Recherche")}</button></h2>
                        </header>
                        <div className="bg-gris-25">
                            <div className="row">
                                <FilterComponent
                                    label={t("Recherche libre")}
                                    name="keyword"
                                    onChange={value => addCriteria("keyword", value)}
                                    value={criterias.keyword || ""}
                                />
                            </div>
                        </div>
                        <div className="bg-gris-25 border-b border-gris-60">
                            <div className="row">
                                <div className="col-md-9">
                                    <button id="clearfilter-category" onClick={() => refresh(true)} type="button" className="btn btn-bleu-4 icon"><i className="icon-filtres-poubelle" aria-hidden="true"></i>Réinitialiser la recherche</button>
                                </div>
                                <div className="col-md-3 text-right">                                    
                                    <LoadButton id="search-category" type="submit" label={t("Rechercher")} loading={loading} />
                                </div>
                            </div>
                        </div>
                    </section>

                    <ListComponent 
                        id="referentials"
                        loading={loading}
                        rows={rows}
                        renderHeader={(cols) => {
                            return (
                                <tr>
                                    <th className="w-34px text-center valign-top"></th>
                                    {cols.map((column) => {
                                        if (column.is_active) {
                                            return (
                                                <th key={column.id}>
                                                    {column.title}
                                                </th>
                                            );
                                        }
                                        return null;
                                    })}
                                </tr>
                            );
                        }}
                        renderRow={(row, cols, index, displayRow) => {
                            return (
                                <React.Fragment key={row.id}>
                                    <tr key={row.id} id={row.id}>
                                        <td className="w-34 text-center valign-top">
                                            {row.childs && (              
                                                <button
                                                    className={"toggler" + (getToggler("row-" + row.id) ? " actif" : "")}
                                                    onClick={(e) => { 
                                                        e.preventDefault(); 
                                                        toggleToggler("row-" + row.id);
                                                    }}
                                                />
                                            )}
                                        </td>
                                        {cols.map((column) => 
                                            <React.Fragment key={column.id}>
                                                {displayRow(row, column)}
                                            </React.Fragment>
                                        )}
                                    </tr>
                                    {getToggler("row-" + row.id) && row.childs && 
                                        row.childs.map((subRow) => 
                                            <tr key={subRow.id}>
                                                <td className="w-34 text-center valign-top"></td>
                                                {cols.map((column) => 
                                                    <React.Fragment key={column.id}>
                                                        {displayRow(subRow, column)}
                                                    </React.Fragment>
                                                )}
                                            </tr>
                                        )
                                    }
                                </React.Fragment>
                            );
                        }}
                        columns={columns}
                        sorting={sorting}
                        direction={direction}
                        onSortingChange={updateSorting}
                        perpage={limit}
                        onPerpageChange={updateLimit}
                        page={page}
                        onPageChange={updatePage}
                        totalrows={totalrows}
                    >
                        <Link 
                            id="new-category"
                            to={prefixLinkTo() + "/categories/new"}
                            className="btn btn-primary h25 icon"
                        >
                            <i className="icon-boutons-ajouter-creer-affecter" aria-hidden="true"></i>
                            &nbsp;Ajouter une catégorie
                        </Link>
                        <Link 
                            id="new-sub-category"
                            to={prefixLinkTo() + "/categories/new?parent=-1"}
                            className="btn btn-primary h25 icon"
                        >
                            <i className="icon-boutons-ajouter-creer-affecter" aria-hidden="true"></i>
                            &nbsp;Ajouter une sous-catégorie
                        </Link>
                    </ListComponent>
                </form>
                <section>
                    <form id="form-categories-labels" className="form" onSubmit={save}>
                        <section className="bloc">
                            <header className="border-rlb border-gris-40">
                                <h2 className="uppercase">Libellés des catégories</h2>
                            </header>
                            <section className="border-rlb border-gris-40">
                                <FieldComponent
                                    className="field small"
                                    label={t("Libellé des catégories")} 
                                    name="category_label" 
                                    value={data.category_label} 
                                    onChange={value => setValue("category_label", value)}
                                />
                                <FieldComponent
                                    className="field small"
                                    label={t("Libellé des sous catégories")} 
                                    name="subcategory_label" 
                                    value={data.subcategory_label} 
                                    onChange={value => setValue("subcategory_label", value)}
                                />
                            </section>
                        </section>    
                        <section className="row">
                            <div className="col text-right">
                                <LoadButton 
                                    loading={saving} 
                                    label={t("Enregistrer")} 
                                    name="save" 
                                    id="save-categories-labels"
                                />
                            </div>
                        </section>
                    </form>
                </section>
            </div>
        </DefaultLayout>
    );
}