import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import StatisticsChartItemBlock from 'views/statistics/list/ChartItemBlock';

export default function ConformityStatisticsChartItem(props) {

    const { t } = useTranslation();
    const { row } = props;

    const state_planned = row.total_by_status["planned"];
    const state_completed = row.total_by_status["completed"];
    const state_evaluated = row.total_by_status["evaluated"];
    const state_closed = row.total_by_status["closed"];
    const state_planned_percentage = row.percentage_by_status["planned"];
    const state_completed_percentage = row.percentage_by_status["completed"];
    const state_evaluated_percentage = row.percentage_by_status["evaluated"];
    const state_closed_percentage = row.percentage_by_status["closed"];

    return (
        <div className="chart-item row">
            <div className="chart-label-container col-md-2 text-right">
                <label className="chart-label">
                {!_.isNil(row.group_label) ? (<>
                    {row.group_label}
                </>) : (
                    "Non catégorisé"
                )}
                </label>
            </div>
            <div className="chart-data-container col-md-10">
                <StatisticsChartItemBlock
                    title={t("Planifié")}
                    value={state_planned}
                    percentage={state_planned_percentage}
                    bgClass="bg-state-todecide"
                />
                <StatisticsChartItemBlock
                    title={t("Réalisé")}
                    value={state_completed}
                    percentage={state_completed_percentage}
                    bgClass="bg-state-unnecessary"
                />
                <StatisticsChartItemBlock
                    title={t("Evalué")}
                    value={state_evaluated}
                    percentage={state_evaluated_percentage}
                    bgClass="bg-state-todo"
                />
                <StatisticsChartItemBlock
                    title={t("Clôturé")}
                    value={state_closed}
                    percentage={state_closed_percentage}
                    bgClass="bg-state-validated"
                />
            </div>
        </div>
    );
}