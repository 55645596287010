import _ from "lodash";

const rules = {
    ROLE_CONSULTANT: {
        static: [
            "user:list",
            "user:read",
            "user:write",
            "tree:read",
            "account:write",
            "sheetaffected:list",
            "sheetaffected:read",
            "sheet:private:write",
            "obligation:list",
        ],
        dynamic: {
            "documentaryspace:list": (user, data) => data.account.access_documentary_space === 1 && _.findIndex(data.countries, (c) => c.options.is_france) > -1,
            "category:list": (user, data) => data.account.access_categories === 1,
            "category:read": (user, data) => data.account.access_categories === 1,
            "category:write": (user, data) => data.account.access_categories === 1,
            "action:list": (user, data) => data.account.access_actionplan === 1,
            "action:read": (user, data) => data.account.access_actionplan === 1 && data.account.is_start === false,
            "action:write": (user, data) => data.account.access_actionplan === 1 && data.account.is_start === false,
            "sheet:list": (user, data) => data.account.access_apave_database === 1,
            "sheet:read": (user, data) => data.account.access_apave_database === 1,
            "user:update-alert-domains": (user, data) => data.account.access_alert_filters === 1,
            "user:update-alert-sheet-status": (user, data) => data.account.access_notfinalized_sheetalert === 1,
            "user:activate-alert-documentary-space": (user, data) => data.account.access_newsletter === 1 && data.account.access_documentary_space === 1,
            "sheetaffected:headquarter_comment:write": (user, data) => data.account.access_headquarter_comment === 1,
            "sheetaffected:comment:create": (user, data) => data.account.access_customer_comment === 1,
            "sheetaffected:comment:write": (user, data) => data.account.access_customer_comment === 1,
            "requirementaffected:list": (user, data) => data.account.access_compliance_management === 1 && data.account.is_start === false,
            "hotline:list": (user, data) => data.account.access_hotline === 1,
            "hotline:read": (user, data) => data.account.access_hotline === 1,
            "hotline:write": (user, data) => data.account.access_hotline === 1 && data.account.is_start === false,
            "hotline:create": (user, data) => data.account.access_hotline === 1 && data.account.is_start === false,
            "account:synthesis_level": (user, data) => data.account.access_synthesis_level === 1,
            "account:technical_level": (user, data) => data.account.access_technical_level === 1,
            "account:consultant_level": (user, data) => data.account.access_consultant_level === 1,
            "account:headquarter_comment": (user, data) => data.account.access_headquarter_comment === 1,
            "account:customer_comment": (user, data) => data.account.access_customer_comment === 1,
            "account:actionplan": (user, data) => data.account.access_actionplan === 1 && data.account.is_start === false && data.account.is_start === false,
            "account:compliance_management": (user, data) => data.account.access_compliance_management === 1,
            "account:categories": (user, data) => data.account.access_categories === 1,
            "account:hotline": (user, data) => data.account.access_hotline === 1,
            "account:documentary_space": (user, data) => data.account.access_documentary_space === 1,
            "account:private_documentary_space": (user, data) => data.account.access_private_documentary_space === 1,
            "statistics:read": (user, data) => data.account.access_compliance_management === 1,
            "obligationgenerator:read": (user, data) => data.account.access_obligation_generator === 1,
        }
    },
    ROLE_ADMIN: {
        static: [
            "user:list",
            "user:read",
            "user:write",
            "tree:read",
            "account:write",
            "sheetaffected:list",
            "sheetaffected:read",
            "sheet:private:write",
            "obligation:list",
        ],
        dynamic: {
            "documentaryspace:list": (user, data) => data.account.access_documentary_space === 1 && _.findIndex(data.countries, (c) => c.options.is_france) > -1,
            "category:list": (user, data) => data.account.access_categories === 1,
            "category:read": (user, data) => data.account.access_categories === 1,
            "category:write": (user, data) => data.account.access_categories === 1,
            "action:list": (user, data) => data.account.access_actionplan === 1,
            "action:read": (user, data) => data.account.access_actionplan === 1 && data.account.is_start === false,
            "action:write": (user, data) => data.account.access_actionplan === 1 && data.account.is_start === false,
            "sheet:list": (user, data) => data.account.access_apave_database === 1,
            "sheet:read": (user, data) => data.account.access_apave_database === 1,
            "user:update-alert-domains": (user, data) => data.account.access_alert_filters === 1,
            "user:update-alert-sheet-status": (user, data) => data.account.access_notfinalized_sheetalert === 1,
            "user:activate-alert-documentary-space": (user, data) => data.account.access_newsletter === 1 && data.account.access_documentary_space === 1,
            "sheetaffected:headquarter_comment:write": (user, data) => data.account.access_headquarter_comment === 1,
            "sheetaffected:comment:create": (user, data) => data.account.access_customer_comment === 1,
            "sheetaffected:comment:write": (user, data) => data.account.access_customer_comment === 1,
            "requirementaffected:list": (user, data) => data.account.access_compliance_management === 1 && data.account.is_start === false,
            "hotline:list": (user, data) => data.account.access_hotline === 1,
            "hotline:read": (user, data) => data.account.access_hotline === 1,
            "hotline:write": (user, data) => data.account.access_hotline === 1 && data.account.is_start === false,
            "hotline:create": (user, data) => data.account.access_hotline === 1 && data.account.is_start === false,
            "account:synthesis_level": (user, data) => data.account.access_synthesis_level === 1,
            "account:technical_level": (user, data) => data.account.access_technical_level === 1,
            "account:consultant_level": (user, data) => data.account.access_consultant_level === 1,
            "account:headquarter_comment": (user, data) => data.account.access_headquarter_comment === 1,
            "account:customer_comment": (user, data) => data.account.access_customer_comment === 1,
            "account:actionplan": (user, data) => data.account.access_actionplan === 1 && data.account.is_start === false && data.account.is_start === false,
            "account:compliance_management": (user, data) => data.account.access_compliance_management === 1,
            "account:categories": (user, data) => data.account.access_categories === 1,
            "account:hotline": (user, data) => data.account.access_hotline === 1,
            "account:documentary_space": (user, data) => data.account.access_documentary_space === 1,
            "account:private_documentary_space": (user, data) => data.account.access_private_documentary_space === 1,
            "statistics:read": (user, data) => data.account.access_compliance_management === 1,
            "obligationgenerator:read": (user, data) => data.account.access_obligation_generator === 1,
        }
    },
    ROLE_SUPER_ADMIN: {
        static: [
            "user:list",
            "user:read",
            "user:write",
            "tree:read",
            "account:write",
            "sheetaffected:list",
            "sheetaffected:read",
            "sheet:private:write",
            "obligation:list",
        ],
        dynamic: {
            "documentaryspace:list": (user, data) => data.account.access_documentary_space === 1 && _.findIndex(data.countries, (c) => c.options.is_france) > -1,
            "category:list": (user, data) => data.account.access_categories === 1,
            "category:read": (user, data) => data.account.access_categories === 1,
            "category:write": (user, data) => data.account.access_categories === 1,
            "action:list": (user, data) => data.account.access_actionplan === 1,
            "action:read": (user, data) => data.account.access_actionplan === 1 && data.account.is_start === false,
            "action:write": (user, data) => data.account.access_actionplan === 1 && data.account.is_start === false,
            "sheet:list": (user, data) => data.account.access_apave_database === 1,
            "sheet:read": (user, data) => data.account.access_apave_database === 1,
            "user:update-alert-domains": (user, data) => data.account.access_alert_filters === 1,
            "user:update-alert-sheet-status": (user, data) => data.account.access_notfinalized_sheetalert === 1,
            "user:activate-alert-documentary-space": (user, data) => data.account.access_newsletter === 1 && data.account.access_documentary_space === 1,
            "sheetaffected:headquarter_comment:write": (user, data) => data.account.access_headquarter_comment === 1,
            "sheetaffected:comment:create": (user, data) => data.account.access_customer_comment === 1,
            "sheetaffected:comment:write": (user, data) => data.account.access_customer_comment === 1,
            "requirementaffected:list": (user, data) => data.account.access_compliance_management === 1 && data.account.is_start === false,
            "hotline:list": (user, data) => data.account.access_hotline === 1,
            "hotline:read": (user, data) => data.account.access_hotline === 1,
            "hotline:write": (user, data) => data.account.access_hotline === 1 && data.account.is_start === false,
            "hotline:create": (user, data) => data.account.access_hotline === 1 && data.account.is_start === false,
            "account:synthesis_level": (user, data) => data.account.access_synthesis_level === 1,
            "account:technical_level": (user, data) => data.account.access_technical_level === 1,
            "account:consultant_level": (user, data) => data.account.access_consultant_level === 1,
            "account:headquarter_comment": (user, data) => data.account.access_headquarter_comment === 1,
            "account:customer_comment": (user, data) => data.account.access_customer_comment === 1,
            "account:actionplan": (user, data) => data.account.access_actionplan === 1 && data.account.is_start === false && data.account.is_start === false,
            "account:compliance_management": (user, data) => data.account.access_compliance_management === 1,
            "account:categories": (user, data) => data.account.access_categories === 1,
            "account:hotline": (user, data) => data.account.access_hotline === 1,
            "account:documentary_space": (user, data) => data.account.access_documentary_space === 1,
            "account:private_documentary_space": (user, data) => data.account.access_private_documentary_space === 1,
            "statistics:read": (user, data) => data.account.access_compliance_management === 1,
            "obligationgenerator:read": (user, data) => data.account.access_obligation_generator === 1,
        }
    },
    ROLE_USER_CUSTOMER: {
        static: [
            "sheetaffected:list",
            "sheetaffected:read",
            "obligation:list",
        ],
        dynamic: {
            "documentaryspace:list": (user, data) => data.account.access_documentary_space === 1 && _.findIndex(data.countries, (c) => c.options.is_france) > -1,
            "sheet:private:write": (user, data) => Array.isArray(data.writableWatchsites) && data.writableWatchsites.length > 0,
            "user:update-profile": (user, data) => user.access_profil === 1,
            "action:list": (user, data) => data.account.access_actionplan === 1 && (data.writableWatchsites.length > 0 || data.readableWatchsites.length > 0),
            "action:read": (user, data) => {
                let hasAccess = true;
                if (data.action.watchsites) {
                    data.action.watchsites.forEach(watchsite => {
                        if (!data.watchsiteReadableCallback(watchsite)) {
                            hasAccess = false;
                        }
                    });
                } else if(data.action.watchsite) {
                    hasAccess = data.watchsiteReadableCallback(data.action.watchsite.id.toString())
                } else {
                    hasAccess = false;
                }
                
                return data.account.access_actionplan === 1 && data.account.is_start === false && (hasAccess || data.action.pilot === user.id || data.action.pilot.id === user.id);
            },
            "action:write": (user, data) => {
                let hasAccess = true;
                if (data.action.watchsites) {
                    data.action.watchsites.forEach(watchsite => {
                        if (!data.watchsiteWritableCallback(watchsite)) {
                            hasAccess = false;
                        }
                    });
                } else if(data.action.watchsite) {
                    hasAccess = data.watchsiteWritableCallback(data.action.watchsite.id.toString())
                } else {
                    hasAccess = false;
                }

                return data.account.access_actionplan === 1 && data.account.is_start === false && (hasAccess || data.action.pilot === user.id  || data.action.pilot.id === user.id);
            },
            "user:list": (user, data) => user.access_user_configuration === 1,
            "user:read": (user, data) => user.access_user_configuration === 1,
            "user:write": (user, data) => user.access_user_configuration === 1,
            "sheet:list": (user, data) => data.account.access_apave_database === 1 && (data.writableWatchsites.length > 0 || data.readableWatchsites.length > 0),
            "sheet:read": (user, data) => data.account.access_apave_database === 1 && (data.writableWatchsites.length > 0 || data.readableWatchsites.length > 0),
            "user:update-alert-domains": (user, data) => data.account.access_alert_filters === 1,
            "user:update-alert-sheet-status": (user, data) => data.account.access_notfinalized_sheetalert === 1,
            "user:activate-alert-documentary-space": (user, data) => data.account.access_newsletter === 1 && data.account.access_documentary_space === 1,
            "sheetaffected:headquarter_comment:write": (user, data) => data.account.access_headquarter_comment === 1 && user.access_write === 1,
            "sheetaffected:comment:create": (user, data) => data.account.access_customer_comment === 1, /** Verification sur ecrtiure du site dans le composant */
            "sheetaffected:comment:write": (user, data) => data.account.access_customer_comment === 1, /** Verification sur ecrtiure du site dans le composant */
            "requirementaffected:list": (user, data) => data.account.access_compliance_management === 1 && data.account.is_start === false,
            "hotline:list": (user, data) => data.account.access_hotline === 1,
            "hotline:read": (user, data) => {
                return data.account.access_hotline === 1 
                    && (
                        data.hotline.confidential === 0
                        || (data.hotline.created_by && data.hotline.created_by.id === user.id)
                    );
            },
            "hotline:write": (user, data) => data.account.access_hotline === 1 && user.access_write_hotline === 1 && (data.hotline.created_by && data.hotline.created_by.id === user.id) && data.account.is_start === false,
            "hotline:create": (user, data) => data.account.access_hotline === 1 && user.access_write_hotline === 1 && data.account.is_start === false,
            "account:synthesis_level": (user, data) => data.account.access_synthesis_level === 1,
            "account:technical_level": (user, data) => data.account.access_technical_level === 1,
            "account:consultant_level": (user, data) => data.account.access_consultant_level === 1,
            "account:headquarter_comment": (user, data) => data.account.access_headquarter_comment === 1,
            "account:customer_comment": (user, data) => data.account.access_customer_comment === 1,
            "account:actionplan": (user, data) => data.account.access_actionplan === 1 && data.account.is_start === false && data.account.is_start === false,
            "account:compliance_management": (user, data) => data.account.access_compliance_management === 1,
            "account:categories": (user, data) => data.account.access_categories === 1,
            "account:hotline": (user, data) => data.account.access_hotline === 1,
            "account:documentary_space": (user, data) => data.account.access_documentary_space === 1,
            "account:private_documentary_space": (user, data) => data.account.access_private_documentary_space === 1,            
            "statistics:read": (user, data) => data.account.access_compliance_management === 1,
            "obligationgenerator:read": (user, data) => data.account.access_obligation_generator === 1,
        }
    },
    ROLE_ADMIN_CUSTOMER: {
        static: [
            "user:list",
            "user:read",
            "user:write",
            "tree:read",
            "account:write",
            "sheetaffected:list",
            "sheetaffected:read",
            "sheet:private:write",
            "obligation:list",
        ],
        dynamic: {
            "documentaryspace:list": (user, data) => data.account.access_documentary_space === 1 && _.findIndex(data.countries, (c) => c.options.is_france) > -1,
            "user:update-profile": (user, data) => user.access_profil === 1,
            "category:read": (user, data) => data.account.access_categories === 1,
            "category:write": (user, data) => data.account.access_categories === 1,
            "category:list": (user, data) => data.account.access_categories === 1,
            "action:list": (user, data) => data.account.access_actionplan === 1 && (data.writableWatchsites.length > 0 || data.readableWatchsites.length > 0),
            "action:read": (user, data) => data.account.access_actionplan === 1 && data.account.is_start === false,
            "action:write": (user, data) => data.account.access_actionplan === 1 && data.account.is_start === false,
            "sheet:list": (user, data) => data.account.access_apave_database === 1 && (data.writableWatchsites.length > 0 || data.readableWatchsites.length > 0),
            "sheet:read": (user, data) => data.account.access_apave_database === 1 && (data.writableWatchsites.length > 0 || data.readableWatchsites.length > 0),
            "user:update-alert-domains": (user, data) => data.account.access_alert_filters === 1,
            "user:update-alert-sheet-status": (user, data) => data.account.access_notfinalized_sheetalert === 1,
            "user:activate-alert-documentary-space": (user, data) => data.account.access_newsletter === 1 && data.account.access_documentary_space === 1,
            "sheetaffected:headquarter_comment:write": (user, data) => data.account.access_headquarter_comment === 1,
            "sheetaffected:comment:create": (user, data) => data.account.access_customer_comment === 1,
            "sheetaffected:comment:write": (user, data) => data.account.access_customer_comment === 1,
            "requirementaffected:list": (user, data) => data.account.access_compliance_management === 1 && data.account.is_start === false,
            "hotline:list": (user, data) => data.account.access_hotline === 1,
            "hotline:read": (user, data) => {
                return data.account.access_hotline === 1 
                    && (
                        data.hotline.confidential === 0
                        || (data.hotline.created_by && data.hotline.created_by.id === user.id)
                    );
            },
            "hotline:write": (user, data) => data.account.access_hotline === 1 && user.access_write_hotline === 1 && (data.hotline.created_by && data.hotline.created_by.id === user.id) && data.account.is_start === false,
            "hotline:create": (user, data) => data.account.access_hotline === 1 && user.access_write_hotline === 1 && data.account.is_start === false,
            "account:synthesis_level": (user, data) => data.account.access_synthesis_level === 1,
            "account:technical_level": (user, data) => data.account.access_technical_level === 1,
            "account:consultant_level": (user, data) => data.account.access_consultant_level === 1,
            "account:headquarter_comment": (user, data) => data.account.access_headquarter_comment === 1,
            "account:customer_comment": (user, data) => data.account.access_customer_comment === 1,
            "account:actionplan": (user, data) => data.account.access_actionplan === 1 && data.account.is_start === false && data.account.is_start === false,
            "account:compliance_management": (user, data) => data.account.access_compliance_management === 1,
            "account:categories": (user, data) => data.account.access_categories === 1,
            "account:hotline": (user, data) => data.account.access_hotline === 1,
            "account:documentary_space": (user, data) => data.account.access_documentary_space === 1,
            "account:private_documentary_space": (user, data) => data.account.access_private_documentary_space === 1,
            "statistics:read": (user, data) => data.account.access_compliance_management === 1,
            "obligationgenerator:read": (user, data) => data.account.access_obligation_generator === 1,
        }
        
    }
};
  
export default rules;