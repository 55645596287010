import React from 'react';
import { useTranslation } from 'react-i18next';
import WatchsiteUtils from 'shared/services/WatchsiteUtils';

export default function TreeWatchsite(props) {

    const { t } = useTranslation();
    const isActive = WatchsiteUtils.isActive(props.item);
    const className = isActive ? "" : "color-rouge";

    const access = props.accesses.find((item => (item.watchsite && item.watchsite.id === props.item.id)));

    let writeAccess = false;
    let readAccess = false;
    if (access) {
        writeAccess = props.parentWriteaccess || access.writeaccess;
        readAccess = !writeAccess && (props.parentReadaccess || access.readaccess);
    } else {
        if (props.parentWriteaccess) {
            writeAccess = props.parentWriteaccess
        }
        if (props.parentReadaccess) {
            readAccess = !writeAccess && props.parentReadaccess;
        }        
    }

    return (
        <li>
            <div className="highlight">
                <fieldset className="at-left">
                    <legend className="sr-only">Droits d'accès&nbsp;:</legend>
                    <div>
                        <input
                            type="checkbox"
                            value={props.item.id}
                            id={`watchsite-${props.item.id}-read`} 
                            onChange={props.onWatchsiteReadAccessChange}
                            disabled={props.parentWriteaccess || props.parentReadaccess}
                            checked={readAccess}
                        />
                        <label htmlFor={`watchsite-${props.item.id}-read`} className="w-100px text-center"><span className="sr-only">Lecture</span></label>
                        <input 
                            type="checkbox"
                            value={props.item.id}
                            id={`watchsite-${props.item.id}-write`}
                            onChange={props.onWatchsiteWriteAccessChange}
                            disabled={props.isStart === true || props.parentWriteaccess}
                            checked={props.isStart === false && writeAccess}
                        />
                        <label htmlFor={`watchsite-${props.item.id}-write`} className="w-100px text-center"><span className="sr-only">Écriture</span></label>
                    </div>
                </fieldset>
                <label htmlFor={`watchsite-${props.item.id}`} className={className}>    
                    <i className="icon-nav-ma-veille"></i>
                    {props.item.label}                
                    {!isActive && (<i className="icon-autres-temps-depasse" title={t("Point de veille expiré ou désactivé")}></i>)}
                </label>
            </div>  
        </li>
    )
}