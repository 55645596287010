import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import logoapave from "images/logoapave.svg";
import logopilotveille from "images/logopilotveille.png";
import { FieldComponent, LoadButton } from 'shared/components';
import { useForm } from 'shared/hooks';
import { PasswordApi } from 'shared/api';
import { compileErrorsFromResponse } from 'shared/services';
import { toast } from 'react-toastify';

export default function PasswordForgot() {

    const [data, errors, setErrors, setValue] = useForm({email: ""});
    const [processing, setProcessing] = useState(false);

    function submit(event) {
        event.preventDefault();
        setProcessing(true);

        PasswordApi
            .requestNewPassword(data.email)
            .then(() => toast.success("Un email contenant un lien vous permettant de réinitialiser votre mot de passe vous a été envoyé sur votre boite mail"))
            .catch(error => {
                if (error.response.data.message) {
                    toast.error(error.response.data.message);
                } else {
                    setErrors(compileErrorsFromResponse(error.response));
                    toast.error("Des erreurs sont survenues");
                }
            })
            .finally(() => setProcessing(false));
    }

    return (
        <section className="login">
            <form className="form" onSubmit={submit}>
                <h1>
                    <div className="main-logo">
                        <img src={logoapave} width="96" alt="Logo Apave" />
                    </div>
                    <div className="main-name">
                        <img src={logopilotveille} alt="Logo Pilot Veille" />&nbsp;&nbsp;Mot de passe oublié
                    </div>
                </h1>
                <div className="bloc">
                    <section>
                        <header>
                            <h2>Mot de passe oublié</h2>
                        </header>
                        <div className="bg-blanc border-rlb border-gris-40">
                            <FieldComponent 
                                name="email"
                                label="Email"
                                error={errors.email}
                                value={data.email}
                                onChange={value => setValue("email", value)}
                            />
                            <div className="row">
                                <div className="col-md-3 text-right order-2">
                                    <LoadButton
                                        loading={processing}
                                        loaderWidth={25}
                                        label="Envoyer"
                                        name="send"
                                        id="send"
                                        displayIcon={false}
                                    />
                                </div>
                                <div className="col-md-9 offset-label order-1">
                                    <Link
                                        id="back-login"
                                        to="/login"
                                        className="btn btn-secondary"
                                    >Retour à la connexion</Link>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </form>
        </section>
    )
}