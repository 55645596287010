import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import _ from 'lodash';
import axios from 'axios';
import logoapave from "images/logoapave.svg";
import logopilotveille from "images/logopilotveille.png";
import { useForm } from 'shared/hooks/Form';
import { useSecurity } from 'shared/hooks/Security';
import { useAccount } from 'hooks/Account';
import SecurityApi from 'shared/api/Security';
import AccountApi from 'shared/api/Account';
import { getLanguages } from 'shared/data';
import { compileErrorsFromResponse } from 'shared/services/Utils';
import FieldComponent from 'shared/components/FieldComponent';
import LoadButton from 'shared/components/LoadButton';

export default function Login(props) {

    const { t, i18n } = useTranslation();
    const [,,, setNbAccounts,,, setTempAccount] = useAccount();
    const [logging, setLogging] = useState(false);
    const [user,, setUser] = useSecurity();
    const [data, errors, setErrors, setValue] = useForm({
        email: "",
        password: "",
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
    });

    if (sessionStorage.getItem("message")) {
        toast.error(sessionStorage.getItem("message"));
        sessionStorage.removeItem("message");
    }

    useEffect(() => {
        if (_.isEmpty(user)) {
            setUser({});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function login(event) {
        event.preventDefault();

        setLogging(true);

        SecurityApi.login(data)
            .then(() => {
                setErrors({});

                axios
                    .all([
                        SecurityApi.getCurrentUser(),
                        AccountApi.list()
                    ])
                    .then(
                        axios.spread((currentUser, [accounts,, nbAccounts]) => {
                            setNbAccounts(nbAccounts);
                            setUser(currentUser);
                            
                            if (accounts.length > 1 || accounts.length === 0) {
                                
                                setLogging(false);
                                props.history.push("/selectaccount");
                            } else {
                                setTempAccount(accounts[0].id);
                                SecurityApi
                                    .selectAccount(accounts[0].id)
                                    .then(r => {
                                        if (r.data.identitychecking) {
                                            document.location.href = process.env.REACT_APP_BACKEND_URL + 'identitychecking';
                                        } else {
                                            props.history.push("/loader");
                                        }
                                    })
                                    .catch(() => {
                                        setLogging(false);
                                        toast.error(t("Impossible de sélectionner le compte"))
                                    });

                            }                            
                        })
                    )
                    .catch(error => {
                        setLogging(false);
                        toast.error(error.response.data.message);
                    });
            })
            .catch(error => {
                setLogging(false);
                if (error.response.data.message) {
                    toast.error(error.response.data.message);
                } else {
                    setErrors(compileErrorsFromResponse(error.response));
                    toast.error(t("Des erreurs sont survenues"));
                }
            });
    }

    function updateLanguage(lang, event) {
        event.preventDefault();
        i18n.changeLanguage(lang);
        document.documentElement.setAttribute("lang", i18n.language);
        if (i18n.language === "ar") {
            document.documentElement.setAttribute("dir", "rtl");
        } else {
            document.documentElement.setAttribute("dir", "ltr");
        }
    }

    return (
        <section className="login">
            <form className="form" onSubmit={login}>
                <h1>
                    <div className="main-logo">
                        <img src={logoapave} width="96" alt="Logo Apave" />
                    </div>
                    <div className="main-name">
                        <img src={logopilotveille} alt="Logo Pilot Veille" />
                    </div>
                </h1>
                <div className="bloc">
                    <section>
                        <header>
                            <h2>{t("Connexion à Pilot Veille")}</h2>
                        </header>
                        <div className="bg-blanc border-rlb border-gris-40">
                            <div className="flex-label">
                                <label></label>
                                <div className="row">
                                {getLanguages().map((lang, index) => <>
                                    <div className="col">
                                        <a key={index} href={`/${lang.value}`} onClick={(e) => updateLanguage(lang.value, e)}>{lang.label}</a>
                                    </div>
                                </>)}
                                </div>
                            </div>
                            <FieldComponent name="email" label={`${t("Identifiant")} *`} error={errors.email} value={data.email} onChange={value => setValue("email", value)} />
                            <FieldComponent type="password" name="password" label={`${t("Mot de passe")} *`} error={errors.password} value={data.password} onChange={value => setValue("password", value)} />
                            <div className="row">
                                <div className="col-md-12 text-right order-1">
                                    <Link id="forgot-password" to="/users/forgotpassword" className="btn btn-alert">{t("Mot de passe oublié ?")}</Link>
                                    <LoadButton loading={logging} loaderWidth={25} label={t("Connexion")} name="login" id="login" displayIcon={false} />
                                    <a id="connexion-sso" href="/oidc" className="btn btn-bleu-4">{t("Connexion SSO")}</a>                                                                    
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </form>
        </section>    
    )
}