import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { toast } from 'react-toastify';
import PreferenceApi from 'api/Preference';
import FieldComponent from 'shared/components/FieldComponent';
import LoadButton from 'shared/components/LoadButton';
import confirm from 'shared/services/Confirmation';

export default function SheetAffectedLoadSearch(props) {

    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [deleting, setDeleting] = useState(false);
    const [search, setSearch] = useState(null);
    const [searches, setSearches] = useState([]);
    const [options, setOptions] = useState([]);

    useEffect(() => {
        PreferenceApi
            .get("E31_filters")
            .then(data => {
                let freshOptions = [];
                _.forEach(data.value, (value, key) => freshOptions.push({value: key, label: key}));
                setSearches(data.value);
                setOptions(freshOptions);
            });
    }, []);

    function load(event) {
        event.preventDefault();

        setLoading(true);
        props.onSearchLoad(searches[search]);
        props.onClose();        
    }

    function deleteSearch() {
        confirm(
            "Confirmez vous la suppression de cette recherche enregistrée ?",
            () => {
                setDeleting(true);

                const freshSearches = {...searches};
                delete freshSearches[search];

                PreferenceApi
                    .save({
                        "identifier": "E31_filters",
                        "value": freshSearches  
                    })
                    .then(() => {
                        const freshOptions = [];
                        _.forEach(freshSearches, (value, key) => freshOptions.push({value: key, label: key}));

                        setSearch(null);
                        setSearches(freshSearches);                        
                        setOptions(freshOptions);
                        toast.success(t("Recherche supprimée."));
                    })
                    .catch(() => {
                        toast.error(t("Une erreur est apparue."));
                    })
                    .finally(() => setDeleting(false));
            }
        );
    }

    return <>
        <form onSubmit={load} id="form-load-search" className="form">
            <section className="bloc">
                <header className="border-rlb border-gris-40">
                    <h2 className="uppercase">Chargement d'une recherche</h2>
                </header>
                <section>
                    <FieldComponent
                        type="select"
                        name="search" 
                        label={t("Recherche")} 
                        value={search} 
                        onChange={value => setSearch(value)}
                        options={options}
                        clearable
                    />
                    <section className="row">
                        <div className="col">
                            <button
                                type="button"
                                id="back-sheetsaffected-list"
                                onClick={props.onClose}
                                className="btn btn-bleu-4"
                            >{t("Retour à la liste")}</button>
                        </div>
                        <div className="col text-right">
                            {search && <>
                                <LoadButton 
                                    loading={deleting} 
                                    label={t("Supprimer")}
                                    name="delete-search"
                                    id="delete-search"
                                    type="button"
                                    onClick={() => deleteSearch()}
                                    className="btn btn-rouge"
                                    iconClass="icon-actions-supprimer"
                                />
                                <LoadButton 
                                    loading={loading} 
                                    label={t("Charger")}
                                    name="save-and-close"
                                    id="save-and-close-load-search"
                                />
                            </>}
                        </div>
                    </section>   
                </section>
            </section>
        </form>
    </>;
}