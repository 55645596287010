import React, { useEffect, useState , useContext} from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Link, Prompt, useLocation, useHistory } from 'react-router-dom';
import { ReferentialContext } from 'shared/stores/Referential';
import { useAccount } from 'hooks/Account';
import { useAccess } from 'hooks/Access';
import { useForm } from 'shared/hooks/Form';
import { useToggler } from "shared/hooks/Toggler";
import { useSecurity } from 'shared/hooks/Security';
import { useQuery } from 'shared/hooks/Query';
import ActionApi from 'api/Action';
import ExternalUserApi from 'shared/api/ExternalUser';
import RequirementAffectedApi from 'api/RequirementAffected';
import SheetAffectedApi from 'shared/api/SheetAffected';
import SheetAffectedAutocomplete, { getSheetAffectedDefaultOptions } from 'services/autocomplete/SheetAffected';
import RequirementAffectedAutocomplete from 'services/autocomplete/RequirementAffected';
import DefaultLayout from "layouts/DefaultLayout";
import FileComponent from 'shared/components/FileComponent';
import FieldComponent from 'shared/components/FieldComponent';
import SidebarComponent from 'shared/components/SidebarComponent';
import LoadButton from 'shared/components/LoadButton';
import ToggableBlocComponent from "shared/components/ToggableBlocComponent";
import PageLoadingComponent from 'shared/components/PageLoadingComponent';
import PreviewFieldComponent from 'shared/components/PreviewFieldComponent';
import { formatDate, compileErrorsFromResponse, compileDataToSelectOptions, stripTags } from 'shared/services/Utils';
import { ACTION_STATUS_CLOSED, ACTION_STATUS_COMPLETED, ACTION_STATUS_EVALUATED, ACTION_STATUS_PLANNED, getActionsStatus } from 'data/Action';
import { getPriorities } from 'data/Priority';
import { CONFORMITY_MODE_GLOBAL } from 'data/SheetAffected';
import confirm from 'shared/services/Confirmation';
import Can, { denyIfCantPerform } from "shared/services/Can";
import { prefixLinkTo } from 'shared/services';

export default function ActionForm(props) {

    const { t } = useTranslation();
    const query = useQuery();
    const history = useHistory();
    const [user] = useSecurity();
    const [currentWatchsite, setCurrentWatchsite] = useState(null);
    const [canWrite, canRead, writableWatchsites] = useAccess();
    const [defaultsheets, setDefaultSheets] = useState([]);
    const [defaultrequirements, setDefaultRequirements] = useState([]);
    const [currentSheetAffected, setCurrentSheetAffected] = useState(null);
    const [currentRequirementsAffected, setCurrentRequirementsAffected] = useState([]);
    const [pilots, setPilots] = useState([{value: user.id, label: user.firstname + " " + user.lastname}]);
    const [created, setCreated] = useState({by: null, at: null});
    const [tree, setTree] = useState([]);
    const [referentialContext] = useContext(ReferentialContext);
    const [account] = useAccount();
    const [togglers, toggle] = useToggler({'info': true, 'planning': true, 'execution': true, 'evaluation': true, 'closing': true, 'status': true});
    const accountTree = referentialContext["accountTree"];

    let defaultWatchsites = [];
    if (query.has("watchsites")) {
        defaultWatchsites = query.get("watchsites").split(",").map(w => 'watchsite:' + parseInt(w));
    }
    if (writableWatchsites.length === 1) {
        defaultWatchsites = ['watchsite:' + parseInt(writableWatchsites[0])];
    }
    const [data, errors, setErrors, setValue, setData, reloadOrClose, formHasModifications] = useForm({
        id: null,
        watchsites: defaultWatchsites,
        sheets: query.has("sheets") ? query.get("sheets").split(",").map(s => parseInt(s)) : [],
        requirements: query.has("requirements") ? query.get("requirements").split(",").map(r => parseInt(r)) : [],
        status: 1,
        label: null,
        description: null,
        cost: null,
        pilot: null,
        send_alert: 1,
        priority: 0,
        hidden: 0,
        attachments: [],
        custom_field1: null,
        custom_field2: null,
        achievement_report: null,
        achievement_date: null,
        deadline_date: null,
        evaluation_report: null,
        evaluation_date: null,
        closing_date: null,
        grouping_code: Date.now(),
    });  

    const [saving, setSaving] = useState(false);
    const [deleting, setDeleting] = useState(false);
    const [loading, setLoading] = useState(true);

    const watchsiteMapper = (watchsite) => {
        return {
            "label": watchsite.label,
            "value": 'watchsite:' + watchsite.id,
            "checked": data.watchsites.includes('watchsite:' +watchsite.id),
            "disabled": !canWrite(watchsite.id)
        } 
    };
    const nodeMapper = (node) => {
        let children = node.childs.map(nodeMapper);
        children = children.concat(node.watchsites.map(watchsiteMapper));
        return {
            "label": node.label,
            "value": 'node:' + node.id,
            "children": children,
            "checked": false,
            "disabled": false,
        }
    };

    const listUri = "/actions" + useLocation().search;

    useEffect(() => {
        if (props.match.params.id) {
            loadAction();
        } else {
            loadDefaultValue();
        }

        ExternalUserApi
            .list({active: 1, valid: 1, sort: 'lastname', limit: 500})
            .then(([r]) => setPilots(r.map(u => { return { value: u.user_id, label: u.firstname + " " + u.lastname }})));
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const newTree = accountTree.nodes.map(nodeMapper).concat(accountTree.watchsites.map(watchsiteMapper));

        const flatten = (item) => {
            if (item.children) {
                return [item].concat(item.children.flatMap(flatten));
            } else {
                return item;
            }
        };
        const flatTree = newTree.flatMap(flatten);
        if (currentWatchsite && flatTree.length > 0 && !flatTree.map(w => w.value).includes("watchsite:" + currentWatchsite.id)) {
            newTree.push({label: currentWatchsite.label, value: "watchsite:" + currentWatchsite.id, checked: true, disabled: true});
        }

        setTree(newTree);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data.watchsites, accountTree]);

    function loadAction() {
        ActionApi
            .get(props.match.params.id)                
            .then(d => {
                setCurrentWatchsite(d.watchsite);
                setData({
                    attachments: d.attachments,
                    id: d.id,
                    watchsites: ["watchsite:" + d.watchsite.id],
                    sheets: d.sheet_affected ? [d.sheet_affected.sheet.id] : [],
                    requirements: d.requirements_affected.map(r => r.requirement.id),
                    status: d.status,
                    label: d.label,
                    description: d.description,
                    cost: d.cost,
                    pilot: d.pilot ? d.pilot.id : null,
                    send_alert: d.send_alert,
                    priority: d.priority,
                    hidden: d.hidden ? 1 : 0,
                    custom_field1: d.custom_field1,
                    custom_field2: d.custom_field2,
                    achievement_report: d.achievement_report,
                    achievement_date: d.achievement_date ? new Date(d.achievement_date) : null,
                    deadline_date: d.deadline_date ? new Date(d.deadline_date) : null,
                    evaluation_report: d.evaluation_report,
                    evaluation_date: d.evaluation_date ? new Date(d.evaluation_date) : null,
                    closing_date: d.closing_date ? new Date(d.closing_date) : null,
                    grouping_code: d.grouping_code,
                });
                setDefaultSheets(d.sheet_affected ? [{value: d.sheet_affected.sheet.id, label: d.sheet_affected.sheet.id + " " + stripTags(d.sheet_affected.sheet.title)}] : []);
                setDefaultRequirements(d.requirements_affected.map(r => { return {value: r.requirement.id, label: r.requirement.id}; }));
                setCurrentSheetAffected(d.sheet_affected);
                setCurrentRequirementsAffected(d.requirements_affected);
                setCreated({by: d.created_by, at: d.created_at});
                setLoading(false);
            })
            .catch(() => {
                toast.error(t("Une erreur est survenue lors de la récupération de l'action"));
                history.goBack();
            });
    }

    function loadDefaultValue() {
        const defaultAsyncValue = [];
        if (query.has("requirements")) {
            defaultAsyncValue.push(
                RequirementAffectedApi
                    .search(
                        {"bool": {"must": [{"terms": {"requirement.id": query.get("requirements").split(",")}}]}},
                        0,
                        25,
                        "requirement.id",
                        "ASC"
                    )
                    .then(([requirements]) => {
                        if (requirements.length > 0) {
                            let sheets = requirements.map((r) => {
                                return {
                                    "value": parseInt(r.requirement.sheet.id), 
                                    "label": r.requirement.sheet.id + "  " + r.requirement.sheet.title
                                };
                            });
                            setDefaultRequirements(compileDataToSelectOptions(requirements, 'requirement.id', ['requirement.id']));
                            setDefaultSheets(sheets);
                            setValue("requirements", query.get("requirements").split(",").map(r => parseInt(r)));
                            setValue("sheets", sheets.map(s => s.value));
                        }
                    })
            );
        } else if (query.has("sheets")) {
            defaultAsyncValue.push(
                getSheetAffectedDefaultOptions(query.get("sheets").split(","))
                    .then(response => {
                        setDefaultSheets(response);
                        setValue("sheets", query.get("sheets").split(",").map(s => parseInt(s)));
                    })
            );
        }
        if (defaultAsyncValue.length > 0) {
            axios
                .all(defaultAsyncValue)
                .then(axios.spread((...responses) => setLoading(false)));
        } else {
            setLoading(false);
        }
    }

    function preSave(event) {
        setSaving(true);
        event.preventDefault();

        const itemsToCheck = [];

        const selectedWatchsites = getSelectedWatchsites();
        if ((data.status === ACTION_STATUS_CLOSED || data.status === ACTION_STATUS_COMPLETED) && data.requirements.length > 0) {            
            selectedWatchsites.forEach(w => {
                data.requirements.forEach(r => {
                    itemsToCheck.push({requirement: r, watchsite: w});
                });
            });
            checkRequirements(itemsToCheck, event);
        } else if ((data.status === ACTION_STATUS_CLOSED || data.status === ACTION_STATUS_COMPLETED) && data.sheets.length > 0) {
            let requests = [];
            selectedWatchsites.forEach(w => {
                data.sheets.forEach(s => {
                    requests.push(
                        SheetAffectedApi
                            .search(
                                {
                                    "bool": {"must":[
                                        {"match": {"sheet": s}},
                                        {"match": {"watchsite": w}},
                                        {"match": {"conformity_mode": CONFORMITY_MODE_GLOBAL}},
                                    ]}
                                },
                                0,
                                1,
                                "id",
                                "asc"
                            )
                            .then(([,, total]) => {
                                if (total === 1) {
                                    itemsToCheck.push({watchsite: w, sheet: s});
                                }
                            })
                    );
                });
            });

            axios.all(requests).then(() => checkSheets(itemsToCheck, event));
        } else {
            save(event);
        }
    }

    function checkRequirements(itemsToCheck, event) {
        let requests = [];
        itemsToCheck.forEach(item => {            
            requests.push(
                ActionApi
                    .search(
                        {
                            "bool": {"must":[
                                {"match": {"requirements_affected.requirement.id": item.requirement}},
                                {"match": {"watchsite": item.watchsite}},
                                {"match": {"status": ACTION_STATUS_PLANNED}},
                                {"bool": {"must_not": [{"match": {"id": data.id}}]}}                            
                            ]}
                        },
                        0,
                        1,
                        "id",
                        "asc"
                    )
                    .then(([,, total]) => {
                        return {requirement: item.requirement, watchsite: item.watchsite, total: total}
                    })
            );
        });
        closeSheetsAndRequirements(requests, event);
    }

    function checkSheets(itemsToCheck, event) {
        let requests = [];
        itemsToCheck.forEach(item => {            
            requests.push(
                ActionApi
                    .search(
                        {
                            "bool": {"must":[
                                {"match": {"sheet_affected.sheet": item.sheet}},
                                {"match": {"watchsite": item.watchsite}},
                                {"match": {"status": ACTION_STATUS_PLANNED}},
                                {"bool": {"must_not": [{"match": {"id": data.id}}]}},
                            ]}
                        },
                        0,
                        1,
                        "id",
                        "asc"
                    )
                    .then(([,, total]) => {
                        return {sheet: item.sheet, watchsite: item.watchsite, total: total}
                    })
            );
        });
        closeSheetsAndRequirements(requests, event);
    }

    function closeSheetsAndRequirements(requests, event) {
        if (requests.length > 0) {
            axios
                .all(requests)
                .then(axios.spread((...responses) => {
                    let message = "";
                    responses.forEach(info => {
                        if (info.total === 0) {
                            let watchsite = searchWatchsite(tree, info.watchsite); 
                            if (info.sheet) {
                                message = message + "<li id=\"alert-sheet-" + info.sheet + "-" + info.watchsite + "\">Fiche " + info.sheet + " pour " + watchsite.label + "</li>";
                            }
                            if (info.requirement) {
                                message = message + "<li id=\"alert-requirement-" + info.requirement + "-" + info.watchsite + "\">Exigence " + info.requirement + " pour " + watchsite.label + "</li>";
                            }
                        }
                    })

                    if (message) {
                        confirm(
                            "Plus aucune action n'est en cours pour la ou les fiche(s) et exigence(s) suivante(s) : <ul>" + message + "</ul> Souhaitez-vous passer cette ou ces fiches au statut \"Conforme\" ?",
                            () => {
                                save(event, true);                           
                            },
                            () => { 
                                save(event);
                            }
                        );
                    } else {
                        save(event);
                    }
                }));
        } else {
            save(event);
        }
    }

    function searchWatchsite(item, id) {
        let result = null;
        if (_.isArray(item)) {
            item.forEach(i => {
                result = searchWatchsite(i, id) || result;
            });
        } else {
            if(item.value && item.value === "watchsite:" + id && item.disabled === false){
                result = item;
            } else if (item.children != null) {
                item.children.forEach(i => {
                    result = searchWatchsite(i, id) || result;
                });
            }
        }
        return result;
    }

    function save(event, updateConformity = false) {
        setSaving(true);

        const requestData = {...data};
        requestData.watchsites = getSelectedWatchsites();
        requestData.update_conformity = updateConformity;
        requestData.attachments = data.attachments.map(file => file.hash) || [];

        ActionApi
            .save(requestData)
            .then(newdatas => {
                setErrors({});
                setSaving(false);
                toast.success("Enregistrement effectué. " + newdatas.length + " action(s) créée(s) ou mise(s) à jour(s)");

                setCurrentSheetAffected(newdatas[0].sheet_affected);
                setCurrentRequirementsAffected(newdatas[0].requirements_affected);

                reloadOrClose(event, props, "actions", newdatas[0].id, true);
            })
            .catch(error => {
                setSaving(false);
                if (error.response.data.message) {
                    error.response.data.message.split('\n').forEach(e => toast.error(e));
                } else {
                    setErrors(compileErrorsFromResponse(error.response));
                    toast.error(t("Des erreurs sont survenues"));
                }
            });
    }

    function updateValue(field, value) {
        if (canWriteField(field)) {
            const newData = {...data};
            newData[field] = value;

            if (field === 'achievement_date' || field === 'evaluation_date' || field === 'closing_date') {
                if ((value && field === 'closing_date')
                    || (!_.isNil(data.closing_date) && field !== 'closing_date')
                ) {
                    newData.status = ACTION_STATUS_CLOSED;
                } else if ((value && field === 'evaluation_date')
                    || (!_.isNil(data.evaluation_date) && field !== 'evaluation_date')
                ) {
                    newData.status = ACTION_STATUS_EVALUATED;
                } else if ((value && field === 'achievement_date')
                    || (!_.isNil(data.achievement_date) && field !== 'achievement_date')
                ) {
                    newData.status = ACTION_STATUS_COMPLETED;
                }
                
            }

            setData(newData);
        }
    }

    function getSelectedWatchsites(forcedTree = null) {
        const selectedWatchsites = [];
        const selectedWatchsitesMapper = (item) => {
            const splitValue = item.value.split(":");
            if (splitValue[0] === "node") {
                if (data.watchsites.includes(item.value) || item.parentSelected) {
                    item.children.forEach(child => {
                        child.parentSelected = true;
                    })    
                }
                item.children.map(selectedWatchsitesMapper);
            } else if (data.watchsites.includes(item.value) || item.parentSelected) {
                selectedWatchsites.push(parseInt(splitValue[1]));
            }

            return item;
        }

        const treeToMap = forcedTree ? [...forcedTree] : [...tree];
        treeToMap.map(selectedWatchsitesMapper);

        return selectedWatchsites;
    }

    function canWriteField(field) {
        let hasAccessWrite = true;
        const accessMapper = (item) => {
            const splitValue = item.split(":");
            if (splitValue[0] === "node") {
                item.children.map(accessMapper);
            } else {
                hasAccessWrite = hasAccessWrite && canWrite(parseInt(splitValue[1]));
            }
            
            return item;
        }

        data.watchsites.map(accessMapper);

        return hasAccessWrite
            || (field === "status" && data.pilot === user.id)
            || (field === "achievement_report" && data.pilot === user.id)
            || (field === "achievement_date" && data.pilot === user.id);
    }

    function getAvailableActionsStatus() {
        const status = getActionsStatus();
        let hasTotalAccess = true;
        getSelectedWatchsites().forEach(watchsite => {
            hasTotalAccess = hasTotalAccess && canWrite(watchsite);
        });
        if (!hasTotalAccess) {
            status.map(s => {
                s.disabled = data.pilot === user.id && s.value === ACTION_STATUS_COMPLETED ? false : true;
                return s;
            });
        }
        return status;
    }

    function deleteAction() {
        setDeleting(true);

        confirm(
            "Etes vous sûr ?",
            () => { 
                ActionApi
                    .delete(data.id)
                    .then(response => {
                        toast.success(t("Action correctement supprimée."));
                        props.history.push(prefixLinkTo() + '/actions')
                    })
                    .catch(error => {
                        setDeleting(false);
                        if (error.response.data.message) {
                            toast.error(error.response.data.message);
                        }
                    });
            },
            () => { setDeleting(false); }
        );
    }

    if (loading) {
        return <PageLoadingComponent label={t("Edition d'une action")} />
    } else {
        if (data.id) {
            denyIfCantPerform(
                props,
                "action:read",
                {
                    watchsiteReadableCallback: (w) => canRead(parseInt(w.replace("watchsite:", ""))),
                    watchsiteWritableCallback: (w) => canWrite(parseInt(w.replace("watchsite:", ""))),
                    account: account,
                    action: data
                }
            );
        }

        return (
            <DefaultLayout screen="E37" title={t("Configuration de l'action")}>
                <Prompt
                    when={formHasModifications()}
                    message={t("Vous avez des modifications non enregistrées, voulez-vous vraiment quitter ?")}
                />
                <section className="sidebar">
                    <h1>{t("Configuration de l'action")}</h1>
                    <SidebarComponent
                        label={t("configuration de l'action")}
                        links={[                            
                            {anchor: "#info", label: t("Informations générales"), active: true},
                            {anchor: "#planning", label: t("Planification"), active: true},
                            {anchor: "#execution", label: t("Réalisation"), active: true},
                            {anchor: "#evaluation", label: t("Evaluation"), active: true},
                            {anchor: "#closing", label: t("Cloture"), active: true},
                            {anchor: "#status", label: t("Statut"), active: true},                            
                        ]}
                    />
                    <section>
                        <form onSubmit={preSave} id="form-action" className="form">
                            <ToggableBlocComponent label={t("Informations générales")} id="info" toggled={togglers["info"]} toggle={toggle}>
                                <FieldComponent 
                                    type="dropdown-tree-select"
                                    name="watchsites"
                                    label={`${t("Point de veilles")} *`}
                                    data={tree}
                                    error={errors.watchsites}
                                    onChange={value => setValue("watchsites", value)}
                                    value={data.watchsites}
                                    mode="multiSelect"
                                    disabled={writableWatchsites.length === 1 || !canWriteField("watchsites")}
                                    showDropdown={defaultWatchsites.length > 0 || data.id ? null : "initial"}
                                />
                                <FieldComponent
                                    label={t("Fiches")}
                                    type="async-select"
                                    name="sheets"
                                    multiple
                                    defaultOptions={defaultsheets}
                                    loadOptions={(inputValue, callback) => SheetAffectedAutocomplete({keyword: inputValue, watchsites: getSelectedWatchsites()}, callback)}
                                    value={data.sheets}
                                    onChange={value => updateValue("sheets", value)}
                                    disabled={!canWriteField("sheets")}
                                >
                                    {currentSheetAffected && <ul className="filelist arborescence no-arrow squared">
                                        <li>
                                            <Link id={`sheet-affected-${currentSheetAffected.id}`} to={prefixLinkTo() + `/sheets-affected/${currentSheetAffected.id}`} target="_blank">
                                                Voir la fiche {currentSheetAffected.sheet.id}
                                            </Link>
                                        </li>
                                    </ul>}
                                </FieldComponent>
                                
                                {account.is_start === false && <FieldComponent
                                    label={t("Exigences")}
                                    type="async-select"
                                    name="requirements"
                                    multiple
                                    defaultOptions={defaultrequirements}
                                    loadOptions={(inputValue, callback) => RequirementAffectedAutocomplete({keyword: inputValue, sheets: data.sheets}, callback)}
                                    value={data.requirements}
                                    onChange={value => updateValue("requirements", value)}
                                    disabled={!canWriteField("requirements")}
                                >
                                    {currentRequirementsAffected.length > 0 && <ul className="filelist arborescence no-arrow squared">
                                        {currentRequirementsAffected.map(r => <li key={r.requirement.id}>
                                            <Link id={`requirement-affected-${r.id}`} to={prefixLinkTo() + `/requirements-affected/${r.id}`} target="_blank">
                                                Voir l'exigence {r.requirement.id}
                                            </Link>
                                        </li>)}
                                    </ul>}
                                </FieldComponent>}
                                
                                <FieldComponent type="status" name="status" label={t("Statut")} value={data.status} onChange={value => updateValue("status", parseInt(value))} options={getAvailableActionsStatus()} />
                                {data.grouping_code && <PreviewFieldComponent label={t("Code regroupement")}>{data.grouping_code}</PreviewFieldComponent>}                                
                            </ToggableBlocComponent>
                            <ToggableBlocComponent label={t("Planification")} id="planning" toggled={togglers["planning"]} toggle={toggle}>
                                <FieldComponent name="label" label={`${t("Libellé de l'action")} *`} value={data.label} error={errors.label} onChange={value => updateValue("label", value)} disabled={!canWriteField("label")} />
                                <FieldComponent type="textarea" className="field h-100px" name="description" label={t("Action détaillée")} value={data.description} onChange={value => updateValue("description", value)} disabled={!canWriteField("description")} />
                                <div className="row">
                                    <div className="col-md-6">
                                        <FieldComponent type="number" name="cost" className="field small" label={t("Cout")} value={data.cost} onChange={value => updateValue("cost", value)} disabled={!canWriteField("cost")}>&nbsp;€</FieldComponent>
                                    </div>
                                    <div className="col-md-6">
                                        <FieldComponent type="date" name="deadline_date" label={`${t("Date limite de réalisation")} *`} value={data.deadline_date} error={errors.deadline_date} onChange={value => updateValue("deadline_date", value)} disabled={!canWriteField("deadline_date")} />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <FieldComponent
                                            type="select"
                                            name="pilot"
                                            label={t("Pilote")}
                                            value={data.pilot}
                                            onChange={value => updateValue("pilot", value)}
                                            options={pilots}
                                            disabled={!canWriteField("pilot")}
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <FieldComponent type="checksingle" name="send_alert" label={t("Envoyer l'alerte")} checksingleLabel={t("Oui")} onChange={value => updateValue("send_alert", (value ? 1 : 0))} checked={data.send_alert} disabled={!canWriteField("send_alert")} />
                                    </div>
                                </div>
                                {data.id && <div className="row">
                                    <div className="col-md-6">
                                        <div className="flex-label">
                                            <label>Date de création : </label>
                                            {formatDate(created.at)}
                                        </div>
                                    </div>
                                    {created.by && <div className="col-md-6">
                                        <div className="flex-label">
                                            <label>Auteur : </label>
                                            {created.by.firstname} {created.by.lastname} ({created.by.email})
                                        </div>
                                    </div>}
                                </div>}
                                <FieldComponent type="status" name="priority" label={t("Priorité")} value={data.priority} onChange={value => updateValue("priority", parseInt(value))} options={getPriorities()} disabled={!canWriteField("priority")} />
                                <FieldComponent type="radio" name="hidden" label={t("Masquage")} value={data.hidden} onChange={value => updateValue("hidden", parseInt(value))} options={[{"value": 1, "label": t("Oui")}, {"value": 0, "label": t("Non")}]} disabled={!canWriteField("hidden")} />
                                <div className="bg-gris-10 border-gris-25 border-tb">
                                    <FileComponent
                                        name="attachments"
                                        label={t("Fichier(s) lié(s)")}
                                        upload={ActionApi.uploadAttachments}
                                        value={data.attachments}
                                        error={errors.attachments}
                                        extensions={['.pdf', '.doc', '.docx', '.xls', '.xlsx', '.ppt', '.pptx', '.jpg', '.jpeg', '.png']}
                                        showDate
                                        showSize
                                        multiple
                                        onChange={values => updateValue("attachments", values)}
                                        disabled={!canWriteField("attachments")}
                                    />
                                </div>
                                <FieldComponent type="textarea" className="field h-100px" name="custom_field1" label={account.customfield1_label ? account.customfield1_label : "Champ complémentaire 1"} value={data.custom_field1} onChange={value => updateValue("custom_field1", value)} disabled={!canWriteField("custom_field1")} />
                                <FieldComponent type="textarea" className="field h-100px" name="custom_field2" label={account.customfield2_label ? account.customfield2_label : "Champ complémentaire 2"} value={data.custom_field2} onChange={value => updateValue("custom_field2", value)} disabled={!canWriteField("custom_field2")} />
                            </ToggableBlocComponent>
                            <ToggableBlocComponent label={t("Réalisation")} id="execution" toggled={togglers["execution"]} toggle={toggle}>
                                <FieldComponent type="textarea" className="field h-100px" name="achievement_report" label={t("CR de réalisation")} value={data.achievement_report} onChange={value => updateValue("achievement_report", value)} disabled={!canWriteField("achievement_report")} />
                                <div className="row">
                                    <div className="col-md-6">
                                        <FieldComponent type="date" name="achievement_date" label={t("Date de réalisation")} value={data.achievement_date} onChange={value => updateValue("achievement_date", value)} disabled={!canWriteField("achievement_date")} />
                                    </div>
                                </div>
                            </ToggableBlocComponent>
                            <ToggableBlocComponent label={t("Evaluation")} id="evaluation" toggled={togglers["evaluation"]} toggle={toggle}>
                                <FieldComponent type="textarea" className="field h-100px" name="evaluation_report" label={t("CR d’évaluation")} value={data.evaluation_report} onChange={value => updateValue("evaluation_report", value)} disabled={!canWriteField("evaluation_report")} />
                                <FieldComponent type="date" name="evaluation_date" label={t("Date d’évaluation")} value={data.evaluation_date} onChange={value => updateValue("evaluation_date", value)} disabled={!canWriteField("evaluation_date")} />
                            </ToggableBlocComponent>
                            <ToggableBlocComponent label={t("Cloture")} id="closing" toggled={togglers["closing"]} toggle={toggle}>
                                <FieldComponent type="date" name="closing_date" label="Date de clôture" value={data.closing_date} onChange={value => updateValue("closing_date", value)} disabled={!canWriteField("closing_date")} />
                            </ToggableBlocComponent>
                            <ToggableBlocComponent label="Statut" id="status" toggled={togglers["status"]} toggle={toggle}>
                                <FieldComponent type="status" name="status_footer" label="Statut" value={data.status} onChange={value => updateValue("status", parseInt(value))} options={getAvailableActionsStatus()} />
                            </ToggableBlocComponent>                            
                            <section className="row">
                                <div className="col">
                                    <Link id="back-action-list" to={prefixLinkTo() + listUri} className="btn btn-bleu-4">{t("Retour à la liste")}</Link>
                                </div>                    
                                <div className="col text-right">
                                    {data.id && <Link
                                        id="link-to-history"
                                        to={prefixLinkTo() + `/history/actions/${data.id}`}
                                        className="btn btn-primary"
                                        target="_blank"
                                    >
                                        <i className="icon-boutons-voir-historique" aria-hidden="true"></i>
                                        Voir l'historique
                                    </Link>}
                                    <Can 
                                        perform="action:write"
                                        data={{
                                            watchsiteReadableCallback: (w) => canRead(parseInt(w.replace("watchsite:", ""))),
                                            watchsiteWritableCallback: (w) => canWrite(parseInt(w.replace("watchsite:", ""))),
                                            account: account,
                                            action: data
                                        }}
                                        yes={() => (<>
                                            {data.id && <LoadButton 
                                                loading={deleting} 
                                                label={t("Supprimer")}
                                                name="delete"
                                                id="delete-action"
                                                onClick={deleteAction}
                                                iconClass="icon-boutons-poubelle"
                                            />}
                                            <LoadButton 
                                                loading={saving} 
                                                label={t("Enregistrer et fermer")}
                                                name="save-and-close"
                                                id="save-and-close-action"
                                            />
                                            <LoadButton 
                                                loading={saving} 
                                                label={t("Enregistrer")} 
                                                name="save" 
                                                id="save-action"
                                            />
                                        </>)}
                                    />
                                </div>
                            </section>
                        </form>
                    </section>
                </section>
            </DefaultLayout>
        );
    }
}
