import React from 'react';
import { useTranslation } from 'react-i18next';
import ReactTooltip from 'react-tooltip';
import { PieChart } from 'react-minimal-pie-chart';
import { Link } from "react-router-dom";
import FileUtils from 'shared/services/FileUtils';
import HtmlComponent from "shared/components/HtmlComponent";
import { prefixLinkTo } from 'shared/services';

export default function SheetAffectedListItemSheet(props) {

    const { t } = useTranslation();
    const { hasComplianceManagementAccess, row, selection, updateSelection, className, openAutoaffectation, activeAutoaffectation } = props;

    let conformityData = {
        new: 0,
        na: 0,
        info: 0,
        tospecified: 0,
        nok: 0,
        ok: 0,
    };
    row.data.forEach(item => {
        switch (parseInt(item.conformity)) {
            case 0: conformityData.new = conformityData.new + 1; break;
            case 1: conformityData.info = conformityData.info + 1; break;
            case 2: conformityData.na = conformityData.na + 1; break;
            case 3: conformityData.tospecified = conformityData.tospecified + 1; break;
            case 4: conformityData.nok = conformityData.nok + 1; break;
            case 5: conformityData.ok = conformityData.ok + 1; break;
            default: break;
        }
    });

    return (
        <tr id={`sheet-${row.sheet.id}`} className={className}>            
            <th className="w-34px text-center valign-top" scope="row" rowSpan={row.data.length === 1 ? "2" : null}>                
                <input 
                    type="checkbox"
                    id={`select-row-${row.sheet.id}`}
                    checked={selection.filter(item => item.sheet === row.sheet.id).length === row.data.length}
                    onChange={(e) => {
                        updateSelection(
                            row.sheet.id,
                            row.sheet.country.options.is_france,
                            row.data.map(item => { return {sheetaffected: item.id, watchsite: item.watchsite_id}; }),
                            e.target.checked
                        );
                    }}
                />
                <label htmlFor={`select-row-${row.sheet.id}`}>&nbsp;</label>
            </th>
            <td className="valign-top">
                <div className="d-flex">
                    <div>
                        <p>
                            {row.data.length === 1 && <>
                                <Link className="color-gris-texte" to={prefixLinkTo() + `/sheets-affected/${row.data[0].id}`}>
                                    {row.sheet.country.picture && row.sheet.country.picture.image && <>
                                        <img src={`data:image/jpeg;base64,${row.sheet.country.picture.image}`} height="12" title={row.sheet.country.value} alt={`Drapeau ${row.sheet.country.value}`} />&nbsp;
                                    </>}
                                    {row.sheet.id} : <HtmlComponent>{row.sheet.title}</HtmlComponent>
                                </Link>
                            </>}
                            {row.data.length > 1 && <>
                                {hasComplianceManagementAccess && <Link className="color-gris-texte" to={prefixLinkTo() + `/sheets/${row.sheet.id}/watchsites#watchsite-affected-search`}>
                                    {row.sheet.country.picture && row.sheet.country.picture.image && <>
                                        <img src={`data:image/jpeg;base64,${row.sheet.country.picture.image}`} height="12" alt={`Drapeau ${row.sheet.country.value}`} />&nbsp;
                                    </>}
                                    {row.sheet.id} : <HtmlComponent>{row.sheet.title}</HtmlComponent>
                                </Link>}
                                {!hasComplianceManagementAccess && <Link className="color-gris-texte" to={prefixLinkTo() + `/sheets/${row.sheet.id}`}>
                                    {row.sheet.country.picture && row.sheet.country.picture.image && <>
                                        <img src={`data:image/jpeg;base64,${row.sheet.country.picture.image}`} height="12" alt={`Drapeau ${row.sheet.country.value}`} />&nbsp;
                                    </>}
                                    {row.sheet.id} : <HtmlComponent>{row.sheet.title}</HtmlComponent>
                                </Link>}
                            </>}
                        </p>
                    </div>
                    {hasComplianceManagementAccess && row.data.length > 1 && 
                        <div id={`sheet-${row.sheet.id}-conformity-chart`} style={{marginLeft: "auto"}} data-tip data-for={`chart-sheet-${row.sheet.id}`}>
                            <PieChart
                                data={[
                                    { title: t("Nouveau"), value: conformityData.new, color: '#45BFBF' },
                                    { title: t("Pour information"), value: conformityData.info, color: '#DDDDDD' },
                                    { title: t("Non applicable"), value: conformityData.na, color: '#BDB7B1' },
                                    { title: t("A préciser"), value: conformityData.tospecified, color: '#FFB607' },
                                    { title: t("En écart"), value: conformityData.nok, color: '#F52824' },
                                    { title: t("Conforme"), value: conformityData.ok, color: '#A9D009' },
                                ]}
                                style={{ height: '58px', width: '58px' }}
                            />
                            <ReactTooltip id={`chart-sheet-${row.sheet.id}`} place="left" type="dark" effect="float">
                                <strong>Détail conformité des points de veilles</strong>
                                <ul>
                                    {conformityData.new > 0 && <li>Nouveau <span className="color-conformity-new">{(conformityData.new / row.data.length * 100).toFixed(2)}% ({conformityData.new})</span></li>}
                                    {conformityData.info > 0 && <li>Pour information <span className="color-conformity-info">{(conformityData.info / row.data.length * 100).toFixed(2)}% ({conformityData.info})</span></li>}
                                    {conformityData.na > 0 && <li>Non applicable <span className="color-conformity-na">{(conformityData.na / row.data.length * 100).toFixed(2)}% ({conformityData.na})</span></li>}                                    
                                    {conformityData.tospecified > 0 && <li>A préciser <span className="color-conformity-tospecified">{(conformityData.tospecified / row.data.length * 100).toFixed(2)}% ({conformityData.tospecified})</span></li>}
                                    {conformityData.nok > 0 && <li>En écart <span className="color-conformity-nok">{(conformityData.nok / row.data.length * 100).toFixed(2)}% ({conformityData.nok})</span></li>}
                                    {conformityData.ok > 0 && <li>Conforme <span className="color-conformity-ok">{(conformityData.ok / row.data.length * 100).toFixed(2)}% ({conformityData.ok})</span></li>}
                                </ul>
                            </ReactTooltip>
                        </div>
                    }
                </div>
            </td>
            <td className="w-34px no-padding valign-top" rowSpan="2">
                <ul className="actions">
                    {row.sheet.reglementary_text && <li>
                        <Link to={prefixLinkTo() + "/texte"} title={t("Consulter le texte")} onClick={e => { e.preventDefault(); FileUtils.download(row.sheet.reglementary_text); }}><i className="icon-actions-consulter-pdf" aria-hidden="true"></i></Link>
                    </li>}
                    <li>
                        {row.data.length > 1 && <>
                            {hasComplianceManagementAccess && <Link to={prefixLinkTo() + `/sheets/${row.sheet.id}/watchsites#watchsite-affected-search`} title={t("Traiter la fiche en multi point de veille")} target="_blank">
                                <i className="icon-actions-traitement-mpv" aria-hidden="true"></i>
                            </Link>}
                            {!hasComplianceManagementAccess && <Link to={prefixLinkTo() + `/sheets/${row.sheet.id}`} title={t("Voir la fiche")} target="_blank">
                                <i className="icon-actions-consulter-fiche" aria-hidden="true"></i>
                            </Link>}
                        </>}
                    </li>
                    {activeAutoaffectation && <li>
                        <Link title={t("S'affecter")} to={`/sheets/${row.sheet.id}`} onClick={(e) => openAutoaffectation(e, row.sheet)}>
                            <i className="icon-actions-affecter" aria-hidden="true"></i>
                        </Link>
                    </li>}
                </ul>
            </td>
        </tr>
    );    
}