import { React } from 'commons';
import { useTranslation } from 'react-i18next';
import { useToggler } from "shared/hooks";
import { ToggableBlocComponent, HtmlComponent } from "shared/components";
import { prefixLinkTo } from 'shared/services';
import DocumentarySpaceDetailCommonRows from "views/documentaryspace/Detail/CommonRows";
import DocumentarySpaceDetailRow from "views/documentaryspace/Detail/Row";

export default function DocumentarySpaceDetailForm(props) {

    const { t } = useTranslation();
    const [togglers, toggle] = useToggler({"document": true});

    return <form className="form">
        <section>
            <h1>{props.document.title}</h1>
            <ToggableBlocComponent label={t("Document")} id="document" toggled={togglers["document"]} toggle={toggle} className="border-gris-25 border-lrb">
                <DocumentarySpaceDetailCommonRows document={props.document} />
                {props.document.number && <DocumentarySpaceDetailRow label={t("Numéro")} value={props.document.number} />}
                {props.document.sheets && props.document.sheets.map(sheet => 
                    <DocumentarySpaceDetailRow
                        label="Fiche"
                        value={sheet.id}
                        type="link"
                        to={prefixLinkTo() + `/sheets/${sheet.id}`}>
                        <HtmlComponent>{sheet.title}</HtmlComponent>
                    </DocumentarySpaceDetailRow>
                )}            
                <DocumentarySpaceDetailRow label={t("Référence règlementaire")} value={props.document.regulatory_reference} type="text" />
                <DocumentarySpaceDetailRow label={t("Formulaire")} value={props.document.form} type="file" />
                <DocumentarySpaceDetailRow label={t("Formulaire interactif")} value={props.document.interactive_form} type="file" />
                {props.document.online_declaration &&
                    <DocumentarySpaceDetailRow
                        label={t("Déclaration en ligne")}
                        value={props.document.online_declaration}
                        type="link-external" />
                }
                <DocumentarySpaceDetailRow label={t("Notice technique")} value={props.document.technical_manual} type="file" />
                {props.document.comment &&
                    <DocumentarySpaceDetailRow label={t("Commentaire")} value={props.document.comment} type="rte" />}
            </ToggableBlocComponent>
            <section className="row">
                <div className="col text-right">
                    <button id="close-detail" onClick={props.onRequestClose} className="btn btn-bleu-4">{t("Fermer")}</button>
                </div>
            </section>
        </section>
    </form>;
}