import React from 'react';
import { useTranslation } from 'react-i18next';
import { useListBatch } from 'shared/hooks/ListBatch';
import FieldComponent from 'shared/components/FieldComponent';
import LoadButton from 'shared/components/LoadButton';
import RequirementAffectedApi from 'api/RequirementAffected';
import { getConformities } from 'data/SheetAffected';
import { getPriorities } from 'data/Priority';
import { useAccount } from 'hooks/Account';
import { canPerform } from "shared/services/Can";

export default function RequirementAffectedListBatch(props) {

    const  { t } = useTranslation();
    const [account] = useAccount();
    const { selection, selectionData, refresh, history } = props;

    let BATCHS = {
        "hide": {
            value: "hide",
            label: t("Activer masquage"),
            error: "Une erreur est survenue lors de l'activation du masquage",
            field: "hidden",
            fieldValue: "1",
        },
        "unhide": {
            value: "unhide",
            label: t("Désactiver masquage"),
            error: "Une erreur est survenue lors de la désactivation du masquage",
            field: "hidden",
            fieldValue: "0",
        }
    };
    if (canPerform("account:compliance_management", {account: account})) {
        BATCHS.update_conformity = {
            value: "update_conformity",
            label: t("Modifier la conformité"),
            error: "Veuillez sélectionner une conformité",
            field: "conformity",
        };
        BATCHS.update_priority = {
            value: "update_priority",
            label: t("Modifier la priorité"),
            error: "Veuillez sélectionner une priorité",
            field: "priority",
        };
        BATCHS.update_review_date = {
            value: "update_review_date",
            label: t("Modifier la date de revue"),
            error: "Veuillez sélectionner une date de revue",
            field: "review_date",
        };
        BATCHS.update_evaluation_date = {
            value: "update_evaluation_date",
            label: t("Modifier la date d'évaluation"),
            error: "Veuillez sélectionner une date d'évaluation",
            field: "evaluation_date",
        };
    }
    if (canPerform("account:actionplan", {account: account})) {
        BATCHS.create_action = {
            value: "create_action",
            label: t("Créer une action"),
        };
    }

    const [batch, setBatch, value, setValue, updateField, batching] = useListBatch(BATCHS, RequirementAffectedApi, selection, refresh);

    function submit(event) {
        if (batch === "create_action") {
            event.preventDefault();
            
            const watchsites = [];
            const requirements = [];

            selectionData.forEach(data => {
                if (!watchsites.includes(data.watchsite_id)) {
                    watchsites.push(data.watchsite_id);
                }
                if (!requirements.includes(data.requirement_id)) {
                    requirements.push(data.requirement_id);
                }
            });

            history.push("/actions/new?watchsites=" + watchsites.join(",") + "&requirements=" + requirements.join(","));
        } else {
            updateField(event);
        }
    }

    return (<>
        <FieldComponent
            type="simple-select"
            name="select-batch"
            value={batch}
            onChange={(newvalue) => {
                setBatch(newvalue);
                setValue(null);
            }}
            className=""
            wrap={false}
            options={Object.values(BATCHS)}
            blankLabel
        />

        {batch === "update_conformity" &&
            <FieldComponent
                type="simple-select"
                name="select-conformity"
                value={value}
                onChange={(newvalue) => setValue(newvalue)}
                className=""
                wrap={false}
                options={getConformities()}
                blankLabel
            />
        }

        {batch === "update_priority" &&
            <FieldComponent
                type="simple-select"
                name="select-priority"
                value={value}
                onChange={(newvalue) => setValue(newvalue)}
                className=""
                wrap={false}
                options={getPriorities()}
                blankLabel
            />
        }

        {(batch === "update_review_date" || batch === "update_evaluation_date") &&
            <FieldComponent
                type="date"
                name="select-date"
                value={value}
                className=""
                onChange={(newvalue) => setValue(newvalue)}
                wrap={false}
            />
        }

        {batch && <LoadButton loading={batching} onClick={submit} className="btn btn-primary h25" label={batch === "create_action" ? "Créer" : "Configurer"} />}
    </>);
}