import { React, Link } from 'commons';
import { useTranslation } from 'react-i18next';
import { FileUtils, formatDate, prefixLinkTo } from 'shared/services';
import DocumentarySpaceResultRowPicto from 'views/documentaryspace/Result/Row/Picto';
import DocumentarySpaceDetailRegulatoryFlowchart from 'views/documentaryspace/Detail/RegulatoryFlowchart';

export default function DocumentarySpaceResultRowRegulatoryFlowchart(props) {

    const { t } = useTranslation();

    function openDetail(event) {
        event.preventDefault();
        props.setDetail(<DocumentarySpaceDetailRegulatoryFlowchart onRequestClose={() => props.setDetail(null)} document={props.row} />);
    }

    return <li>
        <DocumentarySpaceResultRowPicto row={props.row} />
        {props.row.pdf && <div className="picto">
            <button className="no-style" onClick={e => { e.preventDefault(); FileUtils.download(props.row.pdf); }} title={t("Télécharger le PDF")}>
                <i className="icon-actions-consulter" aria-hidden="true"></i>
            </button>
        </div>}
        <div className="contenu">
            <div className="infos">
                <Link onClick={openDetail} to={prefixLinkTo() + `/documentary-space/regulatoryflow/${props.row.id}`}>{props.row.title}</Link>
                <p>{formatDate(props.row.date)}</p>
            </div>
        </div>
    </li>;
}