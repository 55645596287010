import React, { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Switch, Redirect, Route, BrowserRouter as Router } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from 'axios';
import BarLoader from "react-spinners/BarLoader";

import routes from "routes";
import Page404 from "shared/views/Page404";
import Logout from "views/Logout";

import { useSecurity } from 'shared/hooks/Security';
import { useAccount } from 'hooks/Account';
import { useAccess } from 'hooks/Access';
import { useAutomaticRedirect } from 'shared/hooks/AutomaticRedirect';
import { ReferentialContext } from 'shared/stores/Referential';
import { prefixLinkTo } from 'shared/services';
import AccountApi from 'shared/api/Account';
import SecurityApi from 'shared/api/Security';
import ReferentialApi from 'shared/api/Referential';
import AccessApi from 'shared/api/Access';
import ExportApi from 'shared/api/Export';

import { REFERENTIALS } from 'shared/data/ReferentialType';

import logoapave from "images/logoapave.svg";
import logopilotveille from "images/logopilotveille.png";

export default function Dispatcher() {

    const { i18n } = useTranslation();
    const [updateLastVisit, getLastVisitPath, shouldRedirect] = useAutomaticRedirect();
    const [,, setUser] = useSecurity();
    const [,, setAccount] = useAccount();
    const [loading, setLoading] = useState(true);
    const [islogged, setIslogged] = useState(null);
    const [hasAccount, setHasaccount] = useState(null);
    const [,,,, setWritableWatchsites, setReadableWatchsites] = useAccess();
    const [referentialContext, referentialDispatcher] = useContext(ReferentialContext);
    const loader = <>
        <section className="login">                
            <h1>
                <div className="main-logo">
                    <img src={logoapave} width="96" alt="Logo apave" />
                </div>
                <div className="main-name">
                    <img src={logopilotveille} alt="Logo Pilot Veille" />
                </div>
            </h1>
        </section>
        <section className="login" style={{marginTop:"50px"}}>
            <BarLoader loading={true} width={100} color="#5bad27" css="display:block;margin:10px auto;" />
        </section>                
    </>;

    useEffect(() => {
        if (document.location.pathname !== "/adsllogin") {
            SecurityApi
                .getCurrentUser()
                .then(response => {
                    setUser(response.data);
                    i18n.changeLanguage(response.data.language);
                    document.documentElement.setAttribute("lang", i18n.language);
                    localStorage.setItem("current-language", i18n.language);
                    if (i18n.language === "ar") {
                        document.documentElement.setAttribute("dir", "rtl");
                    } else {
                        document.documentElement.setAttribute("dir", "ltr");
                    }

                    SecurityApi
                        .getCurrentAccount()
                        .then(accountResponse => {
                            setHasaccount(true);
                            setIslogged(true);
                            setAccount(accountResponse.data);

                            // si déjà connecté sans passer par loader
                            axios
                                .all([AccessApi.listWritableWatchsites(), AccessApi.listReadableWatchsites()])
                                .then(axios.spread(([writableWatchsites], [readableWatchsites]) => {
                                    setWritableWatchsites(writableWatchsites.map(w => w.id));
                                    setReadableWatchsites(readableWatchsites.map(w => w.id));
                    
                                    setLoading(false);
                                }));
                        })
                        .catch(() => {
                            setHasaccount(false);
                            setIslogged(true);
                            setLoading(false);
                        });
                })
                .catch(() => {
                    setHasaccount(false);
                    setIslogged(false);
                    setLoading(false);
                });
        } else {
            setHasaccount(false);
            setIslogged(false);
            setLoading(false);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [document.location.pathname]);

    function checkExport() {
        ExportApi
            .checkForNew()
            .then(result => {
                if (result.length > 0) {
                    let message = 'Un export est disponible pour être téléchargé (';
                    if (result.length > 1) {
                        message = 'Des exports sont disponibles pour être téléchargés (';
                    }
                    let files = [];
                    result.forEach(element => {
                        files.push(element.filename);
                    });
                    message += files.join(', ');
                    message += ')';
                    toast.info(message);
                }
            })
            .catch(() => {});

        ExportApi
            .getReadyExportAmount()
            .then(response => localStorage.setItem("exportReady", response.amount))
            .catch(() => {});
    }

    function loadReferential() {
        if (referentialContext.status === "empty") {
            referentialDispatcher({action: "status", data: "loading"});
            axios
                .all([
                    ReferentialApi.list(REFERENTIALS.REFERENTIAL_TEXT_TYPE),
                    ReferentialApi.list(REFERENTIALS.REFERENTIAL_TEXT_IMPACT),
                    ReferentialApi.list(REFERENTIALS.REFERENTIAL_SOURCE),
                    ReferentialApi.list(REFERENTIALS.REFERENTIAL_TRANSMITTER),
                    ReferentialApi.list(REFERENTIALS.REFERENTIAL_INVOLVED_ACTOR),
                    ReferentialApi.list(REFERENTIALS.REFERENTIAL_INVOLVED_FUNCTION),
                    ReferentialApi.list(REFERENTIALS.REFERENTIAL_DESCRIPTORS),
                    ReferentialApi.list(REFERENTIALS.REFERENTIAL_DOCUMENT_TYPE),
                    ReferentialApi.list(REFERENTIALS.REFERENTIAL_APPLICATION_AREA),
                    ReferentialApi.tree(REFERENTIALS.REFERENTIAL_CATEGORY),                    
                    ReferentialApi.tree(),
                    ReferentialApi.restrictedTree(),
                    ReferentialApi.userDomainTree(),
                    AccountApi.getUserTree(true),
                    AccountApi.getUserTree(false),
                ])
                .then(axios.spread((
                    [type],
                    [impact],
                    [source],
                    [transmitter],
                    [involvedActor],
                    [involvedFunction],
                    [descriptor],
                    [documentType],
                    [applicationArea],
                    category,
                    tree,
                    restrictedTree,
                    userDomainTree,
                    accountTree,
                    writableAccountTree
                ) => {
                    referentialDispatcher({action: "populate", data: type, type: REFERENTIALS.REFERENTIAL_TEXT_TYPE});
                    referentialDispatcher({action: "populate", data: impact, type: REFERENTIALS.REFERENTIAL_TEXT_IMPACT});
                    referentialDispatcher({action: "populate", data: source, type: REFERENTIALS.REFERENTIAL_SOURCE});
                    referentialDispatcher({action: "populate", data: transmitter, type: REFERENTIALS.REFERENTIAL_TRANSMITTER});
                    referentialDispatcher({action: "populate", data: involvedActor, type: REFERENTIALS.REFERENTIAL_INVOLVED_ACTOR});
                    referentialDispatcher({action: "populate", data: involvedFunction, type: REFERENTIALS.REFERENTIAL_INVOLVED_FUNCTION});
                    referentialDispatcher({action: "populate", data: descriptor, type: REFERENTIALS.REFERENTIAL_DESCRIPTORS});
                    referentialDispatcher({action: "populate", data: documentType, type: REFERENTIALS.REFERENTIAL_DOCUMENT_TYPE});
                    referentialDispatcher({action: "populate", data: applicationArea, type: REFERENTIALS.REFERENTIAL_APPLICATION_AREA});
                    referentialDispatcher({action: "populate", data: category, type: REFERENTIALS.REFERENTIAL_CATEGORY});
                    referentialDispatcher({action: "populate", data: tree, type: 'tree'});
                    referentialDispatcher({action: "populate", data: restrictedTree, type: 'restrictedTree'});
                    referentialDispatcher({action: "populate", data: userDomainTree, type: 'userDomainTree'});
                    referentialDispatcher({action: "populate", data: accountTree, type: 'accountTree'});
                    referentialDispatcher({action: "populate", data: writableAccountTree, type: 'writableAccountTree'});

                    referentialDispatcher({action: "status", data: "load"});
                }));
        }
    }

    if ((loading || referentialContext.status === "loading") && document.location.pathname !== "/loader") {
        return <>{loader}</>;
    }

    if (document.location.pathname === "/logout") {
        return <Logout />;
    }

    let allowNoLogged = (
        /users\/validation\/[a-z0-9]*/gi.test(document.location.pathname)
        || /users\/forgotpassword/gi.test(document.location.pathname)
        || /users\/initpassword\/[a-z0-9]*/gi.test(document.location.pathname)
        || /maintenance.*/gi.test(document.location.pathname)
        || /cgu/gi.test(document.location.pathname)
        || /adsllogin/gi.test(document.location.pathname)
        || /denied/gi.test(document.location.pathname)
        || /logout/gi.test(document.location.pathname)
    );

    if (!islogged && !allowNoLogged
        && document.location.pathname !== "/logout"
        && document.location.pathname !== "/login"
        && document.location.pathname !== "/loader"
    ) {
        updateLastVisit();
        window.history.pushState("Login", "Login", "/login");

    } else if (islogged && !hasAccount && !allowNoLogged && document.location.pathname !== "/selectaccount") {
        updateLastVisit();
        window.history.pushState("Select account", "Select account", "/selectaccount");

    } else if (islogged && hasAccount && shouldRedirect()) {
        return <Redirect to={prefixLinkTo() + getLastVisitPath()} />
    }

    if (referentialContext.status === "empty" && islogged && hasAccount) {
        // if referential not logged
        loadReferential();
        return <>{loader}</>;
    }

    setInterval(checkExport, process.env.REACT_APP_INTERVAL_TO_CHECK_FOR_READY_EXPORTS);

    return (
        <Router>
            <Switch>
                {routes.map(route => (
                    <Route exact path={route.path} key={route.path} component={route.component} />
                ))}
                <Route component={Page404} />
            </Switch>
        </Router>
    );
}