import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import FieldComponent from 'shared/components/FieldComponent';
import LoadButton from 'shared/components/LoadButton';

export default function SheetAffectedListExportForm(props) {

    const { t } = useTranslation();
    const [mode, setMode] = useState('simple');
    
    function submit(event) {
        event.preventDefault();
        props.export(mode).then(() => props.onRequestClose());
    }

    return (
        <form onSubmit={submit} id="form-export-sheetsaffected" className="form">
            <section className="bloc">
                <header className="border-rlb border-gris-40">
                    <h2 className="uppercase">Exporter</h2>
                </header>
                <section>
                    <FieldComponent
                        type="radio"
                        name="export_mode"
                        label={t("Mode")}
                        value={mode}
                        options={props.exportOptions}
                        onChange={value => setMode(value)}
                    />
                    <section className="row">
                        <div className="col">
                            <button type="button" id="back-sheetsaffected-list" onClick={props.onRequestClose} className="btn btn-bleu-4">{t("Retour à la liste")}</button>
                        </div>
                        <div className="col text-right">  
                            <LoadButton 
                                loading={props.exporting} 
                                label={t("Exporter")}
                                name="export-sheetsaffected"
                                id="export-sheetsaffected"
                            />
                        </div>
                    </section>                    
                </section>
            </section>
        </form>
    );
}