import { React, Link, Modal, DefaultLayout, useState, useEffect } from 'commons';
import { useTranslation } from 'react-i18next';
import { actionLabel, typeLabel } from 'data/Historic';
import { useList } from 'shared/hooks';
import { HistoryApi, SheetAffectedApi } from 'shared/api';
import { ListComponent, FilterComponent } from 'shared/components';
import { smallScrollableModalCustomStyles, preventDefault, prefixLinkTo } from 'shared/services';
import { HistoryDetail } from 'shared/views';

export default function HistoryList(props) {

    const { t } = useTranslation();
    const historyId = props.match.params.id;
    const typeId = props.match.params.type;

    const [detail, setDetail] = useState(null);
    const [isopen, setOpenDetail] = useState(false);
    const [entity, setEntity] = useState(null);

    const columns = [
        {id: 'logged_at', title: t("Date et heure"), format: 'datetime'},
        {id: 'username', title: t("Auteur")},
        {id: 'action', title: t("Type d'action"), render: (r) => <>{actionLabel[r.action]}</>},
        {id: 'detail', title: '', sortable: false, render: (r) => <ul className="actions">
            <li><Link title={t("Détail")} onClick={(e) => openDetail(e, r)} to={prefixLinkTo() + `/history/${r.id}`}><i className="icon-actions-consulter-fiche" aria-hidden="true"></i></Link></li>
        </ul>}
    ];

    const filterType = {
        action: {type: "match", fields: ["action"]},
        logged_at: {type: "range", fields: ["loggedAt"]},
    };

    const [
        rows,
        totalrows,
        criterias,
        sorting,
        direction,
        limit,
        page,,,
        addCriteria,
        updateSorting,
        updateLimit,
        updatePage,
        submitSearch,
        loading,,
        refresh
    ] = useList("history-list", HistoryApi, preFetch, "loggedAt", null, "desc");

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => submitSearch(filterType), []);
    useEffect(loadEntity, [typeId, historyId]);

    function openDetail(event, history) {
        event.preventDefault();
        setDetail(history);
        setOpenDetail(true);
    }

    function preFetch(newSorting, newDirection, newCriterias, newLimit, newPage, newFilters) {        
        return [newSorting, newDirection, newCriterias, newLimit, newPage, newFilters, [typeId, historyId]];
    }

    function loadEntity() {
        if (typeId === "sheets_affected") {
            SheetAffectedApi.get(historyId).then((e) => setEntity(e));
        }
    }

    return (
        <DefaultLayout title={`Historique - ${typeLabel[typeId]} ${historyId}`} screen="E47">
            {(typeId !== "sheets_affected" || !entity) && <h1>Historique - {typeLabel[typeId]} {historyId}</h1>}
            {typeId === "sheets_affected" && entity && <h1>Historique - {typeLabel[typeId]} {entity.sheet.id} - {entity.watchsite.label}</h1>}
            <div className="bloc">
                <form className="form" onSubmit={(e) => preventDefault(e, submitSearch(filterType))}>
                    <section className="filters">
                        <header>
                            <h2><button className="toggler" type="button">{t("Recherche")}</button></h2>
                        </header>
                        <div className="bg-gris-25">
                            <div className="row">
                                <FilterComponent
                                    label={t("Date et heure")}
                                    name="datetime"
                                    type="daterange"
                                    value={criterias.logged_at}
                                    onChange={value => addCriteria("logged_at", value)}
                                />
                                <FilterComponent
                                    label={t("Type ")}
                                    name="action"
                                    onChange={value => addCriteria("action", value)}
                                    value={criterias.action}
                                    type="select"
                                    clearable={true}
                                    options={[
                                        {value: 'create', label: 'Création'},
                                        {value: 'update', label: 'Mise à jour'},
                                    ]}
                                />
                            </div>
                        </div>
                        <div className="bg-gris-25 border-b border-gris-60">
                            <div className="row">
                                <div className="col-md-9">
                                    <button id="clearfilter-history" onClick={() => refresh(true)} type="button" className="btn btn-bleu-4 icon"><i className="icon-filtres-poubelle" aria-hidden="true"></i>Réinitialiser la recherche</button>
                                </div>
                                <div className="col-md-3 text-right">
                                    <button id="search-history" type="submit" className="btn btn-primary">{t("Rechercher")}</button>
                                </div>
                            </div>
                        </div>
                    </section>

                    <ListComponent 
                        id="history"
                        loading={loading}
                        selectable={false}
                        rows={rows}
                        columns={columns}
                        sorting={sorting}
                        direction={direction}
                        onSortingChange={updateSorting}
                        perpage={limit}
                        onPerpageChange={updateLimit}
                        page={page}
                        onPageChange={updatePage}
                        totalrows={totalrows}                        
                    />
                </form>
            </div>

            <Modal isOpen={isopen} onRequestClose={() => setOpenDetail(false)} style={smallScrollableModalCustomStyles}>
                <HistoryDetail
                    history={detail}
                    entity={entity}
                    onRequestClose={() => setOpenDetail(false)}
                />
            </Modal>
        </DefaultLayout>
    );
}