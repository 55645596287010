import ApiRequest from 'shared/services/ApiRequest';
import AbstractApi from 'shared/api/Abstract';
import FileApi from 'shared/api/File';
import { REFERENTIAL_DOMAIN } from 'shared/data/ReferentialType';
import _ from 'lodash';

const ReferentialApi = {

    save: function(data) {
        return AbstractApi.save("referentials", data);
    },

    get: function(id) {
        return AbstractApi.get("referentials", id);
    },

    list: function(type = null, account = null, countries = null, limit = 1000) {
        let params = {};

        if (type) {
            params.type = type;
        }

        if (account) {
            params.account = account;
        }

        if (countries && Array.isArray(countries)) {
            params.country = countries.join(',');
        }

        params.limit = limit;
        return AbstractApi.list("referentials", params).then(([results, count, total]) => {
            return [_.sortBy(results, 'value'), count, total];
        });
    },

    delete: function(id) {
        return AbstractApi.delete("referentials", id);
    },

    search: function(query, from, size, sorting, direction) {
        return AbstractApi.search("referentials/searches", query, from, size, sorting, direction);
    },

    tree: function(type = REFERENTIAL_DOMAIN) {
        return ApiRequest.get("referentials/" + type + "/tree").then(response => response.data);
    },

    restrictedTree: function(type = REFERENTIAL_DOMAIN) {
        return ApiRequest.get("referentials/" + type + "/restrictedtree").then(response => response.data);
    },

    userDomainTree: function() {
        return ApiRequest.get("referentials/userdomaintree").then(response => response.data);
    },

    accountsDomainTree: function(accounts) {
        return ApiRequest
            .post(
                "referentials/accountsdomaintree",
                {ids: accounts}
            )
            .then(response => response.data);
    },

    uploadPicture: function(files) {
        return FileApi.upload("referentials/upload/picture", files);
    },
}
export default ReferentialApi;