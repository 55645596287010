import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// TODO vérifier message manquant (texte, message confirmation (confirm()), etc)

const resources = {
    "en": {
        translation: {
            "Retour à Pilot-Veille": "Retour à Pilot-Veille - EN",
            "Accueil": "Accueil - EN",
            "Ma veille": "Ma veille - EN",
            "Exigences": "Exigences - EN",
            "Plan d'action": "Plan d'action - EN",
            "Hotline": "Hotline - EN",
            "Statistiques": "Statistiques - EN",
            "Rechercher un texte": "Rechercher un texte - EN",
            "Espace documentaire": "Espace documentaire - EN",
            "Mon profil": "Mon profil - EN",
            "Gestion des utilisateurs": "Gestion des utilisateurs - EN",
            "Gestion des utilisateurs en liste": "Gestion des utilisateurs en liste - EN",
            "En liste": "En liste - EN",
            "Gestion des utilisateurs par point de veille": "Gestion des utilisateurs par point de veille - EN",
            "Par point de veille": "Par point de veille - EN",
            "Paramètre": "Paramètre - EN",
            "Configuration du compte": "Configuration du compte - EN",
            "Gestion des catégories": "Gestion des catégories - EN",
            "Mes exports": "Mes exports - EN",
            "Prénom": "Prénom - EN",
            "Nom": "Nom - EN",
            "Compte": "Compte - EN",
            "Recherche libre": "Recherche libre - EN",
            "Numéro de fiche": "Numéro de fiche - EN",
            "Mode gestion": "Mode gestion - EN",
            "Conformité": "Conformité - EN",
            "Domaine / Sous domaine / Thème": "Domaine / Sous domaine / Thème - EN",
            "Type de texte": "Type de texte - EN",
            "Point de veille ou noeud": "Point de veille ou noeud - EN",
            "Date du texte": "Date du texte - EN",
            "Date de publication": "Date de publication - EN",
            "Date d'évaluation": "Date d'évaluation - EN",
            "Date de revue": "Date de revue - EN",
            "Texte majeur": "Texte majeur - EN",
            "Fiche avec action": "Fiche avec action - EN",
            "Ajouter un commentaire": "Ajouter un commentaire - EN",
            "Fiche masquée": "Fiche masquée - EN",
            "Fiche privée": "Fiche privée - EN",
            "Priorité": "Priorité - EN",
            "Pilote": "Pilote - EN",
            "Favoris": "Favoris - EN",
            "Numéro de texte": "Numéro de texte - EN",
            "Numéro NOR": "Numéro NOR - EN",
            "Texte abrogé": "Texte abrogé - EN",
            "Découpé en exigence": "Découpé en exigence - EN",
            "Source": "Source - EN",
            "Emetteur": "Emetteur - EN",
            "Descripteurs": "Descripteurs - EN",
            "Impact du texte": "Impact du texte - EN",
            "Acteurs concernés": "Acteurs concernés - EN",
            "Fonctions concernées": "Fonctions concernées - EN",
            "Responsable de la dernière évaluation": "Responsable de la dernière évaluation - EN",
            "Degré d'impact corporel": "Degré d'impact corporel - EN",
            "Degré d'impact juridique": "Degré d'impact juridique - EN",
            "Degré d'impact médiatique": "Degré d'impact médiatique - EN",
            "Degré d'impact financier": "Degré d'impact financier - EN",
            "Date de mise en ligne": "Date de mise en ligne - EN",
            "Date de masquage": "Date de masquage - EN",
            "Date d'échéance": "Date d'échéance - EN",
            "Commentaire": "Commentaire - EN",
            "Des erreurs sont survenues": "Des erreurs sont survenues - EN",
            "Impossible de charger les données": "Impossible de charger les données - EN",
            "Impossible de charger les accès": "Impossible de charger les accès - EN",
            "Vous n'avez pas les droits suffisants. Veuillez revoir la sélection": "Vous n'avez pas les droits suffisants. Veuillez revoir la sélection - EN",
            "Votre sélection est vide": "Votre sélection est vide - EN",
            "Commentaire ajouté": "Commentaire ajouté - EN",
            "Nouveau": "Nouveau - EN",
            "A préciser": "A préciser - EN",
            "En écart": "En écart - EN",
            "Conforme": "Conforme - EN",
            "Choisir": "Choisir - EN",
            "Recherche": "Recherche - EN",
            "Recherche avancée": "Recherche avancée - EN",
            "L'utilisation des critères de recherche avancée peut augmenter de manière conséquente les temps de recherche": "L'utilisation des critères de recherche avancée peut augmenter de manière conséquente les temps de recherche - EN",
            "Réinitialiser la recherche": "Réinitialiser la recherche - EN",
            "Enregistrer la recherche": "Enregistrer la recherche - EN",
            "Mes recherches enregistrées": "Mes recherches enregistrées - EN",
            "Rechercher": "Rechercher - EN",
            "Tous": "Tous - EN",
            "Oui": "Oui - EN",
            "Non": "Non - EN",
            "Exporter": "Exporter - EN",
            "Sélectionner tous les éléments de la page courante ({{page}})": "Sélectionner tous les éléments de la page courante ({{page}}) - EN",
            "Enregistrer et fermer": "Enregistrer et fermer - EN",
            "Retour à la liste": "Retour à la liste - EN",
            "Connexion à Pilot Veille": "Connexion à Pilot Veille - EN",
            "Langue": "Langue - EN",
            "Identifiant": "Identifiant - EN",
            "Mot de passe": "Mot de passe - EN",
            "Mot de passe oublié ?": "Mot de passe oublié ? - EN",
            "Connexion": "Connexion - EN",
            "Connexion SSO": "Connexion SSO - EN",
            "Impossible de sélectionner le compte": "Impossible de sélectionner le compte - EN",
            "Veuillez sélectionner un compte": "Veuillez sélectionner un compte - EN",
            "Vous êtes associés à plusieurs comptes. Veuillez sélectionner celui à utiliser.": "Vous êtes associés à plusieurs comptes. Veuillez sélectionner celui à utiliser. - EN",
            "Choisir votre compte": "Choisir votre compte - EN",
            "Veuillez patienter, chargement de la liste des comptes accessibles en cours": "Veuillez patienter, chargement de la liste des comptes accessibles en cours - EN",
            "Votre utilisateur n'est associé à aucun compte valide. Veuillez contacter votre conseiller Apave.": "Votre utilisateur n'est associé à aucun compte valide. Veuillez contacter votre conseiller Apave. - EN",
            "Chargement des données et accès": "Chargement des données et accès - EN",
            "Chargement": "Chargement - EN",
            "Bienvenue sur Pilot Veille": "Bienvenue sur Pilot Veille - EN",
            "Dernières fiches": "Dernières fiches - EN",
            "Voir plus": "Voir plus - EN",
            "Voir moins": "Voir moins - EN",
            "Statistique": "Statistique - EN",
            "Détail conformité des points de veilles": "Détail conformité des points de veilles - EN",
            "Gérer ma conformité": "Gérer ma conformité - EN",
            "Gestion de conformité": "Gestion de conformité - EN",
            "Fiche(s) restante(s)": "Fiche(s) restante(s) - EN",
            "Fiche(s) à échéance": "Fiche(s) à échéance - EN",
            "Date revue inférieure à 30 jours": "Date revue inférieure à 30 jours - EN",
            "Action(s) à échéance": "Action(s) à échéance - EN",
            "Date limite de réalisation inférieure à 30 jours": "Date limite de réalisation inférieure à 30 jours - EN",
            "Consultant(s) du compte": "Consultant(s) du compte - EN",
            "Dernières actualités": "Dernières actualités - EN",
            "Voir toutes les actualités": "Voir toutes les actualités - EN",
            "Voir l'actualité": "Voir l'actualité - EN",
            "Mes actions en cours": "Mes actions en cours - EN",
            "Voir l'action": "Voir l'action - EN",
            "Date de création": "Date de création - EN",
            "Date limite de réalisation": "Date limite de réalisation - EN",
            "Voir tout le plan d'actions": "Voir tout le plan d'actions - EN",
            "Dernières questions hotline": "Dernières questions hotline - EN",
            "Voir la question": "Voir la question - EN",
            "Voir toutes mes questions": "Voir toutes mes questions - EN",
            "Créer une fiche privée": "Créer une fiche privée - EN",
            "Confirmation": "Confirmation - EN",
            "Valider": "Valider - EN",
            "Masquage": "Masquage - EN",
            "Fichier à importer": "Fichier à importer - EN",
            "Importer": "Importer - EN",
            "Numéro d'exigence": "Numéro d'exigence - EN",
            "Etat d'applicabilité": "Etat d'applicabilité - EN",
            "Sommaire": "Sommaire - EN",
            "Exigence masquée": "Exigence masquée - EN",
            "Noeud/Point de veille": "Noeud/Point de veille - EN",
            "Domaines / Sous domaines / Thèmes": "Domaines / Sous domaines / Thèmes - EN",
            "Exigence avec action": "Exigence avec action - EN",
            "Conformité de la fiche": "Conformité de la fiche - EN",
            "Priorité de la fiche": "Priorité de la fiche - EN",
            "Date de revue de la fiche": "Date de revue de la fiche - EN",
            "Date d'évaluation de la fiche": "Date d'évaluation de la fiche - EN",
            "Enregistrer les modifications": "Enregistrer les modifications - EN",
            "Conformité d'une exigence": "Conformité d'une exigence - EN",
            "Informations publiques": "Informations publiques - EN",
            "Niveau général": "Niveau général - EN",
            "Exigence": "Exigence - EN",
            "Article": "Article - EN",
            "Emplacement dans l'arborescence": "Emplacement dans l'arborescence - EN",
            "Date de début d'applicabilité": "Date de début d'applicabilité - EN",
            "Date de fin d'applicabilité": "Date de fin d'applicabilité - EN",
            "Statut de conformité": "Statut de conformité - EN",
            "Enregistrer": "Enregistrer - EN",
            "Enregistrer et passer à la conformité suivante": "Enregistrer et passer à la conformité suivante - EN",
            "Type de contrôle": "Type de contrôle - EN",
            "Date": "Date - EN",
            "Type de document": "Type de document - EN",
            "Document": "Document - EN",
            "Fichier pdf": "Fichier pdf - EN",
            "Description": "Description - EN",
            "Image": "Image - EN",
            "Fiche": "Fiche - EN",
            "Lien internet": "Lien internet - EN",
            "Date de dernier envoi": "Date de dernier envoi - EN",
            "Juridiction": "Juridiction - EN",
            "Décision": "Décision - EN",
            "Date de la décision": "Date de la décision - EN",
            "Numéro de pourvoi": "Numéro de pourvoi - EN",
            "Synthèse": "Synthèse - EN",
            "Enseignement": "Enseignement - EN",
            "Numéro": "Numéro - EN",
            "Référence règlementaire": "Référence règlementaire - EN",
            "Formulaire": "Formulaire - EN",
            "Formulaire interactif": "Formulaire interactif - EN",
            "Déclaration en ligne": "Déclaration en ligne - EN",
            "Notice technique": "Notice technique - EN",
            "Afficher par": "Afficher par - EN",
            "Filtrer": "Filtrer - EN",
            "Titre": "Titre - EN",
            "Texte réglementaire (PDF)": "Texte réglementaire (PDF) - EN",
            "Domaine principal": "Domaine principal - EN",
            "Sous-domaine principal": "Sous-domaine principal - EN",
            "Thème principal": "Thème principal - EN",
            "Points de veilles": "Points de veilles - EN",
            "Points de veille": "Points de veille - EN",
            "Émetteur": "Émetteur - EN",
            "Découpé en exigences": "Découpé en exigences - EN",
            "Degrés d'impact du texte corporel": "Degrés d'impact du texte corporel - EN",
            "Degrés d'impact du texte juridique": "Degrés d'impact du texte juridique - EN",
            "Degrés d'impact du texte médiatique": "Degrés d'impact du texte médiatique - EN",
            "Degrés d'impact du texte financier": "Degrés d'impact du texte financier - EN",
            "Détail fiche publique": "Détail fiche publique - EN",
            "Exporter en PDF": "Exporter en PDF - EN",
            "Email": "Email - EN",
            "Civilité": "Civilité - EN",
            "N° de téléphone": "N° de téléphone - EN",
            "Fonction": "Fonction - EN",
            "Alertes": "Alertes - EN",
            "Activer les alertes": "Activer les alertes - EN",
            "Fréquence des alertes": "Fréquence des alertes - EN",
            "Format des alertes": "Format des alertes - EN",
            "Etat d'avancement de la fiche": "Etat d'avancement de la fiche - EN",
            "Accès à tous les domaines, sous domaines, thèmes": "Accès à tous les domaines, sous domaines, thèmes - EN",
            "Restriction des alertes aux domaines/sous-domaines/thèmes": "Restriction des alertes aux domaines/sous-domaines/thèmes - EN",
            "Activation des alertes sur les dates de revue des fiches": "Activation des alertes sur les dates de revue des fiches - EN",
            "Activation des alertes sur les dates limite de réalisation des actions": "Activation des alertes sur les dates limite de réalisation des actions - EN",
            "Réception de la newsletter de l'espace documentaire": "Réception de la newsletter de l'espace documentaire - EN",
            "Utilisateur actif": "Utilisateur actif - EN",
            "Profil": "Profil - EN",
            "Edition d'un utilisateur": "Edition d'un utilisateur - EN",
            "Configuration de l'utilisateur": "Configuration de l'utilisateur - EN",
            "Information sur le compte": "Information sur le compte - EN",
            "Paramètres": "Paramètres - EN",
            "Actif": "Actif - EN",
            "Téléphone": "Téléphone - EN",
            "Effectif": "Effectif - EN",
            "Domaine d'activité": "Domaine d'activité - EN",
            "Configuration des droits": "Configuration des droits - EN",
            "Profils": "Profils - EN",
            "Configuration des utilisateurs": "Configuration des utilisateurs - EN",
            "Consultation de l'arborescence": "Consultation de l'arborescence - EN",
            "Accès au profil": "Accès au profil - EN",
            "Accès en écriture": "Accès en écriture - EN",
            "Hotline en écriture": "Hotline en écriture - EN",
            "Accès aux points de veille": "Accès aux points de veille - EN",
            "Libellé des catégories": "Libellé des catégories - EN",
            "Libellé des sous catégories": "Libellé des sous catégories - EN",
            "Libellé": "Libellé - EN",
            "Parent": "Parent - EN",
            "Droit d'accès": "Droit d'accès - EN",
            "Utilisateur": "Utilisateur - EN",
            "Accès": "Accès - EN",
            "Affecter l'utilisateur": "Affecter l'utilisateur - EN",
            "Configuration des utilisateurs du point de veille": "Configuration des utilisateurs du point de veille - EN",
            "Informations": "Informations - EN",
            "Configuration des utilisateurs du noeud": "Configuration des utilisateurs du noeud - EN",
            "Arborescence et point de veille": "Arborescence et point de veille - EN",
            "Numéro d'action": "Numéro d'action - EN",
            "Statut": "Statut - EN",
            "Point de veille": "Point de veille - EN",
            "Auteur": "Auteur - EN",
            "Date de clôture": "Date de clôture - EN",
            "Date de réalisation": "Date de réalisation - EN",
            "Masqué": "Masqué - EN",
            "Code regroupement": "Code regroupement - EN",
            "Date de texte": "Date de texte - EN",
            "Texte découpé en exigences": "Texte découpé en exigences - EN",
            "Date de revue de l'exigence": "Date de revue de l'exigence - EN",
            "Date d'évaluation de l'exigence": "Date d'évaluation de l'exigence - EN",
            "Conformité de l'exigence": "Conformité de l'exigence - EN",
            "Priorité de l'exigence": "Priorité de l'exigence - EN",
            "État d'applicabilité de l'exigence": "État d'applicabilité de l'exigence - EN",
            "Configurer": "Configurer - EN",
            "Edition d'une action": "Edition d'une action - EN",
            "configuration de l'action": "configuration de l'action - EN",
            "Informations générales": "Informations générales - EN",
            "Point de veilles": "Point de veilles - EN",
            "Fiches": "Fiches - EN",
            "Planification": "Planification - EN",
            "Libellé de l'action": "Libellé de l'action - EN",
            "Action détaillée": "Action détaillée - EN",
            "Cout": "Cout - EN",
            "Envoyer l'alerte": "Envoyer l'alerte - EN",
            "Fichier(s) lié(s)": "Fichier(s) lié(s) - EN",
            "Réalisation": "Réalisation - EN",
            "CR de réalisation": "CR de réalisation - EN",
            "Evaluation": "Evaluation - EN",
            "CR d’évaluation": "CR d’évaluation - EN",
            "Date d’évaluation": "Date d’évaluation - EN",
            "Cloture": "Cloture - EN",
            "Supprimer": "Supprimer - EN",
            "Libellé champ personnalisable 1 du plan d'action": "Libellé champ personnalisable 1 du plan d'action - EN",
            "Libellé champ personnalisable 2 du plan d'action": "Libellé champ personnalisable 2 du plan d'action - EN",
            "Date de revue par défaut": "Date de revue par défaut - EN",
            "Configuration des domaines/sous-domaines/thèmes": "Configuration des domaines/sous-domaines/thèmes - EN",
            "Traitement de fiche en multi points de veille": "Traitement de fiche en multi points de veille - EN",
            "Commentaire siège": "Commentaire siège - EN",
            "Masquée": "Masquée - EN",
            "Affecté": "Affecté - EN",
            "Libellé de la recherche": "Libellé de la recherche - EN",
            "Mode": "Mode - EN",
            "Charger": "Charger - EN",
            "Commentaire consultant": "Commentaire consultant - EN",
            "Mode de gestion": "Mode de gestion - EN",
            "Fichier(s) joint(s)": "Fichier(s) joint(s) - EN",
            "Joindre un fichier": "Joindre un fichier - EN",
            "Visibilité": "Visibilité - EN",
            "Autres informations": "Autres informations - EN",
            "Export PDF": "Export PDF - EN",
            "Plage de date": "Plage de date - EN",
            "Indicateur": "Indicateur - EN",
            "Axe de présentation": "Axe de présentation - EN",
            "Domaines / Sous domaines": "Domaines / Sous domaines - EN",
            "Chargement de la question": "Chargement de la question - EN",
            "Enregistrer et envoyer": "Enregistrer et envoyer - EN",
            "Confidentiel": "Confidentiel - EN",
            "Date et heure de la question": "Date et heure de la question - EN",
            "Question": "Question - EN",
            "Complément question": "Complément question - EN",
            "Pièces-jointes": "Pièces-jointes - EN",
            "Domaines": "Domaines - EN",
            "Réponse": "Réponse - EN",
            "Auteur de la réponse": "Auteur de la réponse - EN",
            "Date et heure de la réponse": "Date et heure de la réponse - EN",
            "Information sur la fiche": "Information sur la fiche - EN",
            "Inclure dans l'alerte mail": "Inclure dans l'alerte mail - EN",
            "Affecter": "Affecter - EN",
            "Date et heure": "Date et heure - EN",
            "Type": "Type - EN",
            "Information": "Information - EN",
            "Données": "Données - EN",
            "Fiches liés": "Fiches liés - EN",
            "Documents et liens": "Documents et liens - EN",
            "Niveau technique": "Niveau technique - EN",
            "Commentaire technique": "Commentaire technique - EN",
            "Échéances": "Échéances - EN",
            "Codification du texte": "Codification du texte - EN",
            "Degrés d'impact corporel": "Degrés d'impact corporel - EN",
            "Degrés d'impact juridique": "Degrés d'impact juridique - EN",
            "Degrés d'impact médiatique": "Degrés d'impact médiatique - EN",
            "Degrés d'impact financier": "Degrés d'impact financier - EN",
            "Impacts du texte": "Impacts du texte - EN",
            "Informations complémentaires": "Informations complémentaires - EN",
            "Condidentiel client": "Condidentiel client - EN",
            "Commentaire consultant type": "Commentaire consultant type - EN",
            "Auteur dernière modification niveau synthèse": "Auteur dernière modification niveau synthèse - EN",
            "Date de dernière modification niveau synthèse": "Date de dernière modification niveau synthèse - EN",
            "Auteur dernière modification niveau technique": "Auteur dernière modification niveau technique - EN",
            "Date de dernière modification niveau technique": "Date de dernière modification niveau technique - EN",
            "Sanctions": "Sanctions - EN",
            "Actions à réaliser": "Actions à réaliser - EN",
            "Communication interne": "Communication interne - EN",
            "Note interne": "Note interne - EN",
            "Tâches clients": "Tâches clients - EN",
            "Contrôle à effectuer": "Contrôle à effectuer - EN",
            "Document à faire": "Document à faire - EN",
            "Périodicité": "Périodicité - EN",
            "Trier par": "Trier par - EN",
            "Choisir données à afficher": "Choisir données à afficher - EN",
            "Accès validé. Vous allez être redirigé.": "Accès validé. Vous allez être redirigé. - EN",
            "Impossible de valider l'accès.": "Impossible de valider l'accès. - EN",
            "Erreur durant la création de l'export.": "Erreur durant la création de l'export. - EN",
            "Enregistrement effectué.": "Enregistrement effectué. - EN",
            "Une erreur est survenue lors de la récupération de la fiche": "Une erreur est survenue lors de la récupération de la fiche - EN",
            "Enregistrement impossible, veuillez corriger préalablement les erreurs.": "Enregistrement impossible, veuillez corriger préalablement les erreurs. - EN",
            "Utilisateur désactivé": "Utilisateur désactivé - EN",
            "Utilisateurs modifiés": "Utilisateurs modifiés - EN",
            "Paramétrage effectué": "Paramétrage effectué - EN",
            "Accès créés.": "Accès créés. - EN",
            "Erreur durant la recherche des points de veilles assignables.": "Erreur durant la recherche des points de veilles assignables. - EN",
            "Cet email correspond à un utilisateur interne. Vous ne pouvez pas l'utiliser.": "Cet email correspond à un utilisateur interne. Vous ne pouvez pas l'utiliser. - EN",
            "Une erreur est survenue lors de la récupération de l'utilisateur": "Une erreur est survenue lors de la récupération de l'utilisateur - EN",
            "Catégorie supprimée": "Catégorie supprimée - EN",
            "Impossible de supprimer la catégorie, elle est utilisée ou possède une sous-catégorie.": "Impossible de supprimer la catégorie, elle est utilisée ou possède une sous-catégorie. - EN",
            "Une erreur est survenue lors de la récupération de la catégorie": "Une erreur est survenue lors de la récupération de la catégorie - EN",
            "Droit d'accès mise à jour": "Droit d'accès mise à jour - EN",
            "Utilisateur affecté": "Utilisateur affecté - EN",
            "Mise à jour effectué": "Mise à jour effectué - EN",
            "Aucune sélection": "Aucune sélection - EN",
            "Veuillez indiquer un status": "Veuillez indiquer un status - EN",
            "Veuillez indiquer un pilote": "Veuillez indiquer un pilote - EN",
            "Veuillez indiquer une date": "Veuillez indiquer une date - EN",
            "Action correctement supprimée.": "Action correctement supprimée. - EN",
            "Une erreur est survenue lors de la récupération de l'action": "Une erreur est survenue lors de la récupération de l'action - EN",
            "Erreur durant la redirection": "Erreur durant la redirection - EN",
            "Authentification réussi.": "Authentification réussi. - EN",
            "Impossible de récupérer les informations du compte ou de l'utilisateur": "Impossible de récupérer les informations du compte ou de l'utilisateur - EN",
            "Veuillez sélectionnez les données sur lesquelles créer une action": "Veuillez sélectionnez les données sur lesquelles créer une action - EN",
            "L'export a été initié, vous serez notifié lorsqu'il sera disponible.": "L'export a été initié, vous serez notifié lorsqu'il sera disponible. - EN",
            "Périmètre de recherche trop important, veuillez le réduire.": "Périmètre de recherche trop important, veuillez le réduire. - EN",
            "Enregistrement effectuée": "Enregistrement effectuée - EN",
            "Recherche supprimée.": "Recherche supprimée. - EN",
            "Une erreur est apparue.": "Une erreur est apparue. - EN",
            "Impossible de récupérer le commentaire": "Impossible de récupérer le commentaire - EN",
            "Une erreur est survenue lors de la récupération de la question": "Une erreur est survenue lors de la récupération de la question - EN",
            "Traitement effectué": "Traitement effectué - EN",
            "Fiche affectée.": "Fiche affectée. - EN",
            "Retirer des favoris": "Retirer des favoris - EN",
            "Ajouter aux favoris": "Ajouter aux favoris - EN",
            "Une erreur est survenue lors de la suppression des favoris": "Une erreur est survenue lors de la suppression des favoris - EN",
            "Une erreur est survenue lors de l'ajout aux favoris": "Une erreur est survenue lors de l'ajout aux favoris - EN",
            "Une erreur est survenue lors du téléversement": "Une erreur est survenue lors du téléversement - EN",
            "Voir l'exigence": "Voir l'exigence - EN",
            "Voir la fiche": "Voir la fiche - EN",
            "Liste des exigences": "Liste des exigences - EN",
            "Contrôles et documents obligatoires": "Contrôles et documents obligatoires - EN",
            "Télécharger le PDF": "Télécharger le PDF - EN",
            "Liste de documents": "Liste de documents - EN",
            "Accueil espace documentaire": "Accueil espace documentaire - EN",
            "Actualité documentaire": "Actualité documentaire - EN",
            "Formulaires et déclaration": "Formulaires et déclaration - EN",
            "Jurisprudences": "Jurisprudences - EN",
            "Dossiers et fiches thématiques": "Dossiers et fiches thématiques - EN",
            "Contrôles réglementaires": "Contrôles réglementaires - EN",
            "Projet de texte": "Projet de texte - EN",
            "Flash d'information": "Flash d'information - EN",
            "Newsletter": "Newsletter - EN",
            "Document privé": "Document privé - EN",
            "Visualiser": "Visualiser - EN",
            "S'affecter": "S'affecter - EN",
            "Recherche de texte": "Recherche de texte - EN",
            "Point de veille expiré ou désactivé": "Point de veille expiré ou désactivé - EN",
            "Modifier": "Modifier - EN",
            "Désactiver": "Désactiver - EN",
            "Relancer l'invitation": "Relancer l'invitation - EN",
            "Liste des utilisateurs": "Liste des utilisateurs - EN",
            "Ajouter une sous-catégorie": "Ajouter une sous-catégorie - EN",
            "Gestion des catégories et sous-catégories": "Gestion des catégories et sous-catégories - EN",
            "Voir l'utilisateur": "Voir l'utilisateur - EN",
            "Editer": "Editer - EN",
            "Liste des actions": "Liste des actions - EN",
            "Configuration de l'action": "Configuration de l'action - EN",
            "Voir/Editer les commentaires": "Voir/Editer les commentaires - EN",
            "Affecter la fiche à d'autres points de veille.": "Affecter la fiche à d'autres points de veille. - EN",
            "Consulter le texte": "Consulter le texte - EN",
            "Traiter la fiche en multi point de veille": "Traiter la fiche en multi point de veille - EN",
            "Traiter la fiche": "Traiter la fiche - EN",
            "Tableau de bord / Statistiques": "Tableau de bord / Statistiques - EN",
            "Planifié": "Planifié - EN",
            "Réalisé": "Réalisé - EN",
            "Evalué": "Evalué - EN",
            "Clôturé": "Clôturé - EN",
            "Pour information": "Pour information - EN",
            "Non applicable": "Non applicable - EN",
            "Modifier la question": "Modifier la question - EN",
            "Consulter la question": "Consulter la question - EN",
            "Liste hotline": "Liste hotline - EN",
            "Retour à l'accueil": "Retour à l'accueil - EN",
            "Aide": "Aide - EN",
            "OneApave": "OneApave - EN",
            "Export de fiches affectées": "Export de fiches affectées - EN",
            "Export hotline": "Export hotline - EN",
            "Export recherche avancée": "Export recherche avancée - EN",
            "Export tableau de bord": "Export tableau de bord - EN",
            "Exports d'historique de connexions": "Exports d'historique de connexions - EN",
            "Fond documentaire": "Fond documentaire - EN",
            "Détail": "Détail - EN",
            "Accès refusé": "Accès refusé - EN",
            "Conditions générales d'utilisation": "Conditions générales d'utilisation - EN",
            "Erreur": "Erreur - EN",
            "Afficher/cacher le mot de passe": "Afficher/cacher le mot de passe - EN",
            "Vider le champ": "Vider le champ - EN",
            "Première page": "Première page - EN",
            "Page précédente": "Page précédente - EN",
            "Page suivante": "Page suivante - EN",
            "Dernière page": "Dernière page - EN",
            "Fermer": "Fermer - EN",
            "Déplier / replier": "Déplier / replier - EN",
            "Tout sélectionner": "Tout sélectionner - EN",
            "Tout désélectionner": "Tout désélectionner - EN",
            "conditions générales d'utilisations": "conditions générales d'utilisations - EN",
            "Annuler": "Annuler - EN",
            "Voir tous les documents": "Voir tous les documents - EN",
            "Configurer la réception des alertes": "Configurer la réception des alertes - EN",
            "Assigner le point de veille": "Assigner le point de veille - EN",
            "Vérifier": "Vérifier - EN",
            "Retour à l'arborescence": "Retour à l'arborescence - EN",
            "Se re-connecter": "Se re-connecter - EN",
            "Retour à la veille reglementaire": "Retour à la veille reglementaire - EN",
            "toggleFilter(!filterShow)}>Recherche": "toggleFilter(!filterShow)}>Recherche - EN",
            "Configuration": "Configuration - EN",
            "Changer de compte": "Changer de compte - EN",
            "Se déconnecter": "Se déconnecter - EN",
            "One Apave": "One Apave - EN",
            "Je souhaite avoir des renseignements ou bénéficier d’une démo": "Je souhaite avoir des renseignements ou bénéficier d’une démo - EN",
            "Configuration d'utilisateur": "Configuration d'utilisateur - EN",
            "Tableau de bord / statistiques": "Tableau de bord / statistiques - EN",
            "Déconnexion": "Déconnexion - EN",
            "Utilisateurs": "Utilisateurs - EN",
            "Nouvel utilisateur": "Nouvel utilisateur - EN",
            "Modification utilisateur": "Modification utilisateur - EN",
            "Catégories": "Catégories - EN",
            "Création": "Création - EN",
            "Edition": "Edition - EN",
            "Historique": "Historique - EN",
            "Création d'une action": "Création d'une action - EN",
            "Modification d'une action": "Modification d'une action - EN",
            "Edition fiche privée": "Edition fiche privée - EN",
            "Conformité d'une fiche": "Conformité d'une fiche - EN",
            "Edition hotline": "Edition hotline - EN",
            "Jurisprudence": "Jurisprudence - EN",
            "Module spécifique client": "Module spécifique client - EN",
            "Formulaire / déclaration (CERFA et notice)": "Formulaire / déclaration (CERFA et notice) - EN",
            "Contrôle règlementaire": "Contrôle règlementaire - EN",
            "Organigramme règlementaire": "Organigramme règlementaire - EN",
            "Sanction": "Sanction - EN",
            "Sélection du compte": "Sélection du compte - EN",
            "Validation d'un utilisateur": "Validation d'un utilisateur - EN",
            "Maintenance": "Maintenance - EN",
            "Séparé : Une fiche par mail": "Séparé : Une fiche par mail - EN",
            "Groupé : Fiches groupées dans un mail": "Groupé : Fiches groupées dans un mail - EN",
            "Alerte lorsque la fiche est finalisée": "Alerte lorsque la fiche est finalisée - EN",
            "Alerte dès que la fiche est affectée": "Alerte dès que la fiche est affectée - EN",
            "Quotidienne": "Quotidienne - EN",
            "Quotidienne + Récapitulatif mensuel": "Quotidienne + Récapitulatif mensuel - EN",
            "Hebdomadaire": "Hebdomadaire - EN",
            "Hebdomadaire + Récapitulatif mensuel": "Hebdomadaire + Récapitulatif mensuel - EN",
            "Bimensuelle": "Bimensuelle - EN",
            "Mensuelle": "Mensuelle - EN",
            "Vous avez des modifications non enregistrées, voulez-vous vraiment quitter ?": "Vous avez des modifications non enregistrées, voulez-vous vraiment quitter ? - EN",
            "Export des exigences affectées": "Export des exigences affectées - EN",
            "A statuer": "A statuer - EN",
            "A traiter": "A traiter - EN",
            "Finalisée": "Finalisée - EN",
            "Archivée": "Archivée - EN",
            "Fiches liées": "Fiches liées - EN",
            "Activer masquage": "Activer masquage - EN",
            "Désactiver masquage": "Désactiver masquage - EN",
            "Modifier la conformité": "Modifier la conformité - EN",
            "Modifier la priorité": "Modifier la priorité - EN",
            "Modifier la date de revue": "Modifier la date de revue - EN",
            "Modifier la date d'évaluation": "Modifier la date d'évaluation - EN",
            "Créer une action": "Créer une action - EN",
            "Commentaire client": "Commentaire client - EN",
            "Monsieur": "Monsieur - EN",
            "Madame": "Madame - EN",
            "Administrateur client": "Administrateur client - EN",
            "Utilisateur simple": "Utilisateur simple - EN",
            "Configuration des alertes": "Configuration des alertes - EN",
            "Simple": "Simple - EN",
            "Avec exigences": "Avec exigences - EN",
            "Suivi": "Suivi - EN",
            "Commentaires": "Commentaires - EN",
            "Avancement plan d'action": "Avancement plan d'action - EN",
            "Global": "Global - EN",
            "Domaine": "Domaine - EN",
            "Sous-domaine": "Sous-domaine - EN",
            "Thème": "Thème - EN",
            "Trimestriel": "Trimestriel - EN",
            "Annuel": "Annuel - EN",
            "Ne pas modifier": "Ne pas modifier - EN",
            "Aucune": "Aucune - EN",
            "P1": "P1 - EN",
            "P2": "P2 - EN",
            "P3": "P3 - EN",
            "P4": "P4 - EN",
            "Construction / Immobilier / Habitat": "Construction / Immobilier / Habitat - EN",
            "Distribution / Commerce": "Distribution / Commerce - EN",
            "Transport / Logistique": "Transport / Logistique - EN",
            "Gouvernement / administration publiques / Enseignement": "Gouvernement / administration publiques / Enseignement - EN",
            "Etablissements social et santé": "Etablissements social et santé - EN",
            "Energie / environnement / eau / Facility managment": "Energie / environnement / eau / Facility managment - EN",
            "Hotellerie / Loisirs": "Hotellerie / Loisirs - EN",
            "Services : Banque Assurances &amp; services / Autres services": "Services : Banque Assurances &amp; services / Autres services - EN",
            "Télécom": "Télécom - EN",
            "Aérospatial / armement": "Aérospatial / armement - EN",
            "Industrie Transport & équipementiers (pneu, ...)": "Industrie Transport & équipementiers (pneu, ...) - EN",
            "Pharmacie / Chimie / Phyto / Agroalimentaire": "Pharmacie / Chimie / Phyto / Agroalimentaire - EN",
            "Pétrole et gas (raffinerie / extraction / distribution / stockage)": "Pétrole et gas (raffinerie / extraction / distribution / stockage) - EN",
            "Nucléaire (filière Areva, IRSN, CEA, CNPE, ...)": "Nucléaire (filière Areva, IRSN, CEA, CNPE, ...) - EN",
            "Industries (Equipement, électrique, extractives)": "Industries (Equipement, électrique, extractives) - EN",
            "Autres (dont agriculture, particuliers, sans NAF, ...)": "Autres (dont agriculture, particuliers, sans NAF, ...) - EN",
            "Choix 3": "Choix 3 - EN",
            "Choix 2": "Choix 2 - EN",
            "Choix 1": "Choix 1 - EN",
            "Par exigence": "Par exigence - EN",
            "Numéro de la fiche": "Numéro de la fiche - EN",
            "Titre de la fiche": "Titre de la fiche - EN",
            "Domaines / sous domaines / thèmes": "Domaines / sous domaines / thèmes - EN",
            "Date de parution": "Date de parution - EN",
            "Nom / Prénom": "Nom / Prénom - EN",
            "Date de dernière connexion": "Date de dernière connexion - EN",
            "Invitation": "Invitation - EN",
            "Affecté à des points de veille": "Affecté à des points de veille - EN",
            "Prénom / Nom": "Prénom / Nom - EN",
            "Droit d’accès sur point de veille": "Droit d’accès sur point de veille - EN",
            "Paramétrage de l’accès": "Paramétrage de l’accès - EN",
            "Accès à la fiche utilisateur": "Accès à la fiche utilisateur - EN",
            "Droit d’accès sur le noeud": "Droit d’accès sur le noeud - EN",
            "Libellé d'action": "Libellé d'action - EN",
            "Coût": "Coût - EN",
            "Pièces jointes": "Pièces jointes - EN",
            "Statut de la conformité": "Statut de la conformité - EN",
            "Nombre d'actions": "Nombre d'actions - EN",
            "Date de la question": "Date de la question - EN",
            "Date de la réponse": "Date de la réponse - EN",
            "Domaines / sous domaines": "Domaines / sous domaines - EN",
            "Type d'action": "Type d'action - EN",
        }
    },
    "ar": {
        translation: {
            "Retour à Pilot-Veille": "Retour à Pilot-Veille - AR",
            "Accueil": "Accueil - AR",
            "Ma veille": "Ma veille - AR",
            "Exigences": "Exigences - AR",
            "Plan d'action": "Plan d'action - AR",
            "Hotline": "Hotline - AR",
            "Statistiques": "Statistiques - AR",
            "Rechercher un texte": "Rechercher un texte - AR",
            "Espace documentaire": "Espace documentaire - AR",
            "Mon profil": "Mon profil - AR",
            "Gestion des utilisateurs": "Gestion des utilisateurs - AR",
            "Gestion des utilisateurs en liste": "Gestion des utilisateurs en liste - AR",
            "En liste": "En liste - AR",
            "Gestion des utilisateurs par point de veille": "Gestion des utilisateurs par point de veille - AR",
            "Par point de veille": "Par point de veille - AR",
            "Paramètre": "Paramètre - AR",
            "Configuration du compte": "Configuration du compte - AR",
            "Gestion des catégories": "Gestion des catégories - AR",
            "Mes exports": "Mes exports - AR",
            "Prénom": "Prénom - AR",
            "Nom": "Nom - AR",
            "Compte": "Compte - AR",
            "Recherche libre": "Recherche libre - AR",
            "Numéro de fiche": "Numéro de fiche - AR",
            "Mode gestion": "Mode gestion - AR",
            "Conformité": "Conformité - AR",
            "Domaine / Sous domaine / Thème": "Domaine / Sous domaine / Thème - AR",
            "Type de texte": "Type de texte - AR",
            "Point de veille ou noeud": "Point de veille ou noeud - AR",
            "Date du texte": "Date du texte - AR",
            "Date de publication": "Date de publication - AR",
            "Date d'évaluation": "Date d'évaluation - AR",
            "Date de revue": "Date de revue - AR",
            "Texte majeur": "Texte majeur - AR",
            "Fiche avec action": "Fiche avec action - AR",
            "Ajouter un commentaire": "Ajouter un commentaire - AR",
            "Fiche masquée": "Fiche masquée - AR",
            "Fiche privée": "Fiche privée - AR",
            "Priorité": "Priorité - AR",
            "Pilote": "Pilote - AR",
            "Favoris": "Favoris - AR",
            "Numéro de texte": "Numéro de texte - AR",
            "Numéro NOR": "Numéro NOR - AR",
            "Texte abrogé": "Texte abrogé - AR",
            "Découpé en exigence": "Découpé en exigence - AR",
            "Source": "Source - AR",
            "Emetteur": "Emetteur - AR",
            "Descripteurs": "Descripteurs - AR",
            "Impact du texte": "Impact du texte - AR",
            "Acteurs concernés": "Acteurs concernés - AR",
            "Fonctions concernées": "Fonctions concernées - AR",
            "Responsable de la dernière évaluation": "Responsable de la dernière évaluation - AR",
            "Degré d'impact corporel": "Degré d'impact corporel - AR",
            "Degré d'impact juridique": "Degré d'impact juridique - AR",
            "Degré d'impact médiatique": "Degré d'impact médiatique - AR",
            "Degré d'impact financier": "Degré d'impact financier - AR",
            "Date de mise en ligne": "Date de mise en ligne - AR",
            "Date de masquage": "Date de masquage - AR",
            "Date d'échéance": "Date d'échéance - AR",
            "Commentaire": "Commentaire - AR",
            "Des erreurs sont survenues": "Des erreurs sont survenues - AR",
            "Impossible de charger les données": "Impossible de charger les données - AR",
            "Impossible de charger les accès": "Impossible de charger les accès - AR",
            "Vous n'avez pas les droits suffisants. Veuillez revoir la sélection": "Vous n'avez pas les droits suffisants. Veuillez revoir la sélection - AR",
            "Votre sélection est vide": "Votre sélection est vide - AR",
            "Commentaire ajouté": "Commentaire ajouté - AR",
            "Nouveau": "Nouveau - AR",
            "A préciser": "A préciser - AR",
            "En écart": "En écart - AR",
            "Conforme": "Conforme - AR",
            "Choisir": "Choisir - AR",
            "Recherche": "Recherche - AR",
            "Recherche avancée": "Recherche avancée - AR",
            "L'utilisation des critères de recherche avancée peut augmenter de manière conséquente les temps de recherche": "L'utilisation des critères de recherche avancée peut augmenter de manière conséquente les temps de recherche - AR",
            "Réinitialiser la recherche": "Réinitialiser la recherche - AR",
            "Enregistrer la recherche": "Enregistrer la recherche - AR",
            "Mes recherches enregistrées": "Mes recherches enregistrées - AR",
            "Rechercher": "Rechercher - AR",
            "Tous": "Tous - AR",
            "Oui": "Oui - AR",
            "Non": "Non - AR",
            "Exporter": "Exporter - AR",
            "Sélectionner tous les éléments de la page courante ({{page}})": "Sélectionner tous les éléments de la page courante ({{page}}) - AR",
            "Enregistrer et fermer": "Enregistrer et fermer - AR",
            "Retour à la liste": "Retour à la liste - AR",
            "Connexion à Pilot Veille": "Connexion à Pilot Veille - AR",
            "Langue": "Langue - AR",
            "Identifiant": "Identifiant - AR",
            "Mot de passe": "Mot de passe - AR",
            "Mot de passe oublié ?": "Mot de passe oublié ? - AR",
            "Connexion": "Connexion - AR",
            "Connexion SSO": "Connexion SSO - AR",
            "Impossible de sélectionner le compte": "Impossible de sélectionner le compte - AR",
            "Veuillez sélectionner un compte": "Veuillez sélectionner un compte - AR",
            "Vous êtes associés à plusieurs comptes. Veuillez sélectionner celui à utiliser.": "Vous êtes associés à plusieurs comptes. Veuillez sélectionner celui à utiliser. - AR",
            "Choisir votre compte": "Choisir votre compte - AR",
            "Veuillez patienter, chargement de la liste des comptes accessibles en cours": "Veuillez patienter, chargement de la liste des comptes accessibles en cours - AR",
            "Votre utilisateur n'est associé à aucun compte valide. Veuillez contacter votre conseiller Apave.": "Votre utilisateur n'est associé à aucun compte valide. Veuillez contacter votre conseiller Apave. - AR",
            "Chargement des données et accès": "Chargement des données et accès - AR",
            "Chargement": "Chargement - AR",
            "Bienvenue sur Pilot Veille": "Bienvenue sur Pilot Veille - AR",
            "Dernières fiches": "Dernières fiches - AR",
            "Voir plus": "Voir plus - AR",
            "Voir moins": "Voir moins - AR",
            "Statistique": "Statistique - AR",
            "Détail conformité des points de veilles": "Détail conformité des points de veilles - AR",
            "Gérer ma conformité": "Gérer ma conformité - AR",
            "Gestion de conformité": "Gestion de conformité - AR",
            "Fiche(s) restante(s)": "Fiche(s) restante(s) - AR",
            "Fiche(s) à échéance": "Fiche(s) à échéance - AR",
            "Date revue inférieure à 30 jours": "Date revue inférieure à 30 jours - AR",
            "Action(s) à échéance": "Action(s) à échéance - AR",
            "Date limite de réalisation inférieure à 30 jours": "Date limite de réalisation inférieure à 30 jours - AR",
            "Consultant(s) du compte": "Consultant(s) du compte - AR",
            "Dernières actualités": "Dernières actualités - AR",
            "Voir toutes les actualités": "Voir toutes les actualités - AR",
            "Voir l'actualité": "Voir l'actualité - AR",
            "Mes actions en cours": "Mes actions en cours - AR",
            "Voir l'action": "Voir l'action - AR",
            "Date de création": "Date de création - AR",
            "Date limite de réalisation": "Date limite de réalisation - AR",
            "Voir tout le plan d'actions": "Voir tout le plan d'actions - AR",
            "Dernières questions hotline": "Dernières questions hotline - AR",
            "Voir la question": "Voir la question - AR",
            "Voir toutes mes questions": "Voir toutes mes questions - AR",
            "Créer une fiche privée": "Créer une fiche privée - AR",
            "Confirmation": "Confirmation - AR",
            "Valider": "Valider - AR",
            "Masquage": "Masquage - AR",
            "Fichier à importer": "Fichier à importer - AR",
            "Importer": "Importer - AR",
            "Numéro d'exigence": "Numéro d'exigence - AR",
            "Etat d'applicabilité": "Etat d'applicabilité - AR",
            "Sommaire": "Sommaire - AR",
            "Exigence masquée": "Exigence masquée - AR",
            "Noeud/Point de veille": "Noeud/Point de veille - AR",
            "Domaines / Sous domaines / Thèmes": "Domaines / Sous domaines / Thèmes - AR",
            "Exigence avec action": "Exigence avec action - AR",
            "Conformité de la fiche": "Conformité de la fiche - AR",
            "Priorité de la fiche": "Priorité de la fiche - AR",
            "Date de revue de la fiche": "Date de revue de la fiche - AR",
            "Date d'évaluation de la fiche": "Date d'évaluation de la fiche - AR",
            "Enregistrer les modifications": "Enregistrer les modifications - AR",
            "Conformité d'une exigence": "Conformité d'une exigence - AR",
            "Informations publiques": "Informations publiques - AR",
            "Niveau général": "Niveau général - AR",
            "Exigence": "Exigence - AR",
            "Article": "Article - AR",
            "Emplacement dans l'arborescence": "Emplacement dans l'arborescence - AR",
            "Date de début d'applicabilité": "Date de début d'applicabilité - AR",
            "Date de fin d'applicabilité": "Date de fin d'applicabilité - AR",
            "Statut de conformité": "Statut de conformité - AR",
            "Enregistrer": "Enregistrer - AR",
            "Enregistrer et passer à la conformité suivante": "Enregistrer et passer à la conformité suivante - AR",
            "Type de contrôle": "Type de contrôle - AR",
            "Date": "Date - AR",
            "Type de document": "Type de document - AR",
            "Document": "Document - AR",
            "Fichier pdf": "Fichier pdf - AR",
            "Description": "Description - AR",
            "Image": "Image - AR",
            "Fiche": "Fiche - AR",
            "Lien internet": "Lien internet - AR",
            "Date de dernier envoi": "Date de dernier envoi - AR",
            "Juridiction": "Juridiction - AR",
            "Décision": "Décision - AR",
            "Date de la décision": "Date de la décision - AR",
            "Numéro de pourvoi": "Numéro de pourvoi - AR",
            "Synthèse": "Synthèse - AR",
            "Enseignement": "Enseignement - AR",
            "Numéro": "Numéro - AR",
            "Référence règlementaire": "Référence règlementaire - AR",
            "Formulaire": "Formulaire - AR",
            "Formulaire interactif": "Formulaire interactif - AR",
            "Déclaration en ligne": "Déclaration en ligne - AR",
            "Notice technique": "Notice technique - AR",
            "Afficher par": "Afficher par - AR",
            "Filtrer": "Filtrer - AR",
            "Titre": "Titre - AR",
            "Texte réglementaire (PDF)": "Texte réglementaire (PDF) - AR",
            "Domaine principal": "Domaine principal - AR",
            "Sous-domaine principal": "Sous-domaine principal - AR",
            "Thème principal": "Thème principal - AR",
            "Points de veilles": "Points de veilles - AR",
            "Points de veille": "Points de veille - AR",
            "Émetteur": "Émetteur - AR",
            "Découpé en exigences": "Découpé en exigences - AR",
            "Degrés d'impact du texte corporel": "Degrés d'impact du texte corporel - AR",
            "Degrés d'impact du texte juridique": "Degrés d'impact du texte juridique - AR",
            "Degrés d'impact du texte médiatique": "Degrés d'impact du texte médiatique - AR",
            "Degrés d'impact du texte financier": "Degrés d'impact du texte financier - AR",
            "Détail fiche publique": "Détail fiche publique - AR",
            "Exporter en PDF": "Exporter en PDF - AR",
            "Email": "Email - AR",
            "Civilité": "Civilité - AR",
            "N° de téléphone": "N° de téléphone - AR",
            "Fonction": "Fonction - AR",
            "Alertes": "Alertes - AR",
            "Activer les alertes": "Activer les alertes - AR",
            "Fréquence des alertes": "Fréquence des alertes - AR",
            "Format des alertes": "Format des alertes - AR",
            "Etat d'avancement de la fiche": "Etat d'avancement de la fiche - AR",
            "Accès à tous les domaines, sous domaines, thèmes": "Accès à tous les domaines, sous domaines, thèmes - AR",
            "Restriction des alertes aux domaines/sous-domaines/thèmes": "Restriction des alertes aux domaines/sous-domaines/thèmes - AR",
            "Activation des alertes sur les dates de revue des fiches": "Activation des alertes sur les dates de revue des fiches - AR",
            "Activation des alertes sur les dates limite de réalisation des actions": "Activation des alertes sur les dates limite de réalisation des actions - AR",
            "Réception de la newsletter de l'espace documentaire": "Réception de la newsletter de l'espace documentaire - AR",
            "Utilisateur actif": "Utilisateur actif - AR",
            "Profil": "Profil - AR",
            "Edition d'un utilisateur": "Edition d'un utilisateur - AR",
            "Configuration de l'utilisateur": "Configuration de l'utilisateur - AR",
            "Information sur le compte": "Information sur le compte - AR",
            "Paramètres": "Paramètres - AR",
            "Actif": "Actif - AR",
            "Téléphone": "Téléphone - AR",
            "Effectif": "Effectif - AR",
            "Domaine d'activité": "Domaine d'activité - AR",
            "Configuration des droits": "Configuration des droits - AR",
            "Profils": "Profils - AR",
            "Configuration des utilisateurs": "Configuration des utilisateurs - AR",
            "Consultation de l'arborescence": "Consultation de l'arborescence - AR",
            "Accès au profil": "Accès au profil - AR",
            "Accès en écriture": "Accès en écriture - AR",
            "Hotline en écriture": "Hotline en écriture - AR",
            "Accès aux points de veille": "Accès aux points de veille - AR",
            "Libellé des catégories": "Libellé des catégories - AR",
            "Libellé des sous catégories": "Libellé des sous catégories - AR",
            "Libellé": "Libellé - AR",
            "Parent": "Parent - AR",
            "Droit d'accès": "Droit d'accès - AR",
            "Utilisateur": "Utilisateur - AR",
            "Accès": "Accès - AR",
            "Affecter l'utilisateur": "Affecter l'utilisateur - AR",
            "Configuration des utilisateurs du point de veille": "Configuration des utilisateurs du point de veille - AR",
            "Informations": "Informations - AR",
            "Configuration des utilisateurs du noeud": "Configuration des utilisateurs du noeud - AR",
            "Arborescence et point de veille": "Arborescence et point de veille - AR",
            "Numéro d'action": "Numéro d'action - AR",
            "Statut": "Statut - AR",
            "Point de veille": "Point de veille - AR",
            "Auteur": "Auteur - AR",
            "Date de clôture": "Date de clôture - AR",
            "Date de réalisation": "Date de réalisation - AR",
            "Masqué": "Masqué - AR",
            "Code regroupement": "Code regroupement - AR",
            "Date de texte": "Date de texte - AR",
            "Texte découpé en exigences": "Texte découpé en exigences - AR",
            "Date de revue de l'exigence": "Date de revue de l'exigence - AR",
            "Date d'évaluation de l'exigence": "Date d'évaluation de l'exigence - AR",
            "Conformité de l'exigence": "Conformité de l'exigence - AR",
            "Priorité de l'exigence": "Priorité de l'exigence - AR",
            "État d'applicabilité de l'exigence": "État d'applicabilité de l'exigence - AR",
            "Configurer": "Configurer - AR",
            "Edition d'une action": "Edition d'une action - AR",
            "configuration de l'action": "configuration de l'action - AR",
            "Informations générales": "Informations générales - AR",
            "Point de veilles": "Point de veilles - AR",
            "Fiches": "Fiches - AR",
            "Planification": "Planification - AR",
            "Libellé de l'action": "Libellé de l'action - AR",
            "Action détaillée": "Action détaillée - AR",
            "Cout": "Cout - AR",
            "Envoyer l'alerte": "Envoyer l'alerte - AR",
            "Fichier(s) lié(s)": "Fichier(s) lié(s) - AR",
            "Réalisation": "Réalisation - AR",
            "CR de réalisation": "CR de réalisation - AR",
            "Evaluation": "Evaluation - AR",
            "CR d’évaluation": "CR d’évaluation - AR",
            "Date d’évaluation": "Date d’évaluation - AR",
            "Cloture": "Cloture - AR",
            "Supprimer": "Supprimer - AR",
            "Libellé champ personnalisable 1 du plan d'action": "Libellé champ personnalisable 1 du plan d'action - AR",
            "Libellé champ personnalisable 2 du plan d'action": "Libellé champ personnalisable 2 du plan d'action - AR",
            "Date de revue par défaut": "Date de revue par défaut - AR",
            "Configuration des domaines/sous-domaines/thèmes": "Configuration des domaines/sous-domaines/thèmes - AR",
            "Traitement de fiche en multi points de veille": "Traitement de fiche en multi points de veille - AR",
            "Commentaire siège": "Commentaire siège - AR",
            "Masquée": "Masquée - AR",
            "Affecté": "Affecté - AR",
            "Libellé de la recherche": "Libellé de la recherche - AR",
            "Mode": "Mode - AR",
            "Charger": "Charger - AR",
            "Commentaire consultant": "Commentaire consultant - AR",
            "Mode de gestion": "Mode de gestion - AR",
            "Fichier(s) joint(s)": "Fichier(s) joint(s) - AR",
            "Joindre un fichier": "Joindre un fichier - AR",
            "Visibilité": "Visibilité - AR",
            "Autres informations": "Autres informations - AR",
            "Export PDF": "Export PDF - AR",
            "Plage de date": "Plage de date - AR",
            "Indicateur": "Indicateur - AR",
            "Axe de présentation": "Axe de présentation - AR",
            "Domaines / Sous domaines": "Domaines / Sous domaines - AR",
            "Chargement de la question": "Chargement de la question - AR",
            "Enregistrer et envoyer": "Enregistrer et envoyer - AR",
            "Confidentiel": "Confidentiel - AR",
            "Date et heure de la question": "Date et heure de la question - AR",
            "Question": "Question - AR",
            "Complément question": "Complément question - AR",
            "Pièces-jointes": "Pièces-jointes - AR",
            "Domaines": "Domaines - AR",
            "Réponse": "Réponse - AR",
            "Auteur de la réponse": "Auteur de la réponse - AR",
            "Date et heure de la réponse": "Date et heure de la réponse - AR",
            "Information sur la fiche": "Information sur la fiche - AR",
            "Inclure dans l'alerte mail": "Inclure dans l'alerte mail - AR",
            "Affecter": "Affecter - AR",
            "Date et heure": "Date et heure - AR",
            "Type": "Type - AR",
            "Information": "Information - AR",
            "Données": "Données - AR",
            "Fiches liés": "Fiches liés - AR",
            "Documents et liens": "Documents et liens - AR",
            "Niveau technique": "Niveau technique - AR",
            "Commentaire technique": "Commentaire technique - AR",
            "Échéances": "Échéances - AR",
            "Codification du texte": "Codification du texte - AR",
            "Degrés d'impact corporel": "Degrés d'impact corporel - AR",
            "Degrés d'impact juridique": "Degrés d'impact juridique - AR",
            "Degrés d'impact médiatique": "Degrés d'impact médiatique - AR",
            "Degrés d'impact financier": "Degrés d'impact financier - AR",
            "Impacts du texte": "Impacts du texte - AR",
            "Informations complémentaires": "Informations complémentaires - AR",
            "Condidentiel client": "Condidentiel client - AR",
            "Commentaire consultant type": "Commentaire consultant type - AR",
            "Auteur dernière modification niveau synthèse": "Auteur dernière modification niveau synthèse - AR",
            "Date de dernière modification niveau synthèse": "Date de dernière modification niveau synthèse - AR",
            "Auteur dernière modification niveau technique": "Auteur dernière modification niveau technique - AR",
            "Date de dernière modification niveau technique": "Date de dernière modification niveau technique - AR",
            "Sanctions": "Sanctions - AR",
            "Actions à réaliser": "Actions à réaliser - AR",
            "Communication interne": "Communication interne - AR",
            "Note interne": "Note interne - AR",
            "Tâches clients": "Tâches clients - AR",
            "Contrôle à effectuer": "Contrôle à effectuer - AR",
            "Document à faire": "Document à faire - AR",
            "Périodicité": "Périodicité - AR",
            "Trier par": "Trier par - AR",
            "Choisir données à afficher": "Choisir données à afficher - AR",
            "Accès validé. Vous allez être redirigé.": "Accès validé. Vous allez être redirigé. - AR",
            "Impossible de valider l'accès.": "Impossible de valider l'accès. - AR",
            "Erreur durant la création de l'export.": "Erreur durant la création de l'export. - AR",
            "Enregistrement effectué.": "Enregistrement effectué. - AR",
            "Une erreur est survenue lors de la récupération de la fiche": "Une erreur est survenue lors de la récupération de la fiche - AR",
            "Enregistrement impossible, veuillez corriger préalablement les erreurs.": "Enregistrement impossible, veuillez corriger préalablement les erreurs. - AR",
            "Utilisateur désactivé": "Utilisateur désactivé - AR",
            "Utilisateurs modifiés": "Utilisateurs modifiés - AR",
            "Paramétrage effectué": "Paramétrage effectué - AR",
            "Accès créés.": "Accès créés. - AR",
            "Erreur durant la recherche des points de veilles assignables.": "Erreur durant la recherche des points de veilles assignables. - AR",
            "Cet email correspond à un utilisateur interne. Vous ne pouvez pas l'utiliser.": "Cet email correspond à un utilisateur interne. Vous ne pouvez pas l'utiliser. - AR",
            "Une erreur est survenue lors de la récupération de l'utilisateur": "Une erreur est survenue lors de la récupération de l'utilisateur - AR",
            "Catégorie supprimée": "Catégorie supprimée - AR",
            "Impossible de supprimer la catégorie, elle est utilisée ou possède une sous-catégorie.": "Impossible de supprimer la catégorie, elle est utilisée ou possède une sous-catégorie. - AR",
            "Une erreur est survenue lors de la récupération de la catégorie": "Une erreur est survenue lors de la récupération de la catégorie - AR",
            "Droit d'accès mise à jour": "Droit d'accès mise à jour - AR",
            "Utilisateur affecté": "Utilisateur affecté - AR",
            "Mise à jour effectué": "Mise à jour effectué - AR",
            "Aucune sélection": "Aucune sélection - AR",
            "Veuillez indiquer un status": "Veuillez indiquer un status - AR",
            "Veuillez indiquer un pilote": "Veuillez indiquer un pilote - AR",
            "Veuillez indiquer une date": "Veuillez indiquer une date - AR",
            "Action correctement supprimée.": "Action correctement supprimée. - AR",
            "Une erreur est survenue lors de la récupération de l'action": "Une erreur est survenue lors de la récupération de l'action - AR",
            "Erreur durant la redirection": "Erreur durant la redirection - AR",
            "Authentification réussi.": "Authentification réussi. - AR",
            "Impossible de récupérer les informations du compte ou de l'utilisateur": "Impossible de récupérer les informations du compte ou de l'utilisateur - AR",
            "Veuillez sélectionnez les données sur lesquelles créer une action": "Veuillez sélectionnez les données sur lesquelles créer une action - AR",
            "L'export a été initié, vous serez notifié lorsqu'il sera disponible.": "L'export a été initié, vous serez notifié lorsqu'il sera disponible. - AR",
            "Périmètre de recherche trop important, veuillez le réduire.": "Périmètre de recherche trop important, veuillez le réduire. - AR",
            "Enregistrement effectuée": "Enregistrement effectuée - AR",
            "Recherche supprimée.": "Recherche supprimée. - AR",
            "Une erreur est apparue.": "Une erreur est apparue. - AR",
            "Impossible de récupérer le commentaire": "Impossible de récupérer le commentaire - AR",
            "Une erreur est survenue lors de la récupération de la question": "Une erreur est survenue lors de la récupération de la question - AR",
            "Traitement effectué": "Traitement effectué - AR",
            "Fiche affectée.": "Fiche affectée. - AR",
            "Retirer des favoris": "Retirer des favoris - AR",
            "Ajouter aux favoris": "Ajouter aux favoris - AR",
            "Une erreur est survenue lors de la suppression des favoris": "Une erreur est survenue lors de la suppression des favoris - AR",
            "Une erreur est survenue lors de l'ajout aux favoris": "Une erreur est survenue lors de l'ajout aux favoris - AR",
            "Une erreur est survenue lors du téléversement": "Une erreur est survenue lors du téléversement - AR",
            "Voir l'exigence": "Voir l'exigence - AR",
            "Voir la fiche": "Voir la fiche - AR",
            "Liste des exigences": "Liste des exigences - AR",
            "Contrôles et documents obligatoires": "Contrôles et documents obligatoires - AR",
            "Télécharger le PDF": "Télécharger le PDF - AR",
            "Liste de documents": "Liste de documents - AR",
            "Accueil espace documentaire": "Accueil espace documentaire - AR",
            "Actualité documentaire": "Actualité documentaire - AR",
            "Formulaires et déclaration": "Formulaires et déclaration - AR",
            "Jurisprudences": "Jurisprudences - AR",
            "Dossiers et fiches thématiques": "Dossiers et fiches thématiques - AR",
            "Contrôles réglementaires": "Contrôles réglementaires - AR",
            "Projet de texte": "Projet de texte - AR",
            "Flash d'information": "Flash d'information - AR",
            "Newsletter": "Newsletter - AR",
            "Document privé": "Document privé - AR",
            "Visualiser": "Visualiser - AR",
            "S'affecter": "S'affecter - AR",
            "Recherche de texte": "Recherche de texte - AR",
            "Point de veille expiré ou désactivé": "Point de veille expiré ou désactivé - AR",
            "Modifier": "Modifier - AR",
            "Désactiver": "Désactiver - AR",
            "Relancer l'invitation": "Relancer l'invitation - AR",
            "Liste des utilisateurs": "Liste des utilisateurs - AR",
            "Ajouter une sous-catégorie": "Ajouter une sous-catégorie - AR",
            "Gestion des catégories et sous-catégories": "Gestion des catégories et sous-catégories - AR",
            "Voir l'utilisateur": "Voir l'utilisateur - AR",
            "Editer": "Editer - AR",
            "Liste des actions": "Liste des actions - AR",
            "Configuration de l'action": "Configuration de l'action - AR",
            "Voir/Editer les commentaires": "Voir/Editer les commentaires - AR",
            "Affecter la fiche à d'autres points de veille.": "Affecter la fiche à d'autres points de veille. - AR",
            "Consulter le texte": "Consulter le texte - AR",
            "Traiter la fiche en multi point de veille": "Traiter la fiche en multi point de veille - AR",
            "Traiter la fiche": "Traiter la fiche - AR",
            "Tableau de bord / Statistiques": "Tableau de bord / Statistiques - AR",
            "Planifié": "Planifié - AR",
            "Réalisé": "Réalisé - AR",
            "Evalué": "Evalué - AR",
            "Clôturé": "Clôturé - AR",
            "Pour information": "Pour information - AR",
            "Non applicable": "Non applicable - AR",
            "Modifier la question": "Modifier la question - AR",
            "Consulter la question": "Consulter la question - AR",
            "Liste hotline": "Liste hotline - AR",
            "Retour à l'accueil": "Retour à l'accueil - AR",
            "Aide": "Aide - AR",
            "OneApave": "OneApave - AR",
            "Export de fiches affectées": "Export de fiches affectées - AR",
            "Export hotline": "Export hotline - AR",
            "Export recherche avancée": "Export recherche avancée - AR",
            "Export tableau de bord": "Export tableau de bord - AR",
            "Exports d'historique de connexions": "Exports d'historique de connexions - AR",
            "Fond documentaire": "Fond documentaire - AR",
            "Détail": "Détail - AR",
            "Accès refusé": "Accès refusé - AR",
            "Conditions générales d'utilisation": "Conditions générales d'utilisation - AR",
            "Erreur": "Erreur - AR",
            "Afficher/cacher le mot de passe": "Afficher/cacher le mot de passe - AR",
            "Vider le champ": "Vider le champ - AR",
            "Première page": "Première page - AR",
            "Page précédente": "Page précédente - AR",
            "Page suivante": "Page suivante - AR",
            "Dernière page": "Dernière page - AR",
            "Fermer": "Fermer - AR",
            "Déplier / replier": "Déplier / replier - AR",
            "Tout sélectionner": "Tout sélectionner - AR",
            "Tout désélectionner": "Tout désélectionner - AR",
            "conditions générales d'utilisations": "conditions générales d'utilisations - AR",
            "Annuler": "Annuler - AR",
            "Voir tous les documents": "Voir tous les documents - AR",
            "Configurer la réception des alertes": "Configurer la réception des alertes - AR",
            "Assigner le point de veille": "Assigner le point de veille - AR",
            "Vérifier": "Vérifier - AR",
            "Retour à l'arborescence": "Retour à l'arborescence - AR",
            "Se re-connecter": "Se re-connecter - AR",
            "Retour à la veille reglementaire": "Retour à la veille reglementaire - AR",
            "toggleFilter(!filterShow)}>Recherche": "toggleFilter(!filterShow)}>Recherche - AR",
            "Configuration": "Configuration - AR",
            "Changer de compte": "Changer de compte - AR",
            "Se déconnecter": "Se déconnecter - AR",
            "One Apave": "One Apave - AR",
            "Je souhaite avoir des renseignements ou bénéficier d’une démo": "Je souhaite avoir des renseignements ou bénéficier d’une démo - AR",
            "Configuration d'utilisateur": "Configuration d'utilisateur - AR",
            "Tableau de bord / statistiques": "Tableau de bord / statistiques - AR",
            "Déconnexion": "Déconnexion - AR",
            "Utilisateurs": "Utilisateurs - AR",
            "Nouvel utilisateur": "Nouvel utilisateur - AR",
            "Modification utilisateur": "Modification utilisateur - AR",
            "Catégories": "Catégories - AR",
            "Création": "Création - AR",
            "Edition": "Edition - AR",
            "Historique": "Historique - AR",
            "Création d'une action": "Création d'une action - AR",
            "Modification d'une action": "Modification d'une action - AR",
            "Edition fiche privée": "Edition fiche privée - AR",
            "Conformité d'une fiche": "Conformité d'une fiche - AR",
            "Edition hotline": "Edition hotline - AR",
            "Jurisprudence": "Jurisprudence - AR",
            "Module spécifique client": "Module spécifique client - AR",
            "Formulaire / déclaration (CERFA et notice)": "Formulaire / déclaration (CERFA et notice) - AR",
            "Contrôle règlementaire": "Contrôle règlementaire - AR",
            "Organigramme règlementaire": "Organigramme règlementaire - AR",
            "Sanction": "Sanction - AR",
            "Sélection du compte": "Sélection du compte - AR",
            "Validation d'un utilisateur": "Validation d'un utilisateur - AR",
            "Maintenance": "Maintenance - AR",
            "Séparé : Une fiche par mail": "Séparé : Une fiche par mail - AR",
            "Groupé : Fiches groupées dans un mail": "Groupé : Fiches groupées dans un mail - AR",
            "Alerte lorsque la fiche est finalisée": "Alerte lorsque la fiche est finalisée - AR",
            "Alerte dès que la fiche est affectée": "Alerte dès que la fiche est affectée - AR",
            "Quotidienne": "Quotidienne - AR",
            "Quotidienne + Récapitulatif mensuel": "Quotidienne + Récapitulatif mensuel - AR",
            "Hebdomadaire": "Hebdomadaire - AR",
            "Hebdomadaire + Récapitulatif mensuel": "Hebdomadaire + Récapitulatif mensuel - AR",
            "Bimensuelle": "Bimensuelle - AR",
            "Mensuelle": "Mensuelle - AR",
            "Vous avez des modifications non enregistrées, voulez-vous vraiment quitter ?": "Vous avez des modifications non enregistrées, voulez-vous vraiment quitter ? - AR",
            "Export des exigences affectées": "Export des exigences affectées - AR",
            "A statuer": "A statuer - AR",
            "A traiter": "A traiter - AR",
            "Finalisée": "Finalisée - AR",
            "Archivée": "Archivée - AR",
            "Fiches liées": "Fiches liées - AR",
            "Activer masquage": "Activer masquage - AR",
            "Désactiver masquage": "Désactiver masquage - AR",
            "Modifier la conformité": "Modifier la conformité - AR",
            "Modifier la priorité": "Modifier la priorité - AR",
            "Modifier la date de revue": "Modifier la date de revue - AR",
            "Modifier la date d'évaluation": "Modifier la date d'évaluation - AR",
            "Créer une action": "Créer une action - AR",
            "Commentaire client": "Commentaire client - AR",
            "Monsieur": "Monsieur - AR",
            "Madame": "Madame - AR",
            "Administrateur client": "Administrateur client - AR",
            "Utilisateur simple": "Utilisateur simple - AR",
            "Configuration des alertes": "Configuration des alertes - AR",
            "Simple": "Simple - AR",
            "Avec exigences": "Avec exigences - AR",
            "Suivi": "Suivi - AR",
            "Commentaires": "Commentaires - AR",
            "Avancement plan d'action": "Avancement plan d'action - AR",
            "Global": "Global - AR",
            "Domaine": "Domaine - AR",
            "Sous-domaine": "Sous-domaine - AR",
            "Thème": "Thème - AR",
            "Trimestriel": "Trimestriel - AR",
            "Annuel": "Annuel - AR",
            "Ne pas modifier": "Ne pas modifier - AR",
            "Aucune": "Aucune - AR",
            "P1": "P1 - AR",
            "P2": "P2 - AR",
            "P3": "P3 - AR",
            "P4": "P4 - AR",
            "Construction / Immobilier / Habitat": "Construction / Immobilier / Habitat - AR",
            "Distribution / Commerce": "Distribution / Commerce - AR",
            "Transport / Logistique": "Transport / Logistique - AR",
            "Gouvernement / administration publiques / Enseignement": "Gouvernement / administration publiques / Enseignement - AR",
            "Etablissements social et santé": "Etablissements social et santé - AR",
            "Energie / environnement / eau / Facility managment": "Energie / environnement / eau / Facility managment - AR",
            "Hotellerie / Loisirs": "Hotellerie / Loisirs - AR",
            "Services : Banque Assurances &amp; services / Autres services": "Services : Banque Assurances &amp; services / Autres services - AR",
            "Télécom": "Télécom - AR",
            "Aérospatial / armement": "Aérospatial / armement - AR",
            "Industrie Transport & équipementiers (pneu, ...)": "Industrie Transport & équipementiers (pneu, ...) - AR",
            "Pharmacie / Chimie / Phyto / Agroalimentaire": "Pharmacie / Chimie / Phyto / Agroalimentaire - AR",
            "Pétrole et gas (raffinerie / extraction / distribution / stockage)": "Pétrole et gas (raffinerie / extraction / distribution / stockage) - AR",
            "Nucléaire (filière Areva, IRSN, CEA, CNPE, ...)": "Nucléaire (filière Areva, IRSN, CEA, CNPE, ...) - AR",
            "Industries (Equipement, électrique, extractives)": "Industries (Equipement, électrique, extractives) - AR",
            "Autres (dont agriculture, particuliers, sans NAF, ...)": "Autres (dont agriculture, particuliers, sans NAF, ...) - AR",
            "Choix 3": "Choix 3 - AR",
            "Choix 2": "Choix 2 - AR",
            "Choix 1": "Choix 1 - AR",
            "Par exigence": "Par exigence - AR",
            "Numéro de la fiche": "Numéro de la fiche - AR",
            "Titre de la fiche": "Titre de la fiche - AR",
            "Domaines / sous domaines / thèmes": "Domaines / sous domaines / thèmes - AR",
            "Date de parution": "Date de parution - AR",
            "Nom / Prénom": "Nom / Prénom - AR",
            "Date de dernière connexion": "Date de dernière connexion - AR",
            "Invitation": "Invitation - AR",
            "Affecté à des points de veille": "Affecté à des points de veille - AR",
            "Prénom / Nom": "Prénom / Nom - AR",
            "Droit d’accès sur point de veille": "Droit d’accès sur point de veille - AR",
            "Paramétrage de l’accès": "Paramétrage de l’accès - AR",
            "Accès à la fiche utilisateur": "Accès à la fiche utilisateur - AR",
            "Droit d’accès sur le noeud": "Droit d’accès sur le noeud - AR",
            "Libellé d'action": "Libellé d'action - AR",
            "Coût": "Coût - AR",
            "Pièces jointes": "Pièces jointes - AR",
            "Statut de la conformité": "Statut de la conformité - AR",
            "Nombre d'actions": "Nombre d'actions - AR",
            "Date de la question": "Date de la question - AR",
            "Date de la réponse": "Date de la réponse - AR",
            "Domaines / sous domaines": "Domaines / sous domaines - AR",
            "Type d'action": "Type d'action - AR",
        }
    }
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: 'fr_FR',
    debug: false,
    interpolation: {
        escapeValue: false, // not needed for react as it escapes by default
    }
});

export default i18n;