export const FR = 'fr';
export const EN = 'en';
export const AR = 'ar';
export const ES = 'es';

export const LANGUAGES = {
    [FR]: "Français",
    [EN]: "English",
    [AR]: "عرب",
    [ES]: "Spanish",
}

export function getLanguageLabel(language) {
    return LANGUAGES[language];
}

export function getLanguages() {
    let languages = [];

    for (const [key, value] of Object.entries(LANGUAGES)) {
        languages.push({
            value: key, 
            label: value
        });
    }

    return languages;
};
