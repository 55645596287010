import { React, DefaultLayout, Modal, Link, useEffect, useState, useContext } from 'commons';
import { useTranslation } from 'react-i18next';
import AutoaffectationForm from 'partials/AutoaffectationForm';
import { useAccount } from 'hooks/Account';
import { useAccess } from 'hooks/Access';
import { useCountryPartitioning } from 'hooks/CountryPartitioning';
import { useList, usePosition } from 'shared/hooks';
import { ReferentialContext } from 'shared/stores';
import { SheetApi, SheetAffectedApi } from 'shared/api';
import { ListComponent, FilterComponent, TreeReferentialComponent, HtmlComponent } from 'shared/components';
import { REFERENTIALS, getDegree } from 'shared/data';
import { AFFECTATION_STATUS } from 'data/SheetAffected';
import { canPerform, denyIfCantPerform, preventDefault, smallModalCustomStyles, referentialsToOptions, injectCountryLevelOnDomainTree, toggleArrayValue, prefixLinkTo } from 'shared/services';

Modal.setAppElement('#root');

export default function SheetList(props) {
   
    const { t } = useTranslation();
    const [initializePosition, setPosition] = usePosition("sheet-list");
    const [countries] = useCountryPartitioning();
    const [account] = useAccount();

    const columns = [
        {id: 'id', title: t("Numéro de la fiche"), render: (row) => <>
            {countries.length > 1 && row.country && row.country.picture && row.country.picture.image && <>
                <img src={`data:image/jpeg;base64,${row.country.picture.image}`} height="12" title={row.country.value} alt={`Drapeau ${row.country.value}`} />&nbsp;
            </>}
            {row.id}        
        </>},
        {id: 'title', title: t("Titre"), render: (row) => <HtmlComponent>{row.title}</HtmlComponent>},
        {id: 'categories', title: t("Domaines / sous domaines / thèmes"), sortable: false, render: (row, params) => <TreeReferentialComponent className="arborescence" items={params.tree} value={row.categories.map(e => e.id)} />},
        {id: 'text_date', title: t("Date du texte"), format: 'date'},
        {id: 'publication_date', title: t("Date de parution"), format: 'date'},
        {id: 'text_number', title: t("Numéro de texte")},
        {id: 'text_types', title: t("Type de texte"), render: (row) => <ul>{row.text_types.map(text_type => <li key={text_type.id}>{text_type.value}</li>)}</ul>},        
        {id: 'actions', title: '', sortable: false, render: (row) => <ul className="actions">
            <li><Link title={t("Visualiser")} to={prefixLinkTo() + `/sheets/${row.id}`} onClick={(e) => redirectToSheet(e, row.id)}>
                <i className="icon-actions-consulter-fiche" aria-hidden="true"></i>
            </Link></li>
            {account.is_start === false && <li><Link title={t("S'affecter")} to={`/sheets/${row.id}`} onClick={(e) => autoAffectation(e, row)}>
                <i className="icon-actions-affecter" aria-hidden="true"></i>
            </Link></li>}
        </ul>}
    ];

    const [,, writableWatchsites, readableWatchsites] = useAccess();
    const [autoaffecting, setAutoaffecting] = useState(false);
    const [sheet, setSheet] = useState();
    const [referentialContext] = useContext(ReferentialContext);

    // referential
    const text_types = referentialsToOptions(referentialContext[REFERENTIALS.REFERENTIAL_TEXT_TYPE], countries);
    const sources = referentialsToOptions(referentialContext[REFERENTIALS.REFERENTIAL_SOURCE], countries);
    const transmitters = referentialsToOptions(referentialContext[REFERENTIALS.REFERENTIAL_TRANSMITTER], countries);
    const involved_actors = referentialsToOptions(referentialContext[REFERENTIALS.REFERENTIAL_INVOLVED_ACTOR], countries);
    const involved_functions = referentialsToOptions(referentialContext[REFERENTIALS.REFERENTIAL_INVOLVED_FUNCTION], countries);
    const application_areas = referentialsToOptions(referentialContext[REFERENTIALS.REFERENTIAL_APPLICATION_AREA], countries);
    const descriptors = referentialsToOptions(referentialContext[REFERENTIALS.REFERENTIAL_DESCRIPTORS], countries);

    const domainTree = injectCountryLevelOnDomainTree(referentialContext["tree"], countries);
    console.log({...domainTree});
    const userTree = referentialContext["writableAccountTree"];

    // access
    const hasTechnicalLevelAccess = canPerform("account:technical_level", {account: account});

    const [
        rows,
        totalrows,
        criterias,
        sorting,
        direction,
        limit,
        page,,,
        addCriteria,
        updateSorting,
        updateLimit,
        updatePage,
        submitSearch,
        loading,,
        refresh
    ] = useList("sheet-list", SheetApi, null, "id", {keyword_fields: ["title", "synthesis", "technical_comment"]});

    let filterType = {
        id: {type: "match", fields: ["id"]},
        text_date: {type: "range", fields: ["text_date"]},
        publication_date: {type: "range", fields: ["publication_date"]},
        text_types: {type: "terms", fields: ["text_types"]},
        text_number: {type: "wildcard", fields: ["text_number"]},
        source: {type: "terms", fields: ["source"]},
        transmitter: {type: "terms", fields: ["transmitter"]},
        nor: {type: "match", fields: ["nor"]},
        repealed_text: {type: "match", fields: ["repealed_text"], cast: "int"},
        major_text: {type: "match", fields: ["major_text"], cast: "int"},
        cut_into_requirements: {type: "match", fields: ["cut_into_requirements"], cast: "int"},
        descriptors: {type: "terms", fields: ["descriptors"]},
        keyword: {type: "wildcard", fields: criterias.keyword_fields},
        application_areas: {type: "terms", fields: ["application_areas"]},

        categories: {type: "terms", fields: ["categories"]},
    };

    if (countries.length > 1) {
        filterType.country = {type: "match", fields: ["country"]};
    }

    if (hasTechnicalLevelAccess) {
        filterType.corporal_impact_degree = {type: "terms", fields: ["corporal_impact_degree"]};
        filterType.legal_impact_degree = {type: "terms", fields: ["legal_impact_degree"]};
        filterType.media_impact_degree = {type: "terms", fields: ["media_impact_degree"]};
        filterType.financial_impact_degree = {type: "terms", fields: ["financial_impact_degree"]};
        filterType.involved_actors = {type: "terms", fields: ["involved_actors"]};
        filterType.involved_functions = {type: "terms", fields: ["involved_functions"]};
    }

    denyIfCantPerform(props, "sheet:list", {account: account, writableWatchsites: writableWatchsites, readableWatchsites: readableWatchsites});

    useEffect(() => {
        submitSearch(filterType, () => initializePosition(), false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function redirectToSheet(event, targetsheet) {
        event.preventDefault();

        SheetAffectedApi
            .getAffectations(targetsheet)
            .then(data => {
                const affectations = data.filter(sa => sa.status === AFFECTATION_STATUS.STATUS_SENT_BY_APAVE || sa.status === AFFECTATION_STATUS.STATUS_ADD_BY_CUSTOMER);
                if (affectations.length === 1) {
                    props.history.push(prefixLinkTo() + '/sheets-affected/' + affectations[0].id)
                } else {
                    props.history.push(prefixLinkTo() + '/sheets/' + targetsheet);
                }
            });
    }

    function autoAffectation(event, targetsheet) {
        event.preventDefault();
        if (account.is_start === false) {
            setSheet(targetsheet);
            setAutoaffecting(true);
        }
    }

    return (     
        <DefaultLayout onScroll={(position) => setPosition(position)} screen="E40" title={t("Recherche de texte")}>
            <h1>{t("Recherche de texte")}</h1>
            <div className="bloc">
                <form className="form" onSubmit={(e) => preventDefault(e, submitSearch(filterType))} >
                    <section className="filters">
                        <header>
                            <h2><button className="toggler" type="button">{t("Recherche")}</button></h2>
                        </header>
                        <div className="bg-gris-25">
                            <div className="row">
                                <FilterComponent label={t("Numéro")} name="id" onChange={value => addCriteria("id", value)} value={criterias.id} />
                                <FilterComponent label={t("Date du texte")} type="daterange" name="text_date" onChange={value => addCriteria("text_date", value)} value={criterias.text_date} />
                                <FilterComponent label={t("Date de publication")} type="daterange" name="publication_date" onChange={value => addCriteria("publication_date", value)} value={criterias.publication_date} />
                                <FilterComponent label={t("Type de texte")} type="select" multiple={true} name="text_types" onChange={value => addCriteria("text_types", value)} value={criterias.text_types} options={text_types} />
                                <FilterComponent label={t("Numéro de texte")} name="text_number" onChange={value => addCriteria("text_number", value)} value={criterias.text_number} />
                                <FilterComponent label={t("Source")} type="select" name="source" multiple={true} onChange={value => addCriteria("source", value)} value={criterias.source} options={sources} />
                                <FilterComponent label={t("Émetteur")} type="select" name="transmitter" multiple={true} onChange={value => addCriteria("transmitter", value)} value={criterias.transmitter} options={transmitters} />
                                <FilterComponent label={t("Numéro NOR")} name="nor" onChange={value => addCriteria("nor", value)} value={criterias.nor} />
                                <FilterComponent label={t("Texte abrogé")} type="radio" name="repealed_text" onChange={value => addCriteria("repealed_text", value)} value={criterias.repealed_text} blankLabel="Tous" options={[{value: "1", label: t("Oui")}, {value: "0", label: t("Non")}]} />
                                <FilterComponent label={t("Texte majeur")} type="radio" name="major_text" onChange={value => addCriteria("major_text", value)} value={criterias.major_text} blankLabel="Tous" options={[{value: "1", label: t("Oui")}, {value: "0", label: t("Non")}]} />
                                <FilterComponent label={t("Découpé en exigences")} type="radio" name="cut_into_requirements" onChange={value => addCriteria("cut_into_requirements", value)} value={criterias.cut_into_requirements} blankLabel="Tous" options={[{value: "1", label: t("Oui")}, {value: "0", label: t("Non")}]} />
                                <FilterComponent label={t("Descripteurs")} type="select" name="descriptors" multiple={true} onChange={value => addCriteria("descriptors", value)} value={criterias.descriptors} options={descriptors} />
                                {countries.length > 1 && <FilterComponent label={t("Pays")} type="select" multiple={false} name="country" onChange={value => addCriteria("country", value)} value={criterias.country} options={referentialsToOptions(countries)} />}
                                {hasTechnicalLevelAccess && <>
                                    <FilterComponent label={t("Degrés d'impact du texte corporel")} type="degree-checkbox" name="corporal_impact_degree" onChange={value => addCriteria("corporal_impact_degree", value)} value={criterias.corporal_impact_degree} options={getDegree()} />
                                    <FilterComponent label={t("Degrés d'impact du texte juridique")} type="degree-checkbox" name="legal_impact_degree" onChange={value => addCriteria("legal_impact_degree", value)} value={criterias.legal_impact_degree} options={getDegree()} />
                                    <FilterComponent label={t("Degrés d'impact du texte médiatique")} type="degree-checkbox" name="media_impact_degree" onChange={value => addCriteria("media_impact_degree", value)} value={criterias.media_impact_degree} options={getDegree()} />
                                    <FilterComponent label={t("Degrés d'impact du texte financier")} type="degree-checkbox" name="financial_impact_degree" onChange={value => addCriteria("financial_impact_degree", value)} value={criterias.financial_impact_degree} options={getDegree()} />
                                </>}
                                <FilterComponent label={t("Domaines / Sous domaines / Thèmes")} type="dropdown-tree-select" name="categories" onChange={value => addCriteria("categories", value)} value={criterias.categories} data={domainTree} mode="hierarchical" />
                                {hasTechnicalLevelAccess && <>
                                    <FilterComponent label={t("Acteurs concernés")} type="select" name="involved_actors" multiple={true} onChange={value => addCriteria("involved_actors", value)} value={criterias.involved_actors} options={involved_actors} />
                                    <FilterComponent label={t("Fonctions concernées")} type="select" name="involved_functions" multiple={true} onChange={value => addCriteria("involved_functions", value)} value={criterias.involved_functions} options={involved_functions} />
                                </>}
                                <FilterComponent 
                                    type="keyword"
                                    name="keyword"
                                    label={t("Recherche libre")} 
                                    fields={[
                                        {value: 'title', label: 'Titre'},
                                        {value: 'synthesis', label: 'Synthèse'},
                                        {value: 'technical_comment', label: 'Commentaire'},
                                        {value: 'reglementary_text', label: 'Texte réglementaire'},                                        
                                        {value: 'attachments', label: 'Documents associés'},
                                    ]}
                                    selectedFields={criterias.keyword_fields}
                                    onChange={value => addCriteria("keyword", value)}
                                    onFieldChange={value => addCriteria("keyword_fields", toggleArrayValue(criterias.keyword_fields, value))}
                                    value={criterias.keyword || ""} 
                                />
                                <FilterComponent label={t("Zone d'application géographique")} type="select" multiple={true} name="application_areas" onChange={value => addCriteria("application_areas", value)} value={criterias.application_areas} options={application_areas} />
                            </div>
                        </div>
                        <div className="bg-gris-25 border-b border-gris-60">
                            <div className="row">
                                <div className="col-md-9">
                                    <button id="clearfilter-sheet" onClick={() => refresh(true)} type="button" className="btn btn-bleu-4 icon"><i className="icon-filtres-poubelle" aria-hidden="true"></i>Réinitialiser la recherche</button>
                                </div>
                                <div className="col-md-3 text-right">
                                    <button id="search-sheet" type="submit" className="btn btn-primary">{t("Rechercher")}</button>
                                </div>
                            </div>
                        </div>
                    </section>
                    
                    <ListComponent 
                        id="sheet"
                        loading={loading}
                        selectable={false}
                        rows={rows}
                        columns={columns}
                        sorting={sorting}
                        direction={direction}
                        onSortingChange={updateSorting}
                        perpage={limit}
                        onPerpageChange={updateLimit}
                        page={page}
                        onPageChange={updatePage}
                        totalrows={totalrows}
                        columnsRenderParams={{
                            tree: domainTree
                        }}
                    />
                </form>
            </div>

            {account.is_start === false &&
                <Modal isOpen={autoaffecting} onRequestClose={() => setAutoaffecting(false)} style={smallModalCustomStyles}>
                    <AutoaffectationForm
                        sheet={sheet}
                        userTree={userTree}
                        onRequestClose={() => setAutoaffecting(false)}
                    />
                </Modal>
            }
        </DefaultLayout>
    );
}