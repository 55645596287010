import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import _ from 'lodash';
import axios from 'axios';
import queryString from 'query-string';
import { toast } from 'react-toastify';
import BarLoader from "react-spinners/BarLoader";
import logoapave from "images/logoapave.svg";
import logopilotveille from "images/logopilotveille.png";
import SecurityApi from 'shared/api/Security';
import AccountApi from 'shared/api/Account';
import AccessApi from 'shared/api/Access';
import { useSecurity } from 'shared/hooks/Security';
import { useAccess } from 'hooks/Access';
import { useAccount } from 'hooks/Account';

export default function AdslLogin(props) {

    const { t } = useTranslation();
    const location = useLocation();
    const [,, setAccount, setNbAccounts] = useAccount();
    const [,, setUser] = useSecurity();
    const [,,,, setWritableWatchsites, setReadableWatchsites] = useAccess();

    useEffect(() => {
        const qs = queryString.parse(location.search);
        SecurityApi
            .adslLogin({
                "email": qs.SSO_UTI,                
                "date": qs.SSO_DATE,
                "hash": qs.SSO_CERT,
                "account": qs.SOCIETY,
            })
            .then(() => {
                axios
                    .all([
                        SecurityApi.getCurrentUser(),
                        SecurityApi.getCurrentAccount(),
                        AccessApi.listWritableWatchsites(),
                        AccessApi.listReadableWatchsites(),
                        AccountApi.list()
                    ])
                    .then(
                        axios.spread((currentUser, currentAccount, [writableWatchsites], [readableWatchsites], [,, nbAccounts]) => {
                            setUser(currentUser.data);                            
                            setAccount(currentAccount.data);
                            setNbAccounts(nbAccounts);
                            setWritableWatchsites(writableWatchsites.map(w => w.id));
                            setReadableWatchsites(readableWatchsites.map(w => w.id));
                        })
                    )
                    .then(() => {
                        toast.success(t("Authentification réussi."));
                        _.delay(() => document.location.href = "/", 500);
                    })
                    .catch(error => {
                        toast.error(t("Impossible de récupérer les informations du compte ou de l'utilisateur"));
                    });
            })
            .catch(error => {
                toast.error(error.response.data.message);
            });        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <section className="login">
            <form className="form">
                <h1>
                    <div className="main-logo">
                        <img src={logoapave} width="96" alt="Logo Apave" />
                    </div>
                    <div className="main-name">
                        <img src={logopilotveille} alt="Logo Pilot Veille" />
                    </div>
                </h1>
                <div className="bloc">
                    <section>
                        <header>
                            <h2>Connexion en cours</h2>
                        </header>
                        <div className="bg-blanc border-rlb border-gris-40">
                            <BarLoader loading={true} width={100} color="#5bad27" css="display:block;margin:10px auto;" />
                        </div>
                    </section>
                </div>
            </form>
        </section>    
    )
}