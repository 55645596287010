import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import DOMPurify from 'dompurify';
import ReactTooltip from 'react-tooltip';
import { PieChart } from 'react-minimal-pie-chart';
import { Link } from "react-router-dom";
import TreeUtils from 'shared/services/TreeUtils';
import { formatDate, isExpired } from "shared/services/Utils";
import { prefixLinkTo } from 'shared/services';
import { SheetAffectedApi } from 'shared/api';
import { CONFORMITY_MODE_GLOBAL, CONFORMITY_MODE_REQUIREMENT, getConformityModeLabel } from 'data/SheetAffected';
import FavoriteButtonComponent from 'components/FavoriteButtonComponent';
import { toast } from 'react-toastify';

export default function SheetAffectedListItemWatchsite(props) {

    const { t } = useTranslation();
    const { hasPrivateSheetWriteAccess, hasActionAccess, hasCustomerCommentAccess, hasComplianceManagementAccess, accountTree, sheet, item, selection, updateSelection } = props;
    const [toggled, setToggled] = useState(true);
    const [fullComment, setFullComment] = useState(null);
    const watchsite = TreeUtils.findWatchsite(accountTree, item.watchsite_id);
    const comment = item.last_comment ? item.last_comment : "";

    function toggleComment() {
        if (fullComment === null) {
            SheetAffectedApi
                .getLastComment(item.id)
                .then(responseData => {
                    setFullComment(responseData.last_comment);
                    setToggled(!toggled);
                })
                .catch(() => toast.warning(t("Impossible de récupérer le commentaire")))
        } else {
            setToggled(!toggled);
        }        
    }

    return (
        <div id={`sheet-affected-${item.id}`} className="d-flex border-t border-gris-25 toggle-group">
            {props.displaySelection && 
                <div className="w-34px cell-padding text-center border-r border-gris-25">
                    <input 
                        type="checkbox"
                        checked={_.findIndex(selection, (o) => o.sheetaffected === item.id) > -1}
                        onChange={(e) => updateSelection(sheet.id, sheet.country.options.is_france, [{sheetaffected: item.id, watchsite: item.watchsite_id}], e.target.checked)}
                        id={`veille-${item.id}`} 
                    />
                    <label htmlFor={`veille-${item.id}`}></label>
                </div>
            }
            <div className="w-auto cell-padding">
                <p className="color-vert-apave big"><strong>
                    <Link to={prefixLinkTo() + `/sheets-affected/${item.id}`} title={t("Traiter la fiche")}>{watchsite ? watchsite.label : ""}</Link>
                </strong></p>
                {hasCustomerCommentAccess && comment && <>
                    <p className="small color-gris-50">Dernier commentaire</p>
                    {comment && <div>
                        {toggled && <span dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(comment)}} />}
                        {!toggled && <div dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(fullComment)}} />}
                        <button onClick={toggleComment} className={"toggler color-vert-apave" + (toggled ? "" : " actif")} type="button" />
                    </div>}
                </>}
            </div>
            {hasComplianceManagementAccess && <>
                <div id={`sheet-affected-${item.id}-conformity-info`} className="w-392px cell-padding">
                    <ul className="no-style small">
                        <li><span className="color-gris-50">Mode de gestion&nbsp;:</span> <strong>{getConformityModeLabel(item.conformity_mode)}</strong></li>
                        {item.conformity_mode === CONFORMITY_MODE_REQUIREMENT && <li>
                            <span className="color-gris-50">Nombre d'exigence(s) à traiter : </span>
                            <strong>
                                Nouveau <span className="color-conformity-new">{item.nb_requirements_new}</span>,
                                A préciser <span className="color-conformity-tospecified">{item.nb_requirements_tospecified}</span>,
                                En écart <span className="color-conformity-nok">{item.nb_requirements_nok}</span> sur {item.nb_requirements}
                            </strong>
                        </li>}
                        {item.review_date && <li><span className="color-gris-50">Date de revue&nbsp;:</span> <strong className={isExpired(new Date(item.review_date)) ? "color-rouge" : null}>{formatDate(item.review_date)}</strong></li>}
                        {item.evaluation_date && <li><span className="color-gris-50">Date d’évaluation&nbsp;:</span> <strong>{formatDate(item.evaluation_date)}</strong></li>}
                        {item.priority > 0 && <li><span className="color-gris-50">Priorité&nbsp;:</span> <strong>P{item.priority}</strong></li>}
                    </ul>
                </div>
                <div id={`sheet-affected-${item.id}-conformity-chart`} className="w-50px cell-padding" data-tip data-for={`chart-sheetaffected-${item.id}`}>
                    {item.conformity_mode === CONFORMITY_MODE_REQUIREMENT && <PieChart
                        data={[
                            { title: t("Nouveau"), value: item.nb_requirements_new, color: '#45BFBF' },
                            { title: t("A préciser"), value: item.nb_requirements_tospecified, color: '#FFB607' },
                            { title: t("En écart"), value: item.nb_requirements_nok, color: '#F52824' },
                            { title: t("Conforme"), value: item.nb_requirements_ok, color: '#A9D009' },
                            { title: t("Pour information"), value: item.nb_requirements_info, color: '#DDDDDD' },
                            { title: t("Non applicable"), value: item.nb_requirements_na, color: '#BDB7B1' },
                        ]}
                        style={{ height: '38px', width: '38px' }}
                    />}
                    {item.conformity_mode === CONFORMITY_MODE_GLOBAL && <PieChart
                        data={[
                            { title: t("Nouveau"), value: (parseInt(item.conformity) === 0 ? 1 : 0), color: '#45BFBF' },
                            { title: t("A préciser"), value: (parseInt(item.conformity) === 3 ? 1 : 0), color: '#FFB607' },
                            { title: t("En écart"), value: (parseInt(item.conformity) === 4 ? 1 : 0), color: '#F52824' },
                            { title: t("Conforme"), value: (parseInt(item.conformity) === 5 ? 1 : 0), color: '#A9D009' },
                            { title: t("Pour information"), value: (parseInt(item.conformity) === 1 ? 1 : 0), color: '#DDDDDD' },
                            { title: t("Non applicable"), value: (parseInt(item.conformity) === 2 ? 1 : 0), color: '#BDB7B1' },
                        ]}
                        style={{ height: '38px', width: '38px' }}
                    />}
                </div>
                <ReactTooltip id={`chart-sheetaffected-${item.id}`} place="left" type="dark" effect="float">
                    {item.conformity_mode === CONFORMITY_MODE_REQUIREMENT && <>
                        <strong>Détail des exigences</strong>
                        <ul>
                            <li>Nouveau <span className="color-conformity-new">{(item.nb_requirements_new / item.nb_requirements * 100).toFixed(2)}% ({item.nb_requirements_new})</span></li>
                            <li>A préciser <span className="color-conformity-tospecified">{(item.nb_requirements_tospecified / item.nb_requirements * 100).toFixed(2)}% ({item.nb_requirements_tospecified})</span></li>
                            <li>En écart <span className="color-conformity-nok">{(item.nb_requirements_nok / item.nb_requirements * 100).toFixed(2)}% ({item.nb_requirements_nok})</span></li>
                            <li>Conforme <span className="color-conformity-ok">{(item.nb_requirements_ok / item.nb_requirements * 100).toFixed(2)}% ({item.nb_requirements_ok})</span></li>
                            <li>Pour information <span className="color-conformity-info">{(item.nb_requirements_info / item.nb_requirements * 100).toFixed(2)}% ({item.nb_requirements_info})</span></li>
                            <li>Non applicable <span className="color-conformity-na">{(item.nb_requirements_na / item.nb_requirements * 100).toFixed(2)}% ({item.nb_requirements_na})</span></li>
                        </ul>
                    </>}
                    {item.conformity_mode === CONFORMITY_MODE_GLOBAL && <>
                        <strong>Conformité de la fiche</strong>
                        <ul>
                            {parseInt(item.conformity) === 0 && <li>Nouveau <span className="color-conformity-new">100% ({item.nb_requirements_new})</span></li>}
                            {parseInt(item.conformity) === 3 && <li>A préciser <span className="color-conformity-tospecified">100% ({item.nb_requirements_nok})</span></li>}
                            {parseInt(item.conformity) === 4 && <li>En écart <span className="color-conformity-nok">100% ({item.nb_requirements_nok})</span></li>}
                            {parseInt(item.conformity) === 5 && <li>Conforme <span className="color-conformity-ok">100% ({item.nb_requirements_nok})</span></li>}
                            {parseInt(item.conformity) === 1 && <li>Pour information <span className="color-conformity-info">100% ({item.nb_requirements_nok})</span></li>}
                            {parseInt(item.conformity) === 2 && <li>Non applicable <span className="color-conformity-na">100% ({item.nb_requirements_tospecified})</span></li>}
                        </ul>
                    </>}
                </ReactTooltip>
            </>}
            <div className="w-34px">
                <ul className="actions">
                    <li>
                        <Link to={prefixLinkTo() + `/sheets-affected/${item.id}`} title={t("Traiter la fiche")} target="_blank">
                            <i className="icon-actions-consulter-fiche" aria-hidden="true"></i>                            
                        </Link>
                    </li>
                    {hasActionAccess && <li>
                        <Link to={prefixLinkTo() + `/actions?sheet=${sheet.id}&watchsite=${item.watchsite_id}`} title={`Voir les actions (${item.nb_actions || 0})`}>
                            <i className="icon-actions-plan-action" aria-hidden="true"></i>
                            <span className="counter">{item.nb_actions || 0}</span>
                        </Link>
                    </li>}
                    {sheet.is_private === true && hasPrivateSheetWriteAccess && <li>
                        <Link to={`/sheets/private/${sheet.id}`} title={`Modifier la fiche privative (${sheet.id})`}>
                            <i className="icon-actions-modifier" aria-hidden="true"></i>
                        </Link>
                    </li>}
                    <li>
                        <FavoriteButtonComponent item={item.id} type="sheetaffected" isFavorite={item.favorite} />
                    </li>
                </ul>
            </div>
        </div>
    );
}