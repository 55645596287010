import ApiRequest from 'shared/services/ApiRequest';

const PasswordApi = {

    initPassword: function(hash, password, confirmation) {
        return ApiRequest
            .post("password", {hash: hash, password: password, confirmation: confirmation})
            .then(res => res.data);
    },

    requestNewPassword: function(email) {
        return ApiRequest
            .post("password/request", {email: email})
            .then(res => res.data);
    },

    validRequest: function(hash) {
        return ApiRequest
            .get("password/request/" + hash)
            .then(res => res.data);
    },
}

export default PasswordApi;