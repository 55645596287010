import React from 'react';
import { useTranslation } from 'react-i18next';
import DefaultLayout from "layouts/DefaultLayout";

export default function Denied() {

    const { t } = useTranslation();

    return (
        <DefaultLayout title={t("Accès refusé")}>
            <h1>{t("Accès refusé")}</h1>
            <p>Vous n'avez pas accès à cette ressource.</p>
        </DefaultLayout>
    );
}
