import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { prefixLinkTo } from 'shared/services';

export default function TreeWatchsiteComponent(props) {

    const { t } = useTranslation();
    const startdate = new Date(props.item.subscription_begin);
    const enddate = new Date(props.item.subscription_end);
    
    const is_active = props.item.active === 1 && startdate < new Date() && enddate > new Date();
    
    const className = is_active ? "" : "color-rouge";

    return (
        <li id={`watchsite-${props.item.id}`}>
            <label htmlFor={`watchsite-${props.item.id}-selection`} className={className}>
                <Link id={`watchsite-${props.item.id}-link`} to={prefixLinkTo() + `/tree/watchsites/${props.item.id}`}>
                    {props.item.label}
                </Link>
                {!is_active && <i className="icon-autres-temps-depasse" title={t("Point de veille expiré ou désactivé")}></i>}
                {is_active && <Link id={`watchsite-${props.item.id}-watch`} to={prefixLinkTo() + `/sheets-affected/?watchsite=${props.item.id}`}><i aria-hidden="true" className="icon-nav-ma-veille" /></Link>}
                <Link id={`watchsite-${props.item.id}-link`} to={prefixLinkTo() + `/tree/watchsites/${props.item.id}`}><i aria-hidden="true" className="icon-actions-modifier" /></Link>
            </label>            
        </li>
    )
}