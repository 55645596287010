import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

export function useListBatch(batchs, api, selection, afterUpdate) {
    const { t } = useTranslation();
    const [batch, setBatch] = useState();
    const [value, setValue] = useState();
    const [running, setRunning] = useState(false);

    function getData() {
        const currentBatch = batchs[batch];
        const newValue = (currentBatch['fieldValue']) ? currentBatch['fieldValue'] : value;

        if (newValue) {
            return {[currentBatch.field]: newValue};
        }

        if (batchs[batch].error) {
            toast.error(batchs[batch].error);
        }

        return false;
    }

    function submit() {
        if (selection.length > 0) {
            const data = getData();
            if (data !== false) {
                setRunning(true);
                
                api.bulkUpdate(selection, data)
                    .then((message) => {
                        setRunning(false);
                        toast.success(message);
                        if (afterUpdate) {
                            afterUpdate();
                        }
                    })
                    .catch((error) => {
                        if (error.response.data.message) {
                            toast.error(error.response.data.message);
                        }
                        setRunning(false);
                    });
            }
        } else {
            toast.error(t("Aucune sélection"));
        }
    }

    return [batch, setBatch, value, setValue, submit, running];
}