import { React } from 'commons';
import { useTranslation } from 'react-i18next';
import DocumentarySpaceDetailRow from "views/documentaryspace/Detail/Row";

export default function DocumentarySpaceDetailCommonRows(props) {

    const { t } = useTranslation();
    const document = props.document;
    let domainLabel = "Domaine / Sous-domaine";
    let domain = document.domain.value + " / " + document.subdomain.value;
    if (document.theme) {
        domainLabel = domainLabel + " / Thème";
        domain = domain + " / " + document.theme.value;
    }

    return <>
        <DocumentarySpaceDetailRow label={t("Date")} value={document.date} type="date" />
        <DocumentarySpaceDetailRow
            label={domainLabel}
            value={domain} />
    </>;
}